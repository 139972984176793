import React from 'react'
import Image from '../../Image/loder.gif.gif'
import './FormLoader.scss'

function FormLoader() {
  return (
    <div className='FormLoader'>
      <img src={Image} alt='' />
    </div>
  )
}

export default FormLoader
