import React, { useState, useEffect } from 'react'
import './VirtualCards.scss'
import Logo from '../../../../Image/cardlogo.png'
import MasterLogo from '../../../../Image/masterlogo.png'
import { Link, useParams } from 'react-router-dom'
import Dashboard from './components/Dashboard'
import Request from './components/Request'
import RequestSudo from './components/RequestSudo'
import CardEdit from './components/EditCard'
import CardEditSudo from './components/EditCard_Sudo'
import API from '../../../../Context/API'
import DollarCard from './components/DollarCard'
import NairaCard from './components/NairaCard'

function VirtualCards({ notify, user }) {
  const { id } = useParams()
  const [loading, setLoading] = useState(true)
  const [sudoCardsList, setSudoCardsList] = useState([])
  useEffect(() => {
    API.FetchSudoCards()
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setSudoCardsList(
              data.card_data.filter((item) => item.currency === 'USD')
            )
            setLoading(false)
          })
        } else {
          res.json().then((data) => {})
        }
      })
      .catch(() => {})
  }, [])
  useEffect(() => {
    document.title = 'Spendwise | Virtual Card'
  }, [])

  const cardPage = () => {
    switch (id) {
      case 'dashboard':
        return (
          <Dashboard
            loading={loading}
            notify={notify}
            sudoCardsList={sudoCardsList}
          />
        )
      case 'usd-card':
        return (
          <DollarCard
            loading={loading}
            notify={notify}
            sudoCardsList={sudoCardsList}
          />
        )
      case 'ngn-card':
        return (
          <NairaCard
            loading={loading}
            notify={notify}
            sudoCardsList={sudoCardsList}
          />
        )
      case 'request':
        return <Request notify={notify} />
      case 'request-sudo':
        return <RequestSudo notify={notify} user={user} />
      case 'edit':
        return <CardEdit notify={notify} loading={loading} />
      case 'edits':
        return (
          <CardEditSudo
            notify={notify}
            cards={sudoCardsList}
            loading={loading}
          />
        )
      case 'usd-edits':
        return (
          <CardEditSudo
            notify={notify}
            cards={sudoCardsList}
            loading={loading}
          />
        )
      case 'ngn-edits':
        return (
          <CardEditSudo
            notify={notify}
            cards={sudoCardsList}
            loading={loading}
          />
        )
      case 'edits':
        return (
          <CardEditSudo
            notify={notify}
            cards={sudoCardsList}
            loading={loading}
          />
        )

      default:
        return <div>No page to display</div>
    }
  }
  const debitPage = () => {
    if (id === 'usd-card') {
      return 'Virtual Dollar Cards'
    } else if (id === 'ngn-card') {
      return 'Virtual Naira Cards'
    } else {
      return 'Debit Cards'
    }
  }
  return (
    <div className='virtual_cards'>
      <div className='virtual_cards_top'>
        <h2 className='virtual_cards-text'>{debitPage()}</h2>
      </div>
      <>{cardPage()}</>
    </div>
  )
}

export default VirtualCards
