import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { AppProvider } from './Context/Context'
// import * as Sentry from '@sentry/react'
// import { BrowserTracing } from '@sentry/tracing'
import reportWebVitals from './reportWebVitals'

// process.env.NODE_ENV === 'production' &&
//   Sentry.init({
//     dsn: 'https://b1936df3614a4be0ba8c6c82a02b929b@o965048.ingest.sentry.io/6467520',
//     integrations: [new BrowserTracing()],

//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     tracesSampleRate: 1.0,
//   })
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <AppProvider>
      <App />
    </AppProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
