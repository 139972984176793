import React, { useEffect, useState } from 'react'
import './Settings.scss'
import { useNavigate, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { MdEdit } from 'react-icons/md'
import API from '../../../../Context/API'
import Loading from '../../../../Component/Loading/Loading'
import Connect from '@mono.co/connect.js'
import { useBankContext } from '../Finance/Finance'
import {
  FaLongArrowAltDown,
  FaLongArrowAltUp,
  FaTrash,
  FaInfoCircle,
} from 'react-icons/fa'
import PasswordChange from './components/PasswordChange'
import SecurityQuestion from './components/SecurityQuestion'
import UpdateSecurityQuestion from './components/UpdateSecurityQuestion'
import BVN from './components/Bvn.js'
import IdCard from './components/IdCard'
import UtilityBill from './components/UtilityBill'
import MagicPin from './components/MagicPin'
import RestMagicPin from './components/RestMagiPin'
import CMagicPin from './components/CMagicPin'
import StateInfo from '../../../../Data/StateData'
import Referer from './components/Referer'
import Loader from '../../../../Image/loder.gif.gif'
import Signature from './components/Signature.js'

function Settings({ logout, notify, mono, FetchUser }) {
  const { id } = useParams()
  const { bankCards, onFetch, findFinance, setBankCards } = useBankContext()
  const authToken = sessionStorage.getItem('userId')
  const [formLoading, setFormLoading] = useState(false)
  const navigate = useNavigate()
  const [user, setUser] = useState({})
  const [loading, setLoading] = useState(true)
  const [profilePicture, setProfilePicture] = useState({
    string: '',
    file: null,
  })
  const returnedUser = JSON.parse(sessionStorage.getItem('user'))
  useEffect(() => {
    document.title = 'Spendwise | Settings'
  }, [])
  const [imgTru, setImgTru] = useState(false)
  const [showVerify, setShowVerify] = useState(false)
  const [verified, setVerified] = useState(false)
  const [findBank, setFindBank] = useState({})
  const [foundxBank, setFoundxBank] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const { handleSubmit: handleSubmit2 } = useForm()
  const { register: register3, handleSubmit: handleSubmit3 } = useForm()
  const { register: register4, handleSubmit: handleSubmit4 } = useForm()
  const [selectedState, setSelectedState] = useState(user.state)
  const [selectedLga, setSelectedLga] = useState(user.lga)
  const [lgaList, setLgaList] = useState([])
  const [selectedBilling, setSelectedBilling] = useState({})
  const [billingLoading, setBillingLoading] = useState(false)
  const [accessLoading, setAccessLoading] = useState(false)
  const sendPhoneCode = () => {
    fetch('https://www.spendwise.ng/api/accounts/verify_phone/', {
      method: 'get',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            notify('success', data.message)
            setShowVerify(true)
          })
        }
      })
      .then((data) => {})
  }
  const onVerify = (formData) => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    setFormLoading(true)
    fetch('https://www.spendwise.ng/api/accounts/verify_phone/', {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
      body: JSON.stringify({
        otp: formData.otp,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message === 'Phone number verified!') {
          setFormLoading(false)
          setTimeout(() => {
            window.location.reload()
          }, 5000)
          notify('success', data.message)
        } else {
          notify('error', data.message)
          setFormLoading(false)
        }
      })
      .catch((err) => {
        notify('error', 'An error occured. Unable to reach server')
        setFormLoading(false)
      })
  }
  const changePhone = (formData) => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    setFormLoading(true)
    fetch(`${process.env.REACT_APP_API_URL}/accounts/update_phone_email/`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
      body: JSON.stringify({
        ...formData,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            notify('success', 'Update Successful')
            setTimeout(() => {
              window.location.reload()
            }, 5000)
          })
        } else {
          res.json().then((data) => {
            notify('error', 'Update Failed Try Again')
          })
        }
      })
      .catch((err) => {
        notify('error', 'Unable to connect to Server')
      })
  }
  const addDP = () => {
    const pictureData = {
      image: profilePicture.file,
    }
    const form_data = new FormData()

    Object.keys(pictureData).forEach((key) => {
      form_data.append(key, pictureData[key])
    })
    fetch(`${process.env.REACT_APP_API_URL}/accounts/profile_pic/`, {
      method: 'post',
      headers: {
        // 'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
      body: form_data,
      redirect: 'follow',
    }).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          notify('success', data.message)
          setTimeout(() => {
            window.location.reload()
          }, 5000)
        })
      } else {
        res.json().then((data) => {
          notify('error', data.message)
        })
      }
    })
  }
  const fetchLga = (value) => {
    const findLga = StateInfo.find((item) => item.state.name === value)
    setLgaList(findLga.state.locals)
  }
  const onUpdate = (formData) => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    setFormLoading(true)
    fetch(`${process.env.REACT_APP_API_URL}/accounts/profile_works/`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
      body: JSON.stringify({
        ...formData,
        state: selectedState,
        lga: selectedLga,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            notify('success', 'Update Successful')
            if (user.bvn === null) {
              setTimeout(() => {
                // window.location.reload()
                navigate('/settings/security_bvn')
              }, 3000)
            } else {
              navigate(-1)
            }
          })
        } else {
          res.json().then((data) => {
            notify('error', 'Update Failed Try Again')
          })
        }
      })
      .catch((err) => {
        notify('error', 'Unable to connect to Server')
      })
  }
  const onSelectProductImage = (e) => {
    const file = e.target.files[0]
    const reader = new FileReader()
    reader.readAsDataURL(file)
    if (file.size > 1000000) {
      notify('error', 'Image must be below 1mb')
    } else {
      reader.onloadend = () => {
        setImgTru(true)
        setProfilePicture({
          string: reader.result,
          file,
        })
      }
    }
  }
  useEffect(() => {
    API.fetchUser()
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setUser(data)
            setLoading(false)
            if (data.phone_verified === true) {
              setVerified(true)
            }
          })
        } else if (res.status === 403) {
          setTimeout(() => {
            logout()
          }, 7000)
        }
      })
      .catch(() => {})
  }, [])
  const findingBank = (bank_name) => {
    const foundBank = bankCards.find((item) => item.bank_name === bank_name)
    setFindBank(foundBank)
    setFoundxBank(true)
  }
  const disconnectAccount = () => {
    fetch(`${process.env.REACT_APP_API_URL}/tx/disconnect_bank/`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
      body: JSON.stringify({
        bank_id: findBank.id,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            notify('success', data.message)
            setTimeout(() => {
              window.location.reload()
            }, 5000)
          })
        } else {
          res.json().then((data) => {
            notify('error', data.message)
          })
        }
      })
      .catch((err) => {})
  }
  const BankCard = ({ item }) => (
    <div className='fin-row'>
      <div className='fin-cards'>
        <div className='circle1'></div>
        <div className='circle2'></div>
        <div className='bank top'>
          {item.account_type !== 'SpendWise Wallet' ? (
            <div className='bank-name'>
              <p>{item.bank_name}</p>

              <span>{item.account_type}</span>
            </div>
          ) : (
            <div className='bank-name'>
              <p>{item.account_type}</p>
            </div>
          )}
          <div className='month'>
            <p>{item.month}</p>
          </div>
        </div>
        <div className='bank'>
          <div className='balance'>
            <p>Balance</p>
            <span>
              &#8358;
              {item.balance === null ? '0' : item.balance.toLocaleString()}
            </span>
            <p className='asat'>as at: {item.as_at}</p>
          </div>
          <div className='dot' title='Disconnect Bank'>
            {item.account_type !== 'SpendWise Wallet' && (
              <FaTrash
                onClick={() => {
                  findingBank(item.bank_name)
                }}
              />
            )}
          </div>
        </div>
        <div className='flow'>
          <div className='flow-sign'>
            <div className='sign'>
              <span>
                <FaLongArrowAltDown />{' '}
              </span>
              <p>Inflow</p>
            </div>

            <p className='mon'> &#8358;{item.inflows.toLocaleString()}</p>
          </div>
          <div className='flow-sign'>
            <div className='sign'>
              <span>
                <FaLongArrowAltUp />{' '}
              </span>{' '}
              <p>Outflow</p>
            </div>
            <p className='mon'> &#8358;{item.outflows.toLocaleString()}</p>
          </div>
        </div>
      </div>
    </div>
  )
  const bilingData = [
    {
      id: 1,
      amount: 300,
      time: 'monthly',
      frequency: 'monthly',
    },
    {
      id: 2,
      amount: 900,
      time: 'quarterly',
      frequency: '3 months',
    },
    {
      id: 3,
      amount: 3000,
      time: 'yearly',
      frequency: 'yearly',
    },
  ]
  const bilingData2 = [
    {
      id: 1,
      amount: 1000,
      time: 'monthly',
      frequency: 'monthly',
    },
    {
      id: 2,
      amount: 3000,
      time: 'quarterly',
      frequency: '3 months',
    },
    {
      id: 3,
      amount: 10000,
      time: 'yearly',
      frequency: 'yearly',
    },
  ]
  const findBilling = (id) => {
    const foundBilling = bilingData.find((item) => item.id === id)
    setSelectedBilling(foundBilling)
  }
  const choosePlan = () => {
    setBillingLoading(true)
    fetch(`${process.env.REACT_APP_API_URL}/payment/choose_access_plan/`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
      body: JSON.stringify({
        plan: selectedBilling.time,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then(() => {
            fetch('https://www.spendwise.ng/api/payment/pay_access_fee/', {
              method: 'get',
              headers: {
                'content-Type': 'application/json',
                Authorization: `Token ${authToken}`,
              },
            })
              .then((res) => {
                if (res.status === 200) {
                  res.json().then((data) => {
                    notify('success', data.message)
                    setBillingLoading(false)
                    setTimeout(() => {
                      window.location.reload()
                    }, 5000)
                  })
                } else {
                  res.json().then((data) => {
                    notify('error', data.message)
                    setBillingLoading(false)
                    fetch(
                      `${process.env.REACT_APP_API_URL}/accounts/profile-update/0/`,
                      {
                        method: 'patch',
                        headers: {
                          'content-Type': 'application/json',
                          Authorization: `Token ${authToken}`,
                        },
                        body: JSON.stringify({
                          subscription_plan: null,
                        }),
                      }
                    )
                      .then((res) => {
                        if (res.status === 200) {
                          res.json().then((data) => {
                            setTimeout(() => {
                              window.location.reload()
                            }, 3000)
                          })
                        } else {
                          res.json().then((data) => {})
                        }
                      })
                      .catch((err) => {
                        notify('error', 'Unable to connect to Server')
                      })
                  })
                }
              })
              .catch((err) => {})
          })
        } else {
          res.json().then((data) => {
            notify('error', data.message)
            setBillingLoading(false)
          })
        }
      })
      .catch((err) => {})
  }
  const payAccessFee = () => {
    setBillingLoading(true)

    fetch('https://www.spendwise.ng/api/payment/pay_access_fee/', {
      method: 'get',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            notify('success', data.message)
            setBillingLoading(false)

            setTimeout(() => {
              window.location.reload()
            }, 5000)
          })
        } else {
          res.json().then((data) => {
            notify('error', data.message)
            setBillingLoading(false)
            setTimeout(() => {
              window.location.reload()
            }, 5000)
          })
        }
      })
      .catch((err) => {})
  }
  const settingsPage = () => {
    switch (id) {
      case 'profile':
        return (
          <div className='profile'>
            <div className='prof_top'>
              <h2>Profile</h2>
              {!returnedUser.is_business_created && (
                <div className='user_img'>
                  <div className='image'>
                    <img
                      src={
                        imgTru
                          ? profilePicture.string
                          : user.image ||
                            (user.image === null &&
                              'https://upload.wikimedia.org/wikipedia/commons/thumb/2/24/Missing_avatar.svg/1200px-Missing_avatar.svg.png')
                      }
                      alt=''
                    />
                  </div>
                  <div className='edit'>
                    <form onSubmit={handleSubmit2(addDP)}>
                      <input
                        type='file'
                        accept='image/*'
                        onChange={onSelectProductImage}
                        name='product_img'
                        id='files'
                      />
                      <label htmlFor='files'>
                        <MdEdit /> <p>Edit</p>
                      </label>
                      <button>Save</button>
                    </form>
                  </div>
                </div>
              )}
            </div>
            <div className='profile_wrap'>
              <h2>Bio</h2>
              <form onSubmit={handleSubmit3(onUpdate)}>
                <div className='card'>
                  <p>Username</p>
                  <input
                    type='text'
                    name=''
                    id=''
                    defaultValue={user.username}
                    disabled
                  />
                </div>
                <div className='wrapper'>
                  <div className='card'>
                    <p>
                      First Name <span className='imp'>*</span>
                    </p>
                    <input
                      type='text'
                      name='first_name'
                      id=''
                      defaultValue={user.first_name}
                      {...register3('first_name')}
                      required
                    />
                  </div>
                  <div className='card'>
                    <p>
                      Last Name <span className='imp'>*</span>
                    </p>
                    <input
                      type='text'
                      name='last_name'
                      id=''
                      defaultValue={user.surname}
                      {...register3('last_name')}
                      required
                    />
                  </div>
                  <div className='card'>
                    <p>
                      Gender <span className='imp'>*</span>
                    </p>
                    <select
                      name='gender'
                      id=''
                      required
                      {...register3('gender')}
                    >
                      <option value={user.gender}>{user.gender}</option>
                      <option value='Female'>Female</option>
                      <option value='Male'>Male</option>
                      <option value='Other'>Others</option>
                    </select>
                  </div>
                  {/* <div className='card'>
                    <p>
                      Education <span className='imp'>*</span>
                    </p>
                    <input
                      required
                      type='text'
                      name='education'
                      id=''
                      defaultValue={user.education}
                      {...register3('education')}
                    />
                  </div> */}
                  <div className='card'>
                    <p>
                      Address <span className='imp'>*</span>
                    </p>
                    <input
                      required
                      type='text'
                      name='address'
                      id=''
                      defaultValue={user.address}
                      {...register3('address')}
                    />
                  </div>
                  <div className='card'>
                    <p>
                      State <span className='imp'>*</span>
                    </p>
                    <select
                      name='state'
                      required
                      id=''
                      onChange={(e) => {
                        fetchLga(e.target.value)
                        setSelectedState(e.target.value)
                      }}
                    >
                      <option value={user.state}>{user.state}</option>
                      {StateInfo.map((item) => (
                        <option key={item.state.id} value={item.state.name}>
                          {item.state.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className='card'>
                    <p>
                      Local Government <span className='imp'>*</span>
                    </p>
                    <select
                      n
                      required
                      id=''
                      onChange={(e) => {
                        setSelectedLga(e.target.value)
                      }}
                    >
                      <option value={user.lga}>{user.lga}</option>
                      {lgaList.map((item) => (
                        <option key={item.id} value={item.name}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className='card'>
                    <p>
                      City <span className='imp'>*</span>
                    </p>
                    <input
                      required
                      type='text'
                      name='city'
                      id=''
                      defaultValue={user.city}
                      {...register3('city')}
                    />
                  </div>
                  <div className='card'>
                    <p>
                      Postal Code <span className='imp'>*</span>
                    </p>
                    <input
                      required
                      type='text'
                      name='postal_code'
                      id=''
                      defaultValue={user.postal_code}
                      {...register3('postal_code')}
                    />
                  </div>
                </div>
                <div className='button'>
                  <button>Update</button>
                </div>
              </form>
              <form onSubmit={handleSubmit4(changePhone)}>
                <div className='card'>
                  <p>
                    Phone Number <span className='imp'>*</span>
                  </p>
                  <input
                    type='text'
                    name='phone'
                    id=''
                    defaultValue={user.phone}
                    {...register4('phone')}
                    required
                  />
                </div>
                <div className='button'>
                  <button>Change Number</button>
                </div>
              </form>
            </div>
          </div>
        )
      case 'add-bank':
        return (
          <div className='bank'>
            <div className='bank_top'>
              <h2>Add Bank Account</h2>
            </div>
            <div className='bank_body'>
              <h3>Link Bank Request</h3>
              <p>
                You will be charged a one-time fee of #300 for adding more than
                1 bank.
              </p>
              <div className='button'>
                <button onClick={() => mono.open()}>Proceed</button>
              </div>
            </div>
          </div>
        )
      case 'connected-bank':
        return (
          <div className='connected-bank'>
            <div className={foundxBank ? 'bank-ask showx' : 'bank-ask'}>
              <div>
                <button className='success' onClick={disconnectAccount}>
                  Yes
                </button>
                <button
                  className='end'
                  onClick={() => {
                    setFindBank({})
                    setFoundxBank(false)
                  }}
                >
                  No
                </button>
              </div>
            </div>
            <div className='connected_top'>
              <h2>All Connected Bank</h2>
            </div>
            <div className='body'>
              {bankCards.map((item) => (
                <BankCard key={item.bank_name} item={item} />
              ))}
            </div>
          </div>
        )
      case 'refer_friend':
        return <Referer user={user} API={API} notify={notify} />
      case 'about':
        return (
          <div className='about'>
            <div className='about_top'>
              <h2>About</h2>
              <p>Spendwise web version 1.0</p>
            </div>
          </div>
        )
      case 'biling':
        return (
          <div className='billing'>
            {billingLoading && (
              <div className='loadd'>
                <img src={Loader} alt='' />
              </div>
            )}

            <div className='biling_top'>
              <h2>Billing</h2>
            </div>
            <div className='biling_wrap'>
              <div className='billing_bottom'>
                <div className='billing_left'>
                  <h3>Pay premium fee</h3>
                  <div>
                    <FaInfoCircle />
                    <p>
                      Subsequent recurring charges would be automatic, However
                      you can choose to opt-out at anytime.
                    </p>
                  </div>
                </div>
                {user.subscription_plan !== null && !user.subscribed && (
                  <div className='billing_right'>
                    <button onClick={() => payAccessFee()}>
                      Pay for plan now
                    </button>
                  </div>
                )}
              </div>
              <div className='wrap-top'>
                <div>
                  <h3>Billing Plans</h3>
                  <p>
                    Status:{' '}
                    <span>
                      {user.subscribed ? user.access_status : 'Inactive'}
                    </span>
                  </p>
                </div>
                {user.subscribed && <button>Cancel Subscription</button>}
              </div>

              <div className='biling_body'>
                {user.is_business ? (
                  <>
                    {bilingData2.map((item) => (
                      <div className='biling_card' key={item.id}>
                        <div
                          className={
                            user.subscription_plan === item.time
                              ? 'inner select'
                              : 'inner'
                          }
                        >
                          <div className='inner_top'>
                            <p>{item.time}</p>
                            <h4> ₦{item.amount.toLocaleString()}</h4>
                          </div>
                          <p className='details'>
                            Bank access fee would be charged{' '}
                            <span>{item.frequency}.</span>
                          </p>
                          {user.subscription_plan === item.time && (
                            <p
                              className='current'
                              style={{ paddingBottom: '10px' }}
                            >
                              You are currently on this plan
                            </p>
                          )}
                          {selectedBilling.id === item.id ? (
                            ''
                          ) : (
                            <div className='butti'>
                              <button
                                className={
                                  user.subscription_plan === item.time
                                    ? 'diff'
                                    : ''
                                }
                                onClick={() => {
                                  if (user.subscription_plan === item.time) {
                                  } else {
                                    findBilling(item.id)
                                  }
                                }}
                              >
                                {user.subscription_plan === item.time
                                  ? 'Current Plan'
                                  : 'Select'}
                              </button>
                            </div>
                          )}

                          {selectedBilling.id === item.id && (
                            <div className='askg'>
                              <p>
                                Are you sure you want to subscribe to this plan?
                              </p>
                              <div className='buttonsd'>
                                <button onClick={() => setSelectedBilling({})}>
                                  Cancel
                                </button>
                                <button onClick={() => choosePlan()}>
                                  Pay
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <>
                    {bilingData.map((item) => (
                      <div className='biling_card' key={item.id}>
                        <div
                          className={
                            user.subscription_plan === item.time
                              ? 'inner select'
                              : 'inner'
                          }
                        >
                          <div className='inner_top'>
                            <p>{item.time}</p>
                            <h4> ₦{item.amount.toLocaleString()}</h4>
                          </div>
                          <p className='details'>
                            Bank access fee would be charged{' '}
                            <span>{item.frequency}.</span>
                          </p>
                          {user.subscription_plan === item.time && (
                            <p
                              className='current'
                              style={{ paddingBottom: '10px' }}
                            >
                              You are currently on this plan
                            </p>
                          )}
                          {selectedBilling.id === item.id ? (
                            ''
                          ) : (
                            <div className='butti'>
                              <button
                                className={
                                  user.subscription_plan === item.time
                                    ? 'diff'
                                    : ''
                                }
                                onClick={() => {
                                  if (user.subscription_plan === item.time) {
                                  } else {
                                    findBilling(item.id)
                                  }
                                }}
                              >
                                {user.subscription_plan === item.time
                                  ? 'Current Plan'
                                  : 'Select'}
                              </button>
                            </div>
                          )}

                          {selectedBilling.id === item.id && (
                            <div className='askg'>
                              <p>
                                Are you sure you want to subscribe to this plan?
                              </p>
                              <div className='buttonsd'>
                                <button onClick={() => setSelectedBilling({})}>
                                  Cancel
                                </button>
                                <button onClick={() => choosePlan()}>
                                  Pay
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>
        )
      case 'security':
        return (
          <div className='security'>
            <div className='security_top'>
              <h2>Security</h2>
            </div>
            <div className='security_wrap'>
              <div>
                <button
                  onClick={() => {
                    navigate('/settings/change_password')
                  }}
                >
                  Change Password
                </button>
              </div>
              {!user.magic_pin_set && (
                <div>
                  <button
                    onClick={() => {
                      navigate('/settings/security_create-pin')
                    }}
                  >
                    Create Magic Pin
                  </button>
                </div>
              )}
              {user.magic_pin_set && (
                <div>
                  <button
                    onClick={() => {
                      navigate('/settings/security_magic-pin')
                    }}
                  >
                    Change Magic Pin
                  </button>
                </div>
              )}
              {/* {user.magic_pin_set && (
                <div>
                  <button
                    onClick={() => {
                      navigate('/settings/security_rest-magic-pin')
                    }}
                  >
                    Reset Magic Pin
                  </button>
                </div>
              )} */}
              {user.security_q_set ? (
                <div>
                  <button
                    onClick={() => {
                      navigate('/settings/security_questions-update')
                    }}
                  >
                    Update Security Questions
                  </button>
                </div>
              ) : (
                <div>
                  <button
                    onClick={() => {
                      navigate('/settings/security_questions')
                    }}
                  >
                    Set Security Questions
                  </button>
                </div>
              )}
              {!user.id_card_uploaded && (
                <div>
                  <button
                    onClick={() => {
                      navigate('/settings/security_ID')
                    }}
                  >
                    ID Card
                  </button>
                </div>
              )}
              {(returnedUser.is_business ||
                returnedUser.is_business_created) && (
                <div>
                  <button
                    onClick={() => {
                      navigate('/settings/signature')
                    }}
                  >
                    Signature
                  </button>
                </div>
              )}

              {!user.utility_bill_uploaded && (
                <div>
                  <button
                    onClick={() => {
                      navigate('/settings/security_utility')
                    }}
                  >
                    Utility Bill
                  </button>
                </div>
              )}
              {/* <div>
                <button>Setup Biometrics</button>
              </div>
              <div>
                <button>Reset Biometrics</button>
              </div> */}
            </div>
          </div>
        )
      case 'bvn':
        return (
          <div className='security'>
            <div className='security_top'>
              <h2>Security</h2>
            </div>
            <div className='security_wrap'>
              <div>
                <button
                  onClick={() => {
                    navigate('/settings/change_password')
                  }}
                >
                  Change Password
                </button>
              </div>
              {!user.magic_pin_set && (
                <div>
                  <button
                    onClick={() => {
                      navigate('/settings/security_create-pin')
                    }}
                  >
                    Create Magic Pin
                  </button>
                </div>
              )}
              <div>
                <button
                  onClick={() => {
                    navigate('/settings/security_magic-pin')
                  }}
                >
                  Change Magic Pin
                </button>
              </div>
              <div>
                <button
                  onClick={() => {
                    navigate('/settings/security_rest-magic-pin')
                  }}
                >
                  Reset Magic Pin
                </button>
              </div>
              {user.security_q_set ? (
                <div>
                  <button
                    onClick={() => {
                      navigate('/settings/security_questions-update')
                    }}
                  >
                    Update Security Questions
                  </button>
                </div>
              ) : (
                <div>
                  <button
                    onClick={() => {
                      navigate('/settings/security_questions')
                    }}
                  >
                    Set Security Questions
                  </button>
                </div>
              )}
              {!user.id_card_uploaded && (
                <div>
                  <button
                    onClick={() => {
                      navigate('/settings/security_ID')
                    }}
                  >
                    ID Card
                  </button>
                </div>
              )}

              {!user.utility_bill_uploaded && (
                <div>
                  <button
                    onClick={() => {
                      navigate('/settings/security_utility')
                    }}
                  >
                    Utility Bill
                  </button>
                </div>
              )}
              <div>
                <button>Setup Biometrics</button>
              </div>
              <div>
                <button>Reset Biometrics</button>
              </div>
            </div>
          </div>
        )
      case 'change_password':
        return <PasswordChange navigate={navigate} notify={notify} />
      case 'security_questions':
        return (
          <SecurityQuestion navigate={navigate} user={user} notify={notify} />
        )
      case 'security_questions-update':
        return (
          <UpdateSecurityQuestion
            navigate={navigate}
            user={user}
            notify={notify}
          />
        )
      case 'security_bvn':
        return (
          <BVN
            navigate={navigate}
            user={user}
            notify={notify}
            FetchUser={FetchUser}
          />
        )
      case 'security_ID':
        return <IdCard navigate={navigate} user={user} notify={notify} />
      case 'security_utility':
        return <UtilityBill navigate={navigate} user={user} notify={notify} />
      case 'security_create-pin':
        return <CMagicPin navigate={navigate} user={user} notify={notify} />
      case 'security_magic-pin':
        return <MagicPin navigate={navigate} user={user} notify={notify} />
      case 'security_rest-magic-pin':
        return <RestMagicPin navigate={navigate} user={user} notify={notify} />
      case 'signature':
        return <Signature navigate={navigate} notify={notify} />
      default:
        return <div>No page to display</div>
    }
  }
  return loading ? (
    <Loading />
  ) : (
    <div className='settings'>
      <div className='top'>
        <h1>Settings</h1>
      </div>
      {verified === false ? (
        <></>
      ) : (
        <div className='navigation'>
          <button
            onClick={() => {
              navigate('/settings/profile')
            }}
            className={id === 'profile' ? 'active' : ''}
          >
            View Profile
          </button>
          <button
            onClick={() => {
              navigate('/settings/security_bvn')
            }}
            className={id === 'security_bvn' ? 'active' : ''}
          >
            BVN
          </button>
          {/* <button
            onClick={() => {
              navigate('/settings/add-bank')
            }}
            className={id === 'add-bank' ? 'active' : ''}
          >
            Add Bank Account
          </button> */}
          {/* <button
            onClick={() => {
              navigate('/settings/connected-bank')
            }}
            className={id === 'connected-bank' ? 'active' : ''}
          >
            Connected Bank
          </button> */}
          <button
            onClick={() => {
              navigate('/settings/security')
            }}
            className={
              id === 'security' ||
              id === 'change_password' ||
              id === 'security_questions' ||
              id === 'security_questions-update' ||
              id === 'security_ID' ||
              id === 'security_utility' ||
              id === 'security_create-pin' ||
              id === 'security_magic-pin' ||
              id === 'security_rest-magic-pin'
                ? 'active'
                : ''
            }
          >
            Security
          </button>
          <button
            onClick={() => {
              navigate('/settings/biling')
            }}
            className={id === 'biling' ? 'active' : ''}
          >
            Billing
          </button>
          <button
            onClick={() => {
              navigate('/settings/refer_friend')
            }}
            className={id === 'refer_friend' ? 'active' : ''}
          >
            Refer Friend
          </button>
          <button
            onClick={() => {
              navigate('/settings/about')
            }}
            className={id === 'about' ? 'active' : ''}
          >
            About
          </button>
        </div>
      )}

      <div className='user_name'>
        <p>
          {user.first_name} {user.surname}
        </p>
        <p>{user.email}</p>
      </div>
      <div className='main_body'>
        {verified === false && (
          <div className='pop-modal'>
            <h2>Verify Phone Number</h2>
            {!showVerify && (
              <div>
                <div>
                  <button onClick={sendPhoneCode}>Verify</button>
                </div>
                <div
                  style={{
                    textAlign: 'right',
                    color: '#001ac6',
                    cursor: 'pointer',
                  }}
                >
                  <h3
                    onClick={() => {
                      setVerified(true)
                    }}
                  >
                    Skip
                  </h3>
                </div>
              </div>
            )}
            {showVerify && (
              <div>
                <p>Input OTP</p>
                <form onSubmit={handleSubmit(onVerify)}>
                  <div className='card'>
                    <p>OTP</p>

                    <input
                      type='tel'
                      name='otp'
                      id=''
                      {...register('otp', {
                        required: 'Field is required',
                      })}
                    />
                    {errors.otp && (
                      <h6 className='vError'>{errors.otp.message}</h6>
                    )}
                  </div>
                  <div>
                    <button>{formLoading ? '.....' : 'Verify'}</button>
                  </div>
                </form>
              </div>
            )}
          </div>
        )}
        <main>{settingsPage()}</main>
      </div>
    </div>
  )
}

export default Settings
