import React, { useState, useEffect } from 'react'
import { AiOutlineCaretDown } from 'react-icons/ai'
import { useGlobalContext } from '../../../../../Context/Context'
import { useNavigate } from 'react-router'
import Loader from '../../../../../Image/loder.gif.gif'

function CreateUser({ notify }) {
  const authToken = sessionStorage.getItem('userId')
  const { toggleShowCreateDept, departmentList } = useGlobalContext()
  const navigate = useNavigate()
  const [showDepartmentList, setShowDepartmentList] = useState('false')
  const [showRoleList, setShowRoleList] = useState('false')

  const [selectedDepartment, setSelectedDepartment] =
    useState('Select Department')
  const [selectedDepartmentId, setSelectedDepartmentId] = useState('')
  const roleArray = [
    'admin',
    'auditor',
    'developer',
    'finance',
    'officer',
    'team_lead',
  ]
  const [roles, setRoles] = useState([])
  const [userName, setUserName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [title, setTitle] = useState('')
  const [loading, setLoading] = useState(false)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')

  const selectingDepartment = (name) => {
    setSelectedDepartment(name)
    if (!showDepartmentList) {
      setShowDepartmentList(!showDepartmentList)
    }
  }
  function handleRoleClick(role) {
    // create a copy of the current roles array
    const rolesList = [...roles]

    // check if the role is already in the list
    if (rolesList.includes(role)) {
      // remove the role from the list
      const updatedRoles = rolesList.filter((r) => r !== role)
      // update the state with the updated array
      setRoles(updatedRoles)
    } else {
      // add the role to the list
      rolesList.push(role)
      // update the state with the updated array
      setRoles(rolesList)
    }
  }

  const ConfirmUser = () => {
    if (userName.length < 1) {
      notify('warn', 'Input a username')
    } else if (email.length < 1) {
      notify('warn', 'Input a email')
    } else if (phone.length < 7) {
      notify('warn', 'Input a phone number')
    } else if (selectedDepartment === 'Select Department') {
      notify('warn', 'Select a Department')
    } else if (roles.length < 1) {
      notify('warn', 'select at least one role')
    } else if (title.length < 1) {
      notify('warn', 'Input a title')
    } else {
      setLoading(true)
      fetch(`${process.env.REACT_APP_API_URL}/accounts/business_user/`, {
        method: 'post',
        headers: {
          'content-Type': 'application/json',
          Authorization: `Token ${authToken}`,
        },
        body: JSON.stringify({
          username: userName,
          email: email,
          phone: phone,
          department_id: selectedDepartmentId,
          name: selectedDepartment,
          roles: roles,
          first_name: firstName,
          last_name: lastName,
          title: title,
        }),
      })
        .then((res) => {
          if (res.status === 201) {
            setLoading(false)
            notify('success', 'User created successfully.')
            setTimeout(() => {
              window.location.replace('/user-management/dashboard')
            }, 300)
          } else {
            res.json().then((data) => {
              notify('error', data.message)
              setLoading(false)
            })
          }
        })
        .catch(() => {
          notify('error', 'Server error')
        })
    }
  }

  return (
    <div className='user-management'>
      <div className='form'>
        <div className='topx'>
          <h2>Add New User</h2>
          <p>
            Expand your workforce and boost productivity with a new team member
          </p>
        </div>
        <div className='down'>
          <div
            className='card'
            onClick={() => {
              if (!showDepartmentList) {
                setShowDepartmentList(!showDepartmentList)
              }
              if (!showRoleList) {
                setShowRoleList(!showRoleList)
              }
            }}
          >
            <div className='left'>
              <h2>First Name</h2>
            </div>
            <div className='right'>
              <input
                type='text'
                placeholder='Enter username'
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
          </div>
          <div
            className='card'
            onClick={() => {
              if (!showDepartmentList) {
                setShowDepartmentList(!showDepartmentList)
              }
              if (!showRoleList) {
                setShowRoleList(!showRoleList)
              }
            }}
          >
            <div className='left'>
              <h2>Last Name</h2>
            </div>
            <div className='right'>
              <input
                type='text'
                placeholder='Enter username'
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
          </div>
          <div
            className='card'
            onClick={() => {
              if (!showDepartmentList) {
                setShowDepartmentList(!showDepartmentList)
              }
              if (!showRoleList) {
                setShowRoleList(!showRoleList)
              }
            }}
          >
            <div className='left'>
              <h2>Username</h2>
            </div>
            <div className='right'>
              <input
                type='text'
                placeholder='Enter username'
                onChange={(e) => setUserName(e.target.value)}
              />
            </div>
          </div>
          <div
            className='card'
            onClick={() => {
              if (!showDepartmentList) {
                setShowDepartmentList(!showDepartmentList)
              }
              if (!showRoleList) {
                setShowRoleList(!showRoleList)
              }
            }}
          >
            <div className='left'>
              <h2>Email</h2>
            </div>
            <div className='right'>
              <input
                type='text'
                placeholder='Enter email address'
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
          <div
            className='card'
            onClick={() => {
              if (!showDepartmentList) {
                setShowDepartmentList(!showDepartmentList)
              }
              if (!showRoleList) {
                setShowRoleList(!showRoleList)
              }
            }}
          >
            <div className='left'>
              <h2>Phone</h2>
            </div>
            <div className='right'>
              <input
                type='text'
                placeholder='Enter phone number'
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
          </div>
          <div className='card'>
            <div className='left'>
              <h2> Department</h2>
            </div>
            <div className='right'>
              <div className='select'>
                <div
                  className='select-top select'
                  onClick={() => {
                    if (!showRoleList) {
                      setShowDepartmentList(!showDepartmentList)
                      setShowRoleList(!showRoleList)
                    } else {
                      setShowDepartmentList(!showDepartmentList)
                    }
                  }}
                >
                  <p>{selectedDepartment}</p>
                  <AiOutlineCaretDown />
                </div>
                {!showDepartmentList && (
                  <div className='select-body'>
                    <ul>
                      {departmentList.map((item) => (
                        <li
                          key={item.name}
                          onClick={() => {
                            selectingDepartment(item.name)
                            setSelectedDepartmentId(item.id)
                          }}
                        >
                          {item.name}
                        </li>
                      ))}
                    </ul>
                    <p onClick={toggleShowCreateDept}>Add New Department</p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='card'>
            <div className='left'>
              <h2> Role</h2>
            </div>
            <div className='right'>
              <div className='select'>
                <div
                  className='select-top select'
                  onClick={() => {
                    if (!showDepartmentList) {
                      setShowDepartmentList(!showDepartmentList)
                      setShowRoleList(!showRoleList)
                    } else {
                      setShowRoleList(!showRoleList)
                    }
                  }}
                >
                  <p>
                    {roles.length > 0 ? (
                      <> {roles.join(', ')}</>
                    ) : (
                      'Select Role'
                    )}
                  </p>
                  <AiOutlineCaretDown />
                </div>
                {!showRoleList && (
                  <div className='select-body'>
                    <ul>
                      {roleArray.map((item) => (
                        <li
                          key={item}
                          onClick={() => handleRoleClick(item)}
                          className={roles.includes(item) ? 'active' : ''}
                        >
                          {item}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div
            className='card'
            onClick={() => {
              if (!showDepartmentList) {
                setShowDepartmentList(!showDepartmentList)
              }
              if (!showRoleList) {
                setShowRoleList(!showRoleList)
              }
            }}
          >
            <div className='left'>
              <h2>Title</h2>
            </div>
            <div className='right'>
              <input
                type='text'
                placeholder='Enter user’s title'
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='buttons'>
        <button onClick={() => navigate('/user-management/dashboard')}>
          Previous
        </button>

        {loading ? (
          <img src={Loader} alt='' style={{ width: '50px' }} />
        ) : (
          <button onClick={ConfirmUser}>Create User</button>
        )}
      </div>
    </div>
  )
}

export default CreateUser
