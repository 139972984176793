import React, { useEffect } from 'react'
import './Footer.scss'
import Logo from '../../Image/logo1.png'
import Logo2 from '../../Pages/Landinpage/images/Spendwise footer.svg'
import facebook from '../../Pages/Landinpage/images/facebook.svg'
import twitter from '../../Pages/Landinpage/images/twitter.svg'
import linkedin from '../../Pages/Landinpage/images/linkedin.svg'
import { Link } from 'react-router-dom'
import AOS from 'aos'
import 'aos/dist/aos.css'

function Footer() {
  useEffect(() => {
    AOS.init()
  }, [])
  return (
    <div className='footer' data-aos='fade-up' data-aos-duration='1000'>
      <div className='footer_top'></div>
      <div className='wrap-g'>
        <div className='footer_wrap'>
          <div className='image' data-aos='fade-up' data-aos-duration='1500'>
            <img src={Logo2} alt='' className='logo' />
            <p>Expense management and payment solution for businesses.</p>
            <div className='footer_icons'>
              <ul>
                <a href='https://web.facebook.com/SpendWiseng-111330207276507'>
                  <li>
                    <img src={facebook} alt='' />
                  </li>
                </a>
                <a href='https://twitter.com/spendwiseng'>
                  <li>
                    <img src={twitter} alt='' />
                  </li>
                </a>
                <a href='https://www.linkedin.com/company/spendwiseng/'>
                  <li>
                    <img src={linkedin} alt='' />
                  </li>
                </a>
              </ul>
            </div>
          </div>

          <div className='wre'>
            <div className='card' data-aos='flip-left' data-aos-duration='1500'>
              <h2>Resources</h2>
              <ul>
                {/* <a href='http://blog.spendwise.ng/'>
              <li>Blog</li>
            </a> */}
                <Link to='/faq'>
                  <li>FAQs</li>
                </Link>
                {/* <Link to='/'>
              <li>Products Updates</li>
            </Link> */}
                <a href='https://spendwise.readme.io/reference/introduction'>
                  <li>Developer Portal</li>
                </a>
                {/* <Link to='/'>
              <li>Help Center</li>
            </Link>
            <Link to='/'>
              <li>Partners</li>
            </Link> */}
              </ul>
            </div>
            <div
              className='card'
              data-aos='flip-right'
              data-aos-duration='1500'
            >
              <h2>Company</h2>
              <ul>
                {/* <a href='https://business.spendwise.ng/'>
                  <li>Security</li>
                </a> */}
                <Link to='/privacy'>
                  <li>Privacy Policy</li>
                </Link>
                <a href='mailto:hello@spendwise.ng'>
                  <li>Contact Us</li>
                </a>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className='last'>
        <div className='footer_last'>
          <h2>
            © 2023 Spendwise. <span>All Rights Reserved.</span>
          </h2>
          <Link to='/privacy'>
            <p>Terms & Conditions</p>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Footer
