import React, { useState, useRef, useEffect } from 'react'
import { BsCreditCardFill, BsPlusLg } from 'react-icons/bs'
import MasterCard from '../../../../../Image/master.svg'
import VerveCard from '../../../../../Image/verve.svg'
import VisaCard from '../../../../../Image/visa.svg'
import CardComponent from './CardComponent'
import NewCardComponent from './CreditCard/NewCardComponent'
import PinInput from 'react-pin-input'
import API from '../../../../../Context/API'
import { FcOk, FcCancel } from 'react-icons/fc'
import Loader from '../../../../../Image/loder.gif.gif'

const userBank = [
  {
    id: 1,
    image: MasterCard,
  },
  {
    id: 2,
    image: VerveCard,
  },
  {
    id: 3,
    image: VisaCard,
  },
]
function FundWallet({ user, notify, sudoDetails }) {
  const authToken = sessionStorage.getItem('userId')
  const [fundingTab, setFundingTab] = useState('bank_transfer-naira')
  const [cardFundingStage, setCardFundingStage] = useState('one')
  const [cardTabs, setCardTabs] = useState('default')
  const [age, setAge] = useState()
  const [accountCopied, setAccountCopied] = useState(false)
  const textAreaRef = useRef(null)
  const [dollarRate, setdollarRate] = useState(0)
  const [creditAmmount, setCreditAmmount] = useState(0)
  const [userWalletDetails, setUserWalletDetails] = useState({})
  const [spendwiseUsername, setSpendwiseUsername] = useState('')
  const [walletError, setWalletError] = useState('')
  const [accountNumberFecthing, setAccountNumberFecthing] = useState()
  const [accountNumberCorrect, setAccountNumberCorrect] = useState(false)
  const [walletDetails, setWalletDetails] = useState({})
  const [transferNarration, setTransferNarration] = useState('')
  const [transferAmmount, setTransferAmmount] = useState('')
  const [reqLoading, setReqLoading] = useState(false)
  const [reqPage, setReqPage] = useState('default')

  const copyAccount = (e) => {
    textAreaRef.current.select()
    document.execCommand('copy')
    e.target.focus()
    setAccountCopied(true)
  }
  useEffect(() => {
    API.getDollarRate()
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setdollarRate(data.rate)
          })
        } else {
          res.json().then((data) => {})
        }
      })
      .catch(() => {})
  }, [])
  useEffect(() => {
    API.checkWallet()
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setUserWalletDetails(data[0])
          })
        } else {
          res.json().then((data) => {})
        }
      })
      .catch(() => {})
  }, [])
  const fetchWalletUsername = () => {
    setAccountNumberFecthing(true)
    fetch(`${process.env.REACT_APP_API_URL}/purse/verify_wallet_account/`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
      body: JSON.stringify({
        account: spendwiseUsername,
        issuer: 'mono',
      }),
    }).then((res) => {
      if (res.status === 200) {
        setAccountNumberCorrect(true)
        res.json().then((data) => {
          setWalletDetails(data)
        })
      } else {
        setAccountNumberFecthing(false)
        res.json().then((data) => {
          setWalletError(data.message)
          setAccountNumberCorrect(false)
        })
      }
    })
  }

  return (
    <div className='fund-wallet'>
      <div className='wallet_top'>
        <h2 className='wallet-text'>E-Wallet</h2>
      </div>
      <div className='fund_wallet-wrap'>
        <div className='funding_navigation'>
          <div
            onClick={() => {
              setFundingTab('bank_transfer-naira')
              setCreditAmmount(0)
            }}
            className={fundingTab === 'bank_transfer-naira' ? 'active' : ''}
          >
            <h2>BANK TRANSFER (Main Wallet)</h2>
            <p>Fund wallet via bank mobile app, online banking or USSD code</p>
          </div>
          {/* <div
            onClick={() => {
              setFundingTab('bank_transfer')
              setCreditAmmount(0)
            }}
            className={fundingTab === 'bank_transfer' ? 'active' : ''}
          >
            <h2>BANK TRANSFER (Dollar card wallet)</h2>
            <p>Fund wallet via bank mobile app, online banking or USSD code</p>
          </div> */}
          <div
            className={fundingTab === 'credit_card' ? 'active' : ''}
            onClick={() => {
              // setFundingTab('credit_card')
              // setCardFundingStage('one')
            }}
            style={{ opacity: '0.35' }}
          >
            <h2>Pay with Debit Card</h2>
            {/* <p>Fund wallet via bank debit/credit cards</p> */}
            <p>Coming soon...</p>
          </div>
          <div
            className={fundingTab === 'credit_card' ? 'active' : ''}
            onClick={() => {
              // setFundingTab('credit_card')
              // setCardFundingStage('one')
            }}
            style={{ opacity: '0.35' }}
          >
            <h2>REQUEST FROM A SPENDWISE USER</h2>
            {/* <p>Fund wallet via bank debit/credit cards</p> */}
            <p>Coming soon...</p>
          </div>
        </div>
        <main className='main-funding '>
          {fundingTab === 'credit_card' && (
            <div className='card_funding'>
              {cardFundingStage === 'one' && (
                <>
                  <div className='fund-wallet-body_top'>
                    <h2>Fund Wallet</h2>
                    <p>How much would you like to add?</p>
                    <div className='amount-input'>
                      <div>
                        <h3>₦</h3>
                        <input
                          type='number'
                          onChange={(e) => setCreditAmmount(e.target.value)}
                          required
                        />
                      </div>
                      <p>(${(creditAmmount / dollarRate).toLocaleString()})</p>
                    </div>
                  </div>
                  <div className='fund-wallet-option'>
                    <p className='pref'>
                      Select a preferred debit/credit card to fund your wallet
                      with.
                    </p>
                    <div className='option_wrapper'>
                      <button
                        onClick={() => setCardTabs('saved_card')}
                        className={cardTabs === 'saved_card' ? 'active' : ''}
                      >
                        <BsCreditCardFill />
                        Debit/Credit Card
                      </button>
                      <button
                        onClick={() => setCardTabs('new_card')}
                        className={cardTabs === 'new_card' ? 'active' : ''}
                      >
                        <BsPlusLg /> Add New Card
                      </button>
                    </div>
                    <div className='card_funding-tabs'>
                      {cardTabs === 'default' && (
                        <div className='ftab_default'>
                          <p>
                            A fee of 2% on amount will be charge for this
                            transaction.
                          </p>
                        </div>
                      )}
                      {cardTabs === 'saved_card' && (
                        <>
                          <div className='saved_cards'>
                            {userBank.map((item) => (
                              <CardComponent
                                item={item}
                                age={age}
                                setAge={setAge}
                                key={item.id}
                              />
                            ))}
                          </div>
                          <div className='transfer_buttons'>
                            <button>Back</button>
                            <button onClick={() => setCardFundingStage('two')}>
                              Proceed
                            </button>
                          </div>
                        </>
                      )}
                      {cardTabs === 'new_card' && (
                        <div className='new_card'>
                          {/* <div className='card_form-wrap'>
                        <div className='long'>
                          <p>Card Number</p>
                          <div>
                            <input type='tel' name='' id='' />
                            <img src={MasterCard} alt='' />
                          </div>
                        </div>
                        <div className='short'>
                          <p>Valid Until</p>
                          <input
                            type='text'
                            name=''
                            id=''
                            placeholder='MM/YY'
                          />
                        </div>
                        <div className='short'>
                          <p>CVV</p>
                          <input
                            type='text'
                            name=''
                            id=''
                            placeholder='***'
                          />
                        </div>
                      </div> */}
                          <NewCardComponent />
                          <div className='transfer_buttons'>
                            <button>Back</button>
                            <button onClick={() => setCardFundingStage('two')}>
                              Proceed
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
              {cardFundingStage === 'two' && (
                <div className='pin_wrapper'>
                  <div
                    className='wallet_transfer-body_top'
                    style={{ paddingBottom: '30px' }}
                  >
                    <h2 style={{ fontSize: '30px' }}>OTP Verification</h2>
                    <p style={{ fontSize: '20px' }}>
                      Enter 4-digit code sent to:
                      <span>
                        <b>+234 704 116 3538</b>
                      </span>
                    </p>
                  </div>
                  <PinInput
                    length={4}
                    initialValue=''
                    secret
                    onChange={(value, index) => {}}
                    type='numeric'
                    inputMode='numeric'
                    style={{ padding: '10px' }}
                    inputStyle={{
                      borderColor: '#e6e6e6',
                      borderWidth: '3px',
                      borderStyle: 'solid',
                      background: '#f1f1f1',
                      fontSize: ' 3rem',
                      borderRadius: '20px',
                      height: '70px',
                      width: '70px',
                      margin: '0 5px',
                    }}
                    inputFocusStyle={{ borderColor: 'blue' }}
                    onComplete={(value, index) => {}}
                    autoSelect={true}
                    regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                  />
                  <div className='t_deta'>
                    <p>
                      Resend code in
                      <span>
                        <b>00:30s</b>
                      </span>
                    </p>
                    <p>
                      Didn’t receive the code?
                      <span style={{ color: '#0629A6' }}>Resend</span> or
                      <span style={{ color: '#0629A6' }}>Send to Email</span>
                    </p>
                  </div>
                  <div className='t_button'>
                    <button>Confirm & Transfer</button>
                  </div>
                </div>
              )}
            </div>
          )}
          {fundingTab === 'bank_transfer' && (
            <div className='bank-transfer'>
              <div className='bank-transfer_wrap'>
                <div
                  className='wallet_transfer-body_top'
                  style={{ paddingBottom: '30px' }}
                >
                  <h2>Bank Transfer</h2>
                  <p>
                    You can fund your wallet by paying directly to your
                    designated account details below.
                  </p>
                </div>
                <div className='bank-details'>
                  <div className='card'>
                    <p>
                      BANK NAME <span>*</span>
                    </p>
                    <input
                      type='text'
                      name=''
                      id=''
                      value={userWalletDetails.bank1}
                    />
                  </div>
                  <div className='card'>
                    <p>
                      ACCOUNT NAME <span>*</span>
                    </p>
                    <input
                      type='text'
                      name=''
                      id=''
                      value={`${userWalletDetails.first_name} ${userWalletDetails.last_name}`}
                    />
                  </div>
                  <div className='card'>
                    <p>
                      ACCOUNT NUMBER <span>*</span>
                    </p>
                    <input
                      ref={textAreaRef}
                      type='tel'
                      name=''
                      id=''
                      value={userWalletDetails.account_no1}
                      onClick={copyAccount}
                    />
                    {accountCopied && (
                      <p
                        style={{
                          color: 'green',
                          padding: '5px 0',
                          fontSize: '10px',
                        }}
                      >
                        account number coppied
                      </p>
                    )}
                  </div>
                  <div className='card'>
                    <p>
                      ACCOUNT TYPE <span>*</span>
                    </p>
                    <input
                      type='text'
                      name=''
                      id=''
                      value={userWalletDetails.account_type}
                      onClick={copyAccount}
                    />
                  </div>
                  <div className='button'></div>
                </div>
              </div>
            </div>
          )}
          {fundingTab === 'bank_transfer-naira' && (
            <div className='bank-transfer'>
              <div className='bank-transfer_wrap'>
                <div
                  className='wallet_transfer-body_top'
                  style={{ paddingBottom: '30px' }}
                >
                  <h2>Bank Transfer</h2>
                  <p>
                    You can fund your wallet by paying directly to your
                    designated account details below.
                  </p>
                </div>
                <div className='bank-details'>
                  <div className='card'>
                    <p>
                      BANK NAME <span>*</span>
                    </p>
                    <input
                      type='text'
                      name=''
                      id=''
                      value={sudoDetails.bank1}
                    />
                  </div>
                  <div className='card'>
                    <p>
                      ACCOUNT NAME <span>*</span>
                    </p>
                    <input
                      type='text'
                      name=''
                      id=''
                      value={`${sudoDetails.account_name}`}
                    />
                  </div>
                  <div className='card'>
                    <p>
                      ACCOUNT NUMBER <span>*</span>
                    </p>
                    <input
                      ref={textAreaRef}
                      type='tel'
                      name=''
                      id=''
                      value={sudoDetails.account_no1}
                      onClick={copyAccount}
                    />
                    {accountCopied && (
                      <p
                        style={{
                          color: 'green',
                          padding: '5px 0',
                          fontSize: '10px',
                        }}
                      >
                        account number coppied
                      </p>
                    )}
                  </div>
                  <div className='card'>
                    <p>
                      ACCOUNT TYPE <span>*</span>
                    </p>
                    <input
                      type='text'
                      name=''
                      id=''
                      value={sudoDetails.account_type}
                      onClick={copyAccount}
                    />
                  </div>
                  <div className='button'></div>
                </div>
              </div>
            </div>
          )}
          {fundingTab === 'user_request' && (
            <div className='bank-transfer'>
              {reqPage === 'default' && (
                <div className='bank-transfer_wrap'>
                  {reqLoading && (
                    <div className='loade'>
                      <img src={Loader} alt='loading...' />
                    </div>
                  )}
                  <div
                    className='wallet_transfer-body_top'
                    style={{ paddingBottom: '30px' }}
                  >
                    <h2>Send Request</h2>
                    <p>Request fund from a friend</p>
                  </div>
                  <div className='bank-details'>
                    <div className='card'>
                      <p>
                        Amount (NGN) <span>*</span>
                      </p>
                      <input
                        type='tel'
                        name=''
                        id=''
                        onChange={(e) => setTransferAmmount(e.target.value)}
                      />
                    </div>
                    <div className='card diff'>
                      <p>
                        Spendwise Username{' '}
                        {accountNumberCorrect ? <FcOk /> : <FcCancel />}
                      </p>
                      <div>
                        <input
                          type='text'
                          onChange={(e) => {
                            setSpendwiseUsername(e.target.value)
                          }}
                          defaultValue={spendwiseUsername}
                        />
                      </div>
                      {accountNumberFecthing === false && (
                        <p
                          style={{
                            color: 'red',
                            fontSize: '10px',
                            paddingTop: '5px',
                          }}
                        >
                          {walletError}
                        </p>
                      )}
                      <button onClick={() => fetchWalletUsername()}>
                        Find User
                      </button>
                    </div>
                    <div className='card diff'>
                      <p>Account Name</p>
                      <input
                        type='text'
                        value={walletDetails.account_name}
                        disabled
                        readOnly
                      />
                    </div>
                    <div className='card diff'>
                      <p>Naration</p>
                      <textarea
                        name=''
                        id=''
                        cols='30'
                        rows='5'
                        onChange={(e) => setTransferNarration(e.target.value)}
                      ></textarea>
                    </div>

                    <div className='button'>
                      <button>Back</button>
                      <button>Proceed</button>
                    </div>
                  </div>
                </div>
              )}
              {reqPage === 'success' && (
                <div className='req-sucess'>
                  <div className='req-top'>
                    <h2>Request Successful</h2>
                    <p>
                      You have successfully sent a request for fund from{' '}
                      {spendwiseUsername}
                    </p>
                  </div>
                  <div className='req-center'>
                    <FcOk />
                    <h3>Successful</h3>
                  </div>
                  <div className='req-botom'>
                    <button
                      onClick={() => {
                        window.location.reload()
                      }}
                    >
                      Done
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}
        </main>
      </div>
    </div>
  )
}

export default FundWallet
