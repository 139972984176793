import React, { useEffect, useState, createContext, useContext } from 'react'
import { useGlobalContext } from '../../../../Context/Context'
import './Finance.scss'
import { FaPlus, FaLongArrowAltDown, FaLongArrowAltUp } from 'react-icons/fa'
import { RiRefreshLine, RiHome4Fill } from 'react-icons/ri'
import { BsThreeDotsVertical } from 'react-icons/bs'
import API from '../../../../Context/API'
import monthDataArray from '../../../../Data/Month'
import Year from '../../../../Data/Year'
import { useForm } from 'react-hook-form'
import Connect from '@mono.co/connect.js'
import { useNavigate } from 'react-router'

const BankContext = createContext()
const BankProvider = ({ children, notify }) => {
  const [bankCards, setBankCards] = useState([])
  const authToken = sessionStorage.getItem('userId')
  const [showFinance, setShowFinance] = useState('false')
  const [financeFound, setfinanceFound] = useState(false)
  const [foundFinance, setfoundFinance] = useState({})
  const [bankTransactions, setBankTransactions] = useState([])
  const [bankTransactionsSeen, setBankTransactionsSeen] = useState(false)
  const [mergeCheck, setMergeCheck] = useState(false)
  const [mergedTransactions, setMergeTransactions] = useState({})

  useEffect(() => {
    API.bankDetails().then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          const keys = Object.values(data.result)
          setBankCards(keys)
        })
      } else {
        res.json().then((data) => {
          notify('error', data.message)
        })
      }
    })
  }, [])
  const toggleFinance = () => {
    setShowFinance(!showFinance)
  }

  const onFetch = (formData) => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    const month = parseInt(formData.month)
    fetch(`${process.env.REACT_APP_API_URL}/tx/bank_summary/`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
      body: JSON.stringify({
        month: month,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            const keys = Object.values(data.result)
            setBankCards(keys)
          })
        } else if (res.status === 404) {
          res.json().then((data) => {})
        } else {
          res.json().then((data) => {})
        }
      })
      .catch((err) => {})
  }
  const findFinance = (id) => {
    const financeData = bankCards.find((item) => item.bank_name === id)
    setfoundFinance(financeData)
    setfinanceFound(true)
    toggleFinance()
    fetch(`${process.env.REACT_APP_API_URL}/tx/account_trail/`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
      body: JSON.stringify({
        account_id: financeData.bank_id,
      }),
    }).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setBankTransactions(data.results)
          setBankTransactionsSeen(true)
        })
      } else {
        res.json().then((data) => {})
        // notify('error', 'An error Occured please contact support. FM')
      }
    })
  }

  return (
    <BankContext.Provider
      value={{
        bankCards,
        onFetch,
        foundFinance,
        financeFound,
        showFinance,
        findFinance,
        toggleFinance,
        notify,
        bankTransactions,
        bankTransactionsSeen,
        setBankCards,
        // mergeCheck,
        // mergedTransactions,
      }}
    >
      {children}
    </BankContext.Provider>
  )
}

function Finance({ mono, notify }) {
  const defaultMonth = monthDataArray[new Date().getMonth()]
  const authToken = sessionStorage.getItem('userId')
  // const { findFinance } = useGlobalContext()
  const { bankCards, onFetch, findFinance, setBankCards } = useBankContext()
  const [codeLoaded, setCodeLoaded] = useState(false)
  const { register, handleSubmit } = useForm()
  const [loadingState, setLoadingState] = useState(false)
  const [refreshLoading, setRefreshLoading] = useState(false)
  const [authConnect, setAuthConnect] = useState('')
  const [founddBank, setFounddBank] = useState({})
  const navigate = useNavigate()
  useEffect(() => {
    document.title = 'Spendwise | Finance'
  }, [])
  const fetchAuthcode = (bank_name) => {
    const foundBank = bankCards.find((item) => item.bank_name === bank_name)
    setLoadingState(true)

    fetch(`${process.env.REACT_APP_API_URL}/tx/get_reauth_code/`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
      body: JSON.stringify({
        bank_id: foundBank.mono_id,
      }),
    }).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setAuthConnect(data.message)
          setCodeLoaded(true)
        })
      } else {
        res.json().then((data) => {
          notify('error', data.message)
        })
      }
    })
  }
  const refreshAccount = (bank_name) => {
    setRefreshLoading(true)
    const foundBank = bankCards.find((item) => item.bank_name === bank_name)
    setFounddBank(foundBank)
    fetch(`${process.env.REACT_APP_API_URL}/tx/mono_refresh_all/`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
      body: JSON.stringify({
        bank_id: foundBank.bank_id,
      }),
    }).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          notify('success', data.message)
          API.bankDetails().then((res) => {
            if (res.status === 200) {
              res.json().then((data) => {
                const keys = Object.values(data.result)
                setBankCards(keys)
                setRefreshLoading(false)
              })
            } else {
              res.json().then((data) => {
                notify('error', data.message)
                setRefreshLoading(false)
              })
            }
          })
        })
      } else {
        res.json().then((data) => {
          notify('error', data.message)
          setRefreshLoading(false)
        })
      }
    })
  }
  const monoConnect = React.useMemo(() => {
    if (codeLoaded === true) {
      const monoInstance = new Connect({
        onClose: () => {
          notify('warn', 'Mono Widget closed')
          setLoadingState(false)
        },
        onLoad: () => {
          monoConnect.open()
        },
        onSuccess: () => {
          window.location.reload()
        },
        key: 'live_pk_vMtEKmsXmcIsTTLrHUeh',
      })
      monoInstance.reauthorise(authConnect)

      // monoInstance.setup()

      return monoInstance
      // eslint-disable-next-line
    }
  }, [authConnect, authToken, codeLoaded, notify])

  const BankCard = ({ item }) => (
    <div className='fin-row' key={item.bank_name}>
      {refreshLoading && (
        <>
          {founddBank.bank_name === item.bank_name && (
            <div className='loading'>
              <p>loading..</p>
            </div>
          )}
        </>
      )}
      <div className='fin-cards'>
        <div className='circle1'></div>
        <div className='circle2'></div>
        <div className='bank top'>
          {item.account_type !== 'SpendWise Wallet - Savings' ? (
            <div className='bank-name'>
              <p>{item.bank_name}</p>

              <span>{item.account_type}</span>
            </div>
          ) : (
            <div className='bank-name'>
              <p>{item.account_type}</p>
            </div>
          )}

          {item.reauth_required ? (
            <div className='re-auth'>
              <button
                onClick={() => {
                  fetchAuthcode(item.bank_name)
                }}
              >
                {loadingState ? 'loading...' : ' Re-Auth'}
              </button>
            </div>
          ) : (
            <div className='month'>
              <div>
                <p>{item.month}</p>
              </div>
            </div>
          )}
        </div>
        <div className='bank'>
          <div className='balance'>
            <p>Balance</p>
            <span>
              &#8358;
              {item.balance === null ? '0' : item.balance.toLocaleString()}
            </span>
            <p className='asat'>as at: {item.as_at}</p>
          </div>
          <div className='dot'>
            {item.account_type !== 'SpendWise Wallet - Savings' && (
              <BsThreeDotsVertical
                onClick={() => {
                  findFinance(item.bank_name)
                }}
              />
            )}
          </div>
        </div>
        <div className='cycle'>
          <div className='flow'>
            <div className='flow-sign'>
              <div className='sign'>
                <span>
                  <FaLongArrowAltDown />{' '}
                </span>
                <p>Inflow</p>
              </div>

              <p className='mon'> &#8358;{item.inflows.toLocaleString()}</p>
            </div>
            <div className='flow-sign'>
              <div className='sign'>
                <span>
                  <FaLongArrowAltUp />{' '}
                </span>{' '}
                <p>Outflow</p>
              </div>
              <p className='mon'> &#8358;{item.outflows.toLocaleString()}</p>
            </div>
          </div>
          {item.account_type !== 'SpendWise Wallet - Savings' ? (
            <RiRefreshLine
              className='refresh'
              onClick={() => {
                refreshAccount(item.bank_name)
              }}
            />
          ) : (
            <RiHome4Fill
              className='refresh'
              onClick={() => navigate('/e-wallet/dashboard')}
            />
          )}
        </div>
      </div>
    </div>
  )

  return (
    <div className='finance'>
      <div className='head'>
        <div>
          <h1>My Finance</h1>
          <p>View account summary and add accounts</p>
        </div>
        <button onClick={() => mono.open()}>
          <FaPlus />
          <p>Add bank account</p>
        </button>
      </div>
      <form className='sort diff' onSubmit={handleSubmit(onFetch)}>
        <div className='select-wrap'>
          <select name='month' id='' {...register('month')}>
            <option value={defaultMonth.id}>{defaultMonth.Content}</option>
            {monthDataArray.map((item) => (
              <option key={item.id} value={item.id}>
                {item.Content}
              </option>
            ))}
          </select>
          <select name='year' id='' {...register('year')}>
            <option value='2022'>2022</option>
            {Year.map((item) => (
              <option key={item.id} value={item.id}>
                {item.Content}
              </option>
            ))}
          </select>
        </div>
        <button>View archive</button>
      </form>
      <div className='body'>
        {bankCards.map((item) => (
          <BankCard key={item.bank_name} item={item} />
        ))}
      </div>
    </div>
  )
}

export const useBankContext = () => {
  return useContext(BankContext)
}

export { BankContext, BankProvider }
export default Finance
