import CoutriedData from './CountriedData'
import EuroData from './EuroData'
import Usd from '../Image/united-states-flag-icon.svg'
import Gbp from '../Image/united-kingdom-flag-icon.svg'
import Eur from '../Image/europe-flag-icon.svg'
import Cny from '../Image/china-flag-icon.svg'
import Cad from '../Image/canada-flag-icon.svg'

const ForeignData = [
  {
    sign: '$',
    flag: Cad,
    currency: 'CAD',
    country: [{ name: 'Canada', shortcode: 'CD' }],
    accounts: ['A/C number + Swift'],
  },
  {
    sign: '¥',
    flag: Cny,
    currency: 'CNY',
    country: [{ name: 'China', shortcode: 'CH' }],
    accounts: ['A/C number + CNAPS', 'A/C number + Swift'],
  },
  {
    sign: '€',
    flag: Eur,
    currency: 'EUR',
    country: EuroData,
    accounts: ['Swift + IBAN'],
  },
  {
    sign: '£',
    flag: Gbp,
    currency: 'GBP',
    country: [{ name: 'United Kingdom', shortcode: 'UK' }],
    accounts: ['A/C number + Sort code', 'Swift + IBAN'],
  },
  {
    sign: '$',
    flag: Usd,
    currency: 'USD',
    country: CoutriedData,
    accounts: ['A/C number + Routing Number/ABA Code', 'A/C number + Swift'],
  },
]

export default ForeignData
