import React from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import { useGlobalContext } from '../../../../Context/Context'

function BudgetButtons() {
  const { toggleBBudget, toggleBudget, toggleGBudget } = useGlobalContext()

  return (
    <div className='createbudget'>
      <div className='close'>
        <AiOutlineClose onClick={toggleBBudget} />
      </div>
      <div className='buttons'>
        <div>
          <button className='normal' onClick={toggleBudget}>
            Create Budget by category
          </button>
        </div>
        <div>
          <button className='general' onClick={toggleGBudget}>
            Create General Budget
          </button>
        </div>
      </div>
    </div>
  )
}

export default BudgetButtons
