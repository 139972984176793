import React, { useState, useEffect } from 'react'
import { AiOutlineCaretDown } from 'react-icons/ai'
import { useGlobalContext } from '../../../../../Context/Context'
import { useNavigate, useParams } from 'react-router'
import Loader from '../../../../../Image/loder.gif.gif'
import excla from '../../../../../Image/alert-circle.png'
import API from '../../../../../Context/API'
import { BsExclamationCircle } from 'react-icons/bs'

function EditUser({ notify, Floading, UserList }) {
  const { new_id } = useParams()
  const authToken = sessionStorage.getItem('userId')
  const { toggleShowCreateDept, departmentList } = useGlobalContext()
  const navigate = useNavigate()
  const [showDepartmentList, setShowDepartmentList] = useState('false')
  const [showRoleList, setShowRoleList] = useState('false')
  const [selectedDepartment, setSelectedDepartment] =
    useState('Select Department')
  const [selectedDepartmentId, setSelectedDepartmentId] = useState('')
  const roleArray = [
    'admin',
    'auditor',
    'developer',
    'finance',
    'officer',
    'team_lead',
  ]
  const [roles, setRoles] = useState([])
  const [title, setTitle] = useState('')
  const [formLoading, setFormLoading] = useState(false)
  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState({})
  const selectingDepartment = (name) => {
    setSelectedDepartment(name)
    if (!showDepartmentList) {
      setShowDepartmentList(!showDepartmentList)
    }
  }
  function handleRoleClick(role) {
    // create a copy of the current roles array
    const rolesList = [...roles]

    // check if the role is already in the list
    if (rolesList.includes(role)) {
      // remove the role from the list
      const updatedRoles = rolesList.filter((r) => r !== role)
      // update the state with the updated array
      setRoles(updatedRoles)
    } else {
      // add the role to the list
      rolesList.push(role)
      // update the state with the updated array
      setRoles(rolesList)
    }
  }

  const [showDeletModal, setShowDeletModal] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [showSuspendModal, setShowSuspendModal] = useState(false)
  const [suspendLoading, setsuspendLoading] = useState(false)

  useEffect(() => {
    const foundUser = UserList.results.find(
      (item) => item.id === parseInt(new_id)
    )
    setUser(foundUser)
    if (foundUser) {
      setLoading(false)
      setSelectedDepartment(foundUser.department)
      setRoles(
        foundUser.roles
          .toLowerCase()
          .split(',')
          .map((role) => role.trim())
      )
      setTitle(foundUser.title)
    }
  }, [Floading])
  const ConfirmUser = () => {
    if (selectedDepartment === 'Select Department') {
      notify('warn', 'Select a Department')
    } else if (roles.length < 1) {
      notify('warn', 'select at least one role')
    } else if (title.length < 1) {
      notify('warn', 'Input a title')
    } else {
      setFormLoading(true)
      fetch(`${process.env.REACT_APP_API_URL}/accounts/update_bu/`, {
        method: 'post',
        headers: {
          'content-Type': 'application/json',
          Authorization: `Token ${authToken}`,
        },
        body: JSON.stringify({
          bu_id: new_id,
          department_id: selectedDepartmentId,
          name: selectedDepartment,
          roles: roles,
          title: title,
        }),
      }).then((res) => {
        if (res.status === 200) {
          setFormLoading(false)
          notify('success', 'User edited successufully.')
          setTimeout(() => {
            window.location.replace('/user-management/dashboard')
          }, 300)
        } else {
          res.json().then((data) => {
            notify('error', data.message)
            setFormLoading(false)
          })
        }
      })
    }
  }
  const DeleteUser = () => {
    setDeleteLoading(true)
    fetch(`${process.env.REACT_APP_API_URL}/accounts/update_bu/`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
      body: JSON.stringify({
        bu_id: new_id,
        action: 'delete',
      }),
    }).then((res) => {
      if (res.status === 200) {
        setDeleteLoading(false)
        notify('success', 'User Deleted successufully.')
        setTimeout(() => {
          window.location.replace('/user-management/dashboard')
        }, 300)
      } else {
        res.json().then((data) => {
          notify('error', data.message)
          setDeleteLoading(false)
        })
      }
    })
  }
  const SuspendUser = () => {
    setsuspendLoading(true)
    fetch(`${process.env.REACT_APP_API_URL}/accounts/update_bu/`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
      body: JSON.stringify({
        bu_id: new_id,
        action: 'suspend',
      }),
    }).then((res) => {
      if (res.status === 200) {
        setsuspendLoading(false)
        notify('success', 'User Suspended successufully.')
        setTimeout(() => {
          window.location.reload()
        }, 300)
      } else {
        res.json().then((data) => {
          notify('error', data.message)
          setsuspendLoading(false)
        })
      }
    })
  }

  return loading ? (
    <h1>Loading</h1>
  ) : (
    <div className='user-management'>
      {showDeletModal && (
        <div className='modalx'>
          <div className='cover'>
            <div className='topx'>
              <img src={excla} alt='' />
              <h2>Are you sure you want delete this User?</h2>
              <p>User’s data has not been saved and will be lost.</p>
            </div>
            <div className='buttons'>
              <button onClick={() => setShowDeletModal(false)}>Cancel</button>
              {deleteLoading ? (
                <img src={Loader} alt='' style={{ width: '50px' }} />
              ) : (
                <button onClick={DeleteUser}>Proceed</button>
              )}
            </div>
          </div>
        </div>
      )}
      {showSuspendModal && (
        <div className='modalx'>
          <div className='cover'>
            <div className='topx'>
              <img src={excla} alt='' />
              <h2>Are you sure you want Suspend this User?</h2>
              <p>User’s data has not been saved and will be lost.</p>
            </div>
            <div className='buttons'>
              <button onClick={() => setShowSuspendModal(false)}>Cancel</button>
              {suspendLoading ? (
                <img src={Loader} alt='' style={{ width: '50px' }} />
              ) : (
                <button onClick={SuspendUser}>Proceed</button>
              )}
            </div>
          </div>
        </div>
      )}

      <div className='form'>
        <div className='topx'>
          <h2>Edit User</h2>
          <p>Edit your team member</p>
        </div>
        <div className='down'>
          <div
            className='card'
            onClick={() => {
              if (!showDepartmentList) {
                setShowDepartmentList(!showDepartmentList)
              }
              if (!showRoleList) {
                setShowRoleList(!showRoleList)
              }
            }}
          >
            <div className='left'>
              <h2>Username</h2>
            </div>
            <div className='right'>
              <input type='text' disabled value={user.username} />
            </div>
          </div>
          <div
            className='card'
            onClick={() => {
              if (!showDepartmentList) {
                setShowDepartmentList(!showDepartmentList)
              }
              if (!showRoleList) {
                setShowRoleList(!showRoleList)
              }
            }}
          >
            <div className='left'>
              <h2>Email</h2>
            </div>
            <div className='right'>
              <input type='text' disabled value={user.email} />
            </div>
          </div>
          <div
            className='card'
            onClick={() => {
              if (!showDepartmentList) {
                setShowDepartmentList(!showDepartmentList)
              }
              if (!showRoleList) {
                setShowRoleList(!showRoleList)
              }
            }}
          >
            <div className='left'>
              <h2>Phone</h2>
            </div>
            <div className='right'>
              <input type='text' disabled value={user.phone} />
            </div>
          </div>
          <div className='card'>
            <div className='left'>
              <h2> Department</h2>
            </div>
            <div className='right'>
              <div className='select'>
                <div
                  className='select-top select'
                  onClick={() => {
                    if (!showRoleList) {
                      setShowDepartmentList(!showDepartmentList)
                      setShowRoleList(!showRoleList)
                    } else {
                      setShowDepartmentList(!showDepartmentList)
                    }
                  }}
                >
                  <p>{selectedDepartment}</p>
                  <AiOutlineCaretDown />
                </div>
                {!showDepartmentList && (
                  <div className='select-body'>
                    <ul>
                      {departmentList.map((item) => (
                        <li
                          key={item.name}
                          onClick={() => {
                            selectingDepartment(item.name)
                            setSelectedDepartmentId(item.id)
                          }}
                        >
                          {item.name}
                        </li>
                      ))}
                    </ul>
                    <p onClick={toggleShowCreateDept}>Add New Department</p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='card'>
            <div className='left'>
              <h2> Role</h2>
            </div>
            <div className='right'>
              <div className='select'>
                <div
                  className='select-top select'
                  onClick={() => {
                    if (!showDepartmentList) {
                      setShowDepartmentList(!showDepartmentList)
                      setShowRoleList(!showRoleList)
                    } else {
                      setShowRoleList(!showRoleList)
                    }
                  }}
                >
                  <p>
                    {roles.length > 0 ? (
                      <> {roles.join(', ')}</>
                    ) : (
                      'Select Role'
                    )}
                  </p>
                  <AiOutlineCaretDown />
                </div>
                {!showRoleList && (
                  <div className='select-body'>
                    <ul>
                      {roleArray.map((item) => (
                        <li
                          key={item}
                          onClick={() => handleRoleClick(item)}
                          className={roles.includes(item) ? 'active' : ''}
                        >
                          {item}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div
            className='card'
            onClick={() => {
              if (!showDepartmentList) {
                setShowDepartmentList(!showDepartmentList)
              }
              if (!showRoleList) {
                setShowRoleList(!showRoleList)
              }
            }}
          >
            <div className='left'>
              <h2>Title</h2>
            </div>
            <div className='right'>
              <input
                type='text'
                placeholder='Enter user’s title'
                onChange={(e) => setTitle(e.target.value)}
                defaultValue={user.title}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='buttons'>
        <button onClick={() => navigate('/user-management/dashboard')}>
          Back
        </button>

        {formLoading ? (
          <img src={Loader} alt='' style={{ width: '50px' }} />
        ) : (
          <button onClick={ConfirmUser}>Edit User</button>
        )}
        <button onClick={() => setShowSuspendModal(true)}>
          {user.is_suspended ? 'Unsuspend' : 'Suspend'}
        </button>
        <button onClick={() => setShowDeletModal(true)}>Delete User</button>
      </div>
    </div>
  )
}

export default EditUser
