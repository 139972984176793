import React from 'react'
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import logo from './images/Spendwise.svg'
import laptop from './images/laptop.png'
import graph from './images/Graph.png'
import person from './images/Personalized.png'
import credit from './images/Credits.png'
import dashboard from './images/Dashboard.png'
import secured from './images/Secured Laptop.png'
import logo2 from './images/Spendwise footer.svg'
import card1 from './images/Card1.png'
import card2 from './images/Card2.png'
import one from './images/1.png'
import two from './images/2.png'
import three from './images/3.png'
import facebook from './images/facebook.svg'
import twitter from './images/twitter.svg'
import linkedin from './images/linkedin.svg'
import { FaBars } from 'react-icons/fa'
import { IoMdClose } from 'react-icons/io'
import './Landingpage.scss'
import Navigation from '../../Component/Navigation/Navigation'
import Typewriter from 'typewriter-effect'
import Footer from '../../Component/Footer/Footer'
import { useNavigate } from 'react-router-dom'
import FrequentQuestions from '../../Component/FrequentQuestions/FrequentQuestions'

const LandingPage = () => {
  const navigate = useNavigate()
  const cool_feats = [
    {
      id: 1,
      image: graph,
      header: 'Track Spending Across Categories, Set Budgets.',
      content:
        'Track both electronic and cash expenses across categories. Set budgets and spending limits.',
    },
    {
      id: 2,
      image: person,
      header: 'Personalized Business Advisory.',
      content:
        'Get real-time personalized advise on your business finance and cashflow. Coming soon...',
    },
    {
      id: 3,
      image: credit,
      header: 'Access Credit Or Pay In Installments',
      content:
        'Get access to credit for your business or pay in installments for them.  Coming soon...',
    },
  ]
  const cards_feats = [
    {
      id: 1,
      image: one,
      header: 'All Expenses Under Control.',
      content:
        'For one-time expenses or monthly subscriptions. Set individual limits and keep an eye on all costs. Automate expense monitoring.',
    },
    {
      id: 2,
      image: two,
      header: 'Unlimited Corporate Expense Cards.',
      content:
        'Create unlimited number of virtual and physical cards, for all spending, and across any categories of choice within seconds.',
    },
    {
      id: 3,
      image: three,
      header: 'Corporate Cards For Employees.',
      content:
        'Create unlimited cards for individual employees of choice. Control where employees cards can be used.',
    },
  ]
  useEffect(() => {
    document.title = 'Spendwise Expense Management'
  }, [])
  return (
    <div className='Landing'>
      <Navigation />
      <div className='landing_hero'>
        <div className='top'>
          <h1>Expense management and payment solution for businesses.</h1>
        </div>
        <div className='hero-wrapper'>
          <div className='hero-left'>
            <p className='automate'>
              Automatically categorize and control your business spend across
              multiple accounts.
            </p>
            <div className='typewritter'>
              {' '}
              <Typewriter
                options={{
                  strings: [
                    // 'Get personalized financial advisory.',
                    // 'Get credit for business expenses.',
                    'Get virtual & physical corporate cards.',
                    'Get Virtual USD and Naira cards.',
                  ],
                  autoStart: true,
                  loop: true,
                  delay: '1',
                }}
                className='typist'
              />
            </div>
            <div className='call_to_action'>
              <input type='email' placeholder={`what's your work email?`} />
              <button onClick={() => navigate('/register')}>Get Started</button>
            </div>
          </div>
          <div className='hero-right'>
            <img src={laptop} alt='' />
          </div>
        </div>
      </div>
      <div className='landing_features'>
        <div className='top'>
          <h1>Cool & Great Features.</h1>
          <p>
            Here are some amazing features to help keep your business finance on
            track!
          </p>
        </div>
        <div className='feat_wrap'>
          {cool_feats.map((item) => (
            <div key={item.id} className='card'>
              <div className='image'>
                <img src={item.image} alt='' />
              </div>
              <h2>{item.header}</h2>
              <p>{item.content}</p>
              {/* <button>Learn More</button> */}
            </div>
          ))}
        </div>
      </div>
      <div className='landing_cards'>
        <div className='left'>
          <div className='top'>
            <h1>Get The Spendwise</h1>
            <h1 className='diff'>Business Debit Cards.</h1>
            <p>The right corporate debit card for your business.</p>
          </div>
          <div className='image'>
            <img src={card1} alt='' />
          </div>
          <div className='image two'>
            <img src={card2} alt='' />
          </div>
        </div>
        <div className='right'>
          <div className='top'>
            <h1>Get The Spendwise</h1>
            <h1 className='diff'>Business Debit Cards.</h1>
            <p>The right corporate debit card for your business.</p>
          </div>
          <div className='right_wrap'>
            {cards_feats.map((item) => (
              <div className='card' key={item.id}>
                <div className='image'>
                  <img src={item.image} alt='' />
                </div>
                <div>
                  <h2>{item.header}</h2>
                  <p className='cont'>{item.content}</p>
                  {/* <p className='ler'>Learn More {`>>>`}</p> */}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className='landing_company'>
        <h1>
          YOU ARE IN <br /> <span>GOOD</span> COMPANY.
        </h1>
        <p>
          Spendwise ensures all monetary operations are executed & monitored
          seamlessly, via the use of our unique & simple expense management
          tools that suits you & your business.
        </p>
      </div>
      <div className='landing_start'>
        <div className='left'>
          <img src={dashboard} alt='' />
        </div>
        <div className='right'>
          <h2>Start Smart Spending With Spendwise, Today.</h2>
          <p>
            Connect your existing business bank accounts to SpendWise and create
            unlimited physical and virtual corporate cards for all types of
            expenses, merchants and teams. Track expenses in real time.
          </p>
          <Link to='/register'>
            <button>Get Started</button>
          </Link>
        </div>
      </div>
      <div className='landing_security'>
        <div className='left'>
          <h2>Spendwise Security</h2>
          <p>
            We are deliberate with security and diligently protect your
            financial information with industry-leading standards and
            technology. Our security features includes:
          </p>
          <ul>
            <li>- Cross site scripting (XSS) protection</li>
            <li>- Cross site request forgery (CSRF) protection</li>
            <li> - SQL injection protection</li>
            <li>- Clickjacking protection</li>
            <li>
              - Spendwise proprietary walking dead token framework for security
              visibility.
            </li>
            {/* <li>
              - Forced SSL/HTTPS connections with AWS certificates (supports
              security standards including PCI-DSS, HIPAA/HITECH, FedRAMP, GDPR,
              FIPS 140-2, and NIST 800-171), helping customers satisfy
              compliance requirements for virtually every regulatory agency
              around the globe.
            </li> */}
          </ul>
        </div>
        <div className='right'>
          <img src={secured} alt='' />
        </div>
      </div>
      <FrequentQuestions />
      <Footer />
    </div>
  )
}

export default LandingPage
