import React, { useState } from 'react'
import VerveLogo from '../../../../../Image/vereve.png'
import VerveLogo3 from '../../../../../Image/masterlogo.png'
import Logo from '../../../../../Image/cardlogo.png'
import Logo2 from '../../../../../Image/cardlogo2.png'

function CardBox({ item, navigate }) {
  return (
    <div
      className='card-box dit'
      key={item.id}
      onClick={() => navigate(`/debit-card/edit/${item.id}`)}
    >
      <div className='card-box_top'>
        <h2>
          {item.alias}{' '}
          <span>
            ({item.type_of} {item.currency} card)
          </span>
        </h2>
      </div>
      <div className='credit-card-body' id={item.currency}>
        <div className='credit-card-body-top'>
          {item.currency === 'USD' ? (
            <img src={Logo2} alt='' />
          ) : (
            <img src={Logo} alt='' />
          )}
          {item.currency === 'USD' && <p>${item.balance.toLocaleString()}</p>}
          {item.currency === 'NGN' && <p> ₦{item.balance.toLocaleString()}</p>}
          {/* <p>-</p> */}
        </div>
        <div className='card-number'>
          <p>{item.pin.slice(0, 4)}</p>
          <p>{item.pin.slice(4, 8)}</p>
          <p>{item.pin.slice(8, 12)}</p>
          <p>{item.pin.slice(12, 16)}</p>
          <p>{item.pin.slice(16, 20)}</p>
        </div>
        <div className='credit-card-body-bottom'>
          <div>
            <p>Card Holder</p>
            <h2>{item.name}</h2>
          </div>
          <div>
            <p>Exp</p>
            <h2>
              {item.exp_month}/{item.exp_year}
            </h2>
          </div>
          <div className='vlogo'>
            {item.currency === 'USD' ? (
              <img src={VerveLogo} alt='' />
            ) : (
              <img src={VerveLogo3} alt='' />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CardBox
