import React, { useState, useEffect } from 'react'
import { ImCheckboxUnchecked } from 'react-icons/im'
import { BsCheckSquare } from 'react-icons/bs'

function ListBox({
  item,
  setBulkAdding,
  BulkAdding,
  deleteBulk,
  bulkAmount,
  notify,
}) {
  let doesExist = BulkAdding.some(function (ele) {
    return ele.card_id === item.card_id
  })
  const [checked, setChecked] = useState(doesExist)

  return (
    <tr key={item.card_id}>
      <td>{item.alias}</td>
      <td>{item.maskedPan}</td>
      <td>
        {item.expiryMonth}/{item.expiryYear}
      </td>
      <td> ₦{0}</td>
      <td className='check'>
        {checked ? (
          <BsCheckSquare
            style={{ color: '#1eb80f' }}
            onClick={() => {
              setChecked(false)
              deleteBulk(item.card_id)
            }}
          />
        ) : (
          <ImCheckboxUnchecked
            onClick={() => {
              if (bulkAmount < 1) {
                notify('error', 'Set an Amount first before selecting cards')
              } else {
                setBulkAdding((bulk) => [
                  ...bulk,
                  {
                    amount: bulkAmount,
                    alias: item.alias,
                    card_id: item.card_id,
                    card_account_id: item.account,
                  },
                ])
                setChecked(true)
              }
            }}
          />
        )}
      </td>
    </tr>
  )
}

export default ListBox
