const sudo_bank_codes = [
  {
    name: 'STERLING BANK',
    routingKey: '000001',
    bankCode: '000001',
    categoryId: '2',
  },
  {
    name: 'KEYSTONE BANK',
    routingKey: '000002',
    bankCode: '000002',
    categoryId: '2',
  },
  {
    name: 'FIRST CITY MONUMENT BANK',
    routingKey: '000003',
    bankCode: '000003',
    categoryId: '2',
  },
  {
    name: 'UNITED BANK FOR AFRICA',
    routingKey: '000004',
    bankCode: '000004',
    categoryId: '2',
  },
  {
    name: 'TRUSTBANC J6 MICROFINANCE BANK LIMITED',
    routingKey: '090123',
    bankCode: '090123',
    categoryId: '9',
  },
  {
    name: 'JAIZ BANK',
    routingKey: '000006',
    bankCode: '000006',
    categoryId: '2',
  },
  {
    name: 'FIDELITY BANK',
    routingKey: '000007',
    bankCode: '000007',
    categoryId: '2',
  },
  {
    name: 'POLARIS BANK',
    routingKey: '000008',
    bankCode: '000008',
    categoryId: '2',
  },
  {
    name: 'CITI BANK',
    routingKey: '000009',
    bankCode: '000009',
    categoryId: '2',
  },
  {
    name: 'ECOBANK',
    routingKey: '000010',
    bankCode: '000010',
    categoryId: '2',
  },
  {
    name: 'UNITY BANK',
    routingKey: '000011',
    bankCode: '000011',
    categoryId: '2',
  },
  {
    name: 'STANBIC IBTC BANK',
    routingKey: '000012',
    bankCode: '000012',
    categoryId: '2',
  },
  {
    name: 'GTBANK PLC',
    routingKey: '000013',
    bankCode: '000013',
    categoryId: '2',
  },
  {
    name: 'ACCESS BANK',
    routingKey: '000014',
    bankCode: '000014',
    categoryId: '2',
  },
  {
    name: 'ZENITH BANK',
    routingKey: '000015',
    bankCode: '000015',
    categoryId: '2',
  },
  {
    name: 'FIRST BANK OF NIGERIA',
    routingKey: '000016',
    bankCode: '000016',
    categoryId: '2',
  },
  {
    name: 'WEMA BANK',
    routingKey: '000017',
    bankCode: '000017',
    categoryId: '2',
  },
  {
    name: 'UNION BANK',
    routingKey: '000018',
    bankCode: '000018',
    categoryId: '2',
  },
  {
    name: 'ENTERPRISE BANK',
    routingKey: '000019',
    bankCode: '000019',
    categoryId: '2',
  },
  {
    name: 'ASTRAPOLARIS MICROFINANCE BANK',
    routingKey: '090172',
    bankCode: '090172',
    categoryId: '9',
  },
  {
    name: 'STANDARD CHARTERED BANK',
    routingKey: '000021',
    bankCode: '000021',
    categoryId: '2',
  },
  {
    name: 'SUNTRUST BANK',
    routingKey: '000022',
    bankCode: '000022',
    categoryId: '2',
  },
  {
    name: 'PROVIDUS BANK',
    routingKey: '000023',
    bankCode: '000023',
    categoryId: '2',
  },
  {
    name: 'CORONATION MERCHANT BANK',
    routingKey: '060001',
    bankCode: '060001',
    categoryId: '6',
  },
  {
    name: 'NPF MICROFINANCE BANK',
    routingKey: '070001',
    bankCode: '070001',
    categoryId: '9',
  },
  {
    name: 'FORTIS MICROFINANCE BANK',
    routingKey: '070002',
    bankCode: '070002',
    categoryId: '9',
  },
  {
    name: 'COVENANT MFB',
    routingKey: '070006',
    bankCode: '070006',
    categoryId: '9',
  },
  {
    name: 'OMOLUABI',
    routingKey: '070007',
    bankCode: '070007',
    categoryId: '7',
  },
  {
    name: 'PAGE MFBank',
    routingKey: '070008',
    bankCode: '070008',
    categoryId: '9',
  },
  {
    name: 'ASO SAVINGS',
    routingKey: '090001',
    bankCode: '090001',
    categoryId: '9',
  },
  {
    name: 'JUBILEE LIFE',
    routingKey: '090003',
    bankCode: '090003',
    categoryId: '9',
  },
  {
    name: 'PARALLEX',
    routingKey: '090004',
    bankCode: '090004',
    categoryId: '9',
  },
  {
    name: 'SEEDVEST MICROFINANCE BANK',
    routingKey: '090369',
    bankCode: '090369',
    categoryId: '9',
  },
  {
    name: 'SAFETRUST',
    routingKey: '090006',
    bankCode: '090006',
    categoryId: '9',
  },
  {
    name: 'FIRST TRUST MORTGAGE BANK PLC',
    routingKey: '090107',
    bankCode: '090107',
    categoryId: '9',
  },
  {
    name: 'NEW PRUDENTIAL BANK',
    routingKey: '090108',
    bankCode: '090108',
    categoryId: '9',
  },
  {
    name: 'FETS',
    routingKey: '100001',
    bankCode: '100001',
    categoryId: '12',
  },
  {
    name: 'PAGA',
    routingKey: '100002',
    bankCode: '100002',
    categoryId: '10',
  },
  {
    name: 'PARKWAY-READYCASH',
    routingKey: '100003',
    bankCode: '100003',
    categoryId: '10',
  },
  {
    name: 'FINCA MICROFINANCE BANK',
    routingKey: '090400',
    bankCode: '090400',
    categoryId: '9',
  },
  {
    name: 'CELLULANT',
    routingKey: '100005',
    bankCode: '100005',
    categoryId: '10',
  },
  {
    name: 'eTRANZACT',
    routingKey: '100006',
    bankCode: '100006',
    categoryId: '10',
  },
  {
    name: 'STANBIC IBTC @Ease WALLET',
    routingKey: '100007',
    bankCode: '100007',
    categoryId: '10',
  },
  {
    name: 'ECOBANK XPRESS ACCOUNT',
    routingKey: '100008',
    bankCode: '100008',
    categoryId: '10',
  },
  {
    name: 'GT MOBILE',
    routingKey: '100009',
    bankCode: '100009',
    categoryId: '10',
  },
  {
    name: 'TEASY MOBILE',
    routingKey: '100010',
    bankCode: '100010',
    categoryId: '10',
  },
  {
    name: 'KUDA MICROFINANCE BANK',
    routingKey: '090267',
    bankCode: '090267',
    categoryId: '9',
  },
  {
    name: 'VT NETWORKS',
    routingKey: '100012',
    bankCode: '100012',
    categoryId: '10',
  },
  {
    name: 'CORESTEP MICROFINANCE BANK',
    routingKey: '090365',
    bankCode: '090365',
    categoryId: '9',
  },
  {
    name: 'CHAMS MOBILE',
    routingKey: '100015',
    bankCode: '100015',
    categoryId: '10',
  },
  {
    name: 'FORTIS MOBILE',
    routingKey: '100016',
    bankCode: '100016',
    categoryId: '10',
  },
  {
    name: 'HEDONMARK',
    routingKey: '100017',
    bankCode: '100017',
    categoryId: '10',
  },
  {
    name: 'ZENITH MOBILE',
    routingKey: '100018',
    bankCode: '100018',
    categoryId: '10',
  },
  {
    name: 'FIDELITY MOBILE',
    routingKey: '100019',
    bankCode: '100019',
    categoryId: '10',
  },
  {
    name: 'MONEY BOX',
    routingKey: '100020',
    bankCode: '100020',
    categoryId: '10',
  },
  {
    name: 'EARTHOLEUM',
    routingKey: '100021',
    bankCode: '100021',
    categoryId: '10',
  },
  {
    name: 'STERLING MOBILE',
    routingKey: '100022',
    bankCode: '100022',
    categoryId: '10',
  },
  {
    name: 'TAGPAY',
    routingKey: '100023',
    bankCode: '100023',
    categoryId: '10',
  },
  {
    name: 'IMPERIAL HOMES MORTGAGE BANK',
    routingKey: '100024',
    bankCode: '100024',
    categoryId: '10',
  },
  {
    name: 'ONE FINANCE',
    routingKey: '100026',
    bankCode: '100026',
    categoryId: '10',
  },
  {
    name: 'INTELLFIN',
    routingKey: '100027',
    bankCode: '100027',
    categoryId: '11',
  },
  {
    name: 'NIP VIRTUAL BANK',
    routingKey: '999999',
    bankCode: '999999',
    categoryId: '11',
  },
  {
    name: 'FINATRUST MICROFINANCE BANK',
    routingKey: '090111',
    bankCode: '090111',
    categoryId: '9',
  },
  {
    name: 'SEED CAPITAL MICROFINANCE BANK',
    routingKey: '090112',
    bankCode: '090112',
    categoryId: '9',
  },
  {
    name: 'TCF MICROFINANCE BANK',
    routingKey: '090115',
    bankCode: '090115',
    categoryId: '9',
  },
  {
    name: 'EMPIRE TRUST MICROFINANCE BANK',
    routingKey: '090114',
    bankCode: '090114',
    categoryId: '9',
  },
  {
    name: 'MICROVIS MICROFINANCE BANK ',
    routingKey: '090113',
    bankCode: '090113',
    categoryId: '9',
  },
  {
    name: 'NOVA MERCHANT BANK',
    routingKey: '060003',
    bankCode: '060003',
    categoryId: '6',
  },
  {
    name: 'AMML MICROFINANCE BANK ',
    routingKey: '090116',
    bankCode: '090116',
    categoryId: '9',
  },
  {
    name: 'YES MICROFINANCE BANK',
    routingKey: '090142',
    bankCode: '090142',
    categoryId: '9',
  },
  {
    name: 'WETLAND  MICROFINANCE BANK',
    routingKey: '090120',
    bankCode: '090120',
    categoryId: '9',
  },
  {
    name: 'REGENT MICROFINANCE BANK',
    routingKey: '090125',
    bankCode: '090125',
    categoryId: '9',
  },
  {
    name: 'INTERLAND MICROFINANCE BANK',
    routingKey: '090386',
    bankCode: '090386',
    categoryId: '9',
  },
  {
    name: 'NDIORAH MICROFINANCE BANK',
    routingKey: '090128',
    bankCode: '090128',
    categoryId: '9',
  },
  {
    name: 'BC KASH MICROFINANCE BANK',
    routingKey: '090127',
    bankCode: '090127',
    categoryId: '9',
  },
  {
    name: 'HASAL MICROFINANCE BANK',
    routingKey: '090121',
    bankCode: '090121',
    categoryId: '9',
  },
  {
    name: 'FBNQUEST MERCHANT BANK',
    routingKey: '060002',
    bankCode: '060002',
    categoryId: '6',
  },
  {
    name: 'AL-BARAKAH MICROFINANCE BANK',
    routingKey: '090133',
    bankCode: '090133',
    categoryId: '9',
  },
  {
    name: 'RICHWAY MICROFINANCE BANK',
    routingKey: '090132',
    bankCode: '090132',
    categoryId: '9',
  },
  {
    name: 'PERSONAL TRUST MICROFINANCE BANK',
    routingKey: '090135',
    bankCode: '090135',
    categoryId: '9',
  },
  {
    name: 'MICROCRED MICROFINANCE BANK',
    routingKey: '090136',
    bankCode: '090136',
    categoryId: '9',
  },
  {
    name: 'GOWANS MICROFINANCE BANK',
    routingKey: '090122',
    bankCode: '090122',
    categoryId: '9',
  },
  {
    name: 'RAND MERCHANT BANK',
    routingKey: '000024',
    bankCode: '000024',
    categoryId: '6',
  },
  {
    name: 'SAGAMU MICROFINANCE BANK',
    routingKey: '090140',
    bankCode: '090140',
    categoryId: '9',
  },
  {
    name: 'ZINTERNET - KONGAPAY',
    routingKey: '100025',
    bankCode: '100025',
    categoryId: '10',
  },
  {
    name: 'BOCTRUST MICROFINANCE BANK LIMITED',
    routingKey: '090117',
    bankCode: '090117',
    categoryId: '9',
  },
  {
    name: 'IBILE MICROFINANCE BANK',
    routingKey: '090118',
    bankCode: '090118',
    categoryId: '9',
  },
  {
    name: 'MONEY TRUST MICROFINANCE BANK',
    routingKey: '090129',
    bankCode: '090129',
    categoryId: '9',
  },
  {
    name: 'LAGOS BUILDING AND INVESTMENT COMPANY',
    routingKey: '070012',
    bankCode: '070012',
    categoryId: '7',
  },
  {
    name: 'GATEWAY MORTGAGE BANK',
    routingKey: '070009',
    bankCode: '070009',
    categoryId: '7',
  },
  {
    name: 'ABBEY MORTGAGE BANK',
    routingKey: '070010',
    bankCode: '070010',
    categoryId: '7',
  },
  {
    name: 'FIRST GENERATION MORTGAGE BANK',
    routingKey: '070014',
    bankCode: '070014',
    categoryId: '7',
  },
  {
    name: 'PLATINUM MORTGAGE BANK',
    routingKey: '070013',
    bankCode: '070013',
    categoryId: '7',
  },
  {
    name: 'INFINITY TRUST MORTGAGE BANK',
    routingKey: '070016',
    bankCode: '070016',
    categoryId: '7',
  },
  {
    name: 'OHAFIA MICROFINANCE BANK',
    routingKey: '090119',
    bankCode: '090119',
    categoryId: '9',
  },
  {
    name: 'XSLNCE MICROFINANCE BANK',
    routingKey: '090124',
    bankCode: '090124',
    categoryId: '9',
  },
  {
    name: 'CONSUMER MICROFINANCE BANK',
    routingKey: '090130',
    bankCode: '090130',
    categoryId: '9',
  },
  {
    name: 'ALLWORKERS MICROFINANCE BANK',
    routingKey: '090131',
    bankCode: '090131',
    categoryId: '9',
  },
  {
    name: 'ACCION MICROFINANCE BANK',
    routingKey: '090134',
    bankCode: '090134',
    categoryId: '9',
  },
  {
    name: 'VISA MICROFINANCE BANK',
    routingKey: '090139',
    bankCode: '090139',
    categoryId: '9',
  },
  {
    name: 'CHIKUM MICROFINANCE BANK',
    routingKey: '090141',
    bankCode: '090141',
    categoryId: '9',
  },
  {
    name: 'APEKS MICROFINANCE BANK',
    routingKey: '090143',
    bankCode: '090143',
    categoryId: '9',
  },
  {
    name: 'CIT MICROFINANCE BANK',
    routingKey: '090144',
    bankCode: '090144',
    categoryId: '9',
  },
  {
    name: 'FULLRANGE MICROFINANCE BANK',
    routingKey: '090145',
    bankCode: '090145',
    categoryId: '9',
  },
  {
    name: 'CEMCS MICROFINANCE BANK',
    routingKey: '090154',
    bankCode: '090154',
    categoryId: '9',
  },
  {
    name: 'DAYLIGHT MICROFINANCE BANK',
    routingKey: '090167',
    bankCode: '090167',
    categoryId: '9',
  },
  {
    name: 'HAGGAI MORTGAGE BANK LIMITED',
    routingKey: '070017',
    bankCode: '070017',
    categoryId: '7',
  },
  {
    name: 'MAINSTREET MICROFINANCE BANK',
    routingKey: '090171',
    bankCode: '090171',
    categoryId: '9',
  },
  {
    name: 'FAST MICROFINANCE BANK',
    routingKey: '090179',
    bankCode: '090179',
    categoryId: '9',
  },
  {
    name: 'UNIVERSITY OF NIGERIA, NSUKKA MICROFINANCE BANK',
    routingKey: '090251',
    bankCode: '090251',
    categoryId: '9',
  },
  {
    name: 'BOSAK MICROFINANCE BANK',
    routingKey: '090176',
    bankCode: '090176',
    categoryId: '9',
  },
  {
    name: 'QUICKFUND MICROFINANCE BANK',
    routingKey: '090261',
    bankCode: '090261',
    categoryId: '9',
  },
  {
    name: 'RENMONEY MICROFINANCE BANK ',
    routingKey: '090198',
    bankCode: '090198',
    categoryId: '9',
  },
  {
    name: 'STELLAS MICROFINANCE BANK ',
    routingKey: '090262',
    bankCode: '090262',
    categoryId: '9',
  },
  {
    name: 'NEW DAWN MICROFINANCE BANK',
    routingKey: '090205',
    bankCode: '090205',
    categoryId: '9',
  },
  {
    name: 'AUCHI MICROFINANCE BANK ',
    routingKey: '090264',
    bankCode: '090264',
    categoryId: '9',
  },
  {
    name: 'AB MICROFINANCE BANK ',
    routingKey: '090270',
    bankCode: '090270',
    categoryId: '9',
  },
  {
    name: 'IMO STATE MICROFINANCE BANK',
    routingKey: '090258',
    bankCode: '090258',
    categoryId: '9',
  },
  {
    name: 'ADEYEMI COLLEGE STAFF MICROFINANCE BANK',
    routingKey: '090268',
    bankCode: '090268',
    categoryId: '9',
  },
  {
    name: 'MEGAPRAISE MICROFINANCE BANK',
    routingKey: '090280',
    bankCode: '090280',
    categoryId: '9',
  },
  {
    name: 'BAINES CREDIT MICROFINANCE BANK',
    routingKey: '090188',
    bankCode: '090188',
    categoryId: '9',
  },
  {
    name: 'FFS MICROFINANCE BANK',
    routingKey: '090153',
    bankCode: '090153',
    categoryId: '9',
  },
  {
    name: 'ADDOSSER MICROFINANCE BANK',
    routingKey: '090160',
    bankCode: '090160',
    categoryId: '9',
  },
  {
    name: 'FIDFUND MICROFINANCE Bank',
    routingKey: '090126',
    bankCode: '090126',
    categoryId: '9',
  },
  {
    name: 'AG MORTGAGE BANK',
    routingKey: '100028',
    bankCode: '100028',
    categoryId: '7',
  },
  {
    name: 'PECANTRUST MICROFINANCE BANK',
    routingKey: '090137',
    bankCode: '090137',
    categoryId: '9',
  },
  {
    name: 'BOWEN MICROFINANCE BANK',
    routingKey: '090148',
    bankCode: '090148',
    categoryId: '9',
  },
  {
    name: 'FUTO MICROFINANCE BANK',
    routingKey: '090158',
    bankCode: '090158',
    categoryId: '9',
  },
  {
    name: 'REFUGE MORTGAGE BANK',
    routingKey: '070011',
    bankCode: '070011',
    categoryId: '7',
  },
  {
    name: 'BRENT MORTGAGE BANK',
    routingKey: '070015',
    bankCode: '070015',
    categoryId: '7',
  },
  {
    name: 'ROYAL EXCHANGE MICROFINANCE BANK',
    routingKey: '090138',
    bankCode: '090138',
    categoryId: '9',
  },
  {
    name: 'HACKMAN MICROFINANCE BANK',
    routingKey: '090147',
    bankCode: '090147',
    categoryId: '9',
  },
  {
    name: 'CONTEC GLOBAL INFOTECH LIMITED(NOWNOW)',
    routingKey: '100032',
    bankCode: '100032',
    categoryId: '10',
  },
  {
    name: 'TRIDENT MICROFINANCE BANK',
    routingKey: '090146',
    bankCode: '090146',
    categoryId: '9',
  },
  {
    name: 'INFINITY MICROFINANCE BANK',
    routingKey: '090157',
    bankCode: '090157',
    categoryId: '9',
  },
  {
    name: 'CREDIT AFRIQUE MICROFINANCE BANK',
    routingKey: '090159',
    bankCode: '090159',
    categoryId: '9',
  },
  {
    name: 'E-BARCS MICROFINANCE BANK',
    routingKey: '090156',
    bankCode: '090156',
    categoryId: '7',
  },
  {
    name: 'VFD MFB',
    routingKey: '090110',
    bankCode: '090110',
    categoryId: '9',
  },
  {
    name: 'ECOMOBILE',
    routingKey: '100030',
    bankCode: '100030',
    categoryId: '10',
  },
  {
    name: 'INNOVECTIVES KESH',
    routingKey: '100029',
    bankCode: '100029',
    categoryId: '10',
  },
  {
    name: 'EKONDO MICROFINANCE BANK',
    routingKey: '090097',
    bankCode: '090097',
    categoryId: '7',
  },
  {
    name: 'VIRTUE MICROFINANCE BANK',
    routingKey: '090150',
    bankCode: '090150',
    categoryId: '9',
  },
  {
    name: 'IRL MICROFINANCE BANK',
    routingKey: '090149',
    bankCode: '090149',
    categoryId: '9',
  },
  {
    name: 'FCMB MOBILE',
    routingKey: '100031',
    bankCode: '100031',
    categoryId: '10',
  },
  {
    name: 'TAJ BANK',
    routingKey: '000026',
    bankCode: '000026',
    categoryId: '2',
  },
  {
    name: 'MUTUAL TRUST MICROFINANCE BANK',
    routingKey: '090151',
    bankCode: '090151',
    categoryId: '9',
  },
  {
    name: 'OKPOGA MICROFINANCE BANK',
    routingKey: '090161',
    bankCode: '090161',
    categoryId: '9',
  },
  {
    name: 'ALERT MICROFINANCE BANK',
    routingKey: '090297',
    bankCode: '090297',
    categoryId: '9',
  },
  {
    name: 'PENNYWISE MICROFINANCE BANK ',
    routingKey: '090196',
    bankCode: '090196',
    categoryId: '9',
  },
  {
    name: 'ALEKUN MICROFINANCE BANK',
    routingKey: '090259',
    bankCode: '090259',
    categoryId: '9',
  },
  {
    name: 'NIGERIAN NAVY MICROFINANCE BANK ',
    routingKey: '090263',
    bankCode: '090263',
    categoryId: '9',
  },
  {
    name: 'ARISE MICROFINANCE BANK',
    routingKey: '090282',
    bankCode: '090282',
    categoryId: '9',
  },
  {
    name: 'GROOMING MICROFINANCE BANK',
    routingKey: '090195',
    bankCode: '090195',
    categoryId: '9',
  },
  {
    name: 'ACCESS MONEY',
    routingKey: '100013',
    bankCode: '100013',
    categoryId: '10',
  },
  {
    name: 'PRESTIGE MICROFINANCE BANK',
    routingKey: '090274',
    bankCode: '090274',
    categoryId: '9',
  },
  {
    name: 'ACCESS(DIAMOND) BANK',
    routingKey: '000005',
    bankCode: '000005',
    categoryId: '2',
  },
  {
    name: 'AFEKHAFE MICROFINANCE BANK',
    routingKey: '090292',
    bankCode: '090292',
    categoryId: '9',
  },
  {
    name: 'BRIGHTWAY MICROFINANCE BANK',
    routingKey: '090308',
    bankCode: '090308',
    categoryId: '9',
  },
  {
    name: 'LAVENDER MICROFINANCE BANK',
    routingKey: '090271',
    bankCode: '090271',
    categoryId: '9',
  },
  {
    name: 'FCT MICROFINANCE BANK',
    routingKey: '090290',
    bankCode: '090290',
    categoryId: '9',
  },
  {
    name: 'IKIRE MICROFINANCE BANK',
    routingKey: '090275',
    bankCode: '090275',
    categoryId: '9',
  },
  {
    name: 'PURPLEMONEY MICROFINANCE BANK',
    routingKey: '090303',
    bankCode: '090303',
    categoryId: '9',
  },
  {
    name: 'PALMPAY',
    routingKey: '100033',
    bankCode: '100033',
    categoryId: '10',
  },
  {
    name: 'SPARKLE',
    routingKey: '090325',
    bankCode: '090325',
    categoryId: '9',
  },
  {
    name: 'BALOGUN GAMBARI MICROFINANCE BANK',
    routingKey: '090326',
    bankCode: '090326',
    categoryId: '9',
  },
  {
    name: 'MAYFRESH MORTGAGE BANK',
    routingKey: '070019',
    bankCode: '070019',
    categoryId: '7',
  },
  {
    name: 'MINT-FINEX MFB',
    routingKey: '090281',
    bankCode: '090281',
    categoryId: '9',
  },
  {
    name: 'EYOWO',
    routingKey: '090328',
    bankCode: '090328',
    categoryId: '9',
  },
  {
    name: 'EVANGEL MICROFINANCE BANK ',
    routingKey: '090304',
    bankCode: '090304',
    categoryId: '9',
  },
  {
    name: 'EVERGREEN MICROFINANCE BANK',
    routingKey: '090332',
    bankCode: '090332',
    categoryId: '9',
  },
  {
    name: 'OCHE MICROFINANCE BANK',
    routingKey: '090333',
    bankCode: '090333',
    categoryId: '9',
  },
  {
    name: 'HEADWAY MICROFINANCE BANK',
    routingKey: '090363',
    bankCode: '090363',
    categoryId: '9',
  },
  {
    name: 'FIRSTMONIE WALLET',
    routingKey: '100014',
    bankCode: '100014',
    categoryId: '10',
  },
  {
    name: 'NEW GOLDEN PASTURES MICROFINANCE BANK',
    routingKey: '090378',
    bankCode: '090378',
    categoryId: '9',
  },
  {
    name: 'FEDPOLY NASARAWA MICROFINANCE BANK',
    routingKey: '090298',
    bankCode: '090298',
    categoryId: '9',
  },
  {
    name: 'MANNY MICROFINANCE BANK',
    routingKey: '090383',
    bankCode: '090383',
    categoryId: '9',
  },
  {
    name: 'DAVODANI  MICROFINANCE BANK',
    routingKey: '090391',
    bankCode: '090391',
    categoryId: '9',
  },
  {
    name: 'FLUTTERWAVE TECHNOLOGY SOLUTIONS LIMITED',
    routingKey: '110002',
    bankCode: '110002',
    categoryId: '12',
  },
  {
    name: 'PAYSTACK PAYMENTS LIMITED',
    routingKey: '110006',
    bankCode: '110006',
    categoryId: '12',
  },
  {
    name: 'ROLEZ MICROFINANCE BANK',
    routingKey: '090405',
    bankCode: '090405',
    categoryId: '9',
  },
  {
    name: 'GREENBANK MICROFINANCE BANK',
    routingKey: '090178',
    bankCode: '090178',
    categoryId: '9',
  },
  {
    name: 'ALPHA KAPITAL MICROFINANCE BANK ',
    routingKey: '090169',
    bankCode: '090169',
    categoryId: '9',
  },
  {
    name: 'PILLAR MICROFINANCE BANK',
    routingKey: '090289',
    bankCode: '090289',
    categoryId: '9',
  },
  {
    name: 'TRUSTFUND MICROFINANCE BANK ',
    routingKey: '090276',
    bankCode: '090276',
    categoryId: '9',
  },
  {
    name: 'OLABISI ONABANJO UNIVERSITY MICROFINANCE ',
    routingKey: '090272',
    bankCode: '090272',
    categoryId: '9',
  },
  {
    name: 'SAFE HAVEN MICROFINANCE BANK',
    routingKey: '090286',
    bankCode: '090286',
    categoryId: '9',
  },
  {
    name: 'NNEW WOMEN MICROFINANCE BANK',
    routingKey: '090283',
    bankCode: '090283',
    categoryId: '9',
  },
  {
    name: 'UNAAB MICROFINANCE BANK',
    routingKey: '090331',
    bankCode: '090331',
    categoryId: '9',
  },
  {
    name: 'YOBE MICROFINANCE  BANK',
    routingKey: '090252',
    bankCode: '090252',
    categoryId: '9',
  },
  {
    name: 'CONPRO MICROFINANCE BANK',
    routingKey: '090380',
    bankCode: '090380',
    categoryId: '9',
  },
  {
    name: 'PAYATTITUDE ONLINE',
    routingKey: '110001',
    bankCode: '110001',
    categoryId: '12',
  },
  {
    name: 'LAPO MICROFINANCE BANK',
    routingKey: '090177',
    bankCode: '090177',
    categoryId: '0',
  },
  {
    name: 'HERITAGE BANK',
    routingKey: '000020',
    bankCode: '000020',
    categoryId: '2',
  },
  {
    name: 'NEPTUNE MICROFINANCE BANK',
    routingKey: '090329',
    bankCode: '090329',
    categoryId: '9',
  },
  {
    name: 'PATRICKGOLD MICROFINANCE BANK',
    routingKey: '090317',
    bankCode: '090317',
    categoryId: '9',
  },
  {
    name: 'ABU MICROFINANCE BANK ',
    routingKey: '090197',
    bankCode: '090197',
    categoryId: '9',
  },
  {
    name: 'NIRSAL NATIONAL MICROFINANCE BANK',
    routingKey: '090194',
    bankCode: '090194',
    categoryId: '9',
  },
  {
    name: 'ABOVE ONLY MICROFINANCE BANK ',
    routingKey: '090260',
    bankCode: '090260',
    categoryId: '9',
  },
  {
    name: 'MOZFIN MICROFINANCE BANK',
    routingKey: '090392',
    bankCode: '090392',
    categoryId: '9',
  },
  {
    name: 'GLORY MICROFINANCE BANK',
    routingKey: '090278',
    bankCode: '090278',
    categoryId: '9',
  },
  {
    name: 'GLOBUS BANK',
    routingKey: '000027',
    bankCode: '000027',
    categoryId: '2',
  },
  {
    name: 'ACCESS YELLO & BETA',
    routingKey: '100052',
    bankCode: '100052',
    categoryId: '9',
  },
  {
    name: 'NUTURE MICROFINANCE BANK',
    routingKey: '090364',
    bankCode: '090364',
    categoryId: '9',
  },
  {
    name: 'IKENNE MICROFINANCE BANK',
    routingKey: '090324',
    bankCode: '090324',
    categoryId: '9',
  },
  {
    name: 'MAYFAIR MICROFINANCE BANK',
    routingKey: '090321',
    bankCode: '090321',
    categoryId: '9',
  },
  {
    name: 'REPHIDIM MICROFINANCE BANK',
    routingKey: '090322',
    bankCode: '090322',
    categoryId: '9',
  },
  {
    name: 'KONTAGORA MICROFINANCE BANK',
    routingKey: '090299',
    bankCode: '090299',
    categoryId: '9',
  },
  {
    name: 'CASHCONNECT MICROFINANCE BANK',
    routingKey: '090360',
    bankCode: '090360',
    categoryId: '9',
  },
  {
    name: 'BIPC MICROFINANCE BANK',
    routingKey: '090336',
    bankCode: '090336',
    categoryId: '9',
  },
  {
    name: 'MOLUSI MICROFINANCE BANK',
    routingKey: '090362',
    bankCode: '090362',
    categoryId: '9',
  },
  {
    name: 'LEGEND MICROFINANCE BANK',
    routingKey: '090372',
    bankCode: '090372',
    categoryId: '9',
  },
  {
    name: 'GTI MICROFINANCE BANK',
    routingKey: '090385',
    bankCode: '090385',
    categoryId: '9',
  },
  {
    name: '9 PAYMENT SOLUTIONS BANK',
    routingKey: '120001',
    bankCode: '120001',
    categoryId: '12',
  },
  {
    name: 'OPAY',
    routingKey: '100004',
    bankCode: '100004',
    categoryId: '12',
  },
  {
    name: 'RUBIES MICROFINANCE BANK',
    routingKey: '090175',
    bankCode: '090175',
    categoryId: '9',
  },
  {
    name: 'ILISAN MICROFINANCE BANK',
    routingKey: '090370',
    bankCode: '090370',
    categoryId: '9',
  },
  {
    name: 'FIRST OPTION MICROFINANCE BANK',
    routingKey: '090285',
    bankCode: '090285',
    categoryId: '9',
  },
  {
    name: 'POLYUNWANA MICROFINANCE BANK',
    routingKey: '090296',
    bankCode: '090296',
    categoryId: '9',
  },
  {
    name: 'OMIYE MICROFINANCE BANK',
    routingKey: '090295',
    bankCode: '090295',
    categoryId: '9',
  },
  {
    name: 'ASSETMATRIX MICROFINANCE BANK',
    routingKey: '090287',
    bankCode: '090287',
    categoryId: '9',
  },
  {
    name: 'TITAN TRUST BANK',
    routingKey: '000025',
    bankCode: '000025',
    categoryId: '2',
  },
  {
    name: 'EAGLE FLIGHT MICROFINANCE BANK',
    routingKey: '090294',
    bankCode: '090294',
    categoryId: '9',
  },
  {
    name: 'THINK FINANCE MICROFINANCE BANK',
    routingKey: '090373',
    bankCode: '090373',
    categoryId: '9',
  },
  {
    name: 'FSDH',
    routingKey: '400001',
    bankCode: '400001',
    categoryId: '4',
  },
  {
    name: 'SULSPAP MICROFINANCE BANK',
    routingKey: '090305',
    bankCode: '090305',
    categoryId: '9',
  },
  {
    name: 'ESO-E MICROFINANCE BANK',
    routingKey: '090166',
    bankCode: '090166',
    categoryId: '9',
  },
  {
    name: 'EMERALD MICROFINANCE BANK',
    routingKey: '090273',
    bankCode: '090273',
    categoryId: '9',
  },
  {
    name: 'TRUST MICROFINANCE BANK',
    routingKey: '090327',
    bankCode: '090327',
    categoryId: '9',
  },
  {
    name: 'COASTLINE MICROFINANCE BANK',
    routingKey: '090374',
    bankCode: '090374',
    categoryId: '9',
  },
  {
    name: 'ISALEOYO MICROFINANCE BANK',
    routingKey: '090377',
    bankCode: '090377',
    categoryId: '9',
  },
  {
    name: 'IKIRE MICROFINANCE BANK',
    routingKey: '090279',
    bankCode: '090279',
    categoryId: '9',
  },
  {
    name: 'U & C MICROFINANCE BANK',
    routingKey: '090315',
    bankCode: '090315',
    categoryId: '9',
  },
  {
    name: 'FIRMUS MICROFINANCE BANK',
    routingKey: '090366',
    bankCode: '090366',
    categoryId: '9',
  },
  {
    name: 'EK-RELIABLE MICROFINANCE BANK',
    routingKey: '090389',
    bankCode: '090389',
    categoryId: '9',
  },
  {
    name: 'VENTURE GARDEN NIGERIA LIMITED',
    routingKey: '110009',
    bankCode: '110009',
    categoryId: '9',
  },
  {
    name: 'NWANNEGADI MICROFINANCE BANK',
    routingKey: '090399',
    bankCode: '090399',
    categoryId: '9',
  },
]
export default sudo_bank_codes
