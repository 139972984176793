import React, { useEffect, useState } from 'react'
import Logo from '../../../../../Image/cardlogo.png'
import Logo2 from '../../../../../Image/cardlogo2.png'
import MasterLogo from '../../../../../Image/vereve.png'
import VerveLogo3 from '../../../../../Image/masterlogo.png'
import { useNavigate } from 'react-router'

function RequestedCards({ notify }) {
  const authToken = sessionStorage.getItem('userId')
  const [formLoading, setFormLoading] = useState(true)
  const [requestedData, setRequestedData] = useState([])
  const [requestedPage, setRequestedPage] = useState('one')
  const [pickedCard, setPickedCard] = useState({
    request_id: '',
    metadata: {
      alias: '',
    },
  })
  const [cardNumber, setCardNumber] = useState('')
  useEffect(() => {
    fetch(
      `https://y18x0oais3.execute-api.eu-west-1.amazonaws.com/prod/card/map_card`,
      {
        method: 'post',
        headers: {
          'content-Type': 'application/json',
          Authorization: `Token ${authToken}`,
        },
        body: JSON.stringify({ action: 'list' }),
      }
    )
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setRequestedData(data.requests_data)
            setFormLoading(false)
          })
        } else {
          res.json().then((data) => {})
          setFormLoading(false)
        }
      })
      .catch((err) => {
        setFormLoading(false)
      })
  }, [])
  const navigate = useNavigate()

  const findCard = (id) => {
    const selectedCard = requestedData.find((item) => item.request_id === id)
    setPickedCard(selectedCard)
  }
  const activateCard = () => {
    if (cardNumber.length !== 19) {
      notify('error', 'Card Number must be 19')
    } else {
      fetch(
        `https://y18x0oais3.execute-api.eu-west-1.amazonaws.com/prod/card/map_card`,
        {
          method: 'post',
          headers: {
            'content-Type': 'application/json',
            Authorization: `Token ${authToken}`,
          },
          body: JSON.stringify({
            request_id: pickedCard.request_id,
            card_number: cardNumber,
            action: 'map',
          }),
        }
      )
        .then((res) => {
          if (res.status === 200) {
            res.json().then((data) => {
              notify('success', data.message)
              setTimeout(() => {
                navigate('/physical-card/dashboard')
              }, 4000)
            })
          } else {
            res.json().then((data) => {
              notify('error', data.message)
            })
            setFormLoading(false)
          }
        })
        .catch((err) => {
          setFormLoading(false)
        })
    }
  }

  return (
    <div className='physical_cards-wrap'>
      {requestedPage === 'one' && (
        <>
          <div className='req_topp'>
            <h1>Requested Cards</h1>
          </div>
          {requestedData.length === 0 && <h2>No requests available now</h2>}
          <div className='request-wrap'>
            {requestedData.map((item) => (
              <div className='card-box' key={item.request_id}>
                <div className='card-box_top'>
                  <h2>{item.metadata.alias} </h2>
                  <button
                    onClick={() => {
                      findCard(item.request_id)
                      setRequestedPage('two')
                    }}
                  >
                    Activate
                  </button>
                </div>
                <div className='credit-card-body' id='physical'>
                  <div className='credit-card-body-top'>
                    <img src={Logo} alt='' />
                  </div>
                  <div className='card-number'>
                    <p>Activate card with Physical card</p>
                  </div>
                  <div className='credit-card-body-bottom'>
                    <div>
                      <p>Card Holder</p>
                    </div>
                    <img src={VerveLogo3} alt='' />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
      {requestedPage === 'two' && (
        <div className='stage-two'>
          <div className='stage_wrap'>
            <div className='card-box'>
              <div className='credit-card-body' id='physical'>
                <div className='credit-card-body-top'>
                  <img src={Logo} alt='' />
                </div>
                <div className='card-number'>
                  <p>Activate {pickedCard.metadata.alias} with physical card</p>
                </div>
                <div className='credit-card-body-bottom'>
                  <div>
                    <p>Card Holder</p>
                  </div>
                  <img src={VerveLogo3} alt='' />
                </div>
              </div>
            </div>
            <div className='funding'>
              <p className='fee'>
                What is the card number on the physical card?
              </p>
              <div className='form-group'>
                <input
                  type='number'
                  name='number'
                  className='form-control'
                  placeholder='000000000000'
                  required
                  onChange={(e) => setCardNumber(e.target.value)}
                />
              </div>

              <div className='fund-buttons'>
                <button
                  className='delete'
                  onClick={() => setRequestedPage('one')}
                >
                  Back
                </button>
                <button
                  className='paystack-button'
                  onClick={() => activateCard()}
                >
                  Activate card
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default RequestedCards
