import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import './Auth.scss'
import Logo from '../../Image/logo1.png'
import Man from '../../Image/man.png'
import Icon1 from '../../Image/Group1.png'
import Icon2 from '../../Image/Group2.png'
import Icon3 from '../../Image/Group3.png'
import Angle from '../../Image/angle.png'

const token = sessionStorage.getItem('userId')
function Verify({ notify }) {
  const [formLoading, setFormLoading] = useState(false)
  const [otpLoading, setOtpLoading] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const navigate = useNavigate()

  const onVerify = (formData) => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    setFormLoading(true)
    fetch('https://www.spendwise.ng/api/accounts/verify_email/', {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({
        otp: formData.otp,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setFormLoading(false)
            setTimeout(() => {
              window.location.replace(`/home`)
            }, 2000)
            notify('success', data.message)
          })
        } else {
          res.json().then((data) => {
            notify('error', data.message)
            setFormLoading(false)
          })
        }
      })
      .catch((err) => {
        notify('error', 'An error occured. Unable to reach server')
        setFormLoading(false)
      })
  }
  const onResend = () => {
    setOtpLoading(true)
    fetch('https://www.spendwise.ng/api/accounts/verify_email/', {
      method: 'get',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    }).then((response) => {
      if (response.status === 200) {
        notify('success', 'OTP Sent')
        setOtpLoading(false)
      }
    })
  }
  useEffect(() => {
    document.title = 'Spendwise | Verify Email'
  }, [])
  return (
    <>
      <section className='auth'>
        <div className='logo'>
          <img src={Logo} alt='' onClick={() => navigate('/')} />
        </div>
        <div className='wrap'>
          <div className='left'>
            <div className='icons'>
              <div className='can'>
                <img src={Icon1} alt='' />
              </div>
              <div className='can edit'>
                <img src={Icon2} alt='' />
              </div>
              <div className='can'>
                <img src={Icon3} alt='' />
              </div>
            </div>
            <div className='man'>
              <img src={Man} alt='' />
            </div>
          </div>
          <div className='right'>
            <div className='angle'>
              <div>
                <img src={Angle} alt='' />
              </div>
            </div>
            <div className='form'>
              <div className='head'>
                <h1>Verify your Email Address</h1>
                <p>Enter the OTP code that was sent to your registered mail</p>
              </div>
              <form className='auth-form' onSubmit={handleSubmit(onVerify)}>
                <div className='card'>
                  <p>Enter OTP</p>
                  <input
                    type='number'
                    inputMode='numeric'
                    name='otp'
                    {...register('otp', {
                      required: 'Field is required',
                    })}
                  />
                  {errors.otp && (
                    <h6 className='vError'>{errors.otp.message}</h6>
                  )}
                </div>
                <div className='card check'>
                  <div></div>
                  <div>
                    {otpLoading ? (
                      <h2 className='load'>Resend OTP</h2>
                    ) : (
                      <h2 onClick={onResend}>Resend OTP</h2>
                    )}
                  </div>
                </div>
                <div className='card'>
                  <button type='submit'>
                    {formLoading ? '.....' : 'Verify'}
                  </button>
                </div>
                <div className='card edit'>
                  <p>
                    Remember your password? <Link to='/login'>Login</Link>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Verify
