import React, { useState, useEffect } from 'react'
import './Topnav.scss'
import { FaBell, FaBars } from 'react-icons/fa'
import { BsFillTriangleFill } from 'react-icons/bs'
import API from '../../../../Context/API'
import { useGlobalContext } from '../../../../Context/Context'
import { useNavigate } from 'react-router'

function Topnav({ toggle, active, page, pageName }) {
  const navigate = useNavigate()
  const [user, setUser] = useState({})
  const [userloading, setUserLoading] = useState(true)
  const {
    showNotification,
    toggleNotification,
    loading,
    notificationCount,
    FetchBusinessDetails,
    SessionStorageGet,
    businessInfo,
  } = useGlobalContext()
  const returnedUser = SessionStorageGet('user')
  useEffect(() => {
    API.fetchUser()
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setUser(data)
            setUserLoading(false)
          })
        }
      })
      .catch(() => {})
    if (returnedUser && returnedUser.is_business_created) {
      FetchBusinessDetails()
    }
  }, [])
  return (
    <div
      className={
        page === 'user-management' ||
        page === 'home' ||
        page === 'e-wallet' ||
        page === 'activate-business' ||
        page === 'expense-approval'
          ? 'topnav bord'
          : 'topnav'
      }
    >
      <div className={active ? 'toggle' : 'toggle active'} onClick={toggle}>
        <FaBars />
        <h2>{pageName}</h2>
      </div>
      <div className='flex-end'>
        <div className='notify'>
          <div className='notif'>
            <div className='bell'>
              <FaBell onClick={toggleNotification} />
              {loading ? (
                <span>0</span>
              ) : (
                <>
                  {notificationCount[0].unread > 0 ? (
                    <span>{notificationCount[0].unread}</span>
                  ) : (
                    ''
                  )}
                </>
              )}
            </div>
          </div>
          {/* <BsFillTriangleFill className='trian'/> */}
          <div className={showNotification ? '' : 'trian'}></div>
        </div>
        <div className='namey'>
          <div className='name' onClick={() => navigate('/settings/profile')}>
            <div className='icon'>
              {userloading ? (
                <></>
              ) : (
                <>
                  {user.image === null ? (
                    <>
                      {returnedUser.is_business_created ? (
                        <img src={businessInfo.company_logo} alt='' />
                      ) : (
                        <p>
                          {user.first_name === null || user.surname === null ? (
                            <>
                              {user.username.charAt(0)}
                              {user.username.charAt(1)}
                            </>
                          ) : (
                            <>
                              {user.first_name.charAt(0)}
                              {user.surname.charAt(0)}
                            </>
                          )}
                        </p>
                      )}
                    </>
                  ) : (
                    <img src={user.image} alt='' />
                  )}
                </>
              )}
            </div>
            <div className='name-p'>
              <p>{user.username}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Topnav
