import React, { useState } from 'react'
import './CreateDept.scss'
import { FaTimes } from 'react-icons/fa'
import Loader from '../../../../Image/loder.gif.gif'
import { useGlobalContext } from '../../../../Context/Context'

function CreateDept({ notify }) {
  const { toggleShowCreateDept, showCreateDept, FetchDepartments } =
    useGlobalContext()
  const authToken = sessionStorage.getItem('userId')
  const [name, setName] = useState('')
  const [formLoading, setFormLoading] = useState(false)
  const CreateDepartment = () => {
    setFormLoading(true)
    fetch(`${process.env.REACT_APP_API_URL}/accounts/business_department/`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
      body: JSON.stringify({
        name: name,
      }),
    }).then((res) => {
      if (res.status === 201) {
        setFormLoading(false)
        notify('success', 'Department created successufully.')
        FetchDepartments()
        setTimeout(() => {
          toggleShowCreateDept()
        }, 3000)
      } else {
        res.json().then((data) => {
          notify('error', data.message)
          setFormLoading(false)
        })
      }
    })
  }
  return (
    <div className={showCreateDept ? ' createDept' : 'createDept show'}>
      <div className='cover'>
        <>
          <div className='top'>
            <div className='svg'>
              <FaTimes onClick={toggleShowCreateDept} />
            </div>
            <h2>New Department</h2>
          </div>
          <div className='form'>
            <div className='card'>
              <p>Department Name</p>
              <input
                type='text'
                placeholder='Type your new department'
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className='buts'>
              <p onClick={toggleShowCreateDept}>Cancel</p>
              {formLoading ? (
                <img src={Loader} alt='' />
              ) : (
                <button
                  disabled={name.length < 3}
                  onClick={() => {
                    if (name.length > 3) {
                      CreateDepartment()
                    }
                  }}
                >
                  Create Department
                </button>
              )}
            </div>
          </div>
        </>
      </div>
    </div>
  )
}

export default CreateDept
