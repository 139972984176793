import React from 'react'
import Footer from '../../Component/Footer/Footer'
import Navigation from '../../Component/Navigation/Navigation2'
import { BsWhatsapp } from 'react-icons/bs'
import './Privacy.scss'

function Privacy() {
  return (
    <div>
      <Navigation />
      <div className='privacy'>
        <div className='hero'>
          <div className='hero-wrap'>
            <h1>Privacy Policy</h1>
            <p>
              Ensure that you thoroughly review each item and any additional
              documents linked within these terms prior to agreeing to our terms
              of service.
            </p>
          </div>
        </div>
        <div className='coverxx'>
          <div className='whatsapp'>
            <a href='https://wa.me/2348138134873'>
              <BsWhatsapp />
            </a>
          </div>
          <p>Let's Chat</p>
        </div>
        <div className='contents'>
          <div className='contents-wrap'>
            <div>
              <p>
                SpendWise Finance & Tech, Inc. (“SpendWise”, the “Company“,
                “us“, “our” or “we“) provides vicariously through its designated
                website (the “Website“), web-based services, applications and
                SMS text messages designated to assist individual users to
                better manage their day-to-day financial operations and to
                provide them with financial recommendations, recommendations
                based on behavioral analysis of user’s transaction history and
                financial behavior patterns, and comparative information related
                to management of their spending and financials, through the use
                of behavioral analysis algorithms (the “Service“; the Service
                users shall be referred to as the “Users“, “you“ or “your“).
              </p>
              <p>
                Your privacy is important to us. We highly value and respect the
                privacy of the personal information we obtain from you by using
                our Website, Application and Services and we have created this
                privacy policy (the “Privacy Policy“) to inform you about how we
                collect, use and share your information, and our privacy
                practices in general. Unless otherwise stated below, we do not
                share your Personal Information (as defined below) with any
                third parties.
              </p>
              <p>
                By accessing and using the Website/App and/or Services via the
                User Account, you agree to be bound by this Privacy Policy. If
                you do not agree to any of the provisions as set hereunder in
                this Privacy Policy, you have no right to use the Website/App or
                Services. This Privacy Policy may be updated from time to time
                and therefore we ask you to check back periodically to read the
                most updated Privacy Policy. Your continued use of the
                Website/App and/or Services shall be deemed to be your continued
                acceptance of the terms of this Privacy Policy.
              </p>
              <h2>Information That We Collect</h2>
              <p>
                Personal Information. During your use of the Website/App and/or
                Services, we may collect, save or otherwise keep in our
                possession certain information that identifies you personally,
                such as your name, serial account number/nuban, physical
                address, phone number, email address, photo, documents, details
                and information regarding your financial transactions,
                transaction history and records or statistics, or any other
                information that may personally identify you (“Personal
                Information“). We may collect such information through your use
                of the Website/App and/or Services or from information that is
                entered into our system by any other User uploading, sharing or
                otherwise making such information available.
              </p>
              <p>
                Non-Personal Information. We may also collect, save or otherwise
                keep in our possession information that does not identify you
                personally, such as your history of use of the Website/App
                and/or Services. We refer to information that does not identify
                you personally as “Non-Personal Information“.
              </p>
              <p>
                Additional Information. When using the Website/App and/or
                Services, we may also collect, save or otherwise keep in our
                possession certain information that your browser sends to every
                Internet website you visit, such as your IP address, your
                browser information (including browser type, capabilities and
                language), your operating system, the date and time you accessed
                the Website, certain location-based data, and the website from
                which you linked to the Website and/or Services.
              </p>
              <p>
                By using the Website and/or Services you voluntary agree to
                provide us with any information, and to collect, save or
                otherwise keep in our possession such information (including
                Personal Information). If you refuse to provide us such
                information you may not have access to all functionality of the
                Website/App and/or Services.
              </p>
              <p>
                Please note that while we make reasonable efforts to secure all
                information and User Accounts, it is impossible to have the
                information and User Accounts completely secure. As such, we
                cannot accept any liability for the disclosure of Personal
                Information as a result of unauthorized access to our system,
                Website/App or User Accounts.
              </p>
              <p>
                With respect to accessing your Bank Account(s), we do not save
                or gain access to any bank credentials but use third party
                infrastructure providers such as Okra, Mono, Wallz and Queens
                among others (as defined below) to access account transactions.
                After the creating an Account, once credentials are secured,
                they are passed directly to the Bank through these providers,
                pursuant to which the Company receives an encrypted token that
                allows the Company to access the Bank Account and transaction
                details through same.
              </p>
              <h2>How We Use Your Information</h2>
              <p>
                We use Personal Information for internal purposes only, to
                improve our Website/App or Services and to better tailor them to
                meet your needs.
              </p>
              <p>We do not share your Personal Information with:</p>
              <ol>
                <li>Other financial companies for joint marketing purposes;</li>
                <li>
                  ffiliated companies for their everyday business purposes; or
                </li>
                <li>Any third parties so they can market to you.</li>
              </ol>
              <p>
                We may share your Personal Information with unaffiliated third
                parties:
              </p>
              <ol>
                <li>If you request or authorize it;</li>
                <li>If the information is provided to help complete a</li>
                <li>
                  If the information is provided to:
                  <ul>
                    <li>
                      Comply with applicable laws, rules, regulations,
                      governmental anquasi-governmental requests, court orders,
                      or subpoenas;
                    </li>
                    <li>
                      Enforce our Terms of Service or other agreements; or
                    </li>
                    <li>
                      protect our rights, property, or safety or the rights,
                      property, or safety oour users or others (e.g., to a
                      consumer reporting agency for fraud protection, etc)
                    </li>
                  </ul>
                </li>
                <li>
                  If the disclosure is done as part of a purchase, transfer, or
                  sale of services oassets (e.g., in the event that
                  substantially all of our assets are acquired by anotheparty,
                  your Personal Information may be one of the transferred
                  assets);
                </li>
                <li>
                  If the information is provided to our third-party service
                  providers to perforfunctions on our behalf (e.g., analyzing
                  data, providing marketing assistanceproviding customer
                  service, processing orders, etc.);
                </li>
                <li>For our everyday business purposes; or</li>
                <li>
                  As permitted by applicable law ootherwise described in this
                  Privacy Policy.
                </li>
              </ol>
              <p>
                By analyzing the information we are provided or we collect, save
                or otherwise keep in our possession, including all Personal
                Information, we may compile certain statistical information,
                such as without limitation anonymous information about how Users
                use the Website and/or Services (the “Statistical Information“).
                Statistical Information helps us understand trends and User
                needs so that new products and services can be considered and so
                existing products and services can be tailored to User desires.
                Statistical Information is anonymous and will not be linked to
                any Personal Information for provision to third parties. We may
                share such Statistical Information with our affiliates, without
                restriction, on commercial terms that we can determine in our
                sole discretion.
              </p>
              <p>
                Legal Disclosures. In the event we are required to disclose
                Personal Information or Non-Personal Information, or any other
                related information pursuant to lawful requests, such as summons
                or court orders, or in compliance with applicable laws or
                regulations, we will make such disclosure. It is our policy to
                cooperate with law enforcement agencies, and we will fully
                cooperate with any ongoing law enforcement investigation,
                including to the extent we are required to disclose Personal
                Information.
              </p>
              <p>
                <b>Change of Ownership:</b>In the event the ownership of all or
                a part of our business were to change, your Personal Information
                and other related information pertinent to the business’
                operations would likely be transferred to, or licensed for use
                by, the new owner.
              </p>
              <p>
                <b>To Protect Us or You:</b>In connection with the
                abovementioned, we may disclose your Personal Information and
                other related information in the event we reasonably believe
                such disclosure is necessary to protect the Website or Services,
                our rights or property, or the rights of any third party. We may
                disclose your Personal Information and other related
                information, when we have reason to believe that disclosing such
                information is necessary to identify, contact or bring legal
                action against someone who may be violating this Privacy Policy
                or may be causing injury to or interference with (either
                intentionally or unintentionally), our rights or property, other
                Users, or anyone else that could be harmed by such activities.
                You thus authorize us to disclose any information about you to
                law enforcement or other government officials if we, in our sole
                and absolute discretion, believe that such disclosure is
                necessary or appropriate.
              </p>
              <p>
                <b>Third Party Service Providers.</b>In connection with and in
                addition to the abovementioned, we use third-party service
                providers to perform certain services on our behalf. We may
                provide your Personal Information to these third-party service
                providers. These third-party service providers will be
                contractually restricted from using Personal Information for any
                other purpose and required to comply with all applicable laws
                with respect to your Personal Information. Such third party
                service providers may be located in jurisdictions that are not
                your home jurisdiction and, as such, may be subject to statutory
                or regulatory obligations that are not as protective as the
                applicable laws or regulations in your home jurisdiction.
              </p>
              <h2>Removal of Information</h2>
              <p>
                You may request that we delete your account for the Website or
                Services. If you do so, your Personal Information will be
                permanently expunged from our servers and further access to your
                account will not be possible. We will also promptly disconnect
                any connection we had established to your Account Information
                (see below) and delete all Personal Information in our
                possession. However, portions of your Personal Information, in
                de-identified and aggregate forms, may remain on our system
                indefinitely. We reserve the right to use this aggregate data to
                improve our service and for other lawful purposes, as discussed
                above. Further, please note that we also may need to store your
                data as required by federal, state or local law, even if you
                request that we delete it. To request deletion of your account,
                email info@spendwise.ng requesting a deletion of your account.
                Merely deleting the app from your device does not constitute a
                request to delete your account.
              </p>
              <h2>Other Websites, Services and Communities</h2>
              <p>
                Please be aware that when you are using the Website and/or
                Services, you could be directed via hyperlinks or other means to
                third-party websites, services and online communities that are
                not affiliated with us. Our Privacy Policy only addresses the
                use and disclosure of information we collect from you. Different
                rules or policies may apply to the use or disclosure of the
                information which you disclose to third parties. These
                third-party websites, services and online communities may send
                their own cookies to visitors to collect data or solicit your
                Personal Information. The privacy policies of these websites,
                services and online communities may be very different from this
                Privacy Policy. We are not responsible for the privacy practices
                of these other websites, services and online communities and
                cannot guarantee the security of any of your Personal
                Information collected, used or shared there.
              </p>
              <h2>Okra and Mono API</h2>
              <p>
                In order to provide you with the best service possible, the
                Company uses an Application Programming Interface supplied by
                Okra, Inc. ("Okra") and Mono Technologies Nigeria Limited
                ("Mono"), used to gather your data from financial institutions.
                By using the Website and/or Services, you acknowledge and agree
                to Okra’s privacy policy, available at{' '}
                <a href='https://okra.ng/privacy-policy'>
                  https://okra.ng/privacy-policy
                </a>{' '}
                and Mono's privacy policy, available at{' '}
                <a href='https://mono.co/legal/privacy-end-users'>
                  https://mono.co/legal/privacy-end-users
                </a>{' '}
                and acknowledge and agree that your information shall be treated
                in accordance with such policy.
              </p>
              <h2>Cookies</h2>
              <p>
                A cookie is a small piece of text that is sent to a visitor’s
                browser. The browser provides this piece of text to the device
                of the originating visitor when this visitor returns. A
                “persistent” cookie may be used to help save your settings and
                customizations across visits. Most web browsers are initially
                configured to accept cookies, but you can change this setting so
                your browser either refuses all cookies or informs you when a
                cookie is being sent. Also, you are free to delete any existing
                cookies at any time. Please note that some features of the
                Website may not function properly when cookies are disabled or
                removed.
              </p>
              <h2>Information Sent by Mobile</h2>
              <p>
                We collect certain information that your mobile device sends
                when you use our Website or Services, such as a device
                identifier, user settings and the operating system of your
                device, as well as information about your use of our Services.
              </p>
              <h2>Communications</h2>
              <p>
                We may use any contact information you have provided us to
                provide you with information concerning updates to the Website
                or Services or such other materials and information related to
                the Website and/or Services. You expressly agree to the receipt
                of such communications.We may use any contact information you
                have provided us to provide you with information concerning
                updates to the Website or Services or such other materials and
                information related to the Website and/or Services. You
                expressly agree to the receipt of such communications.
              </p>
              <h2>Policy Regarding Children</h2>
              <p>
                You must be 18 years old or older to use the Website,
                Application or Services; we do not knowingly collect information
                from children under the age of 18. If we become aware that we
                have collected Personal Information from a child under the age
                of 18 years old, we will use commercially reasonable efforts to
                delete such information from our database.
              </p>
              <h2>Responding to Do Not Track Signals</h2>
              <p>
                Our Services do not have the capability to respond to “Do Not
                Track” signals received from various web browsers.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Privacy
