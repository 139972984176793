import React, { useState, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { FaChevronLeft } from 'react-icons/fa'
import Logo from '../../../../../Image/cardlogo.png'
import Logo2 from '../../../../../Image/cardlogo2.png'
import VerveLogo3 from '../../../../../Image/masterlogo.png'
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa'
import Loader from '../../../../../Image/loder.gif.gif'
import { useNavigate, useParams } from 'react-router'
import API from '../../../../../Context/API'
import Atm from '../../../../../Image/ATM Icon.png'
import Pos from '../../../../../Image/POS.png'
import Web from '../../../../../Image/Web Icon.png'
import Mobile from '../../../../../Image/Mobile Icon.png'
import { BsCheckSquare, BsFillDashCircleFill } from 'react-icons/bs'
import { ImCheckboxUnchecked } from 'react-icons/im'
import { AiOutlineCloseSquare } from 'react-icons/ai'
import PinInput from 'react-pin-input'
import { MdToggleOff, MdToggleOn } from 'react-icons/md'

function EditCard({ notify, sudoDetails }) {
  const authToken = sessionStorage.getItem('userId')
  const [showCvv, setShowCvv] = useState(false)
  const { new_id } = useParams()
  const navigate = useNavigate()
  const [cloading, setcLoading] = useState(true)
  const [cardTransactions, setCardTransactions] = useState([])
  const [debitCard, setDebitCard] = useState({})
  const [tab, setTab] = useState('transactions')
  const [bulkLoading, setBulkLoading] = useState(false)
  const [showPassword, setShownPassword] = useState(false)
  const [showPassword2, setShownPassword2] = useState(false)
  const [showPassword3, setShownPassword3] = useState(false)
  const [formFields, setFormFields] = useState([{ interval: '', amount: '' }])
  const [atmField, setAtmField] = useState(false)
  const [posField, setPosField] = useState(false)
  const [webField, setWebField] = useState(false)
  const [mobileField, setMobileField] = useState(false)
  const [referLoading, setReferLoading] = useState(false)
  const [addAChanel, setAddAChanel] = useState('none')
  const [addPChanel, setAddPChanel] = useState('none')
  const [addWChanel, setAddWChanel] = useState('none')
  const [addMChanel, setAddMChanel] = useState('none')
  const [cardStatus, setCardStatus] = useState('inactive')
  const [cardStatusRequest, setCardStatusRequest] = useState(false)
  const [cardStatusLoading, setCardStatusLoading] = useState(false)
  const [cardStatusMessage, setCardStatusMessage] = useState('')
  const [statusPin, setStatusPin] = useState('000')
  const [cvv, setCvv] = useState('')
  const [cardNumber, setCardNumber] = useState('0000000000000000')

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm()
  const { register: register2, handleSubmit: handleSubmit2 } = useForm()
  const password = useRef({})
  password.current = watch('new_pin', '')
  useEffect(() => {
    document.title = 'Spendwise | Physical Cards - Edit  cards'
  }, [])
  useEffect(() => {
    API.FetchSudoCards()
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setDebitCard(data.card_data.find((item) => item.card_id === new_id))
            setAtmField(
              data.card_data.find((item) => item.card_id === new_id)
                .spendingControls.channels.atm
            )
            setPosField(
              data.card_data.find((item) => item.card_id === new_id)
                .spendingControls.channels.pos
            )
            setWebField(
              data.card_data.find((item) => item.card_id === new_id)
                .spendingControls.channels.web
            )
            setMobileField(
              data.card_data.find((item) => item.card_id === new_id)
                .spendingControls.channels.mobile
            )
            setFormFields(
              data.card_data.find((item) => item.card_id === new_id)
                .spendingControls.spendingLimits
            )
            setcLoading(false)

            fetch(
              `https://p499kkwmp9.execute-api.eu-west-1.amazonaws.com/prod/wallet/transactions`,
              {
                method: 'post',
                headers: {
                  'content-Type': 'application/json',
                  Authorization: `Token ${authToken}`,
                },
                body: JSON.stringify({
                  id: data.card_data.find((item) => item.card_id === new_id)
                    .account,
                }),
              }
            ).then((res) => {
              if (res.status === 200) {
                res.json().then((data) => {
                  setCardTransactions(data.transactions)
                })
              } else {
                res.json().then((data) => {})
              }
            })
            fetch(
              `https://y18x0oais3.execute-api.eu-west-1.amazonaws.com/prod/card/tokenise`,
              {
                method: 'post',
                headers: {
                  'content-Type': 'application/json',
                  Authorization: `Token ${authToken}`,
                },
                body: JSON.stringify({
                  card_id: data.card_data.find(
                    (item) => item.card_id === new_id
                  )._id,
                }),
              }
            ).then((res) => {
              if (res.status === 200) {
                res.json().then((datas) => {
                  fetch(
                    `https://vault.sudo.africa/cards/${
                      data.card_data.find((item) => item.card_id === new_id)._id
                    }/secure-data/cvv2`,
                    {
                      headers: {
                        Authorization: `Bearer ${datas.token}`,
                      },
                    }
                  ).then((res) => {
                    if (res.status === 200) {
                      res.json().then((data) => {
                        setCvv(data.data.cvv2)
                      })
                    } else {
                      res.json().then((data) => {})
                    }
                  })
                  fetch(
                    `https://vault.sudo.africa/cards/${
                      data.card_data.find((item) => item.card_id === new_id)._id
                    }/secure-data/number`,
                    {
                      headers: {
                        Authorization: `Bearer ${datas.token}`,
                      },
                    }
                  ).then((res) => {
                    if (res.status === 200) {
                      res.json().then((data) => {
                        setCardNumber(data.data.number)
                      })
                    } else {
                      res.json().then((data) => {})
                    }
                  })
                })
              } else {
                res.json().then((data) => {})
              }
            })
          })
        } else {
          res.json().then((data) => {})
        }
      })
      .catch(() => {})
  }, [])
  const reloadCard = () => {
    setcLoading(true)
    setCardStatusRequest(false)
    setCardStatusLoading(false)
    API.FetchSudoCards()
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setDebitCard(data.card_data.find((item) => item.card_id === new_id))
            setAtmField(
              data.card_data.find((item) => item.card_id === new_id)
                .spendingControls.channels.atm
            )
            setPosField(
              data.card_data.find((item) => item.card_id === new_id)
                .spendingControls.channels.pos
            )
            setWebField(
              data.card_data.find((item) => item.card_id === new_id)
                .spendingControls.channels.web
            )
            setMobileField(
              data.card_data.find((item) => item.card_id === new_id)
                .spendingControls.channels.mobile
            )
            setFormFields(
              data.card_data.find((item) => item.card_id === new_id)
                .spendingControls.spendingLimits
            )
            setcLoading(false)
            fetch(
              `https://p499kkwmp9.execute-api.eu-west-1.amazonaws.com/prod/wallet/transactions`,
              {
                method: 'post',
                headers: {
                  'content-Type': 'application/json',
                  Authorization: `Token ${authToken}`,
                },
                body: JSON.stringify({
                  id: data.card_data.find((item) => item.card_id === new_id)
                    .account,
                }),
              }
            ).then((res) => {
              if (res.status === 200) {
                res.json().then((data) => {
                  setCardTransactions(data.transactions)
                })
              } else {
                res.json().then((data) => {})
              }
            })
          })
        } else {
          res.json().then((data) => {})
        }
      })
      .catch(() => {})
  }
  const onPasswordChange = (formData) => {
    setBulkLoading(true)
    fetch(
      'https://y18x0oais3.execute-api.eu-west-1.amazonaws.com/prod/card/change_pin',
      {
        method: 'post',
        body: JSON.stringify({
          old_pin: parseInt(formData.old_pin),
          new_pin: parseInt(formData.new_pin),
          new_pin2: parseInt(formData.new_pin2),
          card_id: debitCard.card_id,
        }),
        headers: {
          'content-Type': 'application/json',
          Authorization: `Token ${authToken}`,
        },
      }
    )
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            notify('success', data.message)
            setBulkLoading(false)
            setTimeout(() => {
              window.location.reload()
            }, 4000)
          })
        } else {
          res.json().then((data) => {
            notify('error', data.message)
            setBulkLoading(false)
          })
        }
      })
      .catch((err) => {
        notify('error', 'Something went wrong please try again')
        setBulkLoading(false)
      })
  }
  const bulkFund = (formData) => {
    setBulkLoading(true)
    fetch(
      `https://p499kkwmp9.execute-api.eu-west-1.amazonaws.com/prod/wallet/fund_card_account`,
      {
        method: 'post',
        headers: {
          'content-Type': 'application/json',
          Authorization: `Token ${authToken}`,
        },
        body: JSON.stringify({
          data: [
            {
              amount: parseInt(formData.amount),
              alias: debitCard.alias,
              card_id: debitCard.card_id,
              card_account_id: debitCard.account,
            },
          ],
          loader_wallet_id: sudoDetails.account_id,
          pin: atob(
            sessionStorage.getItem('7e6d4defcc85a76e2cd2c156ef64a128e5')
          ),
        }),
      }
    )
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setBulkLoading(false)
            setTimeout(() => {
              window.location.reload()
            }, 4000)
            notify('success', data.message)
          })
        } else {
          res.json().then((data) => {
            notify('error', data.message)
            setBulkLoading(false)
          })
        }
      })
      .catch(() => {
        notify('error', 'Unable to connect to server')
        setBulkLoading(false)
      })
  }
  const handleFormChange = (event, index) => {
    let data = [...formFields]
    data[index][event.target.name] = event.target.value
    setFormFields(data)
  }
  const addFields = () => {
    let object = {
      interval: '',
      amount: '',
    }
    setFormFields([...formFields, object])
  }
  const removeFields = (index) => {
    let data = [...formFields]
    data.splice(index, 1)
    setFormFields(data)
  }
  const submit = (e) => {
    e.preventDefault()
    setReferLoading(true)
    const totalsubmission = {
      controls: {
        channels: {
          atm: atmField,
          pos: posField,
          web: webField,
          mobile: mobileField,
        },
        spendingLimits: formFields,
      },
      card_id: debitCard.card_id,
    }
    fetch(
      `https://y18x0oais3.execute-api.eu-west-1.amazonaws.com/prod/card/update_settings`,
      {
        method: 'post',
        // mode: 'no-cors',
        headers: {
          'content-Type': 'application/json',
          Authorization: `Token ${authToken}`,
        },
        body: JSON.stringify(totalsubmission),
      }
    )
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            notify('success', data.message)
            setReferLoading(false)
          })
        } else {
          res.json().then((data) => {
            setReferLoading(false)
            notify('error', data.message)
          })
        }
      })
      .catch(() => {
        notify('error', 'An error occured please try again.')
      })
  }
  const activateCard = () => {
    setCardStatusLoading(true)
    fetch(
      `https://y18x0oais3.execute-api.eu-west-1.amazonaws.com/prod/card/activation`,
      {
        method: 'post',
        headers: {
          'content-Type': 'application/json',
          Authorization: `Token ${authToken}`,
        },
        body: JSON.stringify({
          card_id: debitCard.card_id,
          action: cardStatus,
          loader: sudoDetails.account_id,
          pin: statusPin,
        }),
      }
    )
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setCardStatusLoading(false)
            setTimeout(() => {
              reloadCard()
            }, 2000)
            notify('success', data.message)
          })
        } else {
          res.json().then((data) => {
            notify('error', data.message)
            setCardStatusLoading(false)
          })
        }
      })
      .catch(() => {
        notify('error', 'Unable to connect to server')
        setCardStatusLoading(false)
      })
  }
  return cloading ? (
    <div className='vloader'>
      <img src={Loader} alt='' />
    </div>
  ) : (
    <div className='physical_cards-edit'>
      <div className='main-card'>
        <div className='back-but'>
          <p onClick={() => navigate('/physical-card/dashboard')}>
            <FaChevronLeft /> Back
          </p>
        </div>
        <div className='card_wrap'>
          <div className='card-box_top'>
            <h2>{debitCard.alias}</h2>
          </div>
          <div className='credit-card-body' id={debitCard.currency}>
            <div className='credit-card-body-top'>
              {debitCard.currency === 'USD' ? (
                <img src={Logo2} alt='' />
              ) : (
                <img src={Logo} alt='' />
              )}
              {debitCard.currency === 'USD'
                ? `$ ${debitCard.balance.toLocaleString()}`
                : ''}
              {/* <p>₦{debitCard.available_balance.toLocaleString()}</p> */}
            </div>
            {debitCard.currency === 'USD' ? (
              <div className='card-number'>
                <p>{cardNumber.slice(0, 4)}</p>
                <p>{cardNumber.slice(4, 8)}</p>
                <p>{cardNumber.slice(8, 12)}</p>
                <p>{cardNumber.slice(12, 16)}</p>
                <p>{cardNumber.slice(16, 20)}</p>
              </div>
            ) : (
              <div className='card-number'>
                <p>{debitCard.maskedPan.slice(0, 4)}</p>
                <p>{debitCard.maskedPan.slice(4, 8)}</p>
                <p>{debitCard.maskedPan.slice(8, 12)}</p>
                <p>{debitCard.maskedPan.slice(12, 16)}</p>
                <p>{debitCard.maskedPan.slice(16, 20)}</p>
              </div>
            )}

            <div className='credit-card-body-bottom'>
              <div>
                <p>Card Holder</p>
                <h2>{debitCard.alias}</h2>
              </div>
              <div>
                <p>Exp</p>
                <h2>
                  {' '}
                  {debitCard.expiryMonth}/{debitCard.expiryYear}
                </h2>
              </div>
              <div>
                <p style={{ display: 'flex', alignItems: 'center' }}>
                  cvv{' '}
                  {showCvv ? (
                    <FaRegEyeSlash
                      style={{ marginLeft: '5px', cursor: 'pointer' }}
                      onClick={() => {
                        setShowCvv(!showCvv)
                      }}
                    />
                  ) : (
                    <FaRegEye
                      style={{ marginLeft: '5px', cursor: 'pointer' }}
                      onClick={() => {
                        setShowCvv(!showCvv)
                      }}
                    />
                  )}
                </p>
                <h2
                  onClick={() => {
                    setShowCvv(!showCvv)
                  }}
                >
                  {showCvv ? (
                    <>{debitCard.currency === 'USD' ? cvv : debitCard.cvv2}</>
                  ) : (
                    <>***</>
                  )}
                </h2>
              </div>
            </div>
            <div className='vlogo'>
              <img src={VerveLogo3} alt='' />
            </div>
          </div>
        </div>
      </div>
      <div className='card-sec_wrap hf'>
        <div className='tabblet'>
          <div
            className={tab === 'transactions' ? 'active' : ''}
            onClick={() => setTab('transactions')}
          >
            <h2>Transactions</h2>
          </div>
          {/* <div
            className={tab === 'fund_card' ? 'active' : ''}
            onClick={() => setTab('fund_card')}
          >
            <h2>Fund Card</h2>
          </div> */}
          {/* <div
            className={tab === 'change_pin' ? 'active' : ''}
            onClick={() => setTab('change_pin')}
          >
            <h2>Change Pin</h2>
          </div> */}
          <div
            className={tab === 'settings' ? 'active' : ''}
            onClick={() => setTab('settings')}
          >
            <h2>Settings</h2>
          </div>
          <div
            className={tab === 'activate' ? 'active' : ''}
            onClick={() => setTab('activate')}
          >
            <h2>Activate Card</h2>
          </div>
        </div>
        <div className='tab-body'>
          {/* {tab === 'change_pin' && (
            <div>
              <div className='toprr'>
                <h3>Change Card Pin</h3>
                <p>
                  Current pin is <b>{debitCard.pin}</b>
                </p>
              </div>
              <form onSubmit={handleSubmit(onPasswordChange)}>
                <div className='card'>
                  <p>Current Pin</p>
                  <div className='input'>
                    <input
                      type={showPassword ? 'number' : 'password'}
                      name='old_pin'
                      id=''
                      {...register('old_pin', {
                        required: 'Provide a current pin',
                        minLength: {
                          value: 4,
                          message:
                            'Current pin must have at least 4 characters',
                        },
                        maxLength: {
                          value: 4,
                          message:
                            'Current pin must have at least 4 characters',
                        },
                        pattern: {
                          value: /^(?=.*?[0-9]).{4,}$/,
                          message: 'Pin must be numbers',
                        },
                      })}
                    />
                    {showPassword ? (
                      <FaRegEyeSlash
                        onClick={() => {
                          setShownPassword(!showPassword)
                        }}
                      />
                    ) : (
                      <FaRegEye
                        onClick={() => {
                          setShownPassword(!showPassword)
                        }}
                      />
                    )}
                  </div>
                  {errors.old_pin && (
                    <h6
                      className='vError'
                      style={{ fontSize: '10px', color: 'red' }}
                    >
                      {errors.old_pin.message}
                    </h6>
                  )}
                </div>
                <div className='card'>
                  <p>New Pin</p>
                  <div className='input'>
                    <input
                      type={showPassword2 ? 'number' : 'password'}
                      name='new_pin'
                      {...register('new_pin', {
                        required: 'Provide a new pin',
                        minLength: {
                          value: 4,
                          message:
                            'Current pin must have at least 4 characters',
                        },
                        maxLength: {
                          value: 4,
                          message:
                            'Current pin must have at least 4 characters',
                        },
                        pattern: {
                          value: /^(?=.*?[0-9]).{4,}$/,
                          message: 'Pin must be numbers',
                        },
                      })}
                    />
                    {showPassword2 ? (
                      <FaRegEyeSlash
                        onClick={() => {
                          setShownPassword2(!showPassword2)
                        }}
                      />
                    ) : (
                      <FaRegEye
                        onClick={() => {
                          setShownPassword2(!showPassword2)
                        }}
                      />
                    )}
                  </div>
                  {errors.new_pin && (
                    <h6
                      className='vError'
                      style={{ fontSize: '10px', color: 'red' }}
                    >
                      {errors.new_pin.message}
                    </h6>
                  )}
                </div>
                <div className='card'>
                  <p>Confirm New Pin</p>
                  <div className='input'>
                    <input
                      type={showPassword3 ? 'number' : 'password'}
                      name='new_pin2'
                      {...register('new_pin2', {
                        required: 'You must re-enter your pin',
                        validate: (value) =>
                          value === password.current || 'The pin do not match',
                      })}
                    />
                    {showPassword3 ? (
                      <FaRegEyeSlash
                        onClick={() => {
                          setShownPassword3(!showPassword3)
                        }}
                      />
                    ) : (
                      <FaRegEye
                        onClick={() => {
                          setShownPassword3(!showPassword3)
                        }}
                      />
                    )}
                  </div>
                  {errors.new_pin2 && (
                    <h6
                      className='vError'
                      style={{ fontSize: '10px', color: 'red' }}
                    >
                      {errors.new_pin2.message}
                    </h6>
                  )}
                </div>
                {bulkLoading ? (
                  <img src={Loader} alt='' />
                ) : (
                  <div className='button'>
                    <button>Change Pin</button>
                  </div>
                )}
              </form>
            </div>
          )} */}
          {tab === 'fund_card' && (
            <div>
              <div className='toprr'>
                <h3>Fund Card</h3>
                <p>How much will you like to fund this card with?</p>
              </div>
              <form onSubmit={handleSubmit2(bulkFund)}>
                <div className='card'>
                  <p>Amount</p>
                  <div className='input'>
                    <input
                      type='num'
                      name='amount'
                      min={1}
                      id=''
                      {...register2('amount', {
                        required: 'Amount Is required',
                      })}
                    />
                  </div>
                  {errors.amount && (
                    <h6
                      className='vError'
                      style={{ fontSize: '10px', color: 'red' }}
                    >
                      {errors.amount.message}
                    </h6>
                  )}
                </div>
                {bulkLoading ? (
                  <img src={Loader} alt='' />
                ) : (
                  <div className='button'>
                    <button>Fund Card</button>
                  </div>
                )}
              </form>
            </div>
          )}
          {tab === 'transactions' && (
            <div className='card_transactions'>
              <table>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Type</th>
                    <th>Amount</th>
                    <th>Naration</th>
                    <th>Provider</th>
                    <th>Balance</th>
                  </tr>
                </thead>
                <tbody>
                  {cardTransactions
                    .sort((a, b) => b.tx_date.localeCompare(a.tx_date))
                    .map((item) => (
                      <tr key={item.tx_id}>
                        <td className='date'>
                          {new Date(item.tx_date).toDateString()}
                        </td>
                        <td className={item.mode}>{item.mode}</td>
                        <td>₦{item.amount}</td>
                        <td>{item.narration}</td>
                        <td>{item.provider}</td>
                        <td>₦{item.running_balance}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          )}
          {tab === 'settings' && (
            <div className='chan-chan'>
              <h1>Channels</h1>
              <div className='edit_channel'>
                <div className='card'>
                  <div className='card-wra'>
                    <div className='left'>
                      <img src={Atm} alt='' />
                      <h2>ATM</h2>
                    </div>
                    <div className='right'>
                      {atmField ? (
                        <BsCheckSquare
                          onClick={() => setAddAChanel('ask')}
                          style={{ color: '#1eb80f' }}
                        />
                      ) : (
                        <AiOutlineCloseSquare
                          onClick={() => setAddAChanel('ask')}
                          style={{ color: 'red' }}
                        />
                      )}
                    </div>
                  </div>
                  {addAChanel === 'ask' && (
                    <div className='left_other'>
                      <p>Do you want to:</p>
                      <button
                        onClick={() => {
                          setAddAChanel('nil')
                          setAtmField(true)
                        }}
                      >
                        Allow
                      </button>
                      <button
                        onClick={() => {
                          setAddAChanel('nil')
                          setAtmField(false)
                        }}
                      >
                        Block
                      </button>
                    </div>
                  )}
                </div>
                <div className='card'>
                  <div className='card-wra'>
                    <div className='left'>
                      <img src={Pos} alt='' />
                      <h2>POS</h2>
                    </div>
                    <div className='right'>
                      {posField ? (
                        <BsCheckSquare
                          onClick={() => setAddPChanel('ask')}
                          style={{ color: '#1eb80f' }}
                        />
                      ) : (
                        <AiOutlineCloseSquare
                          onClick={() => setAddPChanel('ask')}
                          style={{ color: 'red' }}
                        />
                      )}
                    </div>
                  </div>
                  {addPChanel === 'ask' && (
                    <div className='left_other'>
                      <p>Do you want to:</p>
                      <button
                        onClick={() => {
                          setAddPChanel('nil')
                          setPosField(true)
                        }}
                      >
                        Allow
                      </button>
                      <button
                        onClick={() => {
                          setAddPChanel('nil')
                          setPosField(false)
                        }}
                      >
                        Block
                      </button>
                    </div>
                  )}
                </div>
                <div className='card'>
                  <div className='card-wra'>
                    <div className='left'>
                      <img src={Web} alt='' />
                      <h2>WEB</h2>
                    </div>
                    <div className='right'>
                      {webField ? (
                        <BsCheckSquare
                          onClick={() => setAddWChanel('ask')}
                          style={{ color: '#1eb80f' }}
                        />
                      ) : (
                        <AiOutlineCloseSquare
                          onClick={() => setAddWChanel('ask')}
                          style={{ color: 'red' }}
                        />
                      )}
                    </div>
                  </div>
                  {addWChanel === 'ask' && (
                    <div className='left_other'>
                      <p>Do you want to:</p>
                      <button
                        onClick={() => {
                          setAddWChanel('nil')
                          setWebField(true)
                        }}
                      >
                        Allow
                      </button>
                      <button
                        onClick={() => {
                          setAddWChanel('nil')
                          setWebField(false)
                        }}
                      >
                        Block
                      </button>
                    </div>
                  )}
                </div>
                <div className='card'>
                  <div className='card-wra'>
                    <div className='left'>
                      <img src={Mobile} alt='' />
                      <h2>MOBILE</h2>
                    </div>
                    <div className='right'>
                      {mobileField ? (
                        <BsCheckSquare
                          onClick={() => setAddMChanel('ask')}
                          style={{ color: '#1eb80f' }}
                        />
                      ) : (
                        <AiOutlineCloseSquare
                          onClick={() => setAddMChanel('ask')}
                          style={{ color: 'red' }}
                        />
                      )}
                    </div>
                  </div>
                  {addMChanel === 'ask' && (
                    <div className='left_other'>
                      <p>Do you want to:</p>
                      <button
                        onClick={() => {
                          setAddMChanel('nil')
                          setMobileField(true)
                        }}
                      >
                        Allow
                      </button>
                      <button
                        onClick={() => {
                          setAddMChanel('nil')
                          setMobileField(false)
                        }}
                      >
                        Block
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <h1>Spending Limits</h1>
              <div className='spendimf'>
                <form onSubmit={submit}>
                  {formFields.map((form, index) => {
                    return (
                      <div className='wrapper'>
                        <div className='card'>
                          <p>
                            Interval{' '}
                            <span
                              style={{ color: '#d00000', fontSize: '20px' }}
                            >
                              *
                            </span>
                          </p>
                          <select
                            name='interval'
                            id=''
                            onChange={(event) => handleFormChange(event, index)}
                            defaultValue={form.interval}
                          >
                            <option value={form.interval}>
                              {form.interval}
                            </option>
                            <option value='daily'>Daily</option>
                            <option value='monthly'>Monthly</option>
                            <option value='weekly'>Weekly</option>
                          </select>
                        </div>
                        <div className='card'>
                          <p>
                            Amount{' '}
                            <span
                              style={{ color: '#d00000', fontSize: '20px' }}
                            >
                              *
                            </span>
                          </p>
                          <input
                            type='number'
                            name='amount'
                            min={1}
                            id=''
                            onChange={(event) => handleFormChange(event, index)}
                            defaultValue={form.amount}
                          />
                        </div>
                        <div className='icon'>
                          <BsFillDashCircleFill
                            onClick={() => removeFields(index)}
                          />
                        </div>
                      </div>
                    )
                  })}
                </form>
                <div className='adder'>
                  <button onClick={addFields}>Add More</button>
                </div>
              </div>
              {referLoading ? (
                <img src={Loader} alt='' />
              ) : (
                <div className='button'>
                  <button onClick={submit}>Save Settings</button>
                </div>
              )}
            </div>
          )}
          {tab === 'activate' && (
            <div className='setinb'>
              {cardStatusRequest && (
                <div className='activate_modal'>
                  <p>Are you sure you want to {cardStatusMessage} this card?</p>
                  <PinInput
                    length={4}
                    initialValue=''
                    secret
                    onChange={(value, index) => {
                      setStatusPin(value)
                    }}
                    type='numeric'
                    inputMode='numeric'
                    style={{ padding: '10px' }}
                    inputStyle={{
                      borderColor: '#e6e6e6',
                      borderWidth: '3px',
                      borderStyle: 'solid',
                      background: '#f1f1f1',
                      fontSize: ' 3rem',
                      borderRadius: '20px',
                      height: '70px',
                      width: '70px',
                      margin: '0 5px',
                    }}
                    inputFocusStyle={{ borderColor: 'blue' }}
                    onComplete={(value, index) => {}}
                    autoSelect={true}
                    regexCriteria={/^[0-9]*$/}
                  />

                  {cardStatusLoading ? (
                    <>
                      <img src={Loader} alt='' />
                    </>
                  ) : (
                    <div className='buttons'>
                      <button onClick={() => setCardStatusRequest(false)}>
                        No
                      </button>
                      <button onClick={() => activateCard()}>Yes</button>
                    </div>
                  )}
                </div>
              )}
              <div className='activate_card'>
                <h1>Card Status</h1>
                {debitCard.card_status === 'active' && (
                  <div>
                    <p>Active</p>
                    <MdToggleOn
                      onClick={() => {
                        setCardStatusRequest(true)
                        setCardStatus('inactive')
                        setCardStatusMessage('deactivate')
                      }}
                    />
                  </div>
                )}
                {debitCard.card_status === 'inactive' && (
                  <div>
                    <p>Inactive</p>
                    <MdToggleOff
                      style={{ color: '#626161' }}
                      onClick={() => {
                        setCardStatusRequest(true)
                        setCardStatus('active')
                        setCardStatusMessage('activate')
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default EditCard
