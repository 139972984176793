import React, { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useParams, useNavigate } from 'react-router'
import Exclamation from '../../../../../Image/Status Report.png'
import PinInput from 'react-pin-input'
import API from '../../../../../Context/API'

const requestList = [
  {
    id: 1,
    name: 'love',
    naira: 60000,
    dollar: 100,
    account_name: 'Oshodi Love',
    account_number: '0458746955',
  },
  {
    id: 2,
    name: 'osas',
    naira: 95000,
    dollar: 158.33,
    account_name: 'Canriel Osas',
    account_number: '5658746978',
  },
  {
    id: 3,
    name: 'evelyn',
    naira: 700000,
    dollar: 1000,
    account_name: 'Smith Evelyn',
    account_number: '4558746987',
  },
]
function FundFriend({ notify }) {
  const navigate = useNavigate()
  const authToken = sessionStorage.getItem('userId')
  const { new_id } = useParams()
  const [transferType, setTransferType] = useState('bank-account')
  const [formLoading, setFormLoading] = useState(false)
  const [transferStage, setTransferStage] = useState('one')
  const [AmountGreater, setAmountGreater] = useState(false)
  const { register, handleSubmit, watch } = useForm()
  const [friend, setFriend] = useState({})
  const [walletDetails, setWalletDetails] = useState({})
  const [NewAmmount, setNewAmmount] = useState('')
  const [pin, setPin] = useState('')

 
  useEffect(() => {}, [])
  const walletVerifyTransfer = () => {
    setFormLoading(true)
    fetch(`${process.env.REACT_APP_API_URL}/purse/transfer_in/`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
      // body: JSON.stringify(walletTransfer),
      body: JSON.stringify({
        receiver_id: friend.user,
        amount: NewAmmount,
        save_action: 'no',
        pin: pin,
        username: friend.username,
        narration: friend.comment,
        issuer: 'mono',
      }),
    }).then((res) => {
      if (res.status === 200) {
        setFormLoading(false)
        res.json().then((data) => {
          notify('success', data.message)
        })
      } else {
        setFormLoading(false)
        res.json().then((data) => {
          notify('error', data.message.message)
          notify('error', data.message)
          if (data.message.includes('Magic PIN not set')) {
            setTimeout(() => {
              navigate('/settings/security')
            }, 4000)
          }
        })
      }
    })
  }
 

  
  return (
    <div className='wallet_transfer'>
      {transferStage === 'one' && (
        <div className='transfer_stage1'>
          <div className='wallet_top'>
            <h2 className='wallet-text'>E-Wallet</h2>
          </div>
          <div className='wallet_transfer-wrap'>
            <div className='wallet_transfer-navigatior'></div>
            <div className='wallet_transfer-body'>
              <div className='wallet_transfer-body_top'>
                <h2>Spendwise Wallet</h2>
                <p>How much would you like to transfer?</p>
                <div className='amount-input'>
                  <div>
                    <h3> ₦</h3>
                    <input
                      type='text'
                      defaultValue={NewAmmount}
                      onChange={(e) => {
                        setNewAmmount(e.target.value)
                      }}
                    />
                  </div>
                </div>
              </div>
              <form action=''>
                <div className='wrapper'>
                  <div className='card'>
                    <p>Spendwise Username</p>
                    <input type='text' value={friend.username} />
                  </div>
                  <div className='card'>
                    <p>Benefactor Name</p>
                    <div>
                      <input type='tel' value={walletDetails.full_name} />
                    </div>
                  </div>
                </div>
                <div className='card diff'>
                  <p>Naration</p>
                  <textarea
                    name=''
                    id=''
                    cols='30'
                    rows='5'
                    // onChange={(e) => setTransferNarration(e.target.value)}
                    value={friend.comment}
                  ></textarea>
                </div>
                {/* <div className='card diff'>
                  <p>Account Name</p>
                  <input type='text' value={friend.account_number} />
                </div> */}
                {/* <div className='transfer_save'>
                  <label className='container'>
                    Generate & Save Receipt
                    <input type='checkbox' />
                    <span className='checkmark'></span>
                  </label>
                </div> */}
                <div className='transfer_buttons'>
                  <button style={{ backgroundColor: 'red' }}>Decline</button>
                  <button onClick={() => navigate('/e-wallet/dashboard')}>
                    Cancel
                  </button>
                  <button onClick={() => setTransferStage('two')}>Send</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      {transferStage === 'two' && (
        <div className='transfer_stage2'>
          <div className='wallet_transfer-wrap'>
            {AmountGreater && (
              <div className='transfer_Modal'>
                <div className='info_card'>
                  <img src={Exclamation} alt='' />
                  <p>
                    You can not transfer an amount greater than your current
                    balance.
                  </p>
                  <button
                    onClick={() => {
                      setTransferStage('one')
                    }}
                  >
                    Back
                  </button>
                </div>
              </div>
            )}
            <div className='wallet_transfer-body'>
              <div
                className='wallet_transfer-body_top'
                style={{ paddingBottom: '30px' }}
              >
                <h2 style={{ fontSize: '30px' }}>Transfer Details</h2>
                <p style={{ fontSize: '20px' }}>
                  Please verify the information below.
                </p>
              </div>
              <div className='wallet_transfer-body_top'>
                <div className='amount-input'>
                  <div>
                    <h3> ₦</h3>
                    <input
                      type='text'
                      disabled
                      value={NewAmmount.toLocaleString()}
                    />
                  </div>
                </div>
              </div>
              <form action=''>
                <div className='wrapper'>
                  <div className='card'>
                    <p>Spendwise Username</p>
                    <input type='text' name='' id='' value={friend.username} />
                  </div>
                  <div className='card'>
                    <p>Benefactor Name</p>
                    <div>
                      <input type='tel' value={walletDetails.full_name} />
                    </div>
                  </div>
                </div>
                <div className='card diff'>
                  <p>Naration</p>
                  <textarea
                    name=''
                    id=''
                    cols='30'
                    rows='5'
                    // onChange={(e) => setTransferNarration(e.target.value)}
                    value={friend.comment}
                  ></textarea>
                </div>
                {/* <div className='transfer_save'>
                  <label className='container'>
                    Generate & Save Receipt
                    <input type='checkbox' disabled checked='checked' />
                    <span className='checkmark'></span>
                  </label>
                </div> */}
                <div className='transfer_buttons'>
                  <button onClick={() => setTransferStage('one')}>Edit</button>
                  <button onClick={() => navigate('/e-wallet/dashboard')}>
                    Cancel
                  </button>
                  <button onClick={() => setTransferStage('three')}>
                    Send
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      {transferStage === 'three' && (
        <div className='transfer_pin'>
          <div className='wallet_transfer-wrap'>
            <div className='wallet_transfer-body'>
              <div
                className='wallet_transfer-body_top'
                style={{ paddingBottom: '30px' }}
              >
                <h2 style={{ fontSize: '30px' }}>Transfer Verification</h2>
                <p style={{ fontSize: '20px' }}>
                  Please input your Magic PIN to initiate this transaction.
                </p>
              </div>
              <div className='transfer_pin'>
                <div className='pin_wrapper'>
                  <PinInput
                    length={4}
                    initialValue=''
                    secret
                    type='numeric'
                    inputMode='numeric'
                    onChange={(value, index) => {
                      setPin(value)
                    }}
                    style={{ padding: '10px' }}
                    inputStyle={{
                      borderColor: '#e6e6e6',
                      borderWidth: '3px',
                      borderStyle: 'solid',
                      background: '#f1f1f1',
                      fontSize: ' 3rem',
                      borderRadius: '20px',
                      height: '70px',
                      width: '70px',
                      margin: '0 5px',
                    }}
                    inputFocusStyle={{ borderColor: 'blue' }}
                    onComplete={(value, index) => {}}
                    autoSelect={true}
                    regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                  />
                  <div className='t_button'>
                    <button onClick={() => walletVerifyTransfer()}>
                      Confirm & Transfer
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default FundFriend
