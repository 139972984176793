import React, { useState, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { FaChevronLeft, FaRegEye, FaRegEyeSlash } from 'react-icons/fa'

function PasswordChange({ navigate, notify }) {
  const authToken = sessionStorage.getItem('userId')

  const [showPassword, setShownPassword] = useState(false)
  const [showPassword2, setShownPassword2] = useState(false)
  const [showPassword3, setShownPassword3] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm()
  const password = useRef({})
  password.current = watch('new_password', '')
  const onPasswordChange = (formData) => {
    fetch('https://www.spendwise.ng/api/accounts/change_password/', {
      method: 'post',
      body: JSON.stringify({ ...formData }),
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            notify('success', data.message)
            navigate('/settings/profile')
          })
        } else {
          res.json().then((data) => {
            notify('error', data.message)
          })
        }
      })
      .catch((err) => {
        notify('error', 'Something went wrong please try again')
      })
  }

  return (
    <div className='password-change'>
      <div className='password-top'>
        <h2 className='mai'>Security</h2>
        <h2
          onClick={() => {
            navigate('/settings/security')
          }}
          className='back'
        >
          <FaChevronLeft /> Back
        </h2>
      </div>
      <div className='password-wrap'>
        <h2>Change Password</h2>
        <form onSubmit={handleSubmit(onPasswordChange)}>
          <div className='card'>
            <p>Current Password</p>
            <div className='input'>
              <input
                type={showPassword ? 'text' : 'password'}
                name='current_password'
                id=''
                {...register('current_password', {
                  required: 'Provide a current password',
                  minLength: {
                    value: 7,
                    message: 'Current password must have at least 8 characters',
                  },
                  pattern: {
                    value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{7,}$/,
                    message:
                      'Passwords have to contain at least one uppercase and one lower case character and a number',
                  },
                })}
              />
              {showPassword ? (
                <FaRegEyeSlash
                  onClick={() => {
                    setShownPassword(!showPassword)
                  }}
                />
              ) : (
                <FaRegEye
                  onClick={() => {
                    setShownPassword(!showPassword)
                  }}
                />
              )}
            </div>
            {errors.current_password && (
              <h6 className='vError' style={{ fontSize: '10px', color: 'red' }}>
                {errors.current_password.message}
              </h6>
            )}
          </div>
          <div className='card'>
            <p>New Password</p>
            <div className='input'>
              <input
                type={showPassword2 ? 'text' : 'password'}
                name='new_password'
                {...register('new_password', {
                  required: 'Provide a new password',
                  minLength: {
                    value: 7,
                    message: ' New password must have at least 8 characters',
                  },
                  pattern: {
                    value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{7,}$/,
                    message:
                      'Passwords have to contain at least one uppercase and one lower case character and a number',
                  },
                })}
              />
              {showPassword2 ? (
                <FaRegEyeSlash
                  onClick={() => {
                    setShownPassword2(!showPassword2)
                  }}
                />
              ) : (
                <FaRegEye
                  onClick={() => {
                    setShownPassword2(!showPassword2)
                  }}
                />
              )}
            </div>
            {errors.new_password && (
              <h6 className='vError' style={{ fontSize: '10px', color: 'red' }}>
                {errors.new_password.message}
              </h6>
            )}
          </div>
          <div className='card'>
            <p>Confirm New Password</p>
            <div className='input'>
              <input
                type={showPassword3 ? 'text' : 'password'}
                name='confirm_password'
                {...register('confirm_password', {
                  required: 'You must re-enter your password',
                  validate: (value) =>
                    value === password.current || 'The passwords do not match',
                })}
              />
              {showPassword3 ? (
                <FaRegEyeSlash
                  onClick={() => {
                    setShownPassword3(!showPassword3)
                  }}
                />
              ) : (
                <FaRegEye
                  onClick={() => {
                    setShownPassword3(!showPassword3)
                  }}
                />
              )}
            </div>
            {errors.confirm_password && (
              <h6 className='vError' style={{ fontSize: '10px', color: 'red' }}>
                {errors.confirm_password.message}
              </h6>
            )}
          </div>
          <div className='button'>
            <button>Update</button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default PasswordChange
