const Questions = [
  {
    id: 1,
    title: `How can I get a USD virtual card?`,
    content: `To get a USD virtual card, you will need to create a wallet from the E-Wallet tab. Once the wallet is ready, you can go ahead and transfer money to the wallet. On request to create a USD or Naira card, the Naira value will be charged from your wallet.`,
  },
  {
    id: 2,
    title: `What is a wallet?`,
    content: `A wallet is a full fledged (virtual) bank account with one of our provider banks. It is the source and destination of your funds for card creation and liquidations respectively. You can also operate it like any regular bank account by making transfers from it or receiving money in it.`,
  },
  {
    id: 3,
    title: `What USD/NGN rate does SpendWise use?`,
    content: `Our rates are determined by our issuing partners and advised in real time. This means that to get the latest rate, you will need to attempt a USD card creation so you see the prevailing rate at the time.`,
  },
  {
    id: 4,
    title: `Are there charges on card creation?`,
    content: `Charges apply for card creation and they are all transparently shown at the point of creating a card. This includes advice on the exchange rate at that moment.`,
  },
  {
    id: 5,
    title: `Does SpendWise virtual USD card owners pay a transaction fee?`,
    content: `Transaction fees do not apply when you make payments with our virtual cards
`,
  },
  {
    id: 6,
    title: `Can I delete my card and get my money back?`,
    content: `You can delete your USD card but you will first need to liquidate the balance to zero before you can. Please see the liquidation rate advised just before confirmation of the action. The Naira value of the liquidated amount will be credited into your SpendWise wallet. For your Naira cards, you can only liquidate the balance.
`,
  },
  {
    id: 7,
    title: `Do I need bank accounts?`,
    content: `You just need a SpendWise wallet which we seamlessly create for you.`,
  },
  {
    id: 8,
    title: `Can I send money to other users?`,
    content: `Yes you can send money as well as request funds from other users. You can also send money to bank accounts outside the SpendWise ecosystem.`,
  },
  {
    id: 9,
    title: `Can I connect my existing bank accounts?`,
    content: `Yes. You can connect any of your existing banks to our expense management system so you can get an idea of where your money is going, as well as advisory on management around it.`,
  },
  {
    id: 10,
    title: `Can I pay from my connected bank?`,
    content: `We plan to enable that for you but at the moment, we only support expense management and advisory on that service.`,
  },
  {
    id: 11,
    title: `I paid with my card but I cannot see the record?`,
    content: `There could be some delay from our switching partners in this case. If you are unable to see your transaction trail after 12 hours, do kindly reach out via Contact Us so we look into it.
`,
  },
]
export default Questions
