import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import Logo from '../../../../../Image/cardlogo.png'
import Logo2 from '../../../../../Image/cardlogo2.png'
import MasterLogo from '../../../../../Image/vereve.png'
import VerveLogo3 from '../../../../../Image/masterlogo.png'
import Successful from '../../../../../Image/Successful.png'
import API from '../../../../../Context/API'
import { FaChevronLeft, FaLastfmSquare } from 'react-icons/fa'
import { PaystackButton } from 'react-paystack'
import Loader from '../../../../../Image/loder.gif.gif'
import { useGlobalContext } from '../../../../../Context/Context'

const cardsList = [
  // {
  //   id: 1,
  //   name: 'Virtual usd card',
  //   type: 'USD',
  //   amount: 340,
  //   card_number: 'Request for virtual USD card',
  //   currency: 'usd',
  //   card_type: 'virtual',
  //   limit: 250,
  // },
  {
    id: 2,
    name: 'Virtual naira card',
    type: 'NGN',
    amount: 11690,
    card_number: 'Request for virtual Naira card',
    currency: 'ngn',
    card_type: 'virtual',
  },
  // {
  //   id: 3,
  //   name: 'Physical naira card',
  //   type: 'physical',
  //   card_number: 'Request for physical Naira card',
  //   valid: '07/25',
  //   currency: 'Naira',
  //   card_type: 'physical',
  // },
]
function Request({ notify }) {
  const { toggleCardHolder } = useGlobalContext()
  const authToken = sessionStorage.getItem('userId')
  const navigate = useNavigate()
  const [cardRequestStage, setCardRequestStage] = useState('one')
  const [user, setUser] = useState({})
  const [SelectedCard, setSelectedCard] = useState({
    name: '',
    type: '',
    amount: 0,
    card_number: '',
    card_type: '',
    currency: '',
  })
  const [dollarRate, setdollarRate] = useState(0)
  const [creditAmmount, setCreditAmmount] = useState(0)
  const [cardName, setCardName] = useState('')
  const [showAmount, setShowAmount] = useState(0)
  const [amount, setAmount] = useState(0)
  const [email, setEmail] = useState('')
  const [id, setId] = useState('')
  const [userName, setUserName] = useState('')
  const [confirmed, setConfirmed] = useState(false)
  const [confirmedDetails, setConfirmedDetails] = useState({
    message: '',
    narration: '',
    total_bill_naira: 0,
  })
  const [reqLoading, setReqLoading] = useState(false)
  useEffect(() => {
    document.title = 'Spendwise | Virtual card request'
  }, [])
  const requestedCard = (id) => {
    const cardRequest = cardsList.find((item) => item.id === id)
    setSelectedCard(cardRequest)
    setCardRequestStage('two')
  }
  useEffect(() => {
    API.getDollarRate()
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setdollarRate(data.rate.funding_rate)
          })
        } else {
          res.json().then((data) => {})
        }
      })
      .catch(() => {
        notify('error', 'Internal server errorr, Contact support.')
      })
  }, [])
  useEffect(() => {
    API.fetchUser()
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setUser(data.user)
            setEmail(data.email)
            setId(data.id)
            setUserName(data.username)
          })
        } else if (res.status === 403) {
        }
      })
      .catch(() => {
        notify('error', 'Internal server errorr, Contact support.')
      })
  }, [])
  const completePurchase = () => {
    setReqLoading(true)
    fetch(`${process.env.REACT_APP_API_URL}/cards/card/`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
      body: JSON.stringify({
        alias: cardName,
        card_type: SelectedCard.card_type,
        currency: SelectedCard.currency,
        amount: parseInt(creditAmmount),
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            notify('success', data.message)
            setCardRequestStage('four')
            setReqLoading(false)
          })
        } else {
          res.json().then((data) => {
            notify('error', data.message)
            setReqLoading(false)
          })
        }
      })
      .catch(() => {
        notify('error', 'Internal server errorr, Contact support.')
      })
  }
  const confirmTotalBills = () => {
    fetch(`${process.env.REACT_APP_API_URL}/cards/total_bill/`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
      body: JSON.stringify({
        alias: cardName,
        event: 'card creation',
        currency: SelectedCard.currency,
        amount: parseInt(creditAmmount),
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setConfirmed(true)
            setConfirmedDetails(data)
          })
        } else {
          res.json().then((data) => {})
        }
      })
      .catch(() => {
        notify('error', 'Internal server errorr, Contact support.')
      })
  }
  const confirmTotalBillsSudo = () => {
    fetch(`${process.env.REACT_APP_API_URL}/cards/total_bill/`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
      body: JSON.stringify({
        alias: cardName,
        event: 'card creation',
        currency: SelectedCard.currency,
        amount: parseInt(creditAmmount),
        issuer: 'sudo',
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setConfirmed(true)
            setConfirmedDetails(data)
          })
        } else {
          res.json().then((data) => {})
        }
      })
      .catch(() => {
        notify('error', 'Internal server errorr, Contact support.')
      })
  }
  const CallCardHolder = () => {
    API.getCardHolder()
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            if (data.data.length >= 1) {
              navigate('/debit-card/request-sudo')
            } else {
              toggleCardHolder()
            }
          })
        } else {
          res.json().then((data) => {})
        }
      })
      .catch(() => {})
  }
  return (
    <div className='virtual_cards-wrap'>
      <div className='cover-wrapper'>
        <div className='back-but'>
          <p onClick={() => navigate(-1)}>
            <FaChevronLeft /> Back
          </p>
        </div>
        {cardRequestStage === 'one' && (
          <div className='step-one'>
            <div className='card-box'>
              <div className='card-box_top'>
                <h2>Virtual usd cards</h2>
                <button onClick={() => CallCardHolder()}>Request</button>
              </div>
              <div
                className='credit-card-body'
                id='USD'
                onClick={() => CallCardHolder()}
              >
                <div className='credit-card-body-top'>
                  <img src={Logo2} alt='' />
                </div>
                <div className='card-number'>
                  <p>Request for virtual USD card</p>
                </div>
                <div className='credit-card-body-bottom'>
                  <div>
                    <p>Card Holder</p>
                  </div>
                  <img src={VerveLogo3} alt='' />
                </div>
              </div>
            </div>
            {cardsList.map((item) => (
              <div className={item.type === 'NGN' ? 'card-box op' : 'card-box'}>
                <div className='card-box_top'>
                  <h2>{item.name}</h2>
                  <button
                    onClick={() => {
                      if (item.type === 'USD') {
                        requestedCard(item.id)
                        document.title =
                          'Spendwise | Virtual dollar card request'
                      }
                    }}
                  >
                    Request
                  </button>
                </div>
                <div
                  className='credit-card-body'
                  id={item.type}
                  onClick={() => {
                    if (item.type === 'USD') {
                      requestedCard(item.id)
                      document.title = 'Spendwise | Virtual dollar card request'
                    }
                  }}
                >
                  <div className='credit-card-body-top'>
                    {item.type === 'USD' ? (
                      <img src={Logo2} alt='' />
                    ) : (
                      <img src={Logo} alt='' />
                    )}
                  </div>
                  <div className='card-number'>
                    <p>{item.card_number}</p>
                  </div>
                  <div className='credit-card-body-bottom'>
                    <div>
                      <p>Card Holder</p>
                    </div>
                    {item.currency === 'usd' ? (
                      <img src={MasterLogo} alt='' />
                    ) : (
                      <img src={VerveLogo3} alt='' />
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
        {cardRequestStage === 'two' && (
          <div className='step-two'>
            <div className='card-box'>
              <div className='credit-card-body' id={SelectedCard.type}>
                <div className='credit-card-body-top'>
                  {SelectedCard.type === 'USD' ? (
                    <img src={Logo2} alt='' />
                  ) : (
                    <img src={Logo} alt='' />
                  )}
                </div>

                <div className='credit-card-body-bottom'>
                  <div>
                    <p>Card Holder</p>
                  </div>
                  {SelectedCard.currency === 'usd' ? (
                    <img src={MasterLogo} alt='' />
                  ) : (
                    <img src={VerveLogo3} alt='' />
                  )}
                </div>
              </div>
              <h2>{SelectedCard.name}</h2>
              <h3>Charges Applies</h3>
              <p className='fee'>
                {/* A fee of ₦100{' '}
              <span>
                {SelectedCard.type === 'USD' &&
                  ' & 1.5% of the amount which is capped at $3'}
              </span>{' '}
              will be charged for this transaction from your wallet. */}
                {/* Charges Applies */}
              </p>
              <button onClick={() => setCardRequestStage('three')}>
                Proceed
              </button>
            </div>
          </div>
        )}
        {cardRequestStage === 'three' && (
          <div className='step-two'>
            {reqLoading && (
              <div className='loade'>
                <img src={Loader} alt='loading...' />
              </div>
            )}
            <div className='card-box'>
              <div className='credit-card-body' id={SelectedCard.type}>
                <div className='credit-card-body-top'>
                  {SelectedCard.type === 'USD' ? (
                    <img src={Logo2} alt='' />
                  ) : (
                    <img src={Logo} alt='' />
                  )}
                </div>

                <div className='credit-card-body-bottom'>
                  <div>
                    <p>Card Holder</p>
                  </div>
                  {SelectedCard.currency === 'usd' ? (
                    <img src={MasterLogo} alt='' />
                  ) : (
                    <img src={VerveLogo3} alt='' />
                  )}
                </div>
              </div>
              <h2>{SelectedCard.name}</h2>
              <h3>Amount to Credit</h3>
              <p className='fee'>
                How much will you like to fund the card with in
                <span> {SelectedCard.type}</span>.
              </p>
              {SelectedCard.type === 'USD' && (
                <>
                  <p className='dollar'>
                    amount to pay in naira (₦{dollarRate} / $1){' '}
                    <span>
                      ₦{(creditAmmount * dollarRate).toLocaleString()}
                    </span>
                  </p>
                </>
              )}
              {confirmed && (
                // <p className='dollar'>
                //   Total amount plus charges:{' '}
                //   <span>
                //     ₦{confirmedDetails.total_bill_naira.toLocaleString()}
                //   </span>
                // </p>
                <p
                  className='dollar'
                  style={{
                    color: '#fe8b00',
                    wordSpacing: '3px',
                    lineHeight: '25px',
                    letterSpacing: '1px',
                  }}
                >
                  {confirmedDetails.narration}
                </p>
              )}
              <div className='form-group'>
                <input
                  type='tel'
                  name='number'
                  className='form-control'
                  placeholder={`Amount in ${SelectedCard.type}`}
                  max={SelectedCard.type === 'USD' ? 250 : 500000}
                  onChange={(e) => {
                    setCreditAmmount(e.target.value)
                    if (SelectedCard.type === 'USD') {
                      setShowAmount(e.target.value * dollarRate)
                      // setAmount((e.target.value * dollarRate + 100) * 100)
                      setAmount(e.target.value * dollarRate)
                    } else {
                      setShowAmount(e.target.value * 1 + 100)
                      // setAmount((e.target.value * 1 + 100) * 100)
                      setAmount(e.target.value)
                    }
                  }}
                  required
                  disabled={confirmed}
                />
              </div>
              <div className='form-group'>
                <input
                  type='text'
                  name='alias'
                  className='form-control'
                  placeholder='Card name'
                  maxLength={30}
                  onChange={(e) => setCardName(e.target.value)}
                  disabled={confirmed}
                  required
                />
              </div>
              {SelectedCard.type === 'physical' ? (
                <button
                  onClick={() => {
                    setCardRequestStage('standard')
                  }}
                >
                  Proceed
                </button>
              ) : (
                <>
                  {SelectedCard.type === 'USD' && (
                    <>
                      {(SelectedCard.type === 'USD' && creditAmmount > 10000) ||
                      creditAmmount < 10 ? (
                        <p>
                          Amount is not allowed (amount must be above $10 and
                          below $10,000)
                        </p>
                      ) : (
                        // <PaystackButton
                        //   className='paystack-button'
                        //   {...componentProps}
                        // />
                        <>
                          {confirmed ? (
                            <>
                              <button
                                onClick={() => {
                                  setConfirmed(false)
                                }}
                              >
                                Edit
                              </button>
                              <button
                                onClick={() => {
                                  completePurchase()
                                }}
                              >
                                Pay Now
                              </button>
                            </>
                          ) : (
                            <button
                              onClick={() => {
                                confirmTotalBills()
                              }}
                            >
                              Confirm
                            </button>
                          )}
                        </>
                      )}
                    </>
                  )}
                  {SelectedCard.type !== 'USD' && (
                    <>
                      {(SelectedCard.type !== 'USD' &&
                        creditAmmount > 1000000) ||
                      creditAmmount < 100 ? (
                        <p>
                          Amount is not allowed (amount must be above ₦100 and
                          below ₦1,000,000)
                        </p>
                      ) : (
                        // <PaystackButton
                        //   className='paystack-button'
                        //   {...componentProps}
                        // />
                        <>
                          {confirmed ? (
                            <>
                              <button
                                onClick={() => {
                                  setConfirmed(false)
                                }}
                              >
                                Edit
                              </button>
                              <button
                                onClick={() => {
                                  completePurchase()
                                }}
                              >
                                Pay Now
                              </button>
                            </>
                          ) : (
                            <button
                              onClick={() => {
                                confirmTotalBills()
                              }}
                            >
                              Confirm
                            </button>
                          )}
                        </>
                      )}
                    </>
                  )}

                  {/* <PaystackButton
                  className='paystack-button'
                  {...componentProps}
                /> */}
                </>
              )}
            </div>
          </div>
        )}
        {cardRequestStage === 'four' && (
          <div className='step-two'>
            <div className='card-box'>
              <div className='credit-card-body' id={SelectedCard.type}>
                <div className='credit-card-body-top'>
                  {SelectedCard.type === 'USD' ? (
                    <img src={Logo2} alt='' />
                  ) : (
                    <img src={Logo} alt='' />
                  )}
                </div>
                <div className='che'>
                  <img src={Successful} className='check' alt='' />
                </div>
                <div className='credit-card-body-bottom'>
                  <div>
                    <p>Card Holder</p>
                  </div>
                  {SelectedCard.currency === 'usd' ? (
                    <img src={MasterLogo} alt='' />
                  ) : (
                    <img src={VerveLogo3} alt='' />
                  )}
                </div>
              </div>
              <h2>{SelectedCard.name}</h2>
              <h3>Your request was successful!</h3>
              <p className='fee'>
                Your Virtual <span>{SelectedCard.type}</span> Card, will be
                available within 24 Hours. You will be alerted when the card is
                created and credited.
              </p>
              <button
                onClick={() => window.location.replace('/debit-card/dashboard')}
              >
                Done
              </button>
            </div>
          </div>
        )}
        {cardRequestStage === 'standard' && (
          <div className='step-two'>
            <div className='card-box'>
              <div className='credit-card-body' id={SelectedCard.type}>
                <div className='credit-card-body-top'>
                  {SelectedCard.type === 'USD' ? (
                    <img src={Logo2} alt='' />
                  ) : (
                    <img src={Logo} alt='' />
                  )}
                </div>

                <div className='credit-card-body-bottom'>
                  <div>
                    <p>Card Holder</p>
                  </div>
                  {SelectedCard.currency === 'usd' ? (
                    <img src={MasterLogo} alt='' />
                  ) : (
                    <img src={VerveLogo3} alt='' />
                  )}
                </div>
              </div>
              <h2>{SelectedCard.name}</h2>
              <h3>Select a Delivery Option</h3>
              <p className='fee'>
                Express - delivery within 48 hrs - <b>Costs ₦1,600</b>
              </p>
              <p className='fee'>
                Standard - delivery within 7 - 10 days - <b>Costs ₦1,000</b>
              </p>
              <div className='form-group'>
                <select name='' className='form-control' id=''>
                  <option value=''>select</option>
                  <option value=''>Express</option>
                  <option value=''>Standard</option>
                </select>
              </div>
              <button onClick={() => setCardRequestStage('four')}>
                Proceed
              </button>
            </div>
          </div>
        )}
        {cardRequestStage === 'sudo' && (
          <div className='step-two'>
            {reqLoading && (
              <div className='loade'>
                <img src={Loader} alt='loading...' />
              </div>
            )}
            <div className='card-box'>
              <div className='credit-card-body' id='USD'>
                <div className='credit-card-body-top'>
                  <img src={Logo2} alt='' />
                </div>

                <div className='credit-card-body-bottom'>
                  <div>
                    <p>Card Holder</p>
                  </div>
                  <img src={MasterLogo} alt='' />
                </div>
              </div>
              <h2>Virtual usd card</h2>
              <h3>Amount to Credit</h3>
              <p className='fee'>
                How much will you like to fund the card with in
                <span> {SelectedCard.type}</span>.
              </p>
              <div className='form-group'>
                <input
                  type='tel'
                  name='number'
                  className='form-control'
                  placeholder={`Amount in ${SelectedCard.type}`}
                  max={SelectedCard.type === 'USD' ? 250 : 500000}
                  onChange={(e) => {
                    setCreditAmmount(e.target.value)
                    if (SelectedCard.type === 'USD') {
                      setShowAmount(e.target.value * dollarRate)
                      // setAmount((e.target.value * dollarRate + 100) * 100)
                      setAmount(e.target.value * dollarRate)
                    } else {
                      setShowAmount(e.target.value * 1 + 100)
                      // setAmount((e.target.value * 1 + 100) * 100)
                      setAmount(e.target.value)
                    }
                  }}
                  required
                  disabled={confirmed}
                />
              </div>
              <div className='form-group'>
                <input
                  type='text'
                  name='alias'
                  className='form-control'
                  placeholder='Card name'
                  maxLength={30}
                  onChange={(e) => setCardName(e.target.value)}
                  disabled={confirmed}
                  required
                />
              </div>
              {SelectedCard.type === 'physical' ? (
                <button
                  onClick={() => {
                    setCardRequestStage('standard')
                  }}
                >
                  Proceed
                </button>
              ) : (
                <>
                  {SelectedCard.type === 'USD' && (
                    <>
                      {(SelectedCard.type === 'USD' && creditAmmount > 10000) ||
                      creditAmmount < 1 ? (
                        <p>
                          Amount is not allowed (amount must be above $10 and
                          below $10,000)
                        </p>
                      ) : (
                        // <PaystackButton
                        //   className='paystack-button'
                        //   {...componentProps}
                        // />
                        <>
                          {confirmed ? (
                            <>
                              <button
                                onClick={() => {
                                  setConfirmed(false)
                                }}
                              >
                                Edit
                              </button>
                              <button
                                onClick={() => {
                                  completePurchase()
                                }}
                              >
                                Pay Now
                              </button>
                            </>
                          ) : (
                            <button
                              onClick={() => {
                                confirmTotalBillsSudo()
                              }}
                            >
                              Confirm
                            </button>
                          )}
                        </>
                      )}
                    </>
                  )}
                  {SelectedCard.type !== 'USD' && (
                    <>
                      {(SelectedCard.type !== 'USD' &&
                        creditAmmount > 1000000) ||
                      creditAmmount < 100 ? (
                        <p>
                          Amount is not allowed (amount must be above ₦100 and
                          below ₦1,000,000)
                        </p>
                      ) : (
                        // <PaystackButton
                        //   className='paystack-button'
                        //   {...componentProps}
                        // />
                        <>
                          {confirmed ? (
                            <>
                              <button
                                onClick={() => {
                                  setConfirmed(false)
                                }}
                              >
                                Edit
                              </button>
                              <button
                                onClick={() => {
                                  completePurchase()
                                }}
                              >
                                Pay Now
                              </button>
                            </>
                          ) : (
                            <button
                              onClick={() => {
                                confirmTotalBills()
                              }}
                            >
                              Confirm
                            </button>
                          )}
                        </>
                      )}
                    </>
                  )}

                  {/* <PaystackButton
                  className='paystack-button'
                  {...componentProps}
                /> */}
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Request
