import React, { useState, useEffect } from 'react'
import API from '../../Context/API'
import MonoConnect from '@mono.co/connect.js'
import { useNavigate, useParams } from 'react-router-dom'
import { useGlobalContext } from '../../Context/Context'
import { useBankContext } from './components/Finance/Finance'
import Loading from '../../Component/Loading/Loading'
import IdleTimer from '../Authentication/IdleTimer'
import Topnav from './components/Topnav/Topnav'
import './Dashboard.scss'
import Dashboard2 from './components/Dashboard/Dashboard'
import Expense from './components/Expense/Expense'
import Budget from './components/Budget/Budget'
import CreateBudget from './components/Create-Budget/CreateBudget'
import CreateExpense from './components/Create-Expense/CreateExpense'
import Finance from './components/Finance/Finance'
import FinanceModal from './components/Finance-Modal/FinanceModal'
import month from '../../Data/Month'
import AllExpense from './components/Expense/All-expense'
import GeneralBudget from './components/Create-Budget/GeneralBudget'
import BudgetButtons from './components/Create-Budget/BudgetButtons'
import Notification from './components/Notification/Notification'
import Settings from './components/Settings/Settings'
import Sidebar from './components/Sidebar/Sidebar'
import EWallet from './components/EWallet/EWallet'
import VirtualCards from './components/Virtual-Cards/VirtualCards'
import CreateCHolder from './components/Create-CardHolder/CreateCHolder'
import Contact from './components/Contact/Contact'
import PhysicalCards from './components/Physical-card/PhysicalCards'
import CreateBiz from './components/CreateBiz/CreateBiz'
import SubWallet from './components/SubWallet/SubWallet'
import CardModal from './components/Card-Modal/CardModal'
import Developers from './components/Developers/Developers'
import Occupation from './components/Occupation/Occupation'
import Faq from './components/Faq/Faq'
import InforGraphics from './components/InforGraphics/InforGraphics'
import InfoFund from './components/InforGraphics/InfoFund'
import UserManagement from './components/User-Management/UserManagement'
import CreateDept from './components/CreateDept/CreateDept'
import GetStarted from './components/GetStarted/GetStarted'
import NoPage from './components/NoPage/NoPage'
import Spendwise2 from './components/WhatsNew/Spendwise2'
import ExpenseApproval from './components/ExpenseApproval/ExpenseApproval'
import MagicPin from './components/Occupation/MagicPin'

function Dashboard({ logout, notify, logout2 }) {
  const {
    showBudget,
    showExpense,
    showGBudget,
    showBBudget,
    showCardHolder,
    showCard,
    showInfo,
    showInfo1,
    getStarted,
    showSpendwise20,
  } = useGlobalContext()
  const navigate = useNavigate()
  const { showFinance } = useBankContext()
  const authToken = sessionStorage.getItem('userId')
  const [budget, setBudget] = useState([])
  const [foundbudget, setFoundBudget] = useState(false)
  const [noBudget, setNoBudget] = useState('')
  const [bank, setBank] = useState([])
  const [recentExpense, setRecentExpense] = useState([])
  const [foundExpense, setFoundExpense] = useState(false)
  const [noExpense, setNoExpense] = useState('')
  const [loading, setLoading] = useState(true)
  const [newLoading, setNewLoading] = useState(true)
  const defaultMonthNo = new Date().getMonth()
  const defaultMonth = month[defaultMonthNo]
  const [newUserData, setNewUserData] = useState({})
  const monoConnect = React.useMemo(() => {
    const monoInstance = new MonoConnect({
      onClose: () => notify('warn', 'Mono Widget closed'),
      onSuccess: (data) => {
        fetch('https://www.spendwise.ng/api/tx/exchange_token/', {
          method: 'post',
          headers: {
            'content-Type': 'application/json',
            Authorization: `Token ${authToken}`,
          },
          body: JSON.stringify({
            code: data.code,
          }),
        })
          .then((response) => {
            if (response.status === 200) {
              response.json()
              setTimeout(() => {
                navigate(`/finance`)
              }, 2000)
            } else if (response.status === 400) {
              response.json().then((data) => {
                notify('error', data.message)
              })
            }
          })
          .catch((err) => {
            // notify(
            //   'error',
            //   'An error occured. Unable to reach server. Please check your internet connection.'
            // )
            // setFormLoading(false)
          })
          .then((data) => {
            setTimeout(() => {
              window.location.reload()
            }, 2000)
          })
      },
      key: 'live_pk_vMtEKmsXmcIsTTLrHUeh',
    })
    monoInstance.setup()

    return monoInstance
    // eslint-disable-next-line
  }, [])
  function FetchUser() {
    API.fetchUser().then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setNewUserData(data)
          setNewLoading(false)
        })
      } else if (res.status === 403) {
        logout2()
      }
    })
  }
  useEffect(() => {
    API.fetchBank()
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setBank(data)
          })
        } else if (res.status === 403) {
          logout2()
        } else {
        }
      })
      .catch(() => {
        setLoading(false)
      })
    API.fetchBudget()
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setFoundBudget(true)
            setBudget(data)
            setLoading(false)
          })
        } else if (res.status === 404) {
          res.json().then((data) => {
            setNoBudget(data.message)
            setFoundBudget(false)
          })
        } else if (res.status === 403) {
          logout2()
        }
      })
      .catch((err) => {})
    API.recentexpense({ month: defaultMonth.id })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setRecentExpense(data)
            setFoundExpense(true)
          })
        } else if (res.status === 404) {
          res.json().then((data) => {
            setNoExpense(data.message)
            setFoundExpense(false)
          })
        } else if (res.status === 403) {
          logout2()
        }
      })
      .catch((err) => {})
    FetchUser()
    setTimeout(() => {
      setLoading(false)
    }, 4000)
    // eslint-disable-next-line
  }, [])
  const [isActives, setActives] = useState('false')
  const { page, id } = useParams()
  const user = JSON.parse(sessionStorage.getItem('user'))
  const handleToggle = () => {
    setActives(!isActives)
  }

  const roles = {
    ADMIN: ['userMgt', 'wallet', 'contactUs', 'settings'],
    FINANCE: ['cards', 'wallet', 'finance', 'faq', 'contactUs', 'settings'],
    TEAM_LEAD: ['cards', 'wallet', 'finance', 'faq', 'contactUs', 'settings'],
    OFFICER: ['cards', 'wallet', 'faq', 'contactUs', 'settings'],
    DEVELOPER: ['developer', 'wallet', 'contactUs', 'settings'],
    AUDITOR: ['wallet', 'finance', 'contactUs', 'settings', 'cards'],
  }
  function hasAccess(userRoles, permission) {
    if (user.is_business_created) {
      for (let i = 0; i < userRoles.length; i++) {
        const role = userRoles[i]
        if (roles[role] && roles[role].includes(permission)) {
          return true
        }
      }
      return false
    } else {
      return true
    }
  }

  const displayPage = () => {
    switch (page) {
      case 'home':
        return (
          <Dashboard2
            user={user}
            mono={monoConnect}
            recentExpense={recentExpense}
            foundExpense={foundExpense}
            noExpense={noExpense}
            logout={logout2}
            notify={notify}
            hasAccess={hasAccess}
          />
        )
      // case 'expense':
      //   if (
      //     hasAccess(
      //       user.roles.map((role) => role.trim()),
      //       'finance'
      //     )
      //   ) {
      //     return <Expense logout={logout2} notify={notify} />
      //   } else {
      //     return <h1>You do not have access to this page</h1>
      //   }
      // case 'all-expense':
      //   if (
      //     hasAccess(
      //       user.roles.map((role) => role.trim()),
      //       'finance'
      //     )
      //   ) {
      //     return <AllExpense logout={logout2} notify={notify} />
      //   } else {
      //     return <h1>You do not have access to this page</h1>
      //   }
      // case 'budget':
      //   if (
      //     hasAccess(
      //       user.roles.map((role) => role.trim()),
      //       'finance'
      //     )
      //   ) {
      //     return (
      //       <Budget
      //         budget={budget}
      //         foundbudget={foundbudget}
      //         noBudget={noBudget}
      //         notify={notify}
      //         logout={logout2}
      //         setBudget={setBudget}
      //         setFoundBudget={setFoundBudget}
      //         setNoBudget={setNoBudget}
      //         refreshBudget={refreshBudget}
      //       />
      //     )
      //   } else {
      //     return <h1>You do not have access to this page</h1>
      //   }
      // case 'finance':
      //   if (
      //     hasAccess(
      //       user.roles.map((role) => role.trim()),
      //       'finance'
      //     )
      //   ) {
      //     return (
      //       <Finance
      //         bank={bank}
      //         mono={monoConnect}
      //         notify={notify}
      //         logout={logout2}
      //       />
      //     )
      //   } else {
      //     return <h1>You do not have access to this page</h1>
      //   }
      case 'settings':
        return (
          <Settings
            logout={logout2}
            notify={notify}
            mono={monoConnect}
            FetchUser={FetchUser}
          />
        )
      case 'e-wallet':
        if (
          hasAccess(
            user.roles.map((role) => role.trim()),
            'wallet'
          )
        ) {
          return <EWallet bank={bank} notify={notify} user={user} />
        } else {
          return <h1>You do not have access to this page</h1>
        }

      case 'debit-card':
        if (
          hasAccess(
            user.roles.map((role) => role.trim()),
            'cards'
          )
        ) {
          return <VirtualCards notify={notify} user={user} />
        } else {
          return <h1>You do not have access to this page</h1>
        }
      case 'physical-card':
        if (
          hasAccess(
            user.roles.map((role) => role.trim()),
            'cards'
          )
        ) {
          return <PhysicalCards notify={notify} />
        } else {
          return <h1>You do not have access to this page</h1>
        }
      case 'developers':
        if (
          user.is_business ||
          (user.is_business_created &&
            hasAccess(
              user.roles.map((role) => role.trim()),
              'developer'
            ))
        ) {
          return <Developers user={user} notify={notify} />
        } else {
          return <h1>You do not have access to this page</h1>
        }

      case 'user-management':
        return <UserManagement notify={notify} />
      case 'expense-approval':
        return <ExpenseApproval notify={notify} />
      case 'contact':
        return <Contact notify={notify} />
      case 'activate-business':
        return <CreateBiz notify={notify} />
      case 'sub-wallet':
        return <SubWallet notify={notify} />
      case 'faqs':
        return <Faq />
      default:
        return <NoPage />
    }
  }
  const handleLogout = () => {
    logout()
    navigate('/login')
  }
  return loading ? (
    <Loading />
  ) : (
    <>
      <IdleTimer logout={handleLogout} notify={notify} />
      <div
        className={
          page === 'user-management' ||
          page === 'home' ||
          page === 'e-wallet' ||
          page === 'activate-business' ||
          id === 'transfers' ||
          page === 'expense-approval'
            ? 'dashboard bg'
            : 'dashboard'
        }
      >
        <section className={isActives ? 'side' : 'side active'}>
          <Sidebar
            navigate={navigate}
            page={page}
            handleToggle={handleToggle}
            handleLogout={handleLogout}
            user={newUserData}
            hasAccess={hasAccess}
            notify={notify}
          />
        </section>
        <section className='major'>
          <CreateDept notify={notify} />
          <header className='header'>
            <Topnav
              user={user}
              loadings={loading}
              toggle={handleToggle}
              active={isActives}
              page={page}
              pageName={
                page === 'home'
                  ? 'Dashboard'
                  : page.includes('card')
                  ? 'Cards'
                  : page === 'e-wallet'
                  ? 'E-Wallet'
                  : ''
              }
            />
          </header>
          <Notification notify={notify} />
          {/* Main part of the Site */}
          <main className='main'>{displayPage()}</main>
        </section>
        {/* modals */}
        {/* {newLoading ? (
          ''
        ) : (
          <>
            {newUserData.occupation === null || newUserData.purpose === null ? (
              <section className='occipationmodal'>
                <Occupation notify={notify} />
              </section>
            ) : (
              ''
            )}
          </>
        )} */}
        {newLoading ? (
          ''
        ) : (
          <>
            {!newUserData.magic_pin_set ? (
              <section className='occipationmodal'>
                <MagicPin notify={notify} />
              </section>
            ) : (
              ''
            )}
          </>
        )}

        <section className={showBBudget ? 'sidebudget' : 'sidebudget show'}>
          <BudgetButtons />
        </section>
        <section className={showBudget ? 'sidebudget' : 'sidebudget show'}>
          <CreateBudget notify={notify} />
        </section>
        <section className={showGBudget ? 'sidebudget' : 'sidebudget show'}>
          <GeneralBudget notify={notify} />
        </section>
        <section className={showExpense ? 'sidebudget' : 'sidebudget show'}>
          <CreateExpense notify={notify} />
        </section>
        <section className={showCardHolder ? 'sidebudget' : 'sidebudget show'}>
          <CreateCHolder notify={notify} page={page} />
        </section>
        <section
          className={showFinance ? 'finance-modal' : 'finance-modal active'}
        >
          <FinanceModal />
        </section>
        <div
          className={
            showCard ? 'create-Card-modal' : 'create-Card-modal active'
          }
        >
          <CardModal user={user} />
        </div>
        <div
          className={
            showInfo ? 'create-Card-modal' : 'create-Card-modal active'
          }
        >
          <InforGraphics />
        </div>
        <div
          className={
            showInfo1 ? 'create-Card-modal' : 'create-Card-modal active'
          }
        >
          <InfoFund />
        </div>
        <div
          className={
            getStarted ? 'create-Card-modal' : 'create-Card-modal active'
          }
        >
          <GetStarted user={user} hasAccess={hasAccess} />
        </div>
        <div
          className={
            showSpendwise20 ? 'create-Card-modal' : 'create-Card-modal active'
          }
        >
          <Spendwise2 />
        </div>
      </div>
    </>
  )
}

export default Dashboard
