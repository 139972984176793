import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { FaChevronLeft } from 'react-icons/fa'
import { BsFillImageFill } from 'react-icons/bs'
import FormLoader from '../../../../../Component/FormLoader/FormLoader'
import API from '../../../../../Context/API'

function Signature({ navigate, user, notify }) {
  const authToken = sessionStorage.getItem('userId')
  const [complainPicture, setComplainPicture] = useState({
    string: 'Upload an image',
    file: null,
  })
  const [signatureExists, setSignatureExists] = useState(null)
  const [formLoading, setFormLoading] = useState(false)
  const { handleSubmit } = useForm()
  const onIDCard = () => {
    const pictureData = {
      image: complainPicture.file,
    }
    const form_data = new FormData()
    Object.keys(pictureData).forEach((key) => {
      form_data.append(key, pictureData[key])
    })
    setFormLoading(true)
    fetch('https://www.spendwise.ng/api/expense/create_signature/', {
      method: 'post',
      body: form_data,
      headers: {
        Authorization: `Token ${authToken}`,
      },
    })
      .then((res) => {
        if (res.status === 201) {
          res.json().then((data) => {
            notify('success', data.message)
            setFormLoading(false)
            setTimeout(() => {
              window.location.replace('/settings/security')
            }, 2000)
          })
        } else {
          res.json().then((data) => {
            notify('error', data.message)
            setFormLoading(false)
          })
        }
      })
      .catch((err) => {
        notify('error', 'Something went wrong please try again')
      })
  }
  const onSelectComplainImage = (e) => {
    const file = e.target.files[0]
    const reader = new FileReader()
    reader.readAsDataURL(file)
    if (file.size > 1000000) {
      notify('error', 'Image must be below 1mb')
    } else {
      reader.onloadend = () => {
        setComplainPicture({
          string: file.name,
          file,
        })
      }
    }
  }
  const returnedUser = JSON.parse(sessionStorage.getItem('user'))
  useEffect(
    () => {
      fetch('https://www.spendwise.ng/api/expense/get_signature/', {
        method: 'post',
        body: JSON.stringify({ user_ids: [returnedUser.bu_id] }),
        headers: {
          'content-Type': 'application/json',
          Authorization: `Token ${authToken}`,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            res.json().then((data) => {
              console.log(data)
              setSignatureExists(data.data[0])
            })
          } else {
            res.json().then((data) => {})
          }
        })
        .catch((err) => {
          notify('error', 'Something went wrong please try again')
        })
    },
    // eslint-disable-next-line
    []
  )

  return (
    <div className='bvn'>
      <div className='bvn-top'>
        <h2 className='mai'> Security</h2>
        <h2
          onClick={() => {
            navigate('/settings/security')
          }}
          className='back'
        >
          <FaChevronLeft /> Back
        </h2>
      </div>
      {!signatureExists ? (
        <form onSubmit={handleSubmit(onIDCard)}>
          <h2>Signature</h2>
          <div className='imagee'>
            <input
              type='file'
              name=''
              id='image_complain'
              accept='image/*'
              onChange={onSelectComplainImage}
            />
            <div className='imagee_label'>
              <label htmlFor='image_complain'>
                <p>
                  {complainPicture.string}
                  <BsFillImageFill />
                </p>
              </label>
            </div>
          </div>
          {formLoading ? (
            <FormLoader />
          ) : (
            <div className='button'>
              <button>Submit</button>
            </div>
          )}
        </form>
      ) : (
        <>
          <h4>Signature Saved</h4>
          <div className='sig-image'>
            <img src={signatureExists.image} alt='' />
          </div>
        </>
      )}
    </div>
  )
}

export default Signature
