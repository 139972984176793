const EuroData = [
  { name: 'Andorra', shortcode: 'AD' },
  { name: 'Austria', shortcode: 'AT' },
  { name: 'Belgium', shortcode: 'BE' },
  { name: 'Cyprus', shortcode: 'CY' },
  { name: 'Estonia', shortcode: 'EE' },
  { name: 'Finland', shortcode: 'FI' },
  { name: 'France', shortcode: 'FR' },
  { name: 'Germany', shortcode: 'DE' },
  { name: 'Greece', shortcode: 'GR' },
  { name: 'Ireland', shortcode: 'IE' },
  { name: 'Italy', shortcode: 'IT' },
  { name: 'Kosovo', shortcode: 'XK' },
  { name: 'Latvia', shortcode: 'LV' },
  { name: 'Lithuania', shortcode: 'LT' },
  { name: 'Luxembourg', shortcode: 'LU' },
  { name: 'Malta', shortcode: 'MT' },
  { name: 'Monaco', shortcode: 'MC' },
  { name: 'Montenegro', shortcode: 'ME' },
  { name: 'Netherlands', shortcode: 'NL' },
  { name: 'Portugal', shortcode: 'PT' },
  { name: 'San Marino', shortcode: 'SM' },
  { name: 'Slovakia', shortcode: 'SK' },
  { name: 'Slovenia', shortcode: 'SI' },
  { name: 'Spain', shortcode: 'ES' },
  { name: 'Vatican City (Holy See)', shortcode: 'VA' },
]

export default EuroData
