import React, { useEffect } from 'react'
import Footer from '../../Component/Footer/Footer'
import Navigation from '../../Component/Navigation/Navigation2'
import { BsArrowRight, BsWhatsapp } from 'react-icons/bs'
import './Pricing.scss'
import { AiOutlineCheck } from 'react-icons/ai'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { useNavigate } from 'react-router'

function Pricing() {
  const navigate = useNavigate()
  useEffect(() => {
    AOS.init()
  }, [])

  return (
    <div className='pricing'>
      <Navigation />
      <div className='hero'>
        <div
          className='hero-cover '
          data-aos='fade-up'
          data-aos-duration='1600'
        >
          <h1>Our Pricing Plans</h1>
          <p>
            Flexible payment plans to manage your spending, payments,
            collections and automated financial advisory.
          </p>
          <div className='buttons'>
            <button onClick={() => navigate('/register')}>
              Get Started For Free <BsArrowRight />
            </button>
            {/* <button onClick={() => navigate('/product')}>
              See Products
              <BsArrowRight />
            </button> */}
          </div>
        </div>
      </div>
      <div className='coverxx'>
        <div className='whatsapp'>
          <a href='https://wa.me/2348138134873'>
            <BsWhatsapp />
          </a>
        </div>
        <p>Let's Chat</p>
      </div>
      <div className='profile-g one'>
        <div className='profile-cover'>
          <div className='top' data-aos='fade-up' data-aos-duration='1500'>
            <h2>Individual Profile</h2>
            <p>
              Easy and flexible pricing to get your personal finance, spending
              and payment on track.
            </p>
          </div>
          <div className='pricings'>
            <div
              className='card'
              data-aos='fade-right'
              data-aos-duration='1500'
            >
              <div className='plan'>
                <p>Free</p>
              </div>
              <div className='amount'>
                <h1>NGN 0/mo</h1>
              </div>
              <div className='lists'>
                <div className='row'>
                  <div>
                    <AiOutlineCheck />
                  </div>
                  <p>Create Wallet</p>
                </div>
                <div className='row'>
                  <div>
                    <AiOutlineCheck />
                  </div>
                  <p> Create Unlimited Virtual Cards</p>
                </div>
                <div className='row'>
                  <div>
                    <AiOutlineCheck />
                  </div>
                  <p> Zero transaction fees on virtual cards</p>
                </div>
                <div className='row'>
                  <div>
                    <AiOutlineCheck />
                  </div>
                  <p> Connect one bank account</p>
                </div>
                <div className='row'>
                  <div>
                    <AiOutlineCheck />
                  </div>
                  <p> Expense tracking on connected account (30 days)</p>
                </div>
              </div>
              <button onClick={() => navigate('/register')}>
                Get Started For Free <BsArrowRight />
              </button>
            </div>
            <div
              className='card prim1'
              data-aos='fade-left'
              data-aos-duration='1500'
            >
              <div className='plan'>
                <p>Premium</p>
              </div>
              <div className='amount'>
                <h1>NGN 300/mo</h1>
              </div>
              <div className='lists'>
                <div className='row'>
                  <div>
                    <AiOutlineCheck />
                  </div>
                  <p>Everything on our free plan</p>
                </div>
                <div className='row'>
                  <div>
                    <AiOutlineCheck />
                  </div>
                  <p> 30 Days free premium access</p>
                </div>
                <div className='row'>
                  <div>
                    <AiOutlineCheck />
                  </div>
                  <p> Connect three (3) bank accounts</p>
                </div>
                <div className='row'>
                  <div>
                    <AiOutlineCheck />
                  </div>
                  <p> Expense tracking on connected accounts (Unlimited)</p>
                </div>
                <div className='row'>
                  <div>
                    <AiOutlineCheck />
                  </div>
                  <p>Dedicated 24/7 support officer</p>
                </div>
              </div>
              <button onClick={() => navigate('/register')}>
                Go Premium <BsArrowRight />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='profile-g'>
        <div className='profile-cover'>
          <div className='top' data-aos='fade-up' data-aos-duration='1500'>
            <h2>Business Profile</h2>
            <p>Easy and flexible pricing for businesses of all sizes</p>
          </div>
          <div className='pricings'>
            <div
              className='card biz'
              data-aos='fade-right'
              data-aos-duration='1500'
            >
              <div className='plan'>
                <p>Free</p>
              </div>
              <div className='amount'>
                <h1>NGN 0/mo</h1>
              </div>
              <div className='lists'>
                <div className='row'>
                  <div>
                    <AiOutlineCheck />
                  </div>
                  <p>Create multiple wallet</p>
                </div>
                <div className='row'>
                  <div>
                    <AiOutlineCheck />
                  </div>
                  <p> Create wallet in your business name</p>
                </div>
                <div className='row'>
                  <div>
                    <AiOutlineCheck />
                  </div>
                  <p> Zero transaction fees on virtual cards</p>
                </div>
                <div className='row'>
                  <div>
                    <AiOutlineCheck />
                  </div>
                  <p> Connect one bank account</p>
                </div>
                <div className='row'>
                  <div>
                    <AiOutlineCheck />
                  </div>
                  <p> Expense tracking on connected account (30 days)</p>
                </div>
                <div className='row'>
                  <div>
                    <AiOutlineCheck />
                  </div>
                  <p>
                    Access developer portal (APIs sandbox) 500 API calls per
                    month
                  </p>
                </div>
                <div className='row'>
                  <div>
                    <AiOutlineCheck />
                  </div>
                  <p> Create physical corporate expense cards</p>
                </div>
              </div>
              <button onClick={() => navigate('/register')}>
                Get Started For Free <BsArrowRight />
              </button>
            </div>
            <div
              className='card prim2'
              data-aos='fade-left'
              data-aos-duration='1500'
            >
              <div className='plan'>
                <p>Premium</p>
              </div>
              <div className='amount'>
                <h1>NGN 1000/mo</h1>
              </div>
              <div className='lists'>
                <div className='row'>
                  <div>
                    <AiOutlineCheck />
                  </div>
                  <p>Everything on our free business plan</p>
                </div>
                <div className='row'>
                  <div>
                    <AiOutlineCheck />
                  </div>
                  <p> 30 Days free premium access</p>
                </div>
                <div className='row'>
                  <div>
                    <AiOutlineCheck />
                  </div>
                  <p> Connect three (3) bank accounts</p>
                </div>
                <div className='row'>
                  <div>
                    <AiOutlineCheck />
                  </div>
                  <p> Expense tracking on connected accounts (Unlimited)</p>
                </div>
                <div className='row'>
                  <div>
                    <AiOutlineCheck />
                  </div>
                  <p>
                    Access developer portal (APIs sandbox) with unlimited API
                    calls
                  </p>
                </div>
                <div className='row'>
                  <div>
                    <AiOutlineCheck />
                  </div>
                  <p>Create physical corporate expense/debit cards</p>
                </div>
                <div className='row'>
                  <div>
                    <AiOutlineCheck />
                  </div>
                  <p>Dedicated 24/7 support officer</p>
                </div>
              </div>
              <button onClick={() => navigate('/register')}>
                Go Premium <BsArrowRight />
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Pricing
