import React, { useRef } from 'react'
import IdleTimer from 'react-idle-timer'

function IdleTimerContainer({ logout, notify }) {
  const idleTimerRef = useRef(null)
  const onIdle = () => {
    notify('warn', 'Session Timeout, Please login again')
    setTimeout(() => {
      logout()
    }, 4000)
  }
  return (
    <div>
      <IdleTimer
        ref={idleTimerRef}
        timeout={300 * 1000}
        onIdle={onIdle}
        stopOnIdle={true}
      ></IdleTimer>
    </div>
  )
}

export default IdleTimerContainer
