import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { FaChevronLeft } from 'react-icons/fa'
import { BsFillImageFill } from 'react-icons/bs'

function UtilityBill({ navigate, user, notify }) {
  const authToken = sessionStorage.getItem('userId')
  const [complainPicture, setComplainPicture] = useState({
    string: 'Upload a Screenshot',
    file: null,
  })
  const { handleSubmit } = useForm()
  const onIDCard = (formData) => {
    const pictureData = {
      image: complainPicture.file,
    }
    const form_data = new FormData()
    Object.keys(pictureData).forEach((key) => {
      form_data.append(key, pictureData[key])
    })
    fetch('https://www.spendwise.ng/api/accounts/upload_utility_bill/', {
      method: 'post',
      body: form_data,
      headers: {
        Authorization: `Token ${authToken}`,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            notify('success', data.message)
            setTimeout(() => {
              window.location.replace('/settings/security')
            }, 6000)
          })
        } else {
          res.json().then((data) => {
            notify('error', data.message)
          })
        }
      })
      .catch((err) => {
        notify('error', 'Something went wrong please try again')
      })
  }
  const onSelectComplainImage = (e) => {
    const file = e.target.files[0]
    const reader = new FileReader()
    reader.readAsDataURL(file)
    if (file.size > 1000000) {
      notify('error', 'Image must be below 1mb')
    } else {
      reader.onloadend = () => {
        setComplainPicture({
          string: file.name,
          file,
        })
      }
    }
  }
  return (
    <div className='bvn'>
      <div className='bvn-top'>
        <h2 className='mai'> Security</h2>
        <h2
          onClick={() => {
            navigate('/settings/security')
          }}
          className='back'
        >
          <FaChevronLeft /> Back
        </h2>
      </div>
      <form onSubmit={handleSubmit(onIDCard)}>
        <h2>Utility Bill</h2>
        <div className='imagee'>
          <input
            type='file'
            name=''
            id='image_complain'
            accept='image/*'
            onChange={onSelectComplainImage}
          />
          <div className='imagee_label'>
            <label htmlFor='image_complain'>
              <p>
                {complainPicture.string}
                <BsFillImageFill />
              </p>
            </label>
          </div>
        </div>

        <div className='button'>
          <button>Submit</button>
        </div>
      </form>
    </div>
  )
}

export default UtilityBill
