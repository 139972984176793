import React, { useState, useContext, useEffect } from 'react'
import API from './API'
import moment from 'moment'

const AppContext = React.createContext()

const now = new Date()
const presentWeek = moment(now).format('W')

const Day = {
  options: {
    chart: {
      height: 350,
      type: 'bar',
      events: {
        click: function (chart, w, e) {},
      },
    },
    colors: ['#001AC6'],
    plotOptions: {
      bar: {
        columnWidth: '70%',
        distributed: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    xaxis: {
      categories: [
        ['Mon'],
        ['Tue'],
        ['Wed'],
        ['Thu'],
        ['Fri'],
        ['Sat'],
        ['Sun'],
      ],
      labels: {
        style: {
          colors: ['#001AC6'],
          fontSize: '12px',
        },
      },
    },
  },
}
const Week = {
  options: {
    chart: {
      height: 350,
      type: 'bar',
      events: {
        click: function (chart, w, e) {},
      },
    },
    colors: ['#001AC6'],
    plotOptions: {
      bar: {
        columnWidth: '70%',
        distributed: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    xaxis: {
      categories: [
        [`Week${presentWeek - 6}`],
        [`Week${presentWeek - 5}`],
        [`Week${presentWeek - 4}`],
        [`Week${presentWeek - 3}`],
        [`Week${presentWeek - 2}`],
        [`Week${presentWeek - 1}`],
        [`Week${presentWeek}`],
      ],
      labels: {
        style: {
          colors: ['#001AC6'],
          fontSize: '12px',
        },
      },
    },
  },
}
const Month = {
  series: [
    {
      data: [21, 22, 10, 28, 16, 21, 30, 19, 24, 16, 18, 16],
    },
  ],
  options: {
    chart: {
      height: 350,
      type: 'bar',
      events: {
        click: function (chart, w, e) {},
      },
    },
    colors: ['#001AC6'],
    plotOptions: {
      bar: {
        columnWidth: '70%',
        distributed: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    xaxis: {
      categories: [
        // ['Jan'],
        ['Feb'],
        ['Mar'],
        ['Apr'],
        ['May'],
        ['Jun'],
        // ['Jul'],
        // ['Aug'],
        // ['Sep'],
        // ['Oct'],
        // ['Nov'],
        // ['Dec'],
      ],
      labels: {
        style: {
          colors: ['#001AC6'],
          fontSize: '12px',
        },
      },
    },
  },
}

const AppProvider = ({ children }) => {
  const [bank, setBank] = useState([])
  const [bankLoading, setBankLoading] = useState('')
  const [user, setUser] = useState({})
  const [data, setData] = useState('day')
  const [showBudget, setShowBudget] = useState('false')
  const [showGBudget, setShowGBudget] = useState('false')
  const [showBBudget, setShowBBudget] = useState('false')
  const [showExpense, setShowExpense] = useState('false')
  const [showFinance, setShowFinance] = useState('false')
  const [showCard, setShowCard] = useState('false')
  const [showInfo, setShowInfo] = useState('false')
  const [showInfo1, setShowInfo1] = useState('false')
  const [getStarted, setGetStarted] = useState('false')
  const [showCardHolder, setShowCardHolder] = useState('false')
  const [showSpendwise20, setShowSpendwise20] = useState('false')
  const [financeFound, setfinanceFound] = useState(false)
  const [foundFinance, setfoundFinance] = useState({})
  const [budgetCount, setBudgetCount] = useState({})
  const [pieKeys, setPieKeys] = useState([])
  const [pieValues, setPieValues] = useState([])
  const [pieAvailable, setPieAvailable] = useState(false)
  const [pieNotAvailable, setPieNotAvailable] = useState(false)
  const [showNotification, setShowNotification] = useState('false')
  const [notificationCount, setNotificationCount] = useState(0)
  const [notifications, setNotifications] = useState({})
  const [loading, setLoading] = useState(true)
  const [cardTransactions, setCardTransactionsx] = useState([])
  const [showCreateDept, setShowCreateDept] = useState('false')
  const [departmentList, setDepartmentList] = useState([])
  const [businessInfo, setBusinessInfo] = useState({})

  useEffect(() => {
    API.bankDetails()
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            const keys = Object.values(data.result)
            setBank(keys)
          })
        } else {
          res.json().then((data) => {
            setBankLoading(data.message)
          })
        }
      })
      .catch(() => {})
    API.fetchCategoryTotal()
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setPieValues(Object.values(data[1].totals))
            setPieKeys(Object.keys(data[1].totals))
            if (Object.keys(data[1].totals).length > 0) {
              setPieAvailable(true)
            } else {
              setPieNotAvailable(true)
            }
          })
        } else {
          res.json().then((data) => {})
        }
      })
      .catch(() => {})
    API.budgetStatus().then((res) =>
      res.json().then((data) => {
        setBudgetCount(data.result)
      })
    )
    API.getNotification().then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setNotificationCount(data.results.splice(0, 1))
          setLoading(false)
        })
      }
    })

    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    API.fetchUser()
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setUser(data)
          })
        }
      })
      .catch(() => {})
  }, [])
  const refreshNotification = () => {
    API.getNotification().then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setLoading(false)
          setNotificationCount(data.results.splice(0, 1))
          setNotifications(data.results)
        })
      }
    })
  }
  const refreshUser = () => {
    API.fetchUser()
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setUser(data)
          })
        }
      })
      .catch(() => {})
  }
  const Pie = {
    series: [...pieValues],
    options: {
      labels: [...pieKeys],
      dataLabels: {
        enabled: true,
        // formatter: function (val) {
        //   return val
        // },
        style: {
          colors: ['#fff'],
          fontSize: ['9px'],
        },
      },
      legend: {
        position: 'bottom',
        horinzontalAlign: 'left',
      },
      chart: {
        type: 'donut',
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 400,
            },
          },
        },
      ],
      colors: [
        '#20CB85',
        '#FFAA00',
        '#1B7DBF',
        '#003F5C',
        '#FFEC00',
        '#7CDDDD',
        '#FF0000',
        '#377B2B',
        '#F47A1F',
        '#FDBB2F',
      ],
    },
  }
  const toggleFinance = () => {
    setShowFinance(!showFinance)
  }
  const findFinance = (id) => {
    const financeData = bank.find((item) => item.bank_name === id)
    setfoundFinance(financeData)
    setfinanceFound(true)
    toggleFinance()
  }
  const toggleBudget = () => {
    setShowBudget(!showBudget)
  }
  const toggleShowCreateDept = () => {
    setShowCreateDept(!showCreateDept)
  }
  const toggleGBudget = () => {
    setShowGBudget(!showGBudget)
  }
  const toggleBBudget = () => {
    setShowBBudget(!showBBudget)
  }
  const toggleInfo = () => {
    setShowInfo(!showInfo)
  }
  const toggleInfo1 = () => {
    setShowInfo1(!showInfo1)
  }
  const GetStartedG = () => {
    setGetStarted(!getStarted)
  }
  const toggleCard = () => {
    setShowCard(!showCard)
  }
  const toggleExpense = () => {
    setShowExpense(!showExpense)
  }
  const toggleCardHolder = () => {
    setShowCardHolder(!showCardHolder)
  }
  const toggleNotification = () => {
    setShowNotification(!showNotification)
  }
  const toggleShowSpendwise20 = () => {
    setShowSpendwise20(!showSpendwise20)
  }
  const dayChart = () => {
    setData('day')
  }
  const weekChart = () => {
    setData('week')
  }
  const monthChart = () => {
    setData('month')
  }
  const FetchDepartments = () => {
    API.fetchDepartments()
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setDepartmentList(data.results)
          })
        } else if (res.status === 204) {
        }
      })
      .catch(() => {})
  }
  useEffect(() => {
    FetchDepartments()
  }, [])
  const FetchBusinessDetails = () => {
    API.fetchBusinessDet()
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            console.log(data)
            setBusinessInfo(data.data)
          })
        } else if (res.status === 204) {
        }
      })
      .catch(() => {})
  }
  const SessionStorageGet = (id) => {
    const returnedData = JSON.parse(sessionStorage.getItem(id))
    return returnedData
  }

  return (
    <AppContext.Provider
      value={{
        businessInfo,
        FetchBusinessDetails,
        SessionStorageGet,
        toggleShowSpendwise20,
        showSpendwise20,
        Day,
        user,
        refreshUser,
        Week,
        Month,
        Pie,
        data,
        dayChart,
        getStarted,
        GetStartedG,
        weekChart,
        monthChart,
        showBudget,
        toggleBudget,
        showCreateDept,
        FetchDepartments,
        departmentList,
        toggleShowCreateDept,
        showGBudget,
        toggleGBudget,
        showBBudget,
        toggleBBudget,
        showExpense,
        toggleExpense,
        showFinance,
        toggleFinance,
        findFinance,
        toggleCard,
        showCard,
        toggleInfo,
        showInfo,
        toggleInfo1,
        showInfo1,
        foundFinance,
        financeFound,
        budgetCount,
        pieAvailable,
        pieNotAvailable,
        bank,
        showNotification,
        toggleNotification,
        loading,
        notificationCount,
        setNotificationCount,
        refreshNotification,
        setNotifications,
        notifications,
        setPieValues,
        setPieKeys,
        setPieNotAvailable,
        setPieAvailable,
        showCardHolder,
        toggleCardHolder,
        cardTransactions,
        setCardTransactionsx,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export const useGlobalContext = () => {
  return useContext(AppContext)
}

export { AppContext, AppProvider }
