import React from 'react'
import { FaTimes } from 'react-icons/fa'
import Card from '../../../../Image/credit-card (3) 1.png'
import Business from '../../../../Image/business-bag 1.png'
import Wallet from '../../../../Image/wallet (1) 1.png'
import Api from '../../../../Image/computer-setting 1.png'
import { useGlobalContext } from '../../../../Context/Context'
import { useNavigate } from 'react-router'
import API from '../../../../Context/API'

function CardModal({ user }) {
  const { toggleCard, toggleCardHolder } = useGlobalContext()
  const navigate = useNavigate()
  const CallCardHolder = () => {
    API.FetchSudoAccount()
      .then((res) => {
        if (res.status === 200) {
          navigate('/debit-card/request-sudo')
        } else {
          res.json().then((data) => {
            toggleCardHolder()
          })
        }
      })
      .catch(() => {})
  }
  return (
    <div className='get-started'>
      <div className='infor-top'>
        <h2>Request Card </h2>
        <FaTimes onClick={toggleCard} />
      </div>
      <div className='cards'>
        <div
          className='card'
          onClick={() => {
            CallCardHolder()
            toggleCard()
          }}
        >
          <div className='left'>
            <img src={Card} alt='' />
          </div>
          <div className='right'>
            <h2>Get Virtual USD Card</h2>
            <p>
              (Tip: You would be prompted to create wallet if you don’t have
              one)
            </p>
          </div>
        </div>
        <div
          className='card'
          onClick={() => {
            navigate('/debit-card/ngn-card')
            toggleCard()
          }}
        >
          <div className='left'>
            <img src={Card} alt='' />
          </div>
          <div className='right'>
            <h2>Get Virtual Naira Card</h2>
            <p>
              (Tip: You would be prompted to create wallet if you don’t have
              one)
            </p>
          </div>
        </div>
        <div
          className='card'
          onClick={() => {
            if (user.is_business) {
              navigate('/physical-card/secure')
              toggleCard()
            }
          }}
        >
          <div className='left'>
            <img src={Card} alt='' />
          </div>
          <div className='right'>
            <h2>Get Physical Naira Card</h2>
            <p>(Tip: This Feature is only available to business accounts)</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CardModal
