import React, { useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { FaChevronLeft, FaRegEye, FaRegEyeSlash } from 'react-icons/fa'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import 'react-datepicker/dist/react-datepicker.css'

function Bvn({ navigate, user, notify, FetchUser }) {
  const authToken = sessionStorage.getItem('userId')
  const [showPassword2, setShownPassword2] = useState(false)
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm()
  const onSubmitBVN = (formData) => {
    function convert(str) {
      var date = new Date(str),
        mnth = ('0' + (date.getMonth() + 1)).slice(-2),
        day = ('0' + date.getDate()).slice(-2)
      return [date.getFullYear(), mnth, day].join('-')
    }
    const dateOfBirth = convert(formData.dob)
    fetch('https://www.spendwise.ng/api/accounts/submit_bvn/', {
      method: 'post',
      body: JSON.stringify({ ...formData, dob: dateOfBirth }),
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            notify('success', data.message)
            FetchUser()
            setTimeout(() => {
              navigate(-2)
            }, 3000)
          })
        } else {
          res.json().then((data) => {
            notify('error', data.message)
          })
        }
      })
      .catch((err) => {
        notify('error', 'Something went wrong please try again')
      })
  }
  return (
    <div className='bvn'>
      <div className='bvn-top'>
        <h2 className='mai'> Security</h2>
        <h2
          onClick={() => {
            navigate('/settings/security')
          }}
          className='back'
        >
          <FaChevronLeft /> Back
        </h2>
      </div>
      {user.bvn === null ? (
        <form onSubmit={handleSubmit(onSubmitBVN)}>
          <h2>BVN</h2>
          <div className='card'>
            <p>BVN</p>
            <div className='input'>
              <input
                type={showPassword2 ? 'text' : 'password'}
                name='bvn'
                defaultValue={user.bvn}
                {...register('bvn', {
                  required: 'Provide a BVN NUM',
                  minLength: {
                    value: 11,
                    message: 'BVN NUM must be 11 characters',
                  },
                  maxLength: {
                    value: 11,
                    message: 'BVN NUM must be 11 characters',
                  },
                })}
              />
              {showPassword2 ? (
                <FaRegEyeSlash
                  onClick={() => {
                    setShownPassword2(!showPassword2)
                  }}
                />
              ) : (
                <FaRegEye
                  onClick={() => {
                    setShownPassword2(!showPassword2)
                  }}
                />
              )}
            </div>
            {errors.bvn && (
              <h6 className='vError' style={{ fontSize: '10px', color: 'red' }}>
                {errors.bvn.message}
              </h6>
            )}
          </div>
          <div className='card'>
            <p>First name on BVN</p>
            <div className='input'>
              <input
                type='text'
                name='first_name'
                {...register('first_name')}
                required
              />
            </div>
          </div>
          <div className='card'>
            <p>Surname on BVN</p>
            <div className='input'>
              <input
                type='text'
                name='surname'
                {...register('surname')}
                required
              />
            </div>
          </div>
          <div className='card'>
            <p>Date of birth</p>
            <Controller
              control={control}
              name='dob'
              render={({ field: { onChange, onBlur, value } }) => (
                <DatePicker onChange={onChange} selected={value} />
              )}
            />
          </div>
          <div className='button'>
            <button>Submit</button>
          </div>
        </form>
      ) : (
        <div className='show-bvn'>
          <p>BVN</p>
          <div className='input'>
            <input
              disabled
              type={showPassword2 ? 'text' : 'password'}
              name='bvn'
              defaultValue={user.bvn}
            />
            {showPassword2 ? (
              <FaRegEyeSlash
                onClick={() => {
                  setShownPassword2(!showPassword2)
                }}
              />
            ) : (
              <FaRegEye
                onClick={() => {
                  setShownPassword2(!showPassword2)
                }}
              />
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default Bvn
