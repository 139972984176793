import React from 'react'
import './ExpenseApproval.scss'
import { useParams } from 'react-router'
import ExpenseDashboard from './components/ExpenseDashboard/ExpenseDashboard'
import ExpenseRequest from './components/ExpenseRequest/ExpenseRequest'
import ExpenseDocuments from './components/ExpenseDocuments/ExpenseDocuments'
import ExpenseReceipt from './components/ExpenseReceipt/ExpenseReceipt'
import ExpReqPreview from './components/ExpenseReqPreview/ExpenseReqPreview'
import EditRequest from './components/ExpenseRequest/EditRequest'

function ExpenseApproval({ notify }) {
  const { id } = useParams()
  const approvalPage = () => {
    switch (id) {
      case 'dashboard':
        return <ExpenseDashboard />
      case 'expense-request':
        return <ExpenseRequest notify={notify} />
      case 'expense-documents':
        return <ExpenseDocuments />
      case 'expense-req-preview':
        return <ExpReqPreview notify={notify} />
      case 'expense-receipt':
        return <ExpenseReceipt />
      case 'edit-expense-request':
        return <EditRequest notify={notify} />
      default:
        break
    }
  }

  return (
    <div className='expense-approval'>
      <div className='expense-main'>{approvalPage()}</div>
    </div>
  )
}

export default ExpenseApproval
