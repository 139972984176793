import React, { useState, useEffect } from 'react'
import './Notification.scss'
import { FaEnvelope, FaEnvelopeOpen } from 'react-icons/fa'
import { AiOutlineClose } from 'react-icons/ai'
import API from '../../../../Context/API'
import { useGlobalContext } from '../../../../Context/Context'

const authToken = sessionStorage.getItem('userId')

function Notification({ notify }) {
  // const [notifications, setNotifications] = useState({})
  const [loading, setLoading] = useState(true)
  const [showall, setShowAll] = useState('false')
  const [showArchive, setShowArchive] = useState('false')
  const {
    showNotification,
    toggleNotification,
    notificationCount,
    setNotificationCount,
    refreshNotification,
    setNotifications,
    notifications,
  } = useGlobalContext()

  const handleShowToggle = () => {
    setShowAll(!showall)
  }
  // const refreshNotification = () => {
  //   API.getNotification().then((res) => {
  //     if (res.status === 200) {
  //       res.json().then((data) => {
  //         setLoading(false)
  //         setNotificationCount(data.results.splice(0, 1))
  //         setNotifications(data.results)
  //       })
  //     }
  //   })
  // }
  const onRead = (id) => {
    fetch(`${process.env.REACT_APP_API_URL}/accounts/notifications/`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
      body: JSON.stringify({
        id: id,
      }),
    })
      .then((res) => res.json())
      .then((data) => {})
  }
  const onReadAll = () => {
    fetch(`${process.env.REACT_APP_API_URL}/accounts/notifications/`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
      body: JSON.stringify({
        read_all: 'Archive erm',
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        refreshNotification()
        notify('success', data.message)
      })
  }
  const onArchiveAll = () => {
    fetch(`${process.env.REACT_APP_API_URL}/accounts/notifications/`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
      body: JSON.stringify({
        archive_all: 'Archive erm',
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        refreshNotification()
        notify('success', data.message)
        setShowArchive(!showArchive)
      })
  }

  const Card = ({ item }) => (
    <div
      className={item.read ? 'card active' : 'card'}
      onClick={() => {
        onRead(item.id)
      }}
    >
      <div className='message'>
        {item.read ? <FaEnvelopeOpen /> : <FaEnvelope />}
      </div>
      <div className='notification-content'>
        <p>{item.message}</p>
        <p className='dattime'>
          {new Date(item.delivery_date).toLocaleString()}
        </p>
      </div>
      <div>{item.read ? <></> : <input type='checkbox' name='' id='' />}</div>
    </div>
  )

  useEffect(() => {
    API.getNotification().then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setLoading(false)
          setNotificationCount(data.results.splice(0, 1))
          setNotifications(data.results)
        })
      }
    })
  }, [])
  return (
    <div className={showNotification ? 'notification ' : 'notification active'}>
      {loading ? (
        <h1>No notifications to show yet</h1>
      ) : (
        <>
          <div className='teste' onClick={toggleNotification}></div>
          <div className='wrap'>
            <div
              className={
                showArchive ? 'budget-archive ' : 'budget-archive activez'
              }
            >
              <div className='archive-wrap'>
                <div className='top'>
                  <h2>Archive all Notifications?</h2>
                </div>
                <div className='buttons'>
                  <button
                    onClick={() => {
                      setShowArchive(!showArchive)
                    }}
                  >
                    No
                  </button>
                  <button
                    className='normal'
                    onClick={() => {
                      onArchiveAll()
                    }}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
            <div className='notification-top'>
              <p>Notifications</p>
              <div>
                <button
                  onClick={() => {
                    onReadAll()
                  }}
                >
                  Mark all as read
                </button>
                <button onClick={() => refreshNotification()}>
                  Mark as read
                </button>
                <button
                  onClick={() => {
                    setShowArchive(!showArchive)
                  }}
                >
                  Archive all
                </button>
              </div>
              <div className='closee'>
                <AiOutlineClose onClick={toggleNotification} />
              </div>
            </div>
            <div className='notification-count'>
              <p>{notificationCount[0].unread} Unread Messages</p>
            </div>
            <div className='notification-body'>
              <div
                className={
                  showall ? 'notification-wrap ' : 'notification-wrap active '
                }
              >
                {notifications
                  .sort((a, b) => a.read - b.read)
                  .map((item) => (
                    <Card item={item} key={item.id} />
                  ))}
              </div>
              <div className='show-more' onClick={handleShowToggle}>
                {showall ? <p>Show More</p> : <p>Close</p>}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default Notification
