import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Dashboard from './components/Dashboard'
import RequestCard from './components/RequestCard'
import './PhysicalCards.scss'
import API from '../../../../Context/API'
import EditCard from './components/EditCard'
import Pin from './components/Pin'
import { useGlobalContext } from '../../../../Context/Context'
import RequestedCards from './components/RequestedCards'
import Loader from '../../../../Image/loder.gif.gif'

function PhysicalCards({ notify }) {
  const authToken = sessionStorage.getItem('userId')
  const [sudoCardsList, setSudoCardsList] = useState([])
  const { id } = useParams()
  const [sudoDetails, setSudoDetails] = useState({})
  const [checkWallet, setcheckWallet] = useState(true)
  const { toggleCardHolder } = useGlobalContext()
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    document.title = 'Spendwise | Physical Cards'
  }, [])
  useEffect(() => {
    sessionStorage.removeItem('ddf8837e6d4defcc85a76e2cd2c156ef64a128e5')
    sessionStorage.removeItem('wallet-account')
    API.FetchSudoCards()
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setSudoCardsList(
              data.card_data.filter((item) => item.currency === 'NGN')
            )
          })
        } else {
          res.json().then((data) => {})
        }
      })
      .catch(() => {})
  }, [])
  useEffect(() => {
    API.FetchSudoAccount()
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setcheckWallet(false)
            setLoading(false)
            setSudoDetails(
              data.wallet_data.find(
                (item) => item.wallet_type === 'main-wallet'
              )
            )
          })
        } else {
          res.json().then((data) => {
            setSudoDetails({
              account_name: 'unavailable',
              bank1: 'unavailable',
              account_no1: 'unavailable',
              available_balance: 0,
            })
            if (
              data.message ===
              'No user found or user has no sudo holder account.'
            ) {
              setcheckWallet(true)
            }
            setLoading(false)
          })
        }
      })
      .catch(() => {})
  }, [])
  const createSudoWallet = () => {
    API.CreateSudoAccount().then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          notify('success', 'wallet creation in progress')
          window.location.reload()
        })
      } else {
        res.json().then((data) => {
          notify('warn', data.message)
          if (
            data.message === 'No user found or user has no sudo holder account.'
          ) {
            toggleCardHolder()
          }
        })
      }
    })
  }

  const cardPage = () => {
    switch (id) {
      case 'dashboard':
        return (
          <Dashboard
            sudoDetails={sudoDetails}
            notify={notify}
            sudoCardsList={sudoCardsList}
          />
        )
      case 'secure':
        return <Pin notify={notify} sudoDetails={sudoDetails} />
      case 'request':
        return <RequestCard notify={notify} sudoDetails={sudoDetails} />
      case 'edit':
        return <EditCard notify={notify} sudoDetails={sudoDetails} />
      case 'requested-cards':
        return <RequestedCards notify={notify} sudoDetails={sudoDetails} />
      default:
        return <div>No page to display</div>
    }
  }
  return loading ? (
    <div className='vloader'>
      <img src={Loader} />
    </div>
  ) : (
    <div className='physical_card'>
      {checkWallet && (
        <div className='askoverlay'>
          <button onClick={() => createSudoWallet()}>Create Wallet</button>
        </div>
      )}
      <div className='physical_cards_top'>
        <h2 className='physical_cards-text'>Physical Cards</h2>
      </div>
      <>{cardPage()}</>
    </div>
  )
}

export default PhysicalCards
