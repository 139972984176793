import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Logo from '../../Image/logo.png'
import { useNavigate } from 'react-router-dom'
import './Navigation.scss'

function Navigation() {
  const [isActive, setActive] = useState('false')
  const handleToggle = () => {
    setActive(!isActive)
  }
  const navigate = useNavigate()
  const location = useLocation()
  return (
    <div className='over-view'>
      <nav className='landing-navigation2'>
        <div className='logo' onClick={() => navigate('/')}>
          <img className='logo1' src={Logo} alt='Logo' />
        </div>
        <div
          onClick={handleToggle}
          className={isActive ? 'hamburger' : 'hamburger toggle'}
        >
          <div className='line1'></div>
          <div className='line2'></div>
          <div className='line3'></div>
        </div>
        <ul className={isActive ? 'nav-links mods' : 'nav-links mods open'}>
          <li onClick={handleToggle} className={isActive ? '' : 'fade'}>
            <Link
              className={
                location.pathname === '/product' ? 'link active' : 'link'
              }
              to='/product'
            >
              Products
            </Link>
          </li>
          <li onClick={handleToggle} className={isActive ? '' : 'fade'}>
            <Link
              className={
                location.pathname === '/pricing' ? 'link active' : 'link'
              }
              to='/pricing'
            >
              Pricing
            </Link>
          </li>
          <li onClick={handleToggle} className={isActive ? '' : 'fade'}>
            <Link
              className={location.pathname === '/faq' ? 'link active' : 'link'}
              to='/faq'
            >
              FAQs
            </Link>
          </li>
          <Link to='/login'>
            <button>Log In</button>
          </Link>
          <Link to='/register'>
            <button className='right-btn'>Sign Up</button>
          </Link>
        </ul>
      </nav>
    </div>
  )
}

export default Navigation
