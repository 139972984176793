import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { FaChevronRight, FaTimes } from 'react-icons/fa'
import API from '../../../../../../../Context/API'
import Loader from '../../../../../../../Image/loder.gif.gif'
import { IoIosArrowDown } from 'react-icons/io'
import { BiTransferAlt, BiUserPlus } from 'react-icons/bi'

function Select() {
  const navigate = useNavigate()
  const [showButtons, setshowButtons] = useState('')
  const [loading, setLoading] = useState(true)
  const [beneData, setBeneData] = useState([])
  const [selectedUser, setSelectedUser] = useState({})
  const [showMore, setShowMore] = useState(false)
  useEffect(() => {
    API.fetchFXTransfers()
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setLoading(false)
            setBeneData(data.data)
          })
        } else {
          setLoading(false)
          res.json().then((data) => {})
        }
      })
      .catch(() => {})
  }, [])
  const showMoreNow = (e) => {
    setSelectedUser(e)
    setShowMore(true)
  }
  return (
    <div className='select'>
      {showMore && (
        <div className='open-modal'>
          <div className='modal-content'>
            <div className='headee'>
              <h1>Transfer Details</h1>
              <FaTimes onClick={() => setShowMore(false)} />
            </div>
            <div className='rows'>
              <div className='cells'>
                <h2>Transfer status</h2>
              </div>
              <div className='cellx'>
                <p>{`${selectedUser.completed}`}</p>
              </div>
            </div>
            <div className='rows'>
              <div className='cells'>
                <h2>Company name</h2>
              </div>
              <div className='cellx'>
                <p>{selectedUser.beneficiary.company_name}</p>
              </div>
            </div>
            <div className='rows'>
              <div className='cells'>
                <h2>Currency</h2>
              </div>
              <div className='cellx'>
                <p>{selectedUser.currency}</p>
              </div>
            </div>
            <div className='rows'>
              <div className='cells'>
                <h2>Amount</h2>
              </div>
              <div className='cellx'>
                <p>{parseInt(selectedUser.amount).toLocaleString()}</p>
              </div>
            </div>
            <div className='rows'>
              <div className='cells'>
                <h2>Charges</h2>
              </div>
              <div className='cellx'>
                <p>{selectedUser.charges}</p>
              </div>
            </div>
            <div className='rows'>
              <div className='cells'>
                <h2>Total amount charged</h2>
              </div>
              <div className='cellx'>
                <p>
                  NGN {parseInt(selectedUser.total_bill_ngn).toLocaleString()}
                </p>
              </div>
            </div>
            <div className='rows'>
              <div className='cells'>
                <h2>Email</h2>
              </div>
              <div className='cellx'>
                <p>{selectedUser.beneficiary.recipient_email}</p>
              </div>
            </div>
            <div className='rows'>
              <div className='cells'>
                <h2>Narration</h2>
              </div>
              <div className='cellx'>
                <p>{selectedUser.narration}</p>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className='label'>
        <p onClick={() => navigate('/e-wallet/transfers/select')}>Wallet</p>
        <FaChevronRight />
        <h3>Foreign Account</h3>
      </div>
      <div className='row'>
        <div
          className='card'
          onClick={() =>
            navigate('/e-wallet/transfers/foreign-transfer/add-beneficiary')
          }
        >
          <BiUserPlus className='trs' />
          <h3>Add Beneficiary</h3>
          <p>Add your beneficiaries for future transactions</p>
        </div>
        <div
          className='card'
          onClick={() =>
            navigate('/e-wallet/transfers/foreign-transfer/initate-transfer')
          }
        >
          <BiTransferAlt className='trs' />
          <h3>Initiate Transfer</h3>
          <p>Make a transfer to a saved beneficiary.</p>
        </div>
      </div>
      <div className='hedas'>
        <h2>Transfer Status</h2>
      </div>
      {loading ? (
        <div className='loader'>
          <img src={Loader} alt='' />
        </div>
      ) : (
        <>
          {beneData.length > 0 ? (
            <div className='select-beneficiary-body'>
              <div className='table'>
                <div className='thead'>
                  <div className='row'>
                    <div className='th'>
                      <p>Company name </p>
                    </div>
                    <div className='th'>
                      <p>Bank name</p>
                    </div>
                    <div className='th'>
                      <p>Currency</p>
                    </div>
                    <div className='th'>
                      <p>Acc / IBAN</p>
                    </div>

                    <div className='th'>
                      <p>Amount</p>
                    </div>
                    <div className='th'>
                      <p>Transfer status</p>
                    </div>
                    <div className='th'>
                      <p>Actions</p>
                    </div>
                  </div>
                </div>
                <div className='tbody'>
                  {beneData.map((item) => (
                    <div className='card' key={item.trf_id}>
                      <div className='row'>
                        <div className='td'>
                          <p>{item.beneficiary.company_name}</p>
                        </div>
                        <div className='td'>
                          <p>{item.beneficiary.bank_name}</p>
                        </div>
                        <div className='td'>
                          <p>{item.currency}</p>
                        </div>
                        <div className='td'>
                          <p>
                            {item.beneficiary.ac_no
                              ? item.beneficiary.ac_no
                              : item.beneficiary.iban}
                          </p>
                        </div>
                        <div className='td'>
                          <p>{parseInt(item.amount).toLocaleString()}</p>
                        </div>
                        <div className='td'>
                          <p>{`${item.completed}`}</p>
                        </div>
                        <div className='td'>
                          <p>
                            <IoIosArrowDown
                              onClick={() => {
                                if (showButtons === item.trf_id) {
                                  setshowButtons('')
                                } else {
                                  setshowButtons(item.trf_id)
                                }
                              }}
                            />
                          </p>
                        </div>
                      </div>
                      {showButtons === item.trf_id && (
                        <div className='buttons'>
                          <button onClick={() => showMoreNow(item)}>
                            Show More
                          </button>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <div className='mobile_transactions'>
                <div className='mobile_table'>
                  {beneData.map((item) => (
                    <div className='cardv' key={item.trf_id}>
                      <div className='rows'>
                        <div className='cells'>
                          <h2>Company name</h2>
                        </div>
                        <div className='cellx'>
                          <p>{item.company_name}</p>
                        </div>
                      </div>
                      <div className='rows'>
                        <div className='cells'>
                          <h2>Currency</h2>
                        </div>
                        <div className='cellx'>
                          <p>{item.currency}</p>
                        </div>
                      </div>
                      <div className='rows'>
                        <div className='cells'>
                          <h2>Bank name</h2>
                        </div>
                        <div className='cellx'>
                          <p>{item.bank_name}</p>
                        </div>
                      </div>
                      <div className='rows'>
                        <div className='cells'>
                          <h2>{item.ac_no ? 'Account Number' : 'IBAN'} </h2>
                        </div>
                        <div className='cellx'>
                          <p>{item.ac_no ? item.ac_no : item.iban}</p>
                        </div>
                      </div>
                      <div className='rows'>
                        <div className='cells'>
                          <h2>
                            {item.sort_code !== null
                              ? 'Sort Code'
                              : item.routing_code !== null
                              ? 'Routing Code'
                              : item.cnaps !== null
                              ? 'CNAPS'
                              : item.routing_no_aba !== null
                              ? 'Routing no / Aba code'
                              : item.swift !== null
                              ? 'Swift'
                              : ''}
                          </h2>
                        </div>
                        <div className='cellx'>
                          <p>
                            {item.sort_code !== null
                              ? item.sort_code
                              : item.routing_code !== null
                              ? item.routing_code
                              : item.cnaps !== null
                              ? item.cnaps
                              : item.routing_no_aba !== null
                              ? item.routing_no_aba
                              : item.swift !== null
                              ? item.swift
                              : ''}
                          </p>
                        </div>
                      </div>
                      <div className='rows'>
                        <div className='cells'>
                          <h2>Country</h2>
                        </div>
                        <div className='cellx'>
                          <p>{item.country}</p>
                        </div>
                      </div>
                      <div className='rows'>
                        <div className='cells'>
                          <h2>verification status</h2>
                        </div>
                        <div className='cellx'>
                          <p>{item.verification_status}</p>
                        </div>
                      </div>
                      <div className='buttons'>
                        <button onClick={() => showMoreNow(item)}>
                          Show More
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            <div>No Transfer initiated yet</div>
          )}
        </>
      )}
    </div>
  )
}

export default Select
