import React, { useState, useEffect } from 'react'
import { useGlobalContext } from '../../../../Context/Context'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import './Expense.scss'
import { FaCarAlt } from 'react-icons/fa'
import { BsInfoCircleFill } from 'react-icons/bs'
import Loading from '../../../../Component/Loading/Loading'
import Chart from 'react-apexcharts'
import monthDataArray from '../../../../Data/Month'
import year from '../../../../Data/Year'
import API from '../../../../Context/API'
import TableLoader from '../../../../Component/TableLoader/TableLoader'
import PieChart from '../../../../Component/PieChart/PieChart'
import CategoriesData from '../../../../Data/CategoriesData'
import { AiOutlineClose, AiFillDelete, AiOutlineEdit } from 'react-icons/ai'
import { MdCallSplit } from 'react-icons/md'

function Expense({ notify, logout }) {
  const navigate = useNavigate()
  const {
    Day,
    Week,
    Month,
    Pie,
    data,
    dayChart,
    weekChart,
    monthChart,
    toggleExpense,
    pieAvailable,
    setPieValues,
    setPieKeys,
    setPieNotAvailable,
    setPieAvailable,
  } = useGlobalContext()
  const [budgetExpense, setBudgetExpense] = useState({})
  const [fetchedBE, setFetchedBE] = useState(false)
  const [fetchedData, setFetchedData] = useState({})
  const [fetched, setFetched] = useState(false)
  const [weekData, setWeekData] = useState({})
  const [monthData, setMonthData] = useState({})
  const [dayData, setDayData] = useState({})
  const [formLoading, setFormLoading] = useState(false)
  const { register, handleSubmit } = useForm()
  const { register: register2, handleSubmit: handleSubmit2 } = useForm()
  const { register: register3, handleSubmit: handleSubmit3 } = useForm()
  const { register: register4, handleSubmit: handleSubmit4 } = useForm()
  const [selected, setSelected] = useState('')
  const [dayAvailable, setDayAvailable] = useState(false)
  const [monthAvailable, setMonthAvailable] = useState(false)
  const [weeksAvailable, setWeeksAvailable] = useState(false)
  const [newExpense, setNewExpense] = useState([])
  const [changeLoading, setChangeLoading] = useState(false)
  const [fetchedChangeData, setFetchedChangeData] = useState({})
  const [fetchedChange, setFetchedChange] = useState(false)
  const [fetchedDeleteData, setFetchedDeleteData] = useState({})
  const [fetchedDelete, setFetchedDelete] = useState(false)
  const [fetchedNewTxData, setFetchedNewTxData] = useState({})
  const [fetchedNewTx, setFetchedNewTx] = useState(false)
  const [showDataChange, setShowDataChange] = useState(false)

  const authToken = sessionStorage.getItem('userId')
  const defaultMonth = monthDataArray[new Date().getMonth()]
  // const weekKeys = Object.values(weekData)
  useEffect(() => {
    document.title = 'Spendwise | Expense'
  }, [])
  useEffect(() => {
    API.userWeekData()
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setWeekData(Object.values(data.message))
            setWeeksAvailable(true)
          })
        } else if (res.status === 404) {
          res.json().then((data) => {})
        } else if (res.status === 403) {
          logout()
        } else {
        }
      })
      .catch((err) => {})
    API.userMonthData()
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setMonthData(data.sme_result)
            setMonthAvailable(true)
          })
        } else if (res.status === 404) {
          res.json().then((data) => {})
        } else {
        }
      })
      .catch((err) => {})
    API.userDayData()
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setDayData(data.sme_result)
            setDayAvailable(true)
          })
        } else if (res.status === 404) {
          res.json().then((data) => {})
        } else {
        }
      })
      .catch((err) => {})
    API.recentbudgetexpense()
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            const keys = Object.values(data.message[defaultMonth.Full])
            setBudgetExpense(
              keys.filter((item) => item.transactions.length > 0)
            )
            setFetchedBE(true)
          })
        } else if (res.status === 404) {
          res.json().then((data) => {})
        } else {
        }
      })
      .catch((err) => {})
  }, [])
  const refreshExpense = () => {
    API.recentbudgetexpense()
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            const keys = Object.values(data.message[defaultMonth.Full])
            setBudgetExpense(
              keys.filter((item) => item.transactions.length > 0)
            )
            setFetchedBE(true)
          })
        } else if (res.status === 404) {
          res.json().then((data) => {})
        } else {
        }
      })
      .catch((err) => {})
  }
  const onFetch = (formData) => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    setFormLoading(true)
    setFetchedBE(false)
    setFetched(false)

    const month = parseInt(formData.month)
    const year = parseInt(formData.year)
    const chosenMonth = monthDataArray[month - 1]
    const monthFull = chosenMonth.Full
    fetch(`${process.env.REACT_APP_API_URL}/budget/budget_expense_analytics/`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
      body: JSON.stringify({
        month: month,
        year: year,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            const keys = Object.values(data.message[monthFull])

            setBudgetExpense(keys)
            setFetchedBE(true)
            setFormLoading(false)
          })
        } else if (res.status === 404) {
          res.json().then((data) => {
            setFormLoading(false)
            setFetchedBE(false)
          })
        } else {
          setFormLoading(false)
        }
      })
      .catch((err) => {
        setFormLoading(false)
      })
  }
  const onChangeTransaction = (formData) => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    setChangeLoading(true)
    fetch(`${process.env.REACT_APP_API_URL}/tx/change_category/`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
      body: JSON.stringify({
        id: fetchedChangeData.id,
        ...formData,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            notify('success', data.message)
            refreshExpense()
            setChangeLoading(false)
            setFetchedChange(false)
            setFetchedChangeData({})
            setFetched(false)
            // setTimeout(() => {
            //   window.location.reload()
            // }, 2000)
          })
        } else if (res.status === 404) {
          res.json().then((data) => {
            notify('error', data.message)
          })
        } else {
          setFormLoading(false)
        }
      })
      .catch((err) => {
        setFormLoading(false)
      })
  }
  const onNewTransaction = (formData) => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    setChangeLoading(true)
    fetch(`${process.env.REACT_APP_API_URL}/tx/convert/`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
      body: JSON.stringify({
        id: fetchedNewTxData.id,
        ...formData,
      }),
    })
      .then((res) => {
        if (res.status === 201) {
          res.json().then((data) => {
            notify('success', data.message)
            refreshExpense()
            setFetchedNewTx(false)
            setFetchedNewTxData({})
            setFetched(false)
            setChangeLoading(false)
          })
        } else if (res.status === 400) {
          res.json().then((data) => {
            notify('error', data.message)
          })
        } else {
          setFormLoading(false)
        }
      })
      .catch((err) => {
        setFormLoading(false)
      })
  }
  const onFindChange = (id) => {
    const foundItem = fetchedData.transactions.find((item) => item.id === id)
    setFetchedChangeData(foundItem)
    setFetchedChange(true)
  }
  const onNewTx = (id) => {
    const foundItem = fetchedData.transactions.find((item) => item.id === id)
    setFetchedNewTxData(foundItem)
    setFetchedNewTx(true)
  }
  const onFetchPie = (formData) => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    setFormLoading(true)

    const month = parseInt(formData.month)
    fetch(`${process.env.REACT_APP_API_URL}/tx/category_totals/`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
      body: JSON.stringify({
        month: month,
      }),
    })
      // .then((res) => res.json())
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setFormLoading(false)
            setPieValues(Object.values(data[1].totals))
            setPieKeys(Object.keys(data[1].totals))
            if (Object.keys(data[1].totals).length > 0) {
              setPieAvailable(true)
            } else {
              setPieNotAvailable(true)
            }
          })
        } else if (res.status === 404) {
          res.json().then((data) => {})
        } else {
          setFormLoading(false)
        }
      })
      .catch((err) => {
        setFormLoading(false)
      })
  }

  const onFind = (id) => {
    const foundItem = budgetExpense.find((item) => item.category === id)
    setFetchedData(foundItem)
    setFetched(true)
  }

  const weekSeries = [
    {
      data: [
        weekData[6],
        weekData[5],
        weekData[4],
        weekData[3],
        weekData[2],
        weekData[1],
        weekData[0],
      ],
    },
  ]
  const monthSeries = [
    {
      data: [
        monthData.month_5,
        monthData.month_4,
        monthData.month_3,
        monthData.month_2,
        monthData.month_1,
      ],
    },
  ]
  const daySeries = [
    {
      data: [
        dayData.Monday,
        dayData.Tuesday,
        dayData.Wednesday,
        dayData.Thursday,
        dayData.Friday,
        dayData.Saturday,
        dayData.Sunday,
      ],
    },
  ]

  const BudgetExpenseRow = ({ item }) => (
    <tr
      key={item.category}
      onClick={() => {
        onFind(item.category)
      }}
      className={fetchedData.category === item.category ? 'actives' : ''}
    >
      <td className='feed'>
        <span className='icon'>
          <img src={item.cat_icon} alt='' />
        </span>
        <span>{item.category}</span>
      </td>
      <td className='trans'>{item.transactions.length}</td>
      <td className='spent'>₦{item.total_expense.toLocaleString()}</td>
      <td className='budget'>₦{item.budget_amount.toLocaleString()}</td>
      <td>
        <progress
          className={item.total_expense > item.budget_amount ? 'rede' : ''}
          max={item.budget_amount}
          value={item.total_expense}
        ></progress>
      </td>
    </tr>
  )
  return (
    <div className='expense'>
      <div className='head'>
        <h1>Expense</h1>
        <button onClick={toggleExpense}>Record Expense</button>
      </div>
      <div className='expense-flow'>
        <form className='sort diff' onSubmit={handleSubmit(onFetch)}>
          <div className='select-wrap'>
            <select name='month' id='' {...register('month')}>
              <option value={defaultMonth.id}>{defaultMonth.Content}</option>
              {monthDataArray.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.Content}
                </option>
              ))}
            </select>
            <select name='year' id='' {...register('year')}>
              <option value='2023'>2023</option>
              <option value='2022'>2022</option>
              <option value='2024'>2024</option>
            </select>
          </div>
          <button>{formLoading ? '.....' : 'View'}</button>
        </form>
        <div className='sort'>
          <button
            onClick={() => {
              navigate('/all-expense')
            }}
          >
            View all expenses
          </button>
        </div>
      </div>
      <div className='transaction-flex'>
        <div className='left'>
          {fetchedBE ? (
            <>
              {budgetExpense.length > 0 ? (
                <table className='left-table'>
                  <thead className='left-tablehead'>
                    <tr>
                      <th>Category</th>
                      <th>Transactions</th>
                      <th>Spent</th>
                      <th>Budget</th>
                      <th>Spent progress</th>
                    </tr>
                  </thead>

                  <tbody className='left-tablebody'>
                    {budgetExpense
                      .sort((a, b) => b.total_expense - a.total_expense)
                      .map((item, i) => (
                        <BudgetExpenseRow item={item} key={item.category} />
                      ))}
                  </tbody>
                </table>
              ) : (
                <h1>No Transaction to show</h1>
              )}
            </>
          ) : (
            <TableLoader className='tableLoader' />
          )}
        </div>
        <div className='right'>
          {fetched ? (
            <>
              <div className='wrap-card'>
                {fetchedData.transactions.map((item) => (
                  <div className='cover' key={item.id}>
                    <div className='deta'>{item.description}</div>
                    <div className='date'>
                      {new Date(item.date).toDateString()}
                    </div>
                    <div className='amou'>₦{item.amount.toLocaleString()}</div>
                    <div className='icons'>
                      {fetchedData.category === 'POS Payment' ||
                      fetchedData.category === 'ATM Transaction' ? (
                        <MdCallSplit
                          title='Split transaction into other categories'
                          className='split'
                          onClick={() => {
                            onNewTx(item.id)
                          }}
                        />
                      ) : (
                        ''
                      )}
                      <AiOutlineEdit
                        className='edit-icon'
                        title='Click to change category'
                        onClick={() => onFindChange(item.id)}
                      />
                    </div>
                  </div>
                ))}
                {fetchedData.transactions.length > 0 ? (
                  <></>
                ) : (
                  <p>No transaction to show</p>
                )}
              </div>
              {fetchedChange ? (
                <div className='change-category'>
                  <div className='change-wrap'>
                    <div className='top'>
                      <h2>Change Category</h2>
                      <AiOutlineClose
                        onClick={() => {
                          setFetchedChange(false)
                          setFetchedChangeData({})
                        }}
                      />
                    </div>
                    <form onSubmit={handleSubmit2(onChangeTransaction)}>
                      <div>
                        <select
                          name='category'
                          id=''
                          {...register2('category')}
                        >
                          <option value={fetchedData.category}>
                            {fetchedData.category}
                          </option>
                          {CategoriesData.map((item) => (
                            <option value={item.Content} key={item.id}>
                              {item.Content}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div>
                        <button>
                          {' '}
                          {changeLoading ? '.....' : 'Change category'}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              ) : (
                ''
              )}
              {fetchedNewTx ? (
                <div className='change-category'>
                  <div className='change-wrap'>
                    <div className='top'>
                      <h2>Split Tansaction</h2>
                      <AiOutlineClose
                        onClick={() => {
                          setFetchedNewTx(false)
                          setFetchedNewTxData({})
                        }}
                      />
                    </div>
                    <form onSubmit={handleSubmit3(onNewTransaction)}>
                      <div>
                        <input
                          type='number'
                          max={fetchedNewTxData.tx_amount}
                          placeholder='Amount'
                          name='amount'
                          {...register3('amount')}
                          required
                        />
                      </div>
                      <div>
                        <select
                          name='category'
                          id=''
                          {...register3('category')}
                        >
                          {CategoriesData.map((item) => (
                            <option value={item.Content} key={item.id}>
                              {item.Content}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div>
                        <textarea
                          name='description'
                          id=''
                          cols='30'
                          rows='10'
                          placeholder='Description'
                          required
                          {...register3('description')}
                        ></textarea>
                      </div>
                      <div>
                        <button>
                          {' '}
                          {changeLoading ? '.....' : 'Create Transaction'}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              ) : (
                ''
              )}
            </>
          ) : (
            <div className='wrap'>
              <BsInfoCircleFill />
              <p>Select category to display information</p>
            </div>
          )}
        </div>
      </div>
      <div className='expense-flex'>
        <div className='left'>
          <div className='top'>
            <h2>Expense Summary</h2>
            <div>
              <button
                onClick={dayChart}
                className={data === 'day' ? 'active' : ''}
              >
                Day
              </button>
              <button
                onClick={weekChart}
                className={data === 'week' ? 'active' : ''}
              >
                Week
              </button>
              <button
                onClick={monthChart}
                className={data === 'month' ? 'active' : ''}
              >
                Month
              </button>
            </div>
          </div>
          <div className='charts'>
            {data === 'day' && (
              <>
                {dayAvailable ? (
                  <Chart
                    options={Day.options}
                    series={daySeries}
                    type='bar'
                    height={300}
                    className='chart-card'
                  />
                ) : (
                  <h1>No Days expense to show</h1>
                )}
              </>
            )}
            {data === 'week' && (
              <>
                {weeksAvailable ? (
                  <Chart
                    options={Week.options}
                    series={weekSeries}
                    type='bar'
                    height={300}
                    className='chart-card'
                  />
                ) : (
                  <h1>No Weeks expense to show</h1>
                )}
              </>
            )}
            {data === 'month' && (
              <>
                {monthAvailable ? (
                  <Chart
                    options={Month.options}
                    series={monthSeries}
                    type='bar'
                    height={300}
                    className='chart-card'
                  />
                ) : (
                  <h1>No Months expense to show</h1>
                )}
              </>
            )}
          </div>
        </div>
        <div className='right'>
          <div className='pie_top'>
            <h2> {!pieAvailable && <>No</>} Expense by pecentage</h2>
            <form onChange={handleSubmit4(onFetchPie)}>
              <select
                name='month'
                id=''
                className='spent-but'
                {...register4('month')}
              >
                <option value={defaultMonth.id}>{defaultMonth.Content}</option>
                {monthDataArray.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.Content}
                  </option>
                ))}
              </select>
            </form>
          </div>
          {pieAvailable && (
            <Chart
              options={Pie.options}
              series={Pie.series}
              type='donut'
              className='pie'
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default Expense
