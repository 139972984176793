import React from 'react'
import './InforGraphics.scss'
import { FaTimes } from 'react-icons/fa'
import ClickImg from '../../../../Image/click (3) 1.png'
import Card1 from '../../../../Image/credit-card (3) 1.png'
import Card2 from '../../../../Image/credit-card (3) 2.png'
import Give from '../../../../Image/financial-support 1.png'
import Like from '../../../../Image/like-button.png'
import Pay from '../../../../Image/payment-option 1.png'
import { useGlobalContext } from '../../../../Context/Context'

const InfoList = [
  {
    id: 1,
    image: ClickImg,
    details: 'Click on ‘Request Card’.',
  },
  {
    id: 2,
    image: Give,
    details: 'Fill in the amount of USD you intend to fund your card with.',
  },
  {
    id: 3,
    image: Pay,
    details:
      'Fill in the Card Name ( This is a name for you to easily uniquely identify this card ).',
  },
  {
    id: 4,
    image: Like,
    details:
      'Click on Confirm to see the total amount. ( Your Spendwise wallet should be funded with minimum of the total amount).',
  },
  {
    id: 5,
    image: Card2,
    details: 'Click on ‘Pay Now’ to complete your card request',
    extra:
      'PS:  If your wallet is not funded,click on ‘Go To Wallet’ to fund your wallet.',
  },
]
function InforGraphics() {
  const { toggleInfo } = useGlobalContext()
  return (
    <div className='info-main'>
      <div className='infor-top'>
        <div className='close'>
          <FaTimes onClick={toggleInfo} />
        </div>
        <h2>Steps On How To Request a Virtual USD Card</h2>
      </div>
      <div className='cards'>
        {InfoList.map((item) => (
          <div className='card' key={item.id}>
            <div className='num'>
              <p>{item.id}</p>
            </div>
            <div className='img'>
              <img src={item.image} alt='' />
            </div>
            <div className='text'>
              <p>{item.details}</p>
            </div>
            <div className='extra'>
              <p>{item.extra}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default InforGraphics
