import React, { useState } from 'react'
import { FaSearch } from 'react-icons/fa'
import { VscDiffAdded } from 'react-icons/vsc'
import { RiDeleteBin5Line } from 'react-icons/ri'
import {
  TbSquareRoundedChevronDownFilled,
  TbSquareRoundedChevronUpFilled,
} from 'react-icons/tb'
import { useNavigate } from 'react-router'
import Loader from '../../../../../Image/loder.gif.gif'

function UDashboard({ UserList, loading }) {
  const navigate = useNavigate()
  const [selectedUser, setSelectedUser] = useState([])
  const [clickedUser, setClickedUser] = useState('')
  return (
    <div className='user-management'>
      <div className='count'>
        <p>
          Total Users: <b>{loading ? '*' : UserList.count.toLocaleString()}</b>
        </p>
      </div>
      <div className='top'>
        <div className='left'>
          <div className='input'>
            <div className='search'>
              <FaSearch />
            </div>
            <input
              type='text'
              name=''
              id=''
              placeholder='Enter Username, Role or department'
            />
          </div>
        </div>
        <div className='right'>
          {selectedUser.length > 0 ? (
            <button className='delete'>
              <RiDeleteBin5Line />
              <p>Delete User(s)</p>
            </button>
          ) : (
            <button onClick={() => navigate('/user-management/create-user')}>
              <VscDiffAdded />
              <p>Add New User</p>
            </button>
          )}
        </div>
      </div>
      {loading ? (
        <div className='load'>
          <img src={Loader} alt='' />
        </div>
      ) : (
        <>
          {' '}
          <div className='table'>
            <div className='table-head'>
              <div className='row'>
                {/* <div className='cardT'></div> */}
                <div className='card'>
                  <h2>Username</h2>
                </div>
                <div className='card g'>
                  <h2>Email </h2>
                </div>
                <div className='card'>
                  <h2>Phone</h2>
                </div>
                <div className='card'>
                  <h2>Department</h2>
                </div>
                <div className='card'>
                  <h2>Role</h2>
                </div>
                <div className='card'>
                  <h2>Title</h2>
                </div>
              </div>
            </div>
            <div className='table-body'>
              {UserList.results.map((item) => (
                <div
                  className={
                    selectedUser.includes(item.id) ? 'row l checked' : 'row l'
                  }
                  key={item.id}
                >
                  {/* <div className='cardT'>
                    <input
                      type='checkbox'
                      id={`checkbox` + item.id}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedUser([...selectedUser, item.id])
                        } else {
                          setSelectedUser(
                            selectedUser.filter((id) => id !== item.id)
                          )
                        }
                      }}
                    />
                    <label for={`checkbox` + item.id}></label>
                  </div> */}
                  <div
                    className='card'
                    onClick={() =>
                      navigate(`/user-management/edit-user/${item.id}`)
                    }
                  >
                    <p>{item.username}</p>
                  </div>
                  <div className='card g'>
                    <p>{item.email} </p>
                  </div>
                  <div className='card'>
                    <p>{item.phone}</p>
                  </div>
                  <div className='card'>
                    <p>{item.department}</p>
                  </div>
                  <div className='card'>
                    <p>{item.roles}</p>
                  </div>
                  <div className='card'>
                    <p>{item.title}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className='mobile-table'>
            {UserList.results.map((item) => (
              <div className='card' key={item.id}>
                <div className='mob-head'>
                  <div className='left'>
                    <div className='name'>
                      <h2
                        onClick={() =>
                          navigate(`/user-management/edit-user/${item.id}`)
                        }
                      >
                        {item.username}
                      </h2>
                    </div>
                    {item.id !== clickedUser && (
                      <div className='shi'>
                        <p>{item.role}</p>
                      </div>
                    )}
                  </div>
                  <div className='right'>
                    {item.id === clickedUser ? (
                      <TbSquareRoundedChevronUpFilled
                        onClick={() => setClickedUser('')}
                      />
                    ) : (
                      <TbSquareRoundedChevronDownFilled
                        onClick={() => {
                          setClickedUser(item.id)
                          setSelectedUser([])
                        }}
                      />
                    )}
                  </div>
                </div>
                {item.id === clickedUser && (
                  <div className='mob-body'>
                    <div className='row'>
                      <div className='left'>
                        <h2>Email</h2>
                      </div>
                      <div className='right'>
                        <p>{item.email}</p>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='left'>
                        <h2>Phone</h2>
                      </div>
                      <div className='right'>
                        <p>{item.phone}</p>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='left'>
                        <h2>Department</h2>
                      </div>
                      <div className='right'>
                        <p>{item.department}</p>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='left'>
                        <h2>Role</h2>
                      </div>
                      <div className='right'>
                        <p>{item.roles}</p>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='left'>
                        <h2>Title</h2>
                      </div>
                      <div className='right'>
                        <p>{item.title}</p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  )
}

export default UDashboard
