import React, { useState } from 'react'
import { useGlobalContext } from '../../../../Context/Context'
import { useForm, Controller } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { AiOutlineClose } from 'react-icons/ai'
import CategoriesData from '../../../../Data/CategoriesData'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import 'react-datepicker/dist/react-datepicker.css'

const authToken = sessionStorage.getItem('userId')

function CreateBudget({ notify }) {
  const [formLoading, setFormLoading] = useState(false)
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm()
  const navigate = useNavigate()

  const onCreate = (formData) => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    setFormLoading(true)

    const parsedDate = moment(formData.date, 'DD.MM.YYYY H:mm:ss')
    const expenseDate = parsedDate.toISOString()
    fetch(`${process.env.REACT_APP_API_URL}/tx/create_tx/`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
      body: JSON.stringify({
        ...formData,
        date: expenseDate,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message === 'Your expense has been recorded!') {
          notify('success', data.message)
          setFormLoading(false)
          setTimeout(() => {
            window.location.reload()
          }, 2000)
        } else {
          notify('error', data.message)
          setFormLoading(false)
        }
      })
      .catch((err) => {})
  }

  const { toggleExpense } = useGlobalContext()
  return (
    <div className='createbudget'>
      <div className='close'>
        <AiOutlineClose onClick={toggleExpense} />
      </div>
      <div className='text'>
        <h2>Create Expense</h2>
        <p>Create Expense and set time frame</p>
      </div>
      <form onSubmit={handleSubmit(onCreate)}>
        <div className='card'>
          <p>Select a category</p>
          <select
            type='text'
            list='category'
            name='category'
            {...register('category', {
              required: 'Category is required',
            })}
          >
            {CategoriesData.map((item) => (
              <option value={item.Content} key={item.id}>
                {item.Content}
              </option>
            ))}
          </select>
          {errors.category && (
            <h6 className='vError'>{errors.category.message}</h6>
          )}
        </div>
        <div className='card'>
          <p>Amount</p>
          <input
            type='number'
            name='amount'
            id=''
            {...register('amount', {
              required: 'Amount is required',
            })}
          />
          {errors.amount && <h6 className='vError'>{errors.amount.message}</h6>}
        </div>
        <div className='card'>
          <p>Description</p>
          <input
            type='text'
            name='description'
            {...register('description', {
              required: 'Description is required',
            })}
          />
          {errors.description && (
            <h6 className='vError'>{errors.description.message}</h6>
          )}
        </div>
        <div className='card'>
          <p>Select date</p>
          <Controller
            control={control}
            name='date'
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <DatePicker
                onChange={onChange}
                onBlur={onBlur}
                selected={value}
                maxDate={new Date()}
                showTimeInput
                showMonthDropdown
                showYearDropdown
                dropdownMode='select'
              />
            )}
          />
        </div>
        <div className='button'>
          <button type='submit' className='create'>
            {formLoading ? '.....' : 'Create'}
          </button>
        </div>
      </form>
    </div>
  )
}

export default CreateBudget
