import React, { useState, useRef, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import './Auth.scss'
import Logo from '../../Image/logo1.png'
import Man from '../../Image/man.png'
import Icon1 from '../../Image/Group1.png'
import Icon2 from '../../Image/Group2.png'
import Icon3 from '../../Image/Group3.png'
import Angle from '../../Image/angle.png'

function Forgot({ notify }) {
  const [formLoading, setFormLoading] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm()
  const navigate = useNavigate()
  const password = useRef({})
  password.current = watch('password', '')

  const onForget = (formData) => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    setFormLoading(true)

    fetch(`https://www.spendwise.ng/api/accounts/token_request/`, {
      method: 'post',
      headers: { 'content-Type': 'application/json' },
      body: JSON.stringify({
        ...formData,
      }),
      redirect: 'follow',
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setFormLoading(false)
            setTimeout(() => {
              navigate(`/password-reset/${formData.user}`)
            }, 2000)
            notify('success', data.message)
          })
        } else if (res.status === 400) {
          res.json().then((data) => {
            notify('error', data.message)
            setFormLoading(false)
          })
        }
      })

      .catch((err) => {
        notify('error', 'An error occured. Unable to reach server')
        setFormLoading(false)
      })
  }
  useEffect(() => {
    document.title = 'Spendwise | Forget Password'
  }, [])
  return (
    <>
      <section className='auth'>
        <div className='logo'>
          <img src={Logo} alt='' onClick={() => navigate('/')} />
        </div>
        <div className='wrap'>
          <div className='left'>
            <div className='icons'>
              <div className='can'>
                <img src={Icon1} alt='' />
              </div>
              <div className='can edit'>
                <img src={Icon2} alt='' />
              </div>
              <div className='can'>
                <img src={Icon3} alt='' />
              </div>
            </div>
            <div className='man'>
              <img src={Man} alt='' />
            </div>
          </div>
          <div className='right'>
            <div className='angle'>
              <div>
                <img src={Angle} alt='' />
              </div>
            </div>
            <div className='form'>
              <div className='head'>
                <h1>Forgot Password?</h1>
                <p>
                  Enter your email address or username to get an Otp and a reset
                  URL via email.
                </p>
              </div>
              <form className='auth-form' onSubmit={handleSubmit(onForget)}>
                <div className='card'>
                  <p>Enter Email address or Username</p>
                  <input
                    type='text'
                    name='user'
                    {...register('user', {
                      required: 'Field is required',
                    })}
                  />
                  {errors.user && (
                    <h6 className='vError'>{errors.user.message}</h6>
                  )}
                </div>
                <div className='card'>
                  <button
                    type='submit'
                    value={formLoading ? '.....' : 'SUBMIT'}
                  >
                    {formLoading ? '.....' : 'Forget Password'}
                  </button>
                </div>
                <div className='card edit'>
                  <p>
                    Remember your password? <Link to='/login'>Login</Link>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Forgot
