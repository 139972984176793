import React, { useRef, useState, useEffect } from 'react'
import html2canvas from 'html2canvas'
import Logo from '../../../../../Image/cardlogo.png'
import { useNavigate, useParams } from 'react-router'
import Loader from '../../../../../Image/loder.gif.gif'

function Receipt({ walletTransactions }) {
  const { new_id } = useParams()
  const receiptRef = useRef(null)
  const navigate = useNavigate()
  const [recieptInfo, setRecieptInfo] = useState(null)

  const downloadReceipt = () => {
    html2canvas(receiptRef.current).then((canvas) => {
      const link = document.createElement('a')
      link.download = 'receipt.png'
      link.href = canvas.toDataURL()
      link.click()
    })
  }
  useEffect(() => {
    const foundTransact = walletTransactions.find(
      (item) => item.tx_id === new_id
    )
    setRecieptInfo(foundTransact)
  }, [walletTransactions])
  if (!recieptInfo) {
    return (
      <div className='vloader'>
        <img src={Loader} />
      </div>
    )
  }
  return (
    <div className='reciept-aiin'>
      <div className='wallet_top'>
        <h2 className='wallet-text'>E-Wallet</h2>
        <button onClick={() => navigate('/e-wallet/transaction-summary')}>
          Back
        </button>
      </div>
      <div className='receipt'>
        <div className=''>
          <div className='main-body' ref={receiptRef}>
            <div className='cover'>
              <div className='head'>
                <img src={Logo} alt='' />
                <h2>TRANSACTION RECEIPT</h2>
              </div>
              <div className='rest'>
                <div className='card'>
                  <p>AMOUNT:</p>
                  <div className='input'>
                    <h2>₦ {recieptInfo.amount.toLocaleString()}</h2>
                  </div>
                </div>
                <div className='cards'>
                  <p>Mode:</p>
                  <div className='input'>
                    <h3 className={recieptInfo.mode}>Debit</h3>
                  </div>
                </div>
                <div className='cards'>
                  <p>Date:</p>
                  <div className='input'>
                    <h3>{new Date(recieptInfo.tx_date).toLocaleString()}</h3>
                  </div>
                </div>
                <div className='cards'>
                  <p>Reference:</p>
                  <div className='input'>
                    <h3>{recieptInfo.ref}</h3>
                  </div>
                </div>
                <div className='cards'>
                  <p>Naration:</p>
                  <div className='input'>
                    <h4>{recieptInfo.narration}</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='button'>
            <button onClick={downloadReceipt}>Download Receipt</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Receipt
