import React from 'react'
import { FaChevronLeft } from 'react-icons/fa'

function RestMagicPin({ navigate }) {
  return (
    <div className='bvn'>
      <div className='bvn-top'>
        <h2 className='mai'> Security</h2>
        <h2
          onClick={() => {
            navigate('/settings/security')
          }}
          className='back'
        >
          <FaChevronLeft /> Back
        </h2>
      </div>
      <div className='not_available'>
        <h2>Reset
          Magic Pin</h2>
        <p>No quesations set by user.</p>
      </div>
    </div>
  )
}

export default RestMagicPin
