import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Time from '../../../../../Image/time.png'
import Video from '../../../../../Image/video.png'
import API from '../../../../../Context/API'
import { useGlobalContext } from '../../../../../Context/Context'
import CardBox from './CardBox'
import CardBoxSudo from './CardBox_Sudo'

function Dashboard({ cards, notify, sudoCardsList }) {
  const authToken = sessionStorage.getItem('userId')
  const { toggleCardHolder } = useGlobalContext()
  const navigate = useNavigate()
  const CallCardHolder = () => {
    API.getCardHolder()
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            if (data.data.length >= 1) {
              navigate('/debit-card/request')
            } else {
              toggleCardHolder()
            }
          })
        } else {
          res.json().then((data) => {})
        }
      })
      .catch(() => {})
  }

  useEffect(() => {
    document.title = 'Spendwise | Virtual Card'
  }, [])

  return (
    <div className='virtual_cards-wrap'>
      <div className='request-head'>
        <div className='request-wrap'>
          <div
            className='card-box'
            onClick={() => {
              CallCardHolder()
            }}
          >
            <div className='card-box_top'>
              <h2>request for card</h2>
            </div>
            <div
              className='credit-card-body'
              id='blank'
              onClick={() => {
                CallCardHolder()
              }}
            >
              {/* <Link to='/debit-card/request'>
            <div className='click'></div>
          </Link> */}
              <div className='click'></div>
            </div>
          </div>
          <div className='infos'>
            <h2>How to Request a card</h2>
            <div className='infox'>
              <div className='infor'>
                <img src={Time} alt='' />
                <p>
                  View our infographic to learn how to request virtual cards
                </p>
              </div>
              <div className='infor'>
                <img src={Video} alt='' />
                <p>Watch our video to learn how to request virtual cards</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='cover-wrapper'>
        {cards.map((item) => (
          <CardBox
            className='dit'
            item={item}
            key={item.id}
            navigate={navigate}
          />
        ))}
        {sudoCardsList.map((item) => (
          <CardBoxSudo
            className='dit'
            item={item}
            key={item.id}
            navigate={navigate}
          />
        ))}
      </div>
    </div>
  )
}

export default Dashboard
