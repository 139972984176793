import React, { useState, useEffect } from 'react'
import CardBox from './CardBox'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { BsGridFill, BsListTask } from 'react-icons/bs'
import ListBox from './ListBox'
import Loader from '../../../../../Image/loder.gif.gif'
import API from '../../../../../Context/API'
import { useGlobalContext } from '../../../../../Context/Context'

const sudoCardsList = [
  {
    id: '123',
    alias: 'Tester',
    type_of: 'physical',
    currency: 'NGN',
    balance: '9000',
    pin: '1234567891234567',
    name: 'Favourene Osas',
    exp_month: '02',
    exp_year: '22',
    cvv: '123',
  },
  {
    id: '13',
    alias: 'Tester#2',
    type_of: 'physical',
    currency: 'NGN',
    balance: '100000',
    pin: '1234567891234567',
    name: 'Baby Osas',
    exp_month: '02',
    exp_year: '22',
    cvv: '123',
  },
  {
    id: '14',
    alias: 'Tester#2',
    type_of: 'physical',
    currency: 'NGN',
    balance: '13000',
    pin: '0000000000000000',
    name: 'Favourene Osas',
    exp_month: '02',
    exp_year: '22',
    cvv: '123',
  },
  {
    id: '15',
    alias: 'Tester#2',
    type_of: 'physical',
    currency: 'NGN',
    balance: '59000',
    pin: '1234567891234567',
    name: 'Hot Boy',
    exp_month: '02',
    exp_year: '22',
    cvv: '123',
  },
  {
    id: '16',
    alias: 'Tester#2',
    type_of: 'physical',
    currency: 'NGN',
    balance: '300000',
    pin: '1234567891234567',
    name: 'Favxurene Osas',
    exp_month: '02',
    exp_year: '22',
    cvv: '123',
  },
]
function Dashboard({ notify, sudoCardsList, sudoDetails }) {
  const authToken = sessionStorage.getItem('userId')
  const navigate = useNavigate()
  const [displayType, setDisplayType] = useState('grid')
  const [cardList, setCardList] = useState('')
  const [ListCard, setListCard] = useState(sudoCardsList)
  const [findCard, setFindCard] = useState('')
  const [BulkAdding, setBulkAdding] = useState([])
  const [bulkAmount, setBulkAmount] = useState(0)
  const [bulkLoading, setBulkLoading] = useState(false)
  const { toggleCardHolder, refreshNotification, user } = useGlobalContext()

  const CallCardHolder = () => {
    API.getCardHolder()
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            if (data.data.length >= 1) {
              API.FetchSudoAccount()
                .then((res) => {
                  if (res.status === 200) {
                    navigate('/physical-card/request')
                  } else if (res.status === 400) {
                    res.json().then((data) => {
                      API.CreateSudoAccount().then((res) => {
                        if (res.status === 200) {
                          res.json().then((data) => {
                            notify('success', 'wallet creation in progress;')
                            navigate('/physical-card/dashboard')
                            refreshNotification()
                          })
                        } else {
                          res.json().then((data) => {
                            notify('warn', data.message)
                          })
                        }
                      })
                    })
                  } else {
                  }
                })
                .catch(() => {})
            } else {
              toggleCardHolder()
            }
          })
        } else {
          res.json().then((data) => {})
        }
      })
      .catch(() => {})
  }
  const finddCard = (found) => {
    setFindCard(found)
    if (findCard !== '') {
      const filteredCard = sudoCardsList.filter((item) => {
        return Object.values(item)
          .join('')
          .toLowerCase()
          .includes(findCard.toLowerCase())
      })
      setListCard(filteredCard)
    } else {
      setListCard(sudoCardsList)
    }
  }
  useEffect(() => {
    document.title = 'Spendwise | Physical Cards'
  }, [])
  function deleteBulk(id) {
    setBulkAdding((bulk) => {
      return bulk.filter((item) => item.card_id !== id)
    })
  }
  const bulkFund = () => {
    setBulkLoading(true)
    fetch(
      `https://p499kkwmp9.execute-api.eu-west-1.amazonaws.com/prod/wallet/fund_card_account`,
      {
        method: 'post',
        headers: {
          'content-Type': 'application/json',
          Authorization: `Token ${authToken}`,
        },
        body: JSON.stringify({
          data: BulkAdding,
          loader_wallet_id: sudoDetails.account_id,
          pin: atob(
            sessionStorage.getItem('7e6d4defcc85a76e2cd2c156ef64a128e5')
          ),
        }),
      }
    )
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setBulkLoading(false)
            notify('success', data.message)
          })
        } else {
          res.json().then((data) => {
            notify('error', data.message)
            setBulkLoading(false)
          })
        }
      })
      .catch(() => {
        notify('error', 'Unable to connect to server')
        setBulkLoading(false)
      })
  }
  useEffect(() => {
    if (user.is_business) {
      if (sessionStorage.getItem('adminIn') === null) {
        navigate('/physical-card/secure')
        sessionStorage.removeItem('7e6d4defcc85a76e2cd2c156ef64a128e5')
        notify('warn', 'Access Denied')
      }
    }
  }, [sudoDetails])

  return (
    <div className='physical_cards-wrap'>
      <div className='physical_topp'>
        <button onClick={() => navigate('/physical-card/requested-cards')}>
          Requested Cards
        </button>
      </div>
      <div className='phy-card_top'>
        <input
          type='search'
          name=''
          id=''
          placeholder='Search for card by Name or number'
          onChange={(e) => finddCard(e.target.value)}
        />

        <select name='' id='' onChange={(e) => setCardList(e.target.value)}>
          <option value=''>Sort By</option>
          <option value='highest'>Highest balance</option>
          <option value='lowest'>Lowest balance</option>
        </select>
        <div>
          {displayType === 'grid' ? (
            <BsListTask onClick={() => setDisplayType('list')} />
          ) : (
            <BsGridFill onClick={() => setDisplayType('grid')} />
          )}
        </div>
      </div>
      {displayType === 'grid' && (
        <>
          {cardList === 'highest' ? (
            <div className='card-cover'>
              <div className='card-box'>
                <div className='card-box_top'>
                  <h2>request for card</h2>
                </div>
                <div
                  className='credit-card-body'
                  id='blank'
                  onClick={() => {
                    CallCardHolder()
                  }}
                >
                  <div className='click'></div>
                  <div className='click'></div>
                </div>
              </div>
              {findCard.length > 1 ? (
                <>
                  {ListCard.sort((a, b) => b.balance - a.balance).map(
                    (item) => (
                      <CardBox item={item} key={item.id} navigate={navigate} />
                    )
                  )}
                </>
              ) : (
                <>
                  {sudoCardsList
                    .sort((a, b) => b.balance - a.balance)
                    .map((item) => (
                      <CardBox item={item} key={item.id} navigate={navigate} />
                    ))}
                </>
              )}
            </div>
          ) : (
            <div className='card-cover'>
              <div className='card-box'>
                <div className='card-box_top'>
                  <h2>request for card</h2>
                </div>
                <div
                  className='credit-card-body'
                  id='blank'
                  onClick={() => {
                    CallCardHolder()
                  }}
                >
                  <div className='click'></div>
                  <div className='click'></div>
                </div>
              </div>
              {findCard.length > 1 ? (
                <>
                  {ListCard.sort((a, b) => a.balance - b.balance).map(
                    (item) => (
                      <CardBox item={item} key={item.id} navigate={navigate} />
                    )
                  )}
                </>
              ) : (
                <>
                  {sudoCardsList
                    .sort((a, b) => a.balance - b.balance)
                    .map((item) => (
                      <CardBox item={item} key={item.id} navigate={navigate} />
                    ))}
                </>
              )}
            </div>
          )}
        </>
      )}
      {displayType === 'list' && (
        <div className='table-boss'>
          <div className='fund-top'>
            <input
              type='number'
              name=''
              min={1}
              id=''
              onChange={(e) => setBulkAmount(e.target.value)}
              disabled={BulkAdding.length > 0 ? true : false}
              placeholder='Amount to fund cards'
            />
            <button
              onClick={() => {
                if (BulkAdding.length === 0) {
                  notify('error', 'Please select cards')
                } else {
                  bulkFund()
                }
              }}
            >
              {bulkLoading ? (
                <img
                  style={{ width: '50px', height: '10px', objectFit: 'cover' }}
                  src={Loader}
                />
              ) : (
                'Bulk Fund Card'
              )}
            </button>
          </div>
          {cardList === 'highest' ? (
            <div className='table-cover'>
              <table>
                <thead>
                  <tr>
                    <th>Name on card</th>
                    <th>Card number</th>
                    <th>Expiry date</th>
                    <th>Card balance</th>
                    <th>Bulk Funding</th>
                  </tr>
                </thead>
                <tbody>
                  {findCard.length > 1 ? (
                    <>
                      {ListCard.sort((a, b) => b.balance - a.balance).map(
                        (item) => (
                          <ListBox
                            item={item}
                            key={item.id}
                            setBulkAdding={setBulkAdding}
                            BulkAdding={BulkAdding}
                            deleteBulk={deleteBulk}
                            bulkAmount={bulkAmount}
                            notify={notify}
                          />
                        )
                      )}
                    </>
                  ) : (
                    <>
                      {sudoCardsList
                        .sort((a, b) => b.balance - a.balance)
                        .map((item) => (
                          <ListBox
                            item={item}
                            key={item.id}
                            setBulkAdding={setBulkAdding}
                            BulkAdding={BulkAdding}
                            deleteBulk={deleteBulk}
                            bulkAmount={bulkAmount}
                            notify={notify}
                          />
                        ))}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          ) : (
            <div className='table-cover'>
              <table>
                <thead>
                  <tr>
                    <th>Name on card</th>
                    <th>Card number</th>
                    <th>Expiry date</th>
                    <th>Card balance</th>
                    <th>Bulk Funding</th>
                  </tr>
                </thead>
                <tbody>
                  {findCard.length > 1 ? (
                    <>
                      {ListCard.sort((a, b) => a.balance - b.balance).map(
                        (item) => (
                          <ListBox
                            item={item}
                            key={item.id}
                            setBulkAdding={setBulkAdding}
                            BulkAdding={BulkAdding}
                            deleteBulk={deleteBulk}
                            bulkAmount={bulkAmount}
                            notify={notify}
                          />
                        )
                      )}
                    </>
                  ) : (
                    <>
                      {sudoCardsList
                        .sort((a, b) => a.balance - b.balance)
                        .map((item) => (
                          <ListBox
                            item={item}
                            key={item.id}
                            setBulkAdding={setBulkAdding}
                            BulkAdding={BulkAdding}
                            deleteBulk={deleteBulk}
                            bulkAmount={bulkAmount}
                            notify={notify}
                          />
                        ))}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default Dashboard
