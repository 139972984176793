import React from 'react'
import './NoPage.scss'
import Image from '../../../../Image/NoDisplay.png'
import { useNavigate } from 'react-router'

function NoPage() {
  const navigate = useNavigate()
  return (
    <div className='nopage'>
      <div className='cover'>
        <div className='img'>
          <img src={Image} alt='' />
        </div>
        <div className='text'>
          <h1>No Page to Display</h1>
          <button onClick={() => navigate('/home')}>Return to Dashboard</button>
        </div>
      </div>
    </div>
  )
}

export default NoPage
