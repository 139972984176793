import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import Loader from '../../../../../Image/loder.gif.gif'
import { BsFillDashCircleFill } from 'react-icons/bs'

function Referer({ user, API, notify }) {
  const authToken = sessionStorage.getItem('userId')
  const [refPoints, setRefPoints] = useState({})
  const [redeemLoading, setRedeemLoading] = useState(false)
  const [referLoading, setReferLoading] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const [formFields, setFormFields] = useState([{ phone: '', email: '' }])
  const handleFormChange = (event, index) => {
    let data = [...formFields]
    data[index][event.target.name] = event.target.value
    setFormFields(data)
  }
  const addFields = () => {
    let object = {
      phone: '',
      email: '',
    }

    setFormFields([...formFields, object])
  }
  const removeFields = (index) => {
    let data = [...formFields]
    data.splice(index, 1)
    setFormFields(data)
  }
  const submit = (e) => {
    e.preventDefault()
    setReferLoading(true)
    fetch(`${process.env.REACT_APP_API_URL}/accounts/refer_a_friend/`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
      body: JSON.stringify(formFields),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            notify('success', data.message)
            setReferLoading(false)
            window.location.reload()
          })
        } else {
          res.json().then((data) => {
            setReferLoading(false)
            notify('error', data.message)
          })
        }
      })
      .catch(() => {
        notify('error', 'An error occured please try again.')
      })
  }

  useEffect(() => {
    API.FetchReferers()
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setRefPoints(data.message)
          })
        } else {
          res.json().then((data) => {
            notify('error', data.message)
          })
        }
      })
      .catch(() => {})
  }, [])

  const redeemPoints = (points) => {
    setRedeemLoading(true)
    if (
      parseInt(points.points) > refPoints.amount_unredeemed ||
      parseInt(points.points) < 500
    ) {
      notify('error', `Points not allowed, confirm the points amount.`)
      setRedeemLoading(false)
    } else {
      fetch(`${process.env.REACT_APP_API_URL}/accounts/cashout/`, {
        method: 'post',
        headers: {
          'content-Type': 'application/json',
          Authorization: `Token ${authToken}`,
        },
        body: JSON.stringify({
          points: parseInt(points),
        }),
      })
        .then((res) => {
          if (res.status === 200) {
            res.json().then((data) => {
              notify('success', data.message)
              setRedeemLoading(false)
            })
          } else {
            res.json().then((data) => {
              setRedeemLoading(false)
              notify('error', data.message)
            })
          }
        })
        .catch(() => {
          notify('error', 'An error occured please try again.')
        })
    }
  }

  return (
    <div className='refer'>
      <div className='refer_top'>
        <h2>Refer a Friend</h2>
        <table>
          <tbody>
            <tr>
              <td className='tag'>Referral Code:</td>
              <td className='result'>{user.referral_code}</td>
            </tr>
            <tr>
              <td className='tag'> Total Referrals:</td>
              <td className='result'>{refPoints.total_referals}</td>
            </tr>
            <tr>
              <td className='tag'> Total Points:</td>
              <td className='result'>{refPoints.total_points}</td>
            </tr>
            <tr>
              <td className='tag'> Total Amount Earned:</td>
              <td className='result'>₦{refPoints.total_amount_earned}</td>
            </tr>
            <tr>
              <td className='tag'> Total Amount Unredeemed:</td>
              <td className='result'>₦{refPoints.amount_unredeemed}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <form onSubmit={submit}>
        {formFields.map((form, index) => {
          return (
            <div className='wrapper'>
              <div className='card'>
                <p>Email</p>
                <input
                  type='email'
                  name='email'
                  id=''
                  onChange={(event) => handleFormChange(event, index)}
                  defaultValue={form.email}
                />
              </div>
              <div className='card'>
                <p>
                  Phone Number{' '}
                  <span style={{ color: '#d00000', fontSize: '20px' }}>*</span>
                </p>
                <input
                  type='tel'
                  name='phone'
                  id=''
                  onChange={(event) => handleFormChange(event, index)}
                  defaultValue={form.phone}
                />
              </div>
              <div className='icon'>
                <BsFillDashCircleFill onClick={() => removeFields(index)} />
              </div>
            </div>
          )
        })}
      </form>
      <div className='new-wrapper'>
        <div className='adder'>
          <button onClick={addFields}>Add More</button>
        </div>

        <div className='button'>
          {referLoading ? (
            <img src={Loader} alt='' />
          ) : (
            <button onClick={submit}>Submit</button>
          )}
        </div>
      </div>
      <div className='refer_top' style={{ marginTop: '40px' }}>
        <h2>Redeem Points</h2>
        <p>Note that minimum point to be redeemed is 500</p>
      </div>
      <form action='' onSubmit={handleSubmit(redeemPoints)}>
        <div className='wrapper'>
          <div className='card'>
            <p>Points</p>
            <input
              type='number'
              name='points'
              id=''
              {...register('points')}
              required
            />
          </div>
          <div className='button'>
            {redeemLoading ? (
              <img src={Loader} alt='' />
            ) : (
              <button>Redeem</button>
            )}
          </div>
        </div>
      </form>
    </div>
  )
}

export default Referer
