const authToken = sessionStorage.getItem('userId')

class APIServices {
  async fetchExpenseRequest(page) {
    return await fetch(
      `${process.env.REACT_APP_API_URL}/expense/get_expense/?page=${page}`,
      {
        headers: {
          Authorization: `Token ${authToken}`,
        },
      }
    )
  }
  async fetchFXBeneficiaries() {
    return await fetch(
      `https://kxu81bfj78.execute-api.eu-west-1.amazonaws.com/prod/fx/beneficiaries`,
      {
        headers: {
          Authorization: `Token ${authToken}`,
        },
      }
    )
  }
  async fetchFXTransfers() {
    return await fetch(
      `https://kxu81bfj78.execute-api.eu-west-1.amazonaws.com/prod/fx/transfers`,
      {
        headers: {
          Authorization: `Token ${authToken}`,
        },
      }
    )
  }
  async fetchUser() {
    return await fetch(
      `${process.env.REACT_APP_API_URL}/accounts/get_profile/`,
      {
        headers: {
          Authorization: `Token ${authToken}`,
        },
      }
    )
  }
  async fetchBusinessDet() {
    return await fetch(
      `${process.env.REACT_APP_API_URL}/accounts/business_details/`,
      {
        headers: {
          Authorization: `Token ${authToken}`,
        },
      }
    )
  }
  async fetchUserMana() {
    return await fetch(
      `${process.env.REACT_APP_API_URL}/accounts/business_user/`,
      {
        headers: {
          Authorization: `Token ${authToken}`,
        },
      }
    )
  }
  async fetchDepartments() {
    return await fetch(
      `${process.env.REACT_APP_API_URL}/accounts/business_department/`,
      {
        headers: {
          Authorization: `Token ${authToken}`,
        },
      }
    )
  }
  async expiredauthCode() {
    return await fetch(`${process.env.REACT_APP_API_URL}/tx/data_sync/`, {
      headers: {
        Authorization: `Token ${authToken}`,
      },
    })
  }
  async budgetStatus() {
    return await fetch(
      `${process.env.REACT_APP_API_URL}/budget/all_budgets_status/`,
      {
        method: 'post',
        headers: {
          Authorization: `Token ${authToken}`,
        },
      }
    )
  }
  async userWeekData() {
    return await fetch(
      `${process.env.REACT_APP_API_URL}/tx/tx_analytics_week/`,
      {
        method: 'post',
        headers: {
          Authorization: `Token ${authToken}`,
        },
      }
    )
  }
  async userMonthData() {
    return await fetch(
      `${process.env.REACT_APP_API_URL}/tx/tx_analytics_month/`,
      {
        method: 'post',
        headers: {
          Authorization: `Token ${authToken}`,
        },
      }
    )
  }
  async userDayData() {
    return await fetch(
      `${process.env.REACT_APP_API_URL}/tx/tx_analytics_day/`,
      {
        method: 'post',
        headers: {
          Authorization: `Token ${authToken}`,
        },
      }
    )
  }
  async fetchBank() {
    return await fetch(`${process.env.REACT_APP_API_URL}/accounts/banks`, {
      headers: {
        Authorization: `Token ${authToken}`,
      },
    })
  }
  async fetchBudget() {
    return await fetch(`${process.env.REACT_APP_API_URL}/budget/all_budgets/`, {
      headers: {
        Authorization: `Token ${authToken}`,
      },
      method: 'post',
    })
  }
  async fetchCategoryTotal() {
    return await fetch(`${process.env.REACT_APP_API_URL}/tx/category_totals/`, {
      headers: {
        Authorization: `Token ${authToken}`,
      },
      method: 'post',
    })
  }
  async createBudget(data) {
    return await fetch(`${process.env.REACT_APP_API_URL}/budget/create/`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
      body: JSON.stringify(data),
    })
  }
  async recentexpense(data) {
    return await fetch(`${process.env.REACT_APP_API_URL}/tx/all_tx/`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
      body: JSON.stringify(data),
    })
  }
  async recentbudgetexpense(data) {
    return await fetch(
      `${process.env.REACT_APP_API_URL}/budget/budget_expense_analytics/`,
      {
        method: 'post',
        headers: {
          'content-Type': 'application/json',
          Authorization: `Token ${authToken}`,
        },
        body: JSON.stringify(data),
      }
    )
  }
  async bankDetails(data) {
    return await fetch(`${process.env.REACT_APP_API_URL}/tx/bank_summary/`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
      body: JSON.stringify(data),
    })
  }
  async blueCard(data) {
    return await fetch(`${process.env.REACT_APP_API_URL}/tx/tx_summary/`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
      body: JSON.stringify(data),
    })
  }
  async getNotification() {
    return await fetch(
      `${process.env.REACT_APP_API_URL}/accounts/notifications/`,
      {
        headers: {
          'content-Type': 'application/json',
          Authorization: `Token ${authToken}`,
        },
      }
    )
  }
  async getCardHolder() {
    return await fetch(`${process.env.REACT_APP_API_URL}/cards/card_holder/`, {
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
    })
  }
  async getDollarRate() {
    return await fetch(
      `${process.env.REACT_APP_API_URL}/cards/usd_exchange_rate/`,
      {
        headers: {
          'content-Type': 'application/json',
          Authorization: `Token ${authToken}`,
        },
      }
    )
  }
  async getvirtualCard() {
    return await fetch(`${process.env.REACT_APP_API_URL}/cards/card/`, {
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
    })
  }
  async checkWallet() {
    return await fetch(
      `${process.env.REACT_APP_API_URL}/purse/main_wallet_details/`,
      {
        headers: {
          'content-Type': 'application/json',
          Authorization: `Token ${authToken}`,
        },
      }
    )
  }
  async checkWalletTransactions() {
    return await fetch(`${process.env.REACT_APP_API_URL}/purse/wallet_trail/`, {
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
    })
  }
  async FetchReferers() {
    return await fetch(
      `${process.env.REACT_APP_API_URL}/accounts/referal_points/`,
      {
        headers: {
          'content-Type': 'application/json',
          Authorization: `Token ${authToken}`,
        },
      }
    )
  }
  async FetchSudoAccount() {
    return await fetch(
      `https://p499kkwmp9.execute-api.eu-west-1.amazonaws.com/prod/wallet/wallet`,
      {
        method: 'post',
        headers: {
          'content-Type': 'application/json',
          Authorization: `Token ${authToken}`,
        },
      }
    )
  }
  async CreateSudoAccount() {
    return await fetch(
      `https://p499kkwmp9.execute-api.eu-west-1.amazonaws.com/prod/wallet/create_wallet`,
      {
        headers: {
          'content-Type': 'application/json',
          Authorization: `Token ${authToken}`,
        },
      }
    )
  }
  async FetchSavedSettings() {
    return await fetch(
      `https://y18x0oais3.execute-api.eu-west-1.amazonaws.com/prod/card/saved_settings`,
      {
        headers: {
          'content-Type': 'application/json',
          Authorization: `Token ${authToken}`,
        },
      }
    )
  }
  async FetchSudoCards() {
    return await fetch(
      `https://y18x0oais3.execute-api.eu-west-1.amazonaws.com/prod/card/card_details`,
      {
        headers: {
          'content-Type': 'application/json',
          Authorization: `Token ${authToken}`,
        },
        method: 'post',
      }
    )
  }
  async FetchCallBack() {
    return await fetch(
      `${process.env.REACT_APP_API_URL}/accounts/callback_url/`,
      {
        headers: {
          'content-Type': 'application/json',
          Authorization: `Token ${authToken}`,
        },
        method: 'get',
      }
    )
  }
}

const instance = new APIServices()

export default instance
