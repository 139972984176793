import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Logo from '../../Image/logo.png'
import { useNavigate } from 'react-router-dom'
import './Navigation.scss'

function Navigation() {
  const [isActive, setActive] = useState('false')
  const handleToggle = () => {
    setActive(!isActive)
  }
  const navigate = useNavigate()
  const location = useLocation()
  return (
    <nav className='landing-navigation'>
      <div className='logo' onClick={() => navigate('/')}>
        <img className='logo1' src={Logo} alt='Logo' />
      </div>
      <div
        onClick={handleToggle}
        className={isActive ? 'hamburger' : 'hamburger toggle'}
      >
        <div className='line1'></div>
        <div className='line2'></div>
        <div className='line3'></div>
      </div>
      <ul className={isActive ? 'nav-links mods' : 'nav-links mods open'}>
        <div className='listing'>
          {/* <li onClick={handleToggle} className={isActive ? '' : 'fade'}>
            <Link
              className={location.pathname === '/faq' ? 'link active' : 'link'}
              to='/faq'
            >
              FAQ
            </Link>
          </li> */}
          <li onClick={handleToggle} className={isActive ? '' : 'fade'}>
            <Link className='link' to='/'>
              {/* Solutions */}
            </Link>
          </li>
          <li onClick={handleToggle} className={isActive ? '' : 'fade'}>
            <Link className='link' to='/'>
              {/* Products */}
            </Link>
          </li>
          <li onClick={handleToggle} className={isActive ? '' : 'fade'}>
            <Link className='link' to='/'>
              {/* Resources */}
            </Link>
          </li>
        </div>
        <div className='buttons'>
          <li onClick={handleToggle} className={isActive ? '' : 'fade'}>
            <Link
              className={location.pathname === '/faq' ? 'link active' : 'link'}
              to='/faq'
            >
              FAQs
            </Link>
          </li>
          <Link to='/login'>
            <button>Log-in</button>
          </Link>
          <Link to='/register'>
            <button className='right-btn'>Sign-Up</button>
          </Link>
        </div>
      </ul>
      <ul className={isActive ? 'nav-links mod' : 'nav-links mod open'}>
        <li onClick={handleToggle} className={isActive ? '' : 'fade'}>
          <Link className='link' to='/faq'>
            FAQs
          </Link>
        </li>
        <li onClick={handleToggle} className={isActive ? '' : 'fade'}>
          <Link className='link' to='/'>
            {/* Solutions */}
          </Link>
        </li>
        <li onClick={handleToggle} className={isActive ? '' : 'fade'}>
          <Link className='link' to='/'>
            {/* Products */}
          </Link>
        </li>
        <li onClick={handleToggle} className={isActive ? '' : 'fade'}>
          <Link className='link' to='/'>
            {/* Resources */}
          </Link>
        </li>
        <li onClick={handleToggle} className={isActive ? '' : 'fade'}>
          {' '}
          <Link to='/login'>
            <button>Log-in</button>
          </Link>
        </li>
        <li onClick={handleToggle} className={isActive ? '' : 'fade'}>
          {' '}
          <Link to='/register'>
            <button className='right-btn'>Sign-Up</button>
          </Link>
        </li>
      </ul>
    </nav>
  )
}

export default Navigation
