import React, { useState } from 'react'
import { useGlobalContext } from '../../../../Context/Context'
import { useForm, Controller } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { AiOutlineClose } from 'react-icons/ai'
import API from '../../../../Context/API'
import { BsFillImageFill } from 'react-icons/bs'
import StateInfo from '../../../../Data/StateData'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Loader from '../../../../Image/loder.gif.gif'

const authToken = sessionStorage.getItem('userId')
function CreateCHolder({ notify, page }) {
  const { toggleCardHolder, user } = useGlobalContext()
  const [formLoading, setFormLoading] = useState(false)
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm()
  const [complainPicture, setComplainPicture] = useState({
    string: 'Upload an image',
    file: null,
  })
  const onSelectComplainImage = (e) => {
    const file = e.target.files[0]
    const reader = new FileReader()
    reader.readAsDataURL(file)
    if (file.size > 1000000) {
      notify('error', 'Image must be below 1mb')
    } else {
      reader.onloadend = () => {
        setComplainPicture({
          string: file.name,
          file,
        })
      }
    }
  }
  const navigate = useNavigate()
  //New adjustments (08-Feb-2023)
  const [selectedState, setSelectedState] = useState('')
  const [selectedLga, setSelectedLga] = useState('')
  const [lgaList, setLgaList] = useState([])
  const fetchLga = (value) => {
    const findLga = StateInfo.find((item) => item.state.name === value)
    setLgaList(findLga.state.locals)
  }
  // const onCreate = (formData) => {
  //   window.scrollTo({ top: 0, behavior: 'smooth' })
  //   setFormLoading(true)
  //   const form_data = new FormData()
  //   form_data.append('image', complainPicture.file)
  //   form_data.append('id_type', formData.identity_type)
  //   form_data.append('id_number', formData.identity_number)
  //   if (user.id_card_uploaded) {
  //     fetch(`${process.env.REACT_APP_API_URL}/cards/card_holder/`, {
  //       method: 'post',
  //       headers: {
  //         'content-Type': 'application/json',
  //         Authorization: `Token ${authToken}`,
  //       },
  //       body: JSON.stringify({
  //         ...formData,
  //         entity: 'INDIVIDUAL',
  //         identity_image: user.identity_image,
  //       }),
  //     })
  //       .then((res) => {
  //         if (res.status === 201) {
  //           res.json().then((data) => {
  //             notify('success', data.message)
  //             setFormLoading(false)
  //             API.CreateSudoAccount().then((res) => {
  //               if (res.status === 200) {
  //                 res.json().then((data) => {
  //                   notify(
  //                     'success',
  //                     'wallet creation in progress, await confirmation email. Do not retry!!!'
  //                   )
  //                   setTimeout(() => {
  //                     window.location.reload()
  //                   }, 3000)
  //                 })
  //               } else {
  //                 res.json().then((data) => {
  //                   notify('warn', data.message)
  //                 })
  //               }
  //             })
  //           })
  //         } else {
  //           setFormLoading(false)
  //           res.json().then((data) => {
  //             notify('error', data.message)
  //             setTimeout(() => {
  //               navigate('/settings/profile')
  //               toggleCardHolder()
  //             }, 3000)
  //             notify('error', data.message.mono.details)
  //             if (
  //               data.message.mono.details.includes('bvn') ||
  //               data.message.mono.details.includes('BVN')
  //             ) {
  //               setTimeout(() => {
  //                 navigate('/settings/security_bvn')
  //                 toggleCardHolder()
  //               }, 4000)
  //             } else if (
  //               data.message.mono.details.includes('nin') ||
  //               data.message.mono.details.includes('NIN')
  //             ) {
  //               navigate(-1)
  //               toggleCardHolder()
  //             }
  //           })
  //         }
  //       })
  //       .catch((err) => {
  //         notify('error', 'Something went wrong please try again')
  //       })
  //   } else {
  //     fetch('https://www.spendwise.ng/api/accounts/upload_id/', {
  //       method: 'post',
  //       body: form_data,
  //       headers: {
  //         Authorization: `Token ${authToken}`,
  //       },
  //     })
  //       .then((res) => {
  //         if (res.status === 200) {
  //           API.fetchUser()
  //             .then((res) => {
  //               if (res.status === 200) {
  //                 res.json().then((userFetch) => {
  //                   fetch(
  //                     `${process.env.REACT_APP_API_URL}/cards/card_holder/`,
  //                     {
  //                       method: 'post',
  //                       headers: {
  //                         'content-Type': 'application/json',
  //                         Authorization: `Token ${authToken}`,
  //                       },
  //                       body: JSON.stringify({
  //                         ...formData,
  //                         entity: 'INDIVIDUAL',
  //                         identity_url: userFetch.identity_image,
  //                       }),
  //                     }
  //                   )
  //                     .then((res) => {
  //                       if (res.status === 201) {
  //                         res.json().then((data) => {
  //                           notify('success', data.message)
  //                           setFormLoading(false)
  //                           API.CreateSudoAccount().then((res) => {
  //                             if (res.status === 200) {
  //                               res.json().then((data) => {
  //                                 notify(
  //                                   'success',
  //                                   'wallet creation in progress, await confirmation email. Do not retry!!!'
  //                                 )
  //                                 setTimeout(() => {
  //                                   window.location.reload()
  //                                 }, 3000)
  //                               })
  //                             } else {
  //                               res.json().then((data) => {
  //                                 notify('warn', data.message)
  //                               })
  //                             }
  //                           })
  //                         })
  //                       } else {
  //                         setFormLoading(false)
  //                         res.json().then((data) => {
  //                           notify('error', data.message)
  //                           setTimeout(() => {
  //                             navigate('/settings/profile')
  //                             toggleCardHolder()
  //                           }, 3000)
  //                           notify('error', data.message.mono.details)
  //                           if (
  //                             data.message.mono.details.includes('bvn') ||
  //                             data.message.mono.details.includes('BVN')
  //                           ) {
  //                             setTimeout(() => {
  //                               navigate('/settings/security_bvn')
  //                               toggleCardHolder()
  //                             }, 4000)
  //                           } else if (
  //                             data.message.mono.details.includes('nin') ||
  //                             data.message.mono.details.includes('NIN')
  //                           ) {
  //                             navigate(-1)
  //                             toggleCardHolder()
  //                           }
  //                         })
  //                       }
  //                     })
  //                     .catch((err) => {
  //                       notify('error', 'Something went wrong please try again')
  //                     })
  //                 })
  //               }
  //             })
  //             .catch(() => {})
  //         } else {
  //           res.json().then((data) => {
  //             notify('error', data.message)
  //           })
  //         }
  //       })
  //       .catch((err) => {
  //         notify('error', 'Something went wrong please try again')
  //       })
  //   }
  // }
  const onCreate = (formData) => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    setFormLoading(true)
    function convert(str) {
      var date = new Date(str),
        mnth = ('0' + (date.getMonth() + 1)).slice(-2),
        day = ('0' + date.getDate()).slice(-2)
      return [date.getFullYear(), mnth, day].join('-')
    }
    const dateOfBirth = convert(formData.dob)
    if (complainPicture.string === 'Upload an image') {
      notify('error', 'Please upload identity image')
      setFormLoading(false)
    } else {
      // this part is for the update of user profile
      const userUpdateData = {
        first_name: formData.first_name,
        last_name: formData.last_name,
        gender: formData.gender,
        address: formData.address,
        city: formData.city,
        postal_code: formData.postal_code,
        state: selectedState,
        lga: selectedLga,
      }
      if (user.bvn && user.first_name) {
        API.fetchUser().then((res) => {
          if (res.status === 200) {
            res.json().then((userData) => {
              fetch(`${process.env.REACT_APP_API_URL}/cards/card_holder/`, {
                method: 'post',
                headers: {
                  'content-Type': 'application/json',
                  Authorization: `Token ${authToken}`,
                },
                body: JSON.stringify({
                  ...formData,
                  country: 'Nigeria',
                  entity: 'INDIVIDUAL',
                  identity_image: userData.identity_image,
                }),
              })
                .then((res) => {
                  if (res.status === 201) {
                    res.json().then((data) => {
                      notify('success', data.message)
                      API.CreateSudoAccount().then((res) => {
                        if (res.status === 200) {
                          res.json().then((data) => {
                            setFormLoading(false)
                            notify(
                              'success',
                              'wallet creation in progress, await confirmation email. Do not retry!!!'
                            )
                            setTimeout(() => {
                              if (page === 'debit-card' || page === 'home') {
                                navigate('/debit-card/request-sudo')
                                toggleCardHolder()
                              } else {
                                window.location.reload()
                              }
                            }, 3000)
                          })
                        } else {
                          res.json().then((data) => {
                            notify('warn', data.message)
                          })
                        }
                      })
                    })
                  } else {
                    setFormLoading(false)
                    res.json().then((data) => {
                      notify('error', data.message)
                      setTimeout(() => {
                        setFormLoading(false)
                        toggleCardHolder()
                      }, 3000)
                    })
                  }
                })
                .catch((err) => {
                  notify('error', 'Something went wrong please try again')
                })
            })
          } else {
            setFormLoading(false)
          }
        })
      } else {
        fetch(`${process.env.REACT_APP_API_URL}/accounts/profile_works/`, {
          method: 'post',
          headers: {
            'content-Type': 'application/json',
            Authorization: `Token ${authToken}`,
          },
          body: JSON.stringify(userUpdateData),
        }).then((res) => {
          if (res.status === 200) {
            //start of bvn
            const bvnData = {
              bvn: formData.bvn,
              first_name: formData.first_name,
              surname: formData.last_name,
              dob: dateOfBirth,
            }
            fetch('https://www.spendwise.ng/api/accounts/submit_bvn/', {
              method: 'post',
              body: JSON.stringify(bvnData),
              headers: {
                'content-Type': 'application/json',
                Authorization: `Token ${authToken}`,
              },
            }).then((res) => {
              if (res.status === 200) {
                const form_data = new FormData()
                form_data.append('image', complainPicture.file)
                form_data.append('id_type', formData.identity_type)
                form_data.append('id_number', formData.identity_number)
                fetch('https://www.spendwise.ng/api/accounts/upload_id/', {
                  method: 'post',
                  body: form_data,
                  headers: {
                    Authorization: `Token ${authToken}`,
                  },
                }).then((res) => {
                  if (res.status === 200) {
                    API.fetchUser().then((res) => {
                      if (res.status === 200) {
                        res.json().then((userData) => {
                          fetch(
                            `${process.env.REACT_APP_API_URL}/cards/card_holder/`,
                            {
                              method: 'post',
                              headers: {
                                'content-Type': 'application/json',
                                Authorization: `Token ${authToken}`,
                              },
                              body: JSON.stringify({
                                ...formData,
                                country: 'Nigeria',
                                entity: 'INDIVIDUAL',
                                identity_image: userData.identity_image,
                              }),
                            }
                          )
                            .then((res) => {
                              if (res.status === 201) {
                                res.json().then((data) => {
                                  notify('success', data.message)
                                  API.CreateSudoAccount().then((res) => {
                                    if (res.status === 200) {
                                      res.json().then((data) => {
                                        setFormLoading(false)
                                        notify(
                                          'success',
                                          'wallet creation in progress, await confirmation email. Do not retry!!!'
                                        )
                                        setTimeout(() => {
                                          if (
                                            page === 'debit-card' ||
                                            page === 'home'
                                          ) {
                                            navigate('/debit-card/request-sudo')
                                            toggleCardHolder()
                                          } else {
                                            window.location.reload()
                                          }
                                        }, 3000)
                                      })
                                    } else {
                                      res.json().then((data) => {
                                        notify('warn', data.message)
                                      })
                                    }
                                  })
                                })
                              } else {
                                setFormLoading(false)
                                res.json().then((data) => {
                                  notify('error', data.message)
                                  setTimeout(() => {
                                    setFormLoading(false)
                                    toggleCardHolder()
                                  }, 3000)
                                })
                              }
                            })
                            .catch((err) => {
                              notify(
                                'error',
                                'Something went wrong please try again'
                              )
                            })
                        })
                      } else {
                      }
                    })
                  } else {
                    res.json().then((idData) => {
                      if (idData.message === 'ID card already submitted!') {
                        API.fetchUser().then((res) => {
                          if (res.status === 200) {
                            res.json().then((userData) => {
                              fetch(
                                `${process.env.REACT_APP_API_URL}/cards/card_holder/`,
                                {
                                  method: 'post',
                                  headers: {
                                    'content-Type': 'application/json',
                                    Authorization: `Token ${authToken}`,
                                  },
                                  body: JSON.stringify({
                                    ...formData,
                                    country: 'Nigeria',
                                    entity: 'INDIVIDUAL',
                                    identity_image: userData.identity_image,
                                  }),
                                }
                              )
                                .then((res) => {
                                  if (res.status === 201) {
                                    res.json().then((data) => {
                                      notify('success', data.message)
                                      setFormLoading(false)
                                      API.CreateSudoAccount().then((res) => {
                                        if (res.status === 200) {
                                          res.json().then((data) => {
                                            notify(
                                              'success',
                                              'wallet creation in progress, await confirmation email. Do not retry!!!'
                                            )
                                            setTimeout(() => {
                                              window.location.reload()
                                            }, 3000)
                                          })
                                        } else {
                                          res.json().then((data) => {
                                            notify('warn', data.message)
                                            setFormLoading(false)
                                          })
                                        }
                                      })
                                    })
                                  } else {
                                    setFormLoading(false)
                                    res.json().then((data) => {
                                      notify('error', data.message)
                                      setTimeout(() => {
                                        navigate('/settings/profile')
                                        toggleCardHolder()
                                      }, 3000)
                                      notify('error', data.message.mono.details)
                                      if (
                                        data.message.mono.details.includes(
                                          'bvn'
                                        ) ||
                                        data.message.mono.details.includes(
                                          'BVN'
                                        )
                                      ) {
                                        setTimeout(() => {
                                          navigate('/settings/security_bvn')
                                          toggleCardHolder()
                                        }, 4000)
                                      } else if (
                                        data.message.mono.details.includes(
                                          'nin'
                                        ) ||
                                        data.message.mono.details.includes(
                                          'NIN'
                                        )
                                      ) {
                                        navigate(-1)
                                        toggleCardHolder()
                                      }
                                    })
                                  }
                                })
                                .catch((err) => {
                                  notify(
                                    'error',
                                    'Something went wrong please try again'
                                  )
                                })
                            })
                          } else {
                            notify('error', 'Update Failed Try Again')
                          }
                        })
                      }
                    })
                  }
                })
              } else {
                notify('error', 'BVN Update Failed Try Again')
              }
            })
            //end of bvn
          } else {
            notify('error', 'User Update Failed Try Again')
            setFormLoading(false)
          }
        })
      }
    }
  }

  return (
    <div className='createbudget'>
      <div className='close'>
        <AiOutlineClose onClick={toggleCardHolder} />
      </div>
      <div className='text'>
        <h2>Create a Wallet</h2>
        <p>
          Hi, I see that you are new here, please create a Wallet before you
          continue.
        </p>
      </div>
      <form onSubmit={handleSubmit(onCreate)}>
        <div className='card'>
          <p>
            First Name <span className='imp'>*</span>
          </p>
          <input
            type='text'
            name='first_name'
            id=''
            {...register('first_name')}
            required
          />
        </div>
        <div className='card'>
          <p>
            Last Name <span className='imp'>*</span>
          </p>
          <input
            type='text'
            name='last_name'
            id=''
            {...register('last_name')}
            required
          />
        </div>
        <div className='card'>
          <p>
            Gender <span className='imp'>*</span>
          </p>
          <select name='gender' id='' required {...register('gender')}>
            <option value=''>-select-</option>
            <option value='Female'>Female</option>
            <option value='Male'>Male</option>
            <option value='Other'>Others</option>
          </select>
        </div>
        <div className='card'>
          <p>
            Date of Birth <span className='imp'>*</span>
          </p>
          <Controller
            control={control}
            name='dob'
            render={({ field: { onChange, value } }) => (
              <DatePicker
                onChange={onChange}
                selected={value}
                showMonthDropdown
                showYearDropdown
                dropdownMode='select'
              />
            )}
          />
        </div>
        <div className='card'>
          <p>
            Address <span className='imp'>*</span>
          </p>
          <input
            required
            type='text'
            name='address'
            id=''
            {...register('address')}
          />
        </div>
        <div className='card'>
          <p>
            State <span className='imp'>*</span>
          </p>
          <select
            name='state'
            required
            id=''
            onChange={(e) => {
              fetchLga(e.target.value)
              setSelectedState(e.target.value)
            }}
          >
            {StateInfo.map((item) => (
              <option key={item.state.id} value={item.state.name}>
                {item.state.name}
              </option>
            ))}
          </select>
        </div>
        <div className='card'>
          <p>
            Local Government <span className='imp'>*</span>
          </p>
          <select
            name='state'
            required
            id=''
            onChange={(e) => {
              setSelectedLga(e.target.value)
            }}
          >
            {lgaList.map((item) => (
              <option key={item.id} value={item.name}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
        <div className='card'>
          <p>
            City <span className='imp'>*</span>
          </p>
          <input required type='text' name='city' id='' {...register('city')} />
        </div>
        <div className='card'>
          <p>
            Postal Code <span className='imp'>*</span>
          </p>
          <input
            required
            type='text'
            name='postal_code'
            id=''
            {...register('postal_code')}
          />
        </div>
        <div className='card'>
          <p>
            BVN <span className='imp'>*</span>
          </p>
          <input
            required
            type='number'
            name='bvn'
            {...register('bvn', {
              required: 'Provide a BVN NUM',
              minLength: {
                value: 11,
                message: 'BVN NUM must be 11 characters',
              },
              maxLength: {
                value: 11,
                message: 'BVN NUM must be 11 characters',
              },
            })}
          />
          {errors.bvn && (
            <h6 className='vError' style={{ fontSize: '10px', color: 'red' }}>
              {errors.bvn.message}
            </h6>
          )}
        </div>

        <div className='card'>
          <p>
            ID Type <span className='imp'>*</span>
          </p>
          <select
            type='text'
            name='identity_type'
            {...register('identity_type', {
              required: 'ID type is required',
            })}
          >
            <option value=''>Select</option>
            <option value='DRIVERS_LICENSE'>Driver’s License</option>
            <option value='INTERNATIONAL_PASSPORT'>
              International Passport
            </option>
            <option value='NIN'>NIN</option>
            <option value='Voters Card'>Voter’s Card</option>
          </select>
          {errors.identity_type && (
            <h6 className='vError'>{errors.identity_type.message}</h6>
          )}
        </div>
        <div className='card'>
          <p>
            Identity card number <span className='imp'>*</span>
          </p>
          <input
            type='tel'
            name='identity_number'
            {...register('identity_number', {
              required: 'Identity number is required',
              // minLength: {
              //   value: 11,
              //   message: 'Phone number must have at least 11 characters',
              // },
              // maxLength: {
              //   value: 11,
              //   message: 'Phone number must contain 11 characters',
              // },
            })}
            id=''
          />
          {errors.identity_number && (
            <h6 className='vError'>{errors.identity_number.message}</h6>
          )}
        </div>
        <div className='imagee'>
          <p className='id-pic'>
            Identity image picture{' '}
            <span className='imp' style={{ color: 'red' }}>
              *
            </span>
          </p>
          <input
            type='file'
            name=''
            id='image_complain'
            accept='image/*'
            onChange={onSelectComplainImage}
          />
          <div className='imagee_label'>
            <label htmlFor='image_complain'>
              <p>
                {complainPicture.string}
                <BsFillImageFill />
              </p>
            </label>
          </div>
        </div>
        <div className='button'>
          {formLoading ? (
            <img src={Loader} alt='' />
          ) : (
            <button type='submit' className='create'>
              Create
            </button>
          )}
        </div>
      </form>
    </div>
  )
}

export default CreateCHolder
