import React, { useState } from 'react'
import VerveLogo from '../../../../../Image/vereve3.png'
import Logo from '../../../../../Image/cardlogo.png'
import Logo2 from '../../../../../Image/cardlogo2.png'
import VerveLogo3 from '../../../../../Image/masterlogo.png'

function CardBox({ item, navigate }) {
  return (
    <div
      className='card-box'
      key={item.card_id}
      onClick={() => navigate(`/physical-card/edit/${item.card_id}`)}
    >
      <div className='card-box_top'>
        <h2>{item.alias} </h2>
      </div>
      <div className='credit-card-body' id={item.currency}>
        <div className='credit-card-body-top'>
          {item.currency === 'USD' ? (
            <img src={Logo2} alt='' />
          ) : (
            <img src={Logo} alt='' />
          )}
          {/* <p>
            {' '}
            ₦
            {item.available_balance
              .toLocaleString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          </p> */}
        </div>
        <div className='card-number'>
          <p>{item.maskedPan.slice(0, 4)}</p>
          <p>{item.maskedPan.slice(4, 8)}</p>
          <p>{item.maskedPan.slice(8, 12)}</p>
          <p>{item.maskedPan.slice(12, 16)}</p>
          <p>{item.maskedPan.slice(16, 20)}</p>
        </div>
        <div className='credit-card-body-bottom'>
          <div>
            <p>Card Holder</p>
            <h2>{item.alias}</h2>
          </div>
          <div>
            <p>Exp</p>
            <h2>
              {item.expiryMonth}/{item.expiryYear}
            </h2>
          </div>
          <div className='vlogo'>
            {item.currency === 'USD' ? (
              <img src={VerveLogo3} alt='' />
            ) : (
              <img src={VerveLogo} alt='' />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CardBox
