import React, { useState, useEffect } from 'react'
import './Developers.scss'
import { useForm } from 'react-hook-form'
import Loader from '../../../../Image/loder.gif.gif'
import API from '../../../../Context/API'
import { FaSlidersH } from 'react-icons/fa'

function Developers({ notify, user }) {
  const authToken = sessionStorage.getItem('userId')
  const [formLoading, setFormLoading] = useState(false)
  const [generateLoading, setGenerateLoading] = useState(false)
  const [showCode, setShowCode] = useState(false)
  const [showProdcution, setShowProdcution] = useState(false)
  const [callBackUrl, setCallBackUrl] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const [tokenData, setTokenData] = useState({})
  const [numberCopied, setNumberCopied] = useState(false)
  const [numberCopied1, setNumberCopied1] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm()
  const callBackURL = (formData) => {
    setFormLoading(true)
    fetch(`https://www.spendwise.ng/api/accounts/callback_url/`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
      body: JSON.stringify({ ...formData }),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            notify('success', data.message)
            setFormLoading(false)
            setTimeout(() => {
              window.location.reload()
            }, 3000)
          })
        } else {
          res.json().then((data) => {
            notify('error', data.message)
            setFormLoading(false)
          })
        }
      })
      .catch(() => {
        setFormLoading(false)
        notify('error', 'internal server error')
      })
  }
  useEffect(() => {
    API.FetchCallBack()
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setCallBackUrl(data.message)
          })
        } else {
          res.json().then((data) => {})
        }
      })
      .catch(() => {})
  }, [])
  const generateNewToken = () => {
    setGenerateLoading(true)
    fetch(`${process.env.REACT_APP_API_URL}/accounts/token/`, {
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
    })
      .then((res) => {
        if (res.status === 201) {
          res.json().then((data) => {
            notify('success', data.message)
            setGenerateLoading(false)
            setOpenModal(false)
            setShowCode(true)
            setTokenData(data)
          })
        } else {
          res.json().then((data) => {
            notify('error', data.message)
            setGenerateLoading(false)
          })
        }
      })
      .catch(() => {
        setGenerateLoading(false)
        notify('error', 'internal server error')
      })
  }
  const switchProdcution = () => {
    setGenerateLoading(true)
    fetch(`${process.env.REACT_APP_API_URL}/accounts/business_active/`, {
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            notify('success', data.message)
            setGenerateLoading(false)
            setTimeout(() => {
              window.location.reload()
            }, 3000)
          })
        } else {
          res.json().then((data) => {
            notify('error', data.message)
            setGenerateLoading(false)
          })
        }
      })
      .catch(() => {
        setGenerateLoading(false)
        notify('error', 'internal server error')
        setGenerateLoading(false)
      })
  }
  const copyCardNumber = (e) => {
    navigator.clipboard.writeText(e)
    setNumberCopied(true)
  }
  const copyCardNumber1 = (e) => {
    navigator.clipboard.writeText(e)
    setNumberCopied1(true)
  }
  return (
    <div className='developers'>
      <div className='top'>
        <div className='left'>
          <h1>Developers</h1>
        </div>
        <div className='right'>
          <div className='label'>
            <p>Production Stage</p>
          </div>
          <label
            for='with-warrenty'
            className='switch-container'
            onClick={() => setShowProdcution(true)}
          >
            <input
              hidden
              type='checkbox'
              checked={user.business_active}
              id='with-warrenty'
            />
            <div className='switch-bg'></div>
            <div className='round-box'></div>
            <div className='switch-left'>
              <span>Live</span>
            </div>
            <div className='switch-right'>
              <span>Test</span>
            </div>
          </label>
        </div>
      </div>
      <div className='dev_body'>
        {openModal && (
          <div className='ask_modal'>
            <div className='modal_body'>
              <h1>Are you sure you want to generate new keys?</h1>
              <p>
                Note that we do not keep your plain token in our record. They
                are kept as hashed keys. If you misplace your token or feel it
                has been compromised, please create a fresh pair. This resets
                all your API calls to use the newly created token.{' '}
              </p>
              {generateLoading ? (
                <div>
                  <img src={Loader} alt='' />
                </div>
              ) : (
                <div className='buttons'>
                  <button
                    className='delete'
                    onClick={() => setOpenModal(false)}
                  >
                    Back
                  </button>
                  <button className='fund' onClick={() => generateNewToken()}>
                    Generate
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
        {showProdcution && (
          <div className='ask_modal'>
            <div className='modal_body'>
              <h1>Are you sure you want to Switch production stage?</h1>

              {generateLoading ? (
                <div>
                  <img src={Loader} alt='' />
                </div>
              ) : (
                <div className='buttons'>
                  <button
                    className='delete'
                    onClick={() => setShowProdcution(false)}
                  >
                    Back
                  </button>
                  <button className='fund' onClick={() => switchProdcution()}>
                    Switch
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
        <div className='api'>
          <div className='api_top'>
            <h2>API keys</h2>
          </div>
          <div className='form'>
            <div className='card'>
              <p>Test Key</p>
              <div className='input'>
                <h3>
                  {showCode ? tokenData.test_token : '***********************'}
                </h3>
                {showCode && (
                  <>
                    {numberCopied ? (
                      <p
                        style={{
                          fontSize: '10px',
                          color: 'green',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        copied
                      </p>
                    ) : (
                      <button
                        onClick={() => copyCardNumber(tokenData.test_token)}
                      >
                        Copy
                      </button>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className='card'>
              <p>Production Key</p>
              <div className='input'>
                <h3>
                  {showCode ? tokenData.prod_token : '***********************'}
                </h3>
                {showCode && (
                  <>
                    {numberCopied1 ? (
                      <p
                        style={{
                          fontSize: '10px',
                          color: 'green',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        copied
                      </p>
                    ) : (
                      <button
                        onClick={() => copyCardNumber1(tokenData.prod_token)}
                      >
                        Copy
                      </button>
                    )}
                  </>
                )}
              </div>
              <div className='gen'>
                <h3 onClick={() => setOpenModal(!openModal)}>
                  Generate new key
                </h3>
              </div>
            </div>
            {/* <div className='card'>
              <p>Encryption Key</p>
              <div className='input'>
                <h3>********************</h3>
                <button>Copy</button>
              </div>
            </div> */}
            <div className='card'>
              <div className='top_button'>
                <a
                  href='https://spendwise.readme.io/reference/introduction'
                  target='_blank'
                >
                  <button>API documentation</button>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className='api'>
          <div className='api_top'>
            <h2>Webhooks</h2>
          </div>
          <div className='set-callback'>
            <h2>Set Callback Url</h2>
            <form onSubmit={handleSubmit(callBackURL)}>
              <div className='card'>
                <p>URL</p>
                <input
                  type='url'
                  name='url'
                  defaultValue={callBackUrl}
                  id=''
                  {...register('url', {
                    required: 'Provide a valid url',
                  })}
                />
                {errors.url && (
                  <h6
                    className='vError'
                    style={{ fontSize: '10px', color: 'red' }}
                  >
                    {errors.url.message}
                  </h6>
                )}
              </div>
              {formLoading ? (
                <div>
                  <img style={{ width: '100px' }} src={Loader} alt='' />
                </div>
              ) : (
                <button>submit</button>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Developers
