import React, { useEffect, useState } from 'react'
import '../ExpenseReqPreview/ExpenseReqPreview.scss'
import pdf from '../../../../../../Image/pdf.svg'
import jpg from '../../../../../../Image/jpg.svg'
import { useParams } from 'react-router-dom'
import API from '../../../../../../Context/API'
import { GrClose } from 'react-icons/gr'
import { BsArrowRightShort } from 'react-icons/bs'
import modalIcon from '../ExpenseReceipt/images/modalicon.svg'
import FormLoader from '../../../../../../Component/FormLoader/FormLoader'

export default function ExpReqPreview({ notify }) {
  const authToken = sessionStorage.getItem('userId')
  const { new_id } = useParams()
  const [requestData, setRequestData] = useState({})
  const [loading, setLoading] = useState(true)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [approvalType, setApprovalType] = useState('')
  const [formLoading, setformLoading] = useState(false)
  const [reason, setreason] = useState('')
  const [approver, setApprover] = useState({})
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/expense/get_expense/`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
      body: JSON.stringify({
        expense_id: parseInt(new_id),
      }),
    }).then((res) => {
      if (res.status === 201) {
        res.json().then((data) => {
          setRequestData(data.data)
          setLoading(false)
          const approve = data.data.approvers.find(
            (item) => item.user === data.data.next_approver_id
          )
          setApprover(approve)
        })
      } else if (res.status === 204) {
        setLoading(false)
      }
    })
  }, [])
  const displayStatus = (item) => {
    if (item.approved) {
      return 'approved'
    } else if (!item.approved) {
      return 'pending'
    } else if (item.approved && item.paid) {
      return 'approved'
    }
  }
  const openApprovalModal = (data) => {
    setIsModalOpen(true)
    setApprovalType(data)
  }
  const getFileName = (url) => {
    const parts = url.split('/')
    const fileName = parts[parts.length - 1]
    return fileName
  }
  const submitApprovalData = () => {
    const data = {
      approval_id: approver.id,
    }
    if (approvalType === 'approve') {
      data.action = 'approve'
    } else {
      data.reason = reason
      data.action = 'deny'
    }
    setformLoading(true)
    fetch(`${process.env.REACT_APP_API_URL}/expense/approve/`, {
      method: 'POST',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
      body: JSON.stringify(data),
    }).then((res) => {
      if (res.status === 200) {
        res.json().then((item) => {
          notify('success', item.message)
          setTimeout(() => {
            window.location.replace('/expense-approval/dashboard')
          }, 2000)
          setformLoading(false)
        })
      } else {
        res.json().then((item) => {
          notify('error', item.detail)
          setformLoading(false)
        })
      }
    })
  }
  return loading ? (
    <FormLoader />
  ) : (
    <div className='expPreview'>
      {isModalOpen && (
        <div className='open-modal'>
          <div className='modal-content'>
            <div className='modal-header'>
              <p>Confirm</p>
              <GrClose size={20} onClick={() => setIsModalOpen(false)} />
            </div>
            <div className='border'></div>
            <div className='modal-body'>
              <img src={modalIcon} />
              <p>Are you sure you want to {approvalType} this Request?</p>
              {approvalType === 'decline' && (
                <div className='text'>
                  <textarea
                    name=''
                    id=''
                    cols='30'
                    rows='10'
                    placeholder='reason'
                    onChange={(e) => setreason(e.target.value)}
                  ></textarea>
                </div>
              )}
              {formLoading ? (
                <FormLoader />
              ) : (
                <div className='buttons'>
                  <button onClick={() => setIsModalOpen(false)}>Cancel</button>
                  {approvalType === 'approve' ? (
                    <button onClick={submitApprovalData}>Approve</button>
                  ) : (
                    <button onClick={submitApprovalData}>Decline</button>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <div className='preview-header'>
        <h1>Expense Request Preview</h1>
        <p className={displayStatus(requestData)}>
          {displayStatus(requestData) === 'approved'
            ? 'Expense has been approved'
            : ' Expense Request is awaiting for approval'}
        </p>
      </div>
      <div className='preview-cover'>
        <div className='preview-form'>
          <h2>1. Request Details</h2>
          <div className='preview-one'>
            <div className='card'>
              <p>Request Title</p>
              <div className='card-input'>
                <p>{requestData.title}</p>
              </div>
            </div>

            <div className='row'>
              <div className='cardx'>
                <p>Request Type</p>
                <div className='cardx-input'>
                  <p>{requestData.type_of.replace(/_/g, ' ')}</p>
                </div>
              </div>
              <div className='cardx'>
                <p>Request Approver</p>
                <div className='cardx-input'>
                  <p>
                    {requestData.approvers
                      .map((item) => item.approver)
                      .join(', ')}
                  </p>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='cardx'>
                <p>Authorization Type</p>
                <div className='cardx-input'>
                  <p>Approve Only</p>
                </div>
              </div>
              <div className='cardx'>
                <p>Expense Amount</p>
                <div className='cardx-input'>
                  <p>#{parseInt(requestData.amount).toLocaleString()}</p>
                </div>
              </div>
            </div>
            <div className='card'>
              <p>Description</p>
              <div className='card-input textarea'>
                <p>{requestData.description}</p>
              </div>
            </div>
            <div className='card'>
              {requestData.support_docs.map((item) => (
                <div className='prev-card-invoice'>
                  <div className='left'>
                    <div>
                      <img src={pdf} />
                    </div>
                    <div>
                      <h6>{getFileName(item.image)}</h6>
                    </div>
                  </div>
                  <div className='right'>
                    <a href={item.image} target='_blank'>
                      <p>View file</p>
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className='buttons'>
            <button onClick={() => openApprovalModal('decline')}>
              Decline
            </button>
            <button onClick={() => openApprovalModal('approve')}>
              Approve
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
