import React, { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import month from '../../../../Data/Month'
import year from '../../../../Data/Year'
import { AiOutlineClose, AiFillDelete, AiOutlineEdit } from 'react-icons/ai'
import { MdCallSplit } from 'react-icons/md'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import CategoriesData from '../../../../Data/CategoriesData'
import ReactPaginate from 'react-paginate'

function AllExpense({ notify }) {
  const defaultMonth = month[new Date().getMonth()]
  const authToken = sessionStorage.getItem('userId')
  const [formLoading, setFormLoading] = useState(false)
  const [changeLoading, setChangeLoading] = useState(false)
  const [foundExpense, setFoundExpense] = useState(false)
  const [allExpense, setAllExpense] = useState({})
  const [noExpense, setNoExpense] = useState('')
  const [changeTransaction, setChangeTransaction] = useState('false')
  const [fetchedChangeData, setFetchedChangeData] = useState({})
  const [fetchedChange, setFetchedChange] = useState(false)
  const [fetchedDeleteData, setFetchedDeleteData] = useState({})
  const [fetchedDelete, setFetchedDelete] = useState(false)
  const [fetchedNewTxData, setFetchedNewTxData] = useState({})
  const [fetchedNewTx, setFetchedNewTx] = useState(false)

  const [searchPage, setSearchPage] = useState('view')
  const my_date = new Date()

  const handleViewPage = () => {
    setSearchPage('view')
  }
  const handleSearchPage = () => {
    setSearchPage('search')
  }

  const { register, handleSubmit, control } = useForm()
  const { register: register2, handleSubmit: handleSubmit2 } = useForm()
  const { register: register3, handleSubmit: handleSubmit3 } = useForm()
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/tx/all_tx/?page=1`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
      body: JSON.stringify({
        month: defaultMonth.id,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setAllExpense(data)
            setFormLoading(false)
            setFoundExpense(true)
          })
        } else if (res.status === 404) {
          res.json().then((data) => {
            setNoExpense(data.message)
            setFormLoading(false)
            setFoundExpense(false)
          })
        } else {
          setFormLoading(false)
        }
      })
      .catch((err) => {
        setFormLoading(false)
      })
  }, [])
  const refreshExpense = () => {
    fetch(`${process.env.REACT_APP_API_URL}/tx/all_tx/?page=1`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
      body: JSON.stringify({
        month: defaultMonth.id,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setAllExpense(data)
            setFormLoading(false)
            setFoundExpense(true)
          })
        } else if (res.status === 404) {
          res.json().then((data) => {
            setNoExpense(data.message)
            setFormLoading(false)
            setFoundExpense(false)
          })
        } else {
          setFormLoading(false)
        }
      })
      .catch((err) => {
        setFormLoading(false)
      })
  }
  const onFetch = (formData) => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    setFormLoading(true)

    const month = parseInt(formData.month)
    const year = parseInt(formData.year)
    fetch(`${process.env.REACT_APP_API_URL}/tx/all_tx/`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
      body: JSON.stringify({
        month: month,
        year: year,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setAllExpense(data)
            setFormLoading(false)
            setFoundExpense(true)
          })
        } else if (res.status === 404) {
          res.json().then((data) => {
            setNoExpense(data.message)
            setFormLoading(false)
            setFoundExpense(false)
          })
        } else {
          setFormLoading(false)
        }
      })
      .catch((err) => {
        setFormLoading(false)
      })
  }
  const onSearch = (formData) => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    setFormLoading(true)

    function convert(str) {
      var date = new Date(str),
        mnth = ('0' + (date.getMonth() + 1)).slice(-2),
        day = ('0' + date.getDate()).slice(-2)
      return [date.getFullYear(), mnth, day].join('-')
    }
    const startDate = convert(formData.start)
    const endDate = convert(formData.end)

    fetch(`${process.env.REACT_APP_API_URL}/tx/tx_search/`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
      body: JSON.stringify({
        ...formData,
        start: startDate,
        end: endDate,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setAllExpense(data)
            setFormLoading(false)
            setFoundExpense(true)
          })
        } else if (res.status === 400) {
          res.json().then((data) => {
            setNoExpense(data.message)
            setFormLoading(false)
            setFoundExpense(false)
          })
        } else if (res.status === 404) {
          res.json().then((data) => {
            setNoExpense(data.message)
            setFormLoading(false)
            setFoundExpense(false)
          })
        } else {
          setFormLoading(false)
        }
      })
      .catch((err) => {
        setFormLoading(false)
      })
  }
  const onChangeTransaction = (formData) => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    setChangeLoading(true)
    fetch(`${process.env.REACT_APP_API_URL}/tx/change_category/`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
      body: JSON.stringify({
        id: fetchedChangeData.id,
        ...formData,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            notify('success', data.message)
            refreshExpense()
            setFetchedChange(false)
            setFetchedChangeData({})
            setChangeLoading(false)
          })
        } else if (res.status === 404) {
          res.json().then((data) => {
            notify('error', data.message)
          })
        } else {
          setFormLoading(false)
        }
      })
      .catch((err) => {
        setFormLoading(false)
      })
  }
  const onDeleteTransaction = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    setChangeLoading(true)
    fetch(`${process.env.REACT_APP_API_URL}/tx/delete_tx/`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
      body: JSON.stringify({
        id: fetchedDeleteData.id,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            notify('success', data.message)
            refreshExpense()
            setFetchedDelete(false)
            setFetchedDeleteData({})
            setChangeLoading(false)
          })
        } else if (res.status === 404) {
          res.json().then((data) => {
            notify('error', data.message)
          })
        } else {
          setFormLoading(false)
        }
      })
      .catch((err) => {
        setFormLoading(false)
      })
  }
  const onNewTransaction = (formData) => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    setChangeLoading(true)
    fetch(`${process.env.REACT_APP_API_URL}/tx/convert/`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
      body: JSON.stringify({
        id: fetchedNewTxData.id,
        ...formData,
      }),
    })
      .then((res) => {
        if (res.status === 201) {
          res.json().then((data) => {
            notify('success', data.message)
            refreshExpense()
            setFetchedNewTx(false)
            setFetchedNewTxData({})
            setChangeLoading(false)
          })
        } else if (res.status === 400) {
          res.json().then((data) => {
            notify('error', data.message)
          })
        } else {
          setFormLoading(false)
        }
      })
      .catch((err) => {
        setFormLoading(false)
      })
  }

  const onFindChange = (id) => {
    const foundItem = allExpense.results.find((item) => item.id === id)
    setFetchedChangeData(foundItem)
    setFetchedChange(true)
  }
  const onDelete = (id) => {
    const foundItem = allExpense.results.find((item) => item.id === id)
    setFetchedDeleteData(foundItem)
    setFetchedDelete(true)
  }
  const onNewTx = (id) => {
    const foundItem = allExpense.results.find((item) => item.id === id)
    setFetchedNewTxData(foundItem)
    setFetchedNewTx(true)
  }

  const handleClick = (item) => {
    let currentPage = item.selected + 1
    setFormLoading(true)
    fetch(`${process.env.REACT_APP_API_URL}/tx/all_tx/?page=${currentPage}`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
      body: JSON.stringify({
        month: defaultMonth.id,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setAllExpense(data)
            setFormLoading(false)
            setFoundExpense(true)
          })
        } else if (res.status === 404) {
          res.json().then((data) => {
            setNoExpense(data.message)
            setFormLoading(false)
            setFoundExpense(false)
          })
        } else {
          setFormLoading(false)
        }
      })
      .catch((err) => {
        setFormLoading(false)
      })
  }
  return (
    <div className='expense'>
      {searchPage === 'view' && (
        <div className='expense-flow'>
          <form className='sort diff' onSubmit={handleSubmit(onFetch)}>
            <div className='select-wrap'>
              <select name='month' id='' {...register('month')}>
                <option value={defaultMonth.id}>{defaultMonth.Content}</option>
                {month.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.Content}
                  </option>
                ))}
              </select>
              <select name='year' id='' {...register('year')}>
                <option value='2022'>2022</option>
                {year.map((item) => (
                  <option key={item.Content} value={item.Content}>
                    {item.Content}
                  </option>
                ))}
              </select>
            </div>
            <button>{formLoading ? '.....' : 'View'}</button>
          </form>
          <div className='sort'>
            <button onClick={handleSearchPage}>Search expenses</button>
          </div>
        </div>
      )}
      {searchPage === 'search' && (
        <div className='expense-flow search'>
          <form className='search-row' onSubmit={handleSubmit(onSearch)}>
            <div className='card'>
              <input
                type='text'
                name='term'
                id=''
                placeholder='Description'
                {...register('term', {})}
              />
            </div>
            <div className='card'>
              <input
                type='number'
                name='gte'
                id=''
                placeholder='Amount from:'
                {...register('gte', {})}
              />
            </div>
            <div className='card'>
              <input
                type='number'
                name='lte'
                id=''
                placeholder='Amount to:'
                {...register('lte', {})}
              />
            </div>
            <div className='card'>
              <Controller
                control={control}
                name='start'
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <DatePicker
                    onChange={onChange}
                    onBlur={onBlur}
                    selected={value}
                    maxDate={my_date}
                    placeholderText='Start date:'
                    required
                  />
                )}
              />
            </div>
            <div className='card'>
              <Controller
                control={control}
                name='end'
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <DatePicker
                    onChange={onChange}
                    onBlur={onBlur}
                    selected={value}
                    maxDate={my_date}
                    placeholderText='End date:'
                    required
                  />
                )}
              />
            </div>
            <div className='card'>
              <button className='search-but'>Search</button>
            </div>
          </form>
          <div className='search-sort'>
            <button
              onClick={() => {
                window.location.reload()
                handleViewPage()
              }}
            >
              View all expenses
            </button>
          </div>
        </div>
      )}

      <div className='all-expenses'>
        {foundExpense ? (
          <div style={{ width: '100%' }}>
            <div className='table-cover'>
              <table>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th title='click category to change category name'>
                      Category
                    </th>
                    <th>Mode</th>
                    <th>Description</th>
                    <th>amount</th>
                    <th>Id</th>
                  </tr>
                </thead>
                <tbody>
                  {allExpense.results.map((item) => (
                    <tr key={item.id}>
                      <td className='date'>
                        {new Date(item.tx_date).toDateString()}
                      </td>
                      <td
                        title='Click to change category'
                        className={
                          fetchedChangeData.id === item.id ||
                          fetchedDeleteData.id === item.id ||
                          fetchedNewTxData.id === item.id
                            ? 'cart activess'
                            : 'cart'
                        }
                        onClick={() => onFindChange(item.id)}
                      >
                        {item.category}
                      </td>
                      <td className={item.tx_mode}>{item.tx_mode}</td>
                      <td>{item.tx_description}</td>
                      <td>
                        ₦
                        {item.tx_amount
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      </td>
                      <td className='id'>
                        {item.tx_id === 'User Created' && (
                          <AiFillDelete
                            title='Delete transaction'
                            className='delete-trans'
                            onClick={() => {
                              onDelete(item.id)
                            }}
                          />
                        )}
                        {item.category === 'POS Payment' ||
                        item.category === 'ATM Transaction' ? (
                          <MdCallSplit
                            title='Split transaction into other categories'
                            className='split'
                            onClick={() => {
                              onNewTx(item.id)
                            }}
                          />
                        ) : (
                          ''
                        )}
                        <AiOutlineEdit
                          className='edit-icon'
                          title='Click to change category'
                          onClick={() => onFindChange(item.id)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {allExpense.total_pages > 1 && (
              <div className='pagination'>
                <ReactPaginate
                  pageCount={allExpense.total_pages}
                  onPageChange={handleClick}
                  className='pagination-wrap'
                  activeClassName='active-pag'
                  pageRangeDisplayed='1'
                  marginPagesDisplayed={window.innerWidth < 420 ? '2' : '3'}
                  previousLabel='Prev'
                />
              </div>
            )}
          </div>
        ) : (
          <div>
            <h1 className='no-expense'>{noExpense}</h1>
          </div>
        )}
        {fetchedChange ? (
          <div className='change-category'>
            <div className='change-wrap'>
              <div className='top'>
                <h2>Change Category</h2>
                <AiOutlineClose
                  onClick={() => {
                    setFetchedChange(false)
                    setFetchedChangeData({})
                  }}
                />
              </div>
              <form onSubmit={handleSubmit2(onChangeTransaction)}>
                <div>
                  <select name='category' id='' {...register2('category')}>
                    <option value={fetchedChangeData.category}>
                      {fetchedChangeData.category}
                    </option>
                    {CategoriesData.map((item) => (
                      <option value={item.Content} key={item.id}>
                        {item.Content}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <button>
                    {' '}
                    {changeLoading ? '.....' : 'Change category'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        ) : (
          ''
        )}
        {fetchedDelete ? (
          <div className='change-category'>
            <div className='change-wrap'>
              <div className='top'>
                <h2>Delete Transaction</h2>
                <AiOutlineClose
                  onClick={() => {
                    setFetchedDelete(false)
                    setFetchedDeleteData({})
                  }}
                />
              </div>
              <div className='button-wrap'>
                <div>
                  {' '}
                  <button
                    onClick={() => {
                      setFetchedDelete(false)
                      setFetchedDeleteData({})
                    }}
                  >
                    No
                  </button>
                </div>
                <div>
                  <button
                    className='delete-button'
                    onClick={onDeleteTransaction}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
        {fetchedNewTx ? (
          <div className='change-category'>
            <div className='change-wrap'>
              <div className='top'>
                <h2>Split Tansaction</h2>
                <AiOutlineClose
                  onClick={() => {
                    setFetchedNewTx(false)
                    setFetchedNewTxData({})
                  }}
                />
              </div>
              <form onSubmit={handleSubmit3(onNewTransaction)}>
                <div>
                  <input
                    type='number'
                    max={fetchedNewTxData.tx_amount}
                    placeholder='Amount'
                    name='amount'
                    {...register3('amount')}
                    required
                  />
                </div>
                <div>
                  <select name='category' id='' {...register3('category')}>
                    {CategoriesData.map((item) => (
                      <option value={item.Content} key={item.id}>
                        {item.Content}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <textarea
                    name='description'
                    id=''
                    cols='30'
                    rows='10'
                    placeholder='Description'
                    required
                    {...register3('description')}
                  ></textarea>
                </div>
                <div>
                  <button>
                    {' '}
                    {changeLoading ? '.....' : 'Create Transaction'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  )
}

export default AllExpense
