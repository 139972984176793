import React, { useState, useEffect } from 'react'
import './UserManagement.scss'
import { useParams } from 'react-router'
import UDashboard from './Component/UDashboard'
import CreateUser from './Component/CreateUser'
import API from '../../../../Context/API'
import EditUser from './Component/EditUser'

function UserManagement({ notify }) {
  const { id } = useParams()
  const [UserList, setUserList] = useState({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    API.fetchUserMana().then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setUserList(data)
          setLoading(false)
        })
      } else if (res.status === 204) {
        setLoading(false)
        setUserList({ count: 0, results: [] })
      }
    })
  }, [])

  const UserPage = () => {
    switch (id) {
      case 'dashboard':
        return <UDashboard UserList={UserList} loading={loading} />
      case 'create-user':
        return <CreateUser UserList={UserList} notify={notify} />
      case 'edit-user':
        return (
          <EditUser UserList={UserList} Floading={loading} notify={notify} />
        )

      default:
        return <h1>Page not found</h1>
    }
  }

  return <>{UserPage()}</>
}

export default UserManagement
