import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import { FaChevronRight, FaCheck } from 'react-icons/fa'
import ForeignData from '../../../../../../../Data/ForeignDatas'
import CoutriedData from '../../../../../../../Data/CountriedData'
import Loader from '../../../../../../../Image/loder.gif.gif'

function AddBenefi({ notify }) {
  const authToken = sessionStorage.getItem('userId')
  const navigate = useNavigate()
  const [detailPage, setDetailPage] = useState('one')
  const [selectedCurrency, setselectedCurrency] = useState({
    currency: '?',
    currency: 'select currency',
  })
  const [selectedAccount, setselectedAccount] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [recipientEmail, setRecipientEmail] = useState('')
  const [bankName, setBankName] = useState('')
  const [country, setCountry] = useState('')
  const [address, setAddress] = useState('')
  const [city, setCity] = useState('')
  const [postalCode, setPostalCode] = useState('')
  const [providence, setProvidence] = useState('')
  const [showIntemidiary, setshowIntemidiary] = useState(false)
  //jhjhcjdfhdujdnc
  const [accountNumber, setAccountNumber] = useState('')
  const [swift, setSwift] = useState('')
  const [cnaps, setCnaps] = useState('')
  const [iban, setIban] = useState('')
  const [routinaba, setRoutinaba] = useState('')
  const [sortCode, setSortCode] = useState('')
  ////gfhggg
  const [InterBankCountry, setInterBankCountry] = useState('')
  const [InterRCode, setInterRCode] = useState('')
  const [InterAccount, setInterAccount] = useState('')
  const [InterBankName, setInterBankName] = useState('')
  const [InterBankAdd, setInterBankAdd] = useState('')
  const [LabelCode, setLabelCode] = useState('')
  const [formLoading, setFormLoading] = useState(false)

  //jsjdjsjdlsjldsljdsdj

  const selectCurrency = (data) => {
    const foundCountry = ForeignData.find((item) => item.currency === data)
    setselectedCurrency(foundCountry)
    setselectedAccount(foundCountry.accounts[0])
  }
  //This is the part to validate
  const checkStepOne = () => {
   if (companyName.length < 1) {
      notify('warn', 'Input company name')
    } else if (recipientEmail.length < 1) {
      notify('warn', 'Input a recipient email')
    } else if (Object.keys(selectedCurrency).length === 0) {
      notify('warn', 'Select a Currency')
    } else {
      setDetailPage('two')
    }
  }
  const checkStepTwo = () => {
    if (companyName.length < 1) {
      notify('warn', 'Input company name')
    } else if (bankName.length < 1) {
      notify('warn', 'Input a bank name')
    } else if (country.length < 1) {
      notify('warn', 'Select a country')
    } else if (address.length < 1) {
      notify('warn', 'Input a address')
    } else if (city.length < 1) {
      notify('warn', 'Input a city')
    } else if (postalCode.length < 1) {
      notify('warn', 'Input a postal Code')
    } else if (providence.length < 1) {
      notify('warn', 'Input a State Or Providence')
    } else if (showIntemidiary && InterRCode.length < 1) {
      notify('warn', 'Input a Routing Code')
    } else if (showIntemidiary && InterAccount.length < 1) {
      notify('warn', 'Input an Intemidiary account number')
    } else if (
      selectedAccount.includes('A/C number') &&
      accountNumber.length < 1
    ) {
      notify('warn', 'Input an account number')
    } else if (selectedAccount.includes('Swift') && swift.length < 1) {
      notify('warn', 'Input a swift value')
    } else if (selectedAccount.includes('CNAPS') && cnaps.length < 1) {
      notify('warn', 'Input a CNAPS')
    } else if (selectedAccount.includes('IBAN') && iban.length < 1) {
      notify('warn', 'Input an IBAN')
    } else if (selectedAccount.includes('Sort code') && sortCode.length < 1) {
      notify('warn', 'Input a Sort code')
    } else if (
      selectedAccount.includes('Routing Number/ABA Code') &&
      routinaba.length < 1
    ) {
      notify('warn', 'Input a Routing Number/ABA Code')
    } else {
      handleSubmit()
    }
  }
  const handleSubmit = () => {
    let countryD = ''
    if (selectedCurrency.currency === 'EUR') {
      countryD = `europe - ${country}`
    } else if (
      selectedCurrency.currency === 'USD' &&
      country !== 'United States'
    ) {
      countryD = `others - ${country}`
    } else {
      countryD = country
    }

    const data = {
      entity: 'company',
      company_name: companyName,
      intermediary: showIntemidiary,
      currency: selectedCurrency.currency,
      country: countryD,
      bank_name: bankName,
      beneficiary_address: address,
      beneficiary_city: city,
      recipient_email: recipientEmail,
    }
    switch (selectedAccount) {
      case 'A/C number + CNAPS':
        data.ac_no = accountNumber
        data.cnaps = cnaps
        break
      case 'A/C number + Swift':
        data.ac_no = accountNumber
        data.swift = swift
        break
      case 'Swift + IBAN':
        data.swift = swift
        data.iban = iban
        break
      case 'A/C number + Sort code':
        data.ac_no = accountNumber
        data.sort_code = sortCode
        break
      case 'A/C number + Routing Number/ABA Code':
        data.ac_no = accountNumber
        data.routing_no_aba = routinaba
        break
      default:
        data.ac_no = accountNumber
        data.swift = swift
        break
    }
    if (showIntemidiary) {
      data.routing_code = InterRCode
      data.intermediary_ac_no = InterAccount
      data.intermediary_bank_name = InterBankName
      data.intermediary_bank_address = InterBankAdd
      data.intermediary_label_tag = LabelCode
      data.intermediary_ac_country = InterBankCountry
    }
    setFormLoading(true)
    fetch(
      `https://kxu81bfj78.execute-api.eu-west-1.amazonaws.com/prod/fx/create_benefeciary`,
      {
        method: 'post',
        headers: {
          'content-Type': 'application/json',
          Authorization: `Token ${authToken}`,
        },
        body: JSON.stringify(data),
      }
    )
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setFormLoading(false)
            notify(
              'success',
              'Beneficiary created successfully. Await confirmation in 24 hours.'
            )
            setTimeout(() => {
              window.location.replace(
                '/e-wallet/transfers/foreign-transfer/initate-transfer'
              )
            }, 4000)
          })
        } else {
          setFormLoading(false)
          res.json().then((data) => {
            notify('error', data.message)
            //  setAccountNumberCorrect(false)
          })
        }
      })
      .catch((err) => {
        notify('error', err.message)
        setFormLoading(false)
      })

  }
  const returnFInput = () => {
    switch (selectedAccount) {
      case 'A/C number + CNAPS':
        return (
          <div className='row'>
            <div className='cardx'>
              <p>
                Account Number <span>*</span>
              </p>
              <input
                type='text'
                name=''
                id=''
                placeholder='Enter Account Number'
                onChange={(e) => setAccountNumber(e.target.value)}
                value={accountNumber}
              />
            </div>
            <div className='cardx'>
              <p>
                CNAPS <span>*</span>
              </p>
              <input
                type='text'
                name=''
                id=''
                placeholder='Enter CNAPS'
                onChange={(e) => setCnaps(e.target.value)}
                value={cnaps}
              />
            </div>
          </div>
        )
      case 'A/C number + Swift':
        return (
          <div className='row'>
            <div className='cardx'>
              <p>
                Account Number <span>*</span>
              </p>
              <input
                type='text'
                name=''
                id=''
                placeholder='Enter Account Number'
                onChange={(e) => setAccountNumber(e.target.value)}
                value={accountNumber}
              />
            </div>
            <div className='cardx'>
              <p>
                Swift <span>*</span>
              </p>
              <input
                type='text'
                name=''
                id=''
                placeholder='Enter Swift'
                onChange={(e) => setSwift(e.target.value)}
                value={swift}
              />
            </div>
          </div>
        )
      case 'Swift + IBAN':
        return (
          <div className='row'>
            <div className='cardx'>
              <p>
                Swift <span>*</span>
              </p>
              <input
                type='text'
                name=''
                id=''
                placeholder='Enter Swift'
                onChange={(e) => setSwift(e.target.value)}
                value={swift}
              />
            </div>
            <div className='cardx'>
              <p>
                Iban <span>*</span>
              </p>
              <input
                type='text'
                name=''
                id=''
                placeholder='Enter Iban'
                onChange={(e) => setIban(e.target.value)}
                value={iban}
              />
            </div>
          </div>
        )
      case 'A/C number + Sort code':
        return (
          <div className='row'>
            <div className='cardx'>
              <p>
                Account Number <span>*</span>
              </p>
              <input
                type='text'
                name=''
                id=''
                placeholder='Enter Account Number'
                onChange={(e) => setAccountNumber(e.target.value)}
                value={accountNumber}
              />
            </div>
            <div className='cardx'>
              <p>
                Sort code <span>*</span>
              </p>
              <input
                type='text'
                name=''
                id=''
                placeholder='Enter Sort code'
                onChange={(e) => setSortCode(e.target.value)}
                value={sortCode}
              />
            </div>
          </div>
        )
      case 'A/C number + Routing Number/ABA Code':
        return (
          <div className='row'>
            <div className='cardx'>
              <p>
                Account Number <span>*</span>
              </p>
              <input
                type='text'
                name=''
                id=''
                placeholder='Enter Account Number'
                onChange={(e) => setAccountNumber(e.target.value)}
                value={accountNumber}
              />
            </div>
            <div className='cardx'>
              <p>
                Routing Number/ABA Code <span>*</span>
              </p>
              <input
                type='text'
                name=''
                id=''
                placeholder='Routing Number/ABA Code'
                onChange={(e) => setRoutinaba(e.target.value)}
                value={routinaba}
              />
            </div>
          </div>
        )

      default:
        break
    }
  }
  return (
    <div className='add-beneficiary'>
      <div className='label'>
        <p onClick={() => navigate('/e-wallet/transfers/select')}>Wallet</p>
        <FaChevronRight />
        <p
          onClick={() =>
            navigate('/e-wallet/transfers/foreign-transfer/select')
          }
        >
          Foreign Account
        </p>
        <FaChevronRight />
        <h3>Add Beneficiary</h3>
      </div>
      <div className='beneficiary-body'>
        <div className='bene-top'>
          <div className='bene-cover'>
            <div className='top'>
              <h2>Add Beneficiary</h2>
              <p>
                Simply future transactions by adding your beneficiaries today
              </p>
            </div>
            <div className='bene-progress'>
              <div
                className={
                  detailPage === 'one' ? 'one round active' : 'one round done'
                }
              >
                <div className='num'>
                  {detailPage === 'one' ? <p>1</p> : <FaCheck />}
                </div>
                <h4>Detail 1</h4>
              </div>
              <div className={detailPage === 'two' ? 'lines k' : 'lines'}></div>
              <div
                className={
                  detailPage === 'two' ? 'two round active' : 'two round'
                }
              >
                <div className='num'>
                  <p>2</p>
                </div>
                <h4>Detail 2</h4>
              </div>
            </div>
            <div className='bene-form'>
              {detailPage === 'one' ? (
                <div className='bene-one'>
                  <div className='card'>
                    <p>
                      Company Name <span>*</span>
                    </p>
                    <input
                      type='text'
                      placeholder='Enter Company Name'
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                    />
                  </div>
                  <div className='card'>
                    <p>
                      Email Address <span>*</span>
                    </p>
                    <input
                      type='email'
                      name='recipient_email'
                      id=''
                      placeholder='Enter Email Address'
                      onChange={(e) => setRecipientEmail(e.target.value)}
                      value={recipientEmail}
                    />
                  </div>
                  <div className='card'>
                    <p>
                      Currency <span>*</span>
                    </p>
                    <select
                      name=''
                      id=''
                      onChange={(e) => selectCurrency(e.target.value)}
                      value={selectedCurrency.currency}
                    >
                      <option value=''> Select Currency</option>
                      {ForeignData.map((item) => (
                        <option value={item.currency}>{item.currency}</option>
                      ))}
                    </select>
                  </div>
                  <div className='buttons'>
                    <button>Cancel</button>
                    <button onClick={() => checkStepOne()}>Next</button>
                  </div>
                </div>
              ) : (
                <div className='bene-one'>
                  <div className='radio-btn'>
                    <div className='card radio'>
                      {selectedCurrency.accounts.map((item) => (
                        <>
                          <input
                            type='radio'
                            name='selectedAccount'
                            id={item}
                            checked={item === selectedAccount}
                            value={item}
                            onChange={(e) => setselectedAccount(e.target.value)}
                          />
                          <p>{item}</p>
                        </>
                      ))}
                    </div>
                  </div>
                  <>{returnFInput()}</>
                  <div className='card'>
                    <p>
                      Bank Name <span>*</span>
                    </p>
                    <input
                      type='text'
                      placeholder='Enter Bank Name'
                      onChange={(e) => setBankName(e.target.value)}
                      value={bankName}
                    />
                  </div>
                  <div className='card'>
                    <p>
                      Country <span>*</span>
                    </p>
                    <select
                      name=''
                      id=''
                      onChange={(e) => setCountry(e.target.value)}
                      value={country}
                    >
                      <option value=''> Select Country</option>
                      {selectedCurrency.country.map((item) => (
                        <option value={item.name} key={item.shortcode}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className='row'>
                    <div className='cardx'>
                      <p>
                        Beneficiary Address <span>*</span>
                      </p>
                      <input
                        type='text'
                        name=''
                        id=''
                        placeholder='Enter Beneficiary Address'
                        onChange={(e) => setAddress(e.target.value)}
                        value={address}
                      />
                    </div>
                    <div className='cardx'>
                      <p>
                        Beneficiary City <span>*</span>
                      </p>
                      <input
                        type='text'
                        name=''
                        id=''
                        placeholder='Enter Beneficiary city'
                        onChange={(e) => setCity(e.target.value)}
                        value={city}
                      />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='cardx'>
                      <p>
                        Beneficiary Postcode <span>*</span>
                      </p>
                      <input
                        type='text'
                        placeholder='Enter Beneficiary Postcode '
                        onChange={(e) => setPostalCode(e.target.value)}
                        value={postalCode}
                      />
                    </div>
                    <div className='cardx'>
                      <p>
                        Beneficiary State Or Providence <span>*</span>
                      </p>
                      <input
                        type='text'
                        placeholder='Enter Beneficiary State Or Providence'
                        onChange={(e) => setProvidence(e.target.value)}
                        value={providence}
                      />
                    </div>
                  </div>

                  <div className='card radio'>
                    <input
                      type='checkbox'
                      name=''
                      id=''
                      onChange={(e) => setshowIntemidiary(!showIntemidiary)}
                    />
                    <p>Intermediary Details</p>
                  </div>
                  {showIntemidiary && (
                    <>
                      <div className='card'>
                        <p>Select Bank Acccount Country</p>
                        <select
                          name=''
                          id=''
                          onChange={(e) => setInterBankCountry(e.target.value)}
                          value={InterBankCountry}
                        >
                          <option value=''> Select Country</option>
                          {CoutriedData.map((item) => (
                            <option value={item.name} key={item.shortcode}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className='row'>
                        <div className='cardx'>
                          <p>
                            Routing Code <span>*</span>
                          </p>
                          <input
                            type='text'
                            name=''
                            id=''
                            placeholder='Enter Routing Code '
                            onChange={(e) => setInterRCode(e.target.value)}
                            value={InterRCode}
                          />
                        </div>
                        <div className='cardx'>
                          <p>
                            Account Number <span>*</span>
                          </p>
                          <input
                            type='text'
                            name=''
                            id=''
                            placeholder='Enter Account Number'
                            onChange={(e) => setInterAccount(e.target.value)}
                            value={InterAccount}
                          />
                        </div>
                      </div>
                      <div className='card'>
                        <p>Bank Name</p>
                        <input
                          type='text'
                          placeholder='Enter Bank Name'
                          onChange={(e) => setInterBankName(e.target.value)}
                          value={InterBankName}
                        />
                      </div>
                      <div className='card'>
                        <p>Bank Address</p>
                        <input
                          type='text'
                          placeholder='Enter Bank Name'
                          onChange={(e) => setInterBankAdd(e.target.value)}
                          value={InterBankAdd}
                        />
                      </div>
                      <div className='card'>
                        <p>Label/Tag</p>
                        <input
                          type='email'
                          name=''
                          id=''
                          placeholder='Enter Label/Tag'
                          onChange={(e) => setLabelCode(e.target.value)}
                          value={LabelCode}
                        />
                      </div>
                    </>
                  )}
                  {formLoading ? (
                    <>
                      <div className='loader'>
                        <img src={Loader} alt='' />
                      </div>
                    </>
                  ) : (
                    <div className='buttons'>
                      <button onClick={() => setDetailPage('one')}>
                        Cancel
                      </button>
                      <button className='dif' onClick={checkStepTwo}>
                        Add Recipient
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddBenefi
