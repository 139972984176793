import React, { useState, useEffect } from 'react'
import './ExpenseDashboard.scss'
import { BsSearch, BsSendFill, BsCaretDownFill } from 'react-icons/bs'
import { BiBell, BiFilterAlt } from 'react-icons/bi'
import { RxCross1 } from 'react-icons/rx'
import { RxCounterClockwiseClock } from 'react-icons/rx'
import { FaQuestion } from 'react-icons/fa'
import { ImArrowRight } from 'react-icons/im'
import { FaCheck } from 'react-icons/fa'
import { FiSearch } from 'react-icons/fi'
import {
  TbSquareRoundedChevronDownFilled,
  TbSquareRoundedChevronUpFilled,
} from 'react-icons/tb'
import plane from './image/Fly Papers.png'
import box from './image/Layer 2 (1).png'
import { useNavigate } from 'react-router-dom/umd/react-router-dom.development'
import API from '../../../../../../Context/API'
import FormLoader from '../../../../../../Component/FormLoader/FormLoader'
import Pagination from '../../../../../../Component/Pagination/Pagination'

function ExpenseDashboard() {
  const authToken = sessionStorage.getItem('userId')
  const loggedInUserDetails = JSON.parse(sessionStorage.getItem('user'))
  const [reRoute, setReRoute] = useState(null)
  const [request, setRequest] = useState(null)
  const [confirm, setConfirm] = useState(null)
  const [status, setStatus] = useState(false)
  const [clickedUser, setClickedUser] = useState('')
  const [requestList, setRequestList] = useState({})
  const [loading, setLoading] = useState(true)
  const [noRequests, setNoRequests] = useState(true)
  const [expenseDataList, setExpenseDataList] = useState([])
  const [userList, setUserList] = useState({})
  const [userArray, setUserArray] = useState([])
  const [userLoading, setUserLoading] = useState(true)
  const [rerouteLoading, setRerouteLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    API.fetchUserMana().then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setUserList(data)
          setUserLoading(false)
          setUserArray(data.results)
        })
      } else if (res.status === 204) {
        setUserList({ count: 0, results: [] })
      }
    })
  }, [])

  function handleReRoute(data) {
    setReRoute(data)
  }
  function handleRequestReRoute() {
    setRerouteLoading(true)
    fetch(`${process.env.REACT_APP_API_URL}/expense/change_approver/`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
      body: JSON.stringify({
        expense_id: reRoute.id,
        approval_id: request.id,
        new_approver: confirm.user,
      }),
    }).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setStatus(true)
          setReRoute(null)
          setRequest(null)
          setConfirm(null)
        })
      } else if (res.status === 204) {
        setRerouteLoading(false)
      } else {
        res.json().then((data) => {
          setRerouteLoading(false)
        })
      }
    })
  }
  function handleConfirmReRoute(data) {
    setConfirm(data)
  }
  function handleStatus() {
    setStatus(false)
    setReRoute(null)
  }
  const navigate = useNavigate()

  useEffect(() => {
    API.fetchExpenseRequest(1).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setRequestList(data)
          setExpenseDataList(data.results)
          setLoading(false)
          setNoRequests(false)
        })
      } else if (res.status === 204) {
        setNoRequests(true)
        setLoading(false)
      }
    })
  }, [])

  const displayStatus = (item) => {
    if (item.approved) {
      return 'Approved'
    } else if (!item.approved && item.action_required) {
      return 'Declined'
    } else if (!item.approved) {
      return 'Pending'
    } else if (item.approved && item.paid) {
      return 'Approved & Disbursed'
    }
  }
  const findNextApproverName = (item) => {
    const foundComrade = item.approvers.find(
      (approver) => approver.user === item.next_approver_id
    )
    if (foundComrade) {
      return foundComrade.approver
    } else {
      return 'N/A'
    }
  }

  const filterSearchExpenses = (data) => {
    const filteredCard = requestList.results.filter((item) => {
      return Object.values(item)
        .join('')
        .toLowerCase()
        .includes(data.toLowerCase())
    })
    setExpenseDataList(filteredCard)
  }
  const filterUserList = (data) => {
    const filteredCard = userList.results.filter((item) => {
      return Object.values(item)
        .join('')
        .toLowerCase()
        .includes(data.toLowerCase())
    })
    setUserArray(filteredCard)
  }
  const filterByStatus = (data) => {
    let filteredCard = null
    if (data === 'pending') {
      filteredCard = requestList.results.filter((item) => {
        return !item.approved && !item.action_required
      })
    } else if (data === 'approved') {
      filteredCard = requestList.results.filter((item) => {
        return item.approved === true
      })
    } else if (data === 'declined') {
      filteredCard = requestList.results.filter((item) => {
        return item.action_required === true
      })
    } else {
      filteredCard = requestList.results
    }

    setExpenseDataList(filteredCard)
  }
  const paginationClick = (num) => {
    setLoading(true)
    API.fetchExpenseRequest(num).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setRequestList(data)
          setExpenseDataList(data.results)
          setLoading(false)
          setNoRequests(false)
          setCurrentPage(num)
        })
      } else if (res.status === 204) {
        setNoRequests(true)
        setLoading(false)
      }
    })
  }
  return (
    <div className='expenseDashboard'>
      {reRoute && (
        <div className='open-modal'>
          <div className='request'>
            <div className='req-header'>
              <div className='approver'>
                <h4>Re-route Request Approver</h4>
                <p className='assign'>
                  Approvers are executed orderly as assigned during requesting
                  process
                </p>
              </div>
              <RxCross1 className='icon' onClick={() => setReRoute(null)} />
            </div>
            {!userLoading && (
              <div className='approved-names'>
                {reRoute.approvers.map((item) => (
                  <div className='name'>
                    <div className='hist'>
                      <h5>{item.approver}</h5>
                      <p className='author'>{item.approver_email}</p>
                    </div>
                    <div className='btn-control'>
                      {!item.approved ? (
                        <button
                          className='re-routeB'
                          onClick={() => setRequest(item)}
                        >
                          Re-route
                        </button>
                      ) : (
                        <p>Approved</p>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )}

            <div className='name'>
              <div className='confirm'>
                <button className='cancel' onClick={() => setReRoute(null)}>
                  Cancel
                </button>
                <button className='confirma' onClick={handleStatus}>
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {request && (
        <div className='open-modal'>
          <div className='list'>
            <div className='list-container'>
              <div className='approver'>
                <h4>Re-route Request Approver</h4>
                <p className='select'>
                  Select an unavailable approver to re-route
                </p>
              </div>
              <RxCross1
                className='icon'
                onClick={() => {
                  setRequest(null)
                }}
              />
            </div>

            <div className='input-main'>
              <div className='username'>
                <input
                  onChange={(e) => filterUserList(e.target.value)}
                  className='input1'
                  type='search'
                  name=''
                  placeholder='Search Username,Title, Role, Department'
                />
              </div>
            </div>
            {!userLoading && (
              <div className='approved-names'>
                {userArray
                  .filter((item) => item.user !== loggedInUserDetails.user_id)
                  .filter((approver) => {
                    // Check if the approver is not in the selectedApprovers array
                    return !reRoute.approvers.some(
                      (selectedApprover) =>
                        selectedApprover.user === approver.user
                    )
                  })
                  .map((item) => (
                    <div className='name'>
                      <div className='hist'>
                        <h5>{item.username}</h5>
                        <p className='author'>{item.roles}</p>
                      </div>
                      <div className='btn-control'>
                        <button
                          className='re-routeB'
                          onClick={() => handleConfirmReRoute(item)}
                        >
                          Select
                        </button>
                      </div>
                    </div>
                  ))}
              </div>
            )}
            <div className='confirm-div'>
              <button
                className='cancel-div'
                onClick={() => {
                  setRequest(null)
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      {confirm && (
        <div className='open-modal'>
          <div className='App'>
            <div className='con-icon'>
              <h4>Confirm</h4>
              <span
                className='icon'
                onClick={() => {
                  setConfirm(false)
                }}
              ></span>
            </div>
            <hr />
            <div className='question'>
              <FaQuestion className='quest' />
            </div>
            <div className='are'>
              <h4>Are you sure you want to re-route?</h4>
              <p className='new'>New request approver will be notified.</p>
            </div>
            <div className='input-arrow'>
              <div className='input4'>
                <p>{request.approver}</p>
              </div>
              <ImArrowRight className='arrow' />
              <div className='input4'>
                <p>{confirm.username}</p>
              </div>
            </div>
            {rerouteLoading ? (
              <FormLoader />
            ) : (
              <div className='confirm-in'>
                <button
                  className='cancel-in'
                  onClick={() => {
                    setConfirm(null)
                  }}
                >
                  Cancel
                </button>
                <button className='confirm-ina' onClick={handleRequestReRoute}>
                  Re-route
                </button>
              </div>
            )}
          </div>
        </div>
      )}
      {status && (
        <div className='open-modal'>
          <div className='re-route'>
            <div className='container-route'>
              <h4>Confirm</h4>
              <span
                className='icon'
                onClick={() => {
                  setStatus(false)
                }}
              >
                <RxCross1 />
              </span>
            </div>
            <hr />
            <div className='question'>
              <FaCheck className='quest' />
            </div>
            <div className='are'>
              <h4>Successfully Re-routed</h4>
              <p className='new'>
                Request approvers has been re-routed successfully.
              </p>
            </div>
            <div className='confirm-in'>
              <button
                className='cancel-in'
                onClick={() => {
                  window.location.reload()
                }}
              >
                Done
              </button>
            </div>
          </div>
        </div>
      )}
      <div className='exp-request-header'>
        <h2>Expense Request Menu</h2>
        <div className='exp-req-btns'>
          <div className='exp-req-field'>
            <input
              placeholder='Select beneficiary...'
              type='search'
              onChange={(e) => filterSearchExpenses(e.target.value)}
            />
          </div>
          <button
            className='req-approval-btn'
            onClick={() => navigate('/expense-approval/expense-request')}
          >
            <p>Request Approval</p>
            <BsSendFill />
          </button>
        </div>
      </div>
      <div className='filter-list'>
        {/* <div className='card'>
          <p>SortBy Date</p>
          <select name='' id=''>
            <option value=''>Latest</option>
            <option value=''>Oldest</option>
          </select>
        </div> */}
        <div className='card'>
          <p>FilterBy Status</p>
          <select
            name=''
            id=''
            onChange={(e) => filterByStatus(e.target.value)}
          >
            <option value='all'>All</option>
            <option value='pending'>Pending</option>
            <option value='approved'>Approved</option>
            <option value='declined'>Declined</option>
          </select>
        </div>
        {/* <div className='card'>
          <p>FilterBy Type</p>
          <select name='' id=''>
            <option value='all'>All</option>
            <option value='expense_request'>Expense request</option>
            <option value='invoice_payment'>Invoice payment</option>
            <option value='expense_reimbursement'>Expense reimbursement</option>
          </select>
        </div> */}
      </div>
      {loading ? (
        <FormLoader />
      ) : (
        <>
          {noRequests ? (
            <h1>No Requests</h1>
          ) : (
            <>
              <div className='table'>
                <div className='table-head'>
                  <div className='row'>
                    {/* <div className='cardT'></div> */}
                    <div className='card'>
                      <h2>Request Title</h2>
                    </div>
                    <div className='card  g'>
                      <h2>Request Type </h2>
                    </div>
                    <div className='card '>
                      <h2>Request Date </h2>
                    </div>
                    <div className='card'>
                      <h2>Expense Approvers</h2>
                    </div>
                    <div className='card'>
                      <h2>Status</h2>
                    </div>
                    <div className='card g'>
                      <h2></h2>
                    </div>
                  </div>
                </div>
                <div className='table-body'>
                  {expenseDataList.map((item, i) => (
                    <div
                      className={i + 1 === 0 ? 'row l checked' : 'row l'}
                      key={item.id}
                    >
                      <div
                        className='card'
                        // onClick={() =>
                        //   navigate(`/user-management/edit-user/${item.id}`)
                        // }
                      >
                        <p>{item.title}</p>
                      </div>
                      <div className='card g'>
                        <p>{item.type_of.replace(/_/g, ' ')}</p>
                      </div>
                      <div className='card'>
                        <p>{new Date(item.create_date).toDateString()}</p>
                      </div>
                      <div className='card '>
                        <p>{findNextApproverName(item)}</p>
                      </div>
                      <div className='card'>
                        <p>{displayStatus(item)}</p>
                      </div>
                      <div className='card g'>
                        {loggedInUserDetails.user ===
                          findNextApproverName(item) &&
                        !item.approved &&
                        !item.action_required ? (
                          <button
                            onClick={() =>
                              navigate(
                                `/expense-approval/expense-req-preview/${item.id}`
                              )
                            }
                          >
                            Approve
                          </button>
                        ) : (
                          <button
                            onClick={() =>
                              navigate(
                                `/expense-approval/expense-receipt/${item.id}`
                              )
                            }
                          >
                            View
                          </button>
                        )}

                        {loggedInUserDetails.user_id ===
                          parseInt(item.receiver_id) &&
                          !item.action_required &&
                          !item.approved && (
                            <button onClick={() => handleReRoute(item)}>
                              Re-route
                            </button>
                          )}
                        {loggedInUserDetails.user_id ===
                          parseInt(item.receiver_id) &&
                          item.action_required &&
                          !item.approved && (
                            <button
                              onClick={() =>
                                navigate(
                                  `/expense-approval/edit-expense-request/${item.id}`
                                )
                              }
                            >
                              Review
                            </button>
                          )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className='mobile-table'>
                {expenseDataList.map((item, i) => (
                  <div className='card' key={i + 1}>
                    <div className='mob-head'>
                      <div className='left'>
                        <div className='name'>
                          <h2>{item.title}</h2>
                        </div>
                        {i !== clickedUser && (
                          <div className='shi'>
                            <p>{item.name}</p>
                          </div>
                        )}
                      </div>
                      <div className='right'>
                        {i === clickedUser ? (
                          <TbSquareRoundedChevronUpFilled
                            onClick={() => setClickedUser('')}
                          />
                        ) : (
                          <TbSquareRoundedChevronDownFilled
                            onClick={() => {
                              setClickedUser(i)
                            }}
                          />
                        )}
                      </div>
                    </div>
                    {i === clickedUser && (
                      <div className='mob-body'>
                        <div className='row'>
                          <div className='left'>
                            <h2>Request Title</h2>
                          </div>
                          <div className='right'>
                            <p>{item.title}</p>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='left'>
                            <h2>Request type</h2>
                          </div>
                          <div className='right'>
                            <p>{item.type_of.replace(/_/g, ' ')}</p>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='left'>
                            <h2>Request Date</h2>
                          </div>
                          <div className='right'>
                            <p>{new Date(item.create_date).toDateString()}</p>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='left'>
                            <h2>Expense Approvers</h2>
                          </div>
                          <div className='right'>
                            <p>{findNextApproverName(item)}</p>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='left'>
                            <h2>Status</h2>
                          </div>
                          <div className='right'>
                            <p>{displayStatus(item)}</p>
                          </div>
                        </div>

                        <div className='mobile-btns'>
                          {loggedInUserDetails.user ===
                            findNextApproverName(item) &&
                          !item.approved &&
                          !item.action_required ? (
                            <button
                              onClick={() =>
                                navigate(
                                  `/expense-approval/expense-req-preview/${item.id}`
                                )
                              }
                            >
                              Approve
                            </button>
                          ) : (
                            <button
                              onClick={() =>
                                navigate(
                                  `/expense-approval/expense-receipt/${item.id}`
                                )
                              }
                            >
                              View
                            </button>
                          )}

                          {loggedInUserDetails.user_id ===
                            parseInt(item.receiver_id) && (
                            <button onClick={() => handleReRoute(item)}>
                              Re-route
                            </button>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
              <Pagination
                currentPage={currentPage}
                totalCount={requestList.total_pages}
                pageSize={10}
                onPageChange={(page) => {
                  setCurrentPage(page)
                  paginationClick(page)
                }}
              />
            </>
          )}
        </>
      )}
    </div>
  )
}

export default ExpenseDashboard
