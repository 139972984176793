import React, { useState } from 'react'
import { FaChevronRight } from 'react-icons/fa'
import { useNavigate } from 'react-router'
import sudo_bank_codes from '../../../../../../Data/BankCodes'
import { FcOk, FcCancel } from 'react-icons/fc'
import PinInput from 'react-pin-input'
import Loader from '../../../../../../Image/loder.gif.gif'

function WalletTransfer({ sudoDetails, notify }) {
  const authToken = sessionStorage.getItem('userId')
  const navigate = useNavigate()
  const [transferStage, setTransferStage] = useState('one')
  const [disableAll, setDisableAll] = useState(false)
  const [transferAmmount, setTransferAmmount] = useState(0)
  const [transferDisplayNumber, setTransferDisplayNumber] = useState('')
  const [sudoUserBankDet, setSudoUserBankDet] = useState({
    bankCode: '',
    name: '',
  })
  const [accountNumberFecthing, setAccountNumberFecthing] = useState()
  const [accountNumberCorrect, setAccountNumberCorrect] = useState(false)
  const [sudoAccountDetails, setSudoAccountDetails] = useState({
    account_name: '',
  })
  const [transferPin, setTransferPin] = useState('')
  const [formLoading, setFormLoading] = useState(false)

  const handleInputChange = (event) => {
    const inputNumber = event.target.value
    const cleanedNumber = inputNumber.replace(/[^0-9,]/g, '')
    const formattedNumber = formatNumber(cleanedNumber)
    setTransferDisplayNumber(formattedNumber.toLocaleString())
    setTransferAmmount(formattedNumber)
  }
  const formatNumber = (number) => {
    if (number === '') return ''
    const numericValue = parseFloat(number.replace(/,/g, ''))
    if (isNaN(numericValue)) return number
    return numericValue
  }
  const [transferNarration, setTransferNarration] = useState('')
  const [spendwiseUsername, setSpendwiseUsername] = useState('')
  const [walletError, setWalletError] = useState('')
  const [walletDetails, setWalletDetails] = useState({
    account_name: '',
  })
  const checkForEmptyLocal = () => {
    if (
      transferAmmount === 0 ||
      spendwiseUsername === '' ||
      walletDetails.account_name === '' ||
      transferNarration === ''
    ) {
      notify('error', 'Fill up empty fields')
    } else {
      if (sudoDetails.available_balance < transferAmmount) {
        notify('error', 'Amount is greater than balance')
      } else {
        setDisableAll(true)
      }
    }
  }
  const fetchWalletUsername = () => {
    setAccountNumberFecthing(true)
    fetch(`${process.env.REACT_APP_API_URL}/purse/verify_wallet_account/`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
      body: JSON.stringify({
        account: spendwiseUsername,
        issuer: 'sudo',
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          setAccountNumberCorrect(true)
          res.json().then((data) => {
            setWalletDetails(data)
          })
        } else {
          setAccountNumberFecthing(false)
          res.json().then((data) => {
            setWalletError(data.message)
            setAccountNumberCorrect(false)
          })
        }
      })
      .catch(() => {
        notify('error', 'Internal server errorr, Contact support.')
      })
  }
  const nairaLocalVerifyTransfer = (value) => {
    setFormLoading(true)
    fetch(
      `https://p499kkwmp9.execute-api.eu-west-1.amazonaws.com/prod/wallet/transfer`,
      {
        method: 'post',
        headers: {
          'content-Type': 'application/json',
          Authorization: `Token ${authToken}`,
        },
        body: JSON.stringify({
          ac_no: sudoAccountDetails.ac_no,
          bank_code: sudoUserBankDet.bankCode,
          amount: transferAmmount,
          narration: transferNarration,
          pin: value,
          issuer: 'sudo',
          direction: 'out',
        }),
      }
    )
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setFormLoading(false)
            setTransferStage('three')
          })
        } else {
          setFormLoading(false)
          res.json().then((data) => {
            notify('error', data.message)
            //  setAccountNumberCorrect(false)
            if (data.message.includes('Magic PIN not set')) {
              setTimeout(() => {
                navigate('/settings/security')
              }, 4000)
            }
          })
        }
      })
      .catch((err) => {
        notify('info', 'Transfer processing.')
        setFormLoading(false)
        setTimeout(() => {
          window.location.replace('/e-wallet/dashboard')
        }, 4000)
      })
  }
  return (
    <div className='bank-tansfer'>
      <div className='label'>
        <p onClick={() => navigate('/e-wallet/transfers/select')}>Wallet</p>
        <FaChevronRight />
        <h3>Bank Transfer</h3>
      </div>
      <div className='bankbody'>
        <div className='coverx'>
          {transferStage === 'one' ? (
            <>
              <div className='top'>
                <h2>Main Wallet (Spendwise User)</h2>
                <p>
                  {disableAll
                    ? 'Confirm the details below before proceeding'
                    : 'How much would you like to transfer?'}
                </p>
              </div>
              <div className='inputAmount'>
                <div
                  className={
                    transferAmmount > sudoDetails.available_balance
                      ? 'inputt def'
                      : 'inputt'
                  }
                >
                  <h1> ₦ </h1>
                  <input
                    type='text'
                    placeholder='0.00'
                    value={transferDisplayNumber}
                    pattern='[0-9]*'
                    onChange={handleInputChange}
                    disabled={disableAll}
                  />
                </div>
                <div className='balance'>
                  <h2>
                    Your Balance:{' '}
                    <span>
                      ₦ {''}
                      {sudoDetails.available_balance
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    </span>
                  </h2>
                </div>
              </div>
              <div className='formx'>
                <div className='card'>
                  <p>
                    Spendwise Username <span>*</span>{' '}
                    {accountNumberCorrect ? <FcOk /> : <FcCancel />}
                  </p>
                  <input
                    type='text'
                    placeholder='Enter username'
                    onChange={(e) => {
                      setSpendwiseUsername(e.target.value)
                    }}
                    defaultValue={spendwiseUsername}
                    disabled={accountNumberFecthing ? true : false}
                  />
                  {accountNumberFecthing === false && (
                    <p
                      style={{
                        color: 'red',
                        fontSize: '10px',
                        paddingTop: '5px',
                      }}
                    >
                      {walletError}
                    </p>
                  )}
                  <button onClick={() => fetchWalletUsername()}>
                    Find User
                  </button>
                </div>
                <div className='card'>
                  <p>Account Name</p>
                  <input
                    type='text'
                    value={walletDetails.account_name}
                    disabled
                    readOnly
                  />
                </div>
                <div className='card'>
                  <p>
                    Description <span>*</span>
                  </p>
                  <textarea
                    name=''
                    id=''
                    cols='30'
                    rows='10'
                    onChange={(e) => setTransferNarration(e.target.value)}
                    defaultValue={transferNarration}
                    disabled={disableAll}
                  ></textarea>
                </div>
                <div className='buttons'>
                  {disableAll ? (
                    <button onClick={() => setDisableAll(false)}>Edit</button>
                  ) : (
                    <button onClick={() => navigate('/e-wallet/dashboard')}>
                      Cancel
                    </button>
                  )}
                  {disableAll ? (
                    <button
                      onClick={() => {
                        setTransferStage('two')
                      }}
                    >
                      Proceed
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        checkForEmptyLocal()
                      }}
                    >
                      Send
                    </button>
                  )}
                </div>
              </div>
            </>
          ) : transferStage === 'two' ? (
            <>
              <div className='transfer_pin'>
                <div className='wallet_transfer-wrap'>
                  <div className='wallet_transfer-body'>
                    <div className='top'>
                      <h2 style={{ marginBottom: '20px', fontSize: '30px' }}>
                        Transfer Verification
                      </h2>
                      <p>
                        Please input your Magic PIN to initiate this transaction
                      </p>
                    </div>
                    <div className='transfer_pin'>
                      <div className='pin_wrapper'>
                        <PinInput
                          length={4}
                          initialValue=''
                          secret
                          type='numeric'
                          inputMode='numeric'
                          style={{ padding: '10px' }}
                          inputStyle={{
                            borderColor: '#e6e6e6',
                            borderWidth: '3px',
                            borderStyle: 'solid',
                            background: '#f1f1f1',
                            fontSize: ' 3rem',
                            borderRadius: '20px',
                            height: '70px',
                            width: '70px',
                            margin: '0 5px',
                          }}
                          inputFocusStyle={{ borderColor: 'blue' }}
                          onComplete={(value, index) => {
                            setTransferPin(value)
                            nairaLocalVerifyTransfer(value)
                          }}
                          autoSelect={true}
                          regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                        />
                        {formLoading ? (
                          <div
                            className='loader'
                            style={{ textAlign: 'center' }}
                          >
                            <img src={Loader} alt='' />
                          </div>
                        ) : (
                          <div className='t_button'>
                            <button
                              onClick={() => {
                                nairaLocalVerifyTransfer()
                              }}
                            >
                              Confirm & Transfer
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className='success'>
                <div>
                  <div className='req-top'>
                    <h2>Transfer Status</h2>
                    <p>Your Transfer was successfully proccessed!</p>
                  </div>
                  <div className='req-center'>
                    <FcOk />
                    <h3>Successful</h3>
                  </div>
                  <div className='req-botom'>
                    <button
                      onClick={() => {
                        window.location.replace('/e-wallet/dashboard')
                      }}
                    >
                      Done
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default WalletTransfer
