import React from 'react'
import { useForm } from 'react-hook-form'
import { FaChevronLeft } from 'react-icons/fa'

function SecurityQuestion({ navigate, notify, user }) {
  const authToken = sessionStorage.getItem('userId')
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm()
  const onSubmitQuestions = (formData) => {
    fetch('https://www.spendwise.ng/api/accounts/update_security_question/', {
      method: 'patch',
      body: JSON.stringify({ ...formData }),
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            notify('success', data.message)
            window.location.replace('/settings/profile')
          })
        } else {
          res.json().then((data) => {
            notify('error', data.message)
          })
        }
      })
      .catch((err) => {
        notify('error', 'Something went wrong please try again')
      })
  }
  return (
    <div className='security_question'>
      <div className='question-top'>
        <h2 className='mai'> Security</h2>
        <h2
          onClick={() => {
            navigate('/settings/security')
          }}
          className='back'
        >
          <FaChevronLeft /> Back
        </h2>
      </div>
      <form onSubmit={handleSubmit(onSubmitQuestions)}>
        <h2>Update security question</h2>
        <div className='wrapper'>
          <div className='card'>
            <p>Question 1</p>
            <input
              type='text'
              name='question1'
              id=''
              {...register('question1')}
            />
          </div>
          <div className='card'>
            <p>Answer 1</p>
            <input type='text' name='answer1' id='' {...register('answer1')} />
          </div>
          <div className='card'>
            <p>Question 2</p>
            <input
              type='text'
              name='question2'
              id=''
              {...register('question2')}
            />
          </div>
          <div className='card'>
            <p>Answer 2</p>
            <input type='text' name='answer2' id='' {...register('answer2')} />
          </div>
          <div className='card'>
            <p>Question 3</p>
            <input
              type='text'
              name='question3'
              id=''
              {...register('question3')}
            />
          </div>
          <div className='card'>
            <p>Answer 3</p>
            <input type='text' name='answer3' id='' {...register('answer3')} />
          </div>
        </div>
        <div className='button'>
          <button>Submit</button>
        </div>
      </form>
    </div>
  )
}

export default SecurityQuestion
