import React from 'react'
import { useState, useEffect } from 'react'
import laptop from './images/laptop.png'
import David from './images/david.jpg'
import Groove from './images/grove.jpg'
import Mamaz from './images/mamaz.jpg'
import Juwon from './images/juwon.jpg'
import Ifi from './images/ifeanyi.jpg'
import card1 from './images/Cards.png'
import FX from './images/fx.png'
import Corporate from './images/corporate.png'
import Bank from './images/corporates.png'
import Wallet from './images/digiwallet.png'
import Bill from './images/bill.png'
import Bill2 from './images/bill2.png'
import CCard from './images/credit-card.png'
import CCard2 from './images/credit-card2.png'
import Coins from './images/coin.png'
import Coins2 from './images/coin2.png'
import Work from './images/workflow.png'
import Code from './images/code.png'
import Cardt from './images/cardt.png'
import Save from './images/Save.png'
import './Landingpage.scss'
import Navigation2 from '../../Component/Navigation/Navigation2'
import Footer from '../../Component/Footer/Footer'
import { useNavigate } from 'react-router-dom'
import FrequentQuestions from '../../Component/FrequentQuestions/FrequentQuestions'
import { AiOutlineCheck } from 'react-icons/ai'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { BsArrowRight, BsWhatsapp } from 'react-icons/bs'

const LandingPage = () => {
  const navigate = useNavigate()
  const [activeIndex, setActiveIndex] = useState(0)
  const cards_feats = [
    {
      id: 1,
      content: 'Zero transaction fee when you pay with your card',
    },
    {
      id: 2,
      content: 'Get your virtual USD card in minutes',
    },
    {
      id: 3,
      content: 'Earn cash back when you refer',
    },
  ]
  const foreign_feats = [
    {
      id: 1,
      content: 'Payout in USD, GBP, CAD, EURO, Chinese Yuan',
    },
    {
      id: 2,
      content: 'Most Competitive FX rates',
    },
    {
      id: 3,
      content: 'Money received in 24 -48 hrs anywhere in the world.',
    },
  ]
  const Products = [
    {
      id: 1,
      image: Corporate,
      header: 'International Business Payment',
      content:
        'No more running around searching for foreign currencies. Send money to business accounts across the globe from your naira wallet with a few clicks.',
      animate: 'fade-up',
    },
    {
      id: 2,
      image: Wallet,
      header: 'Digital Wallet',
      content:
        'Create as many wallets as needed to manage your spend across  multiple teams, power collections from your customers, and much more.',
      animate: 'fade-down',
    },
    {
      id: 3,
      image: Bank,
      header: 'Corporate Expense Card',
      content:
        'Get our Spendwise corporate debit cards for your business, and manage/control your business spend completely with ease.',
    },
  ]
  const coming_soon = [
    {
      id: 1,
      image: Work,
      image2: Work,
      header: 'Spend Management Workflow',
      content:
        '  Easily automate your business expense approvals with our simple yet powerful approval workflow. ',
      animate: 'fade-up',
    },
    {
      id: 2,
      image: Coins,
      image2: Coins2,
      header: 'Multi-currency Secure Wallet ',
      content:
        ' Send and receive money securely in other currencies directly with your Spendwise multi-currency wallet. ',
      animate: 'fade-down',
    },
    {
      id: 3,
      image: CCard,
      image2: CCard2,
      header: 'Corporate Credit and Employee Cards',
      content:
        'Make credit purchases with our corporate credit cards and power employee benefits with our employee card.',
      animate: 'fade-up',
    },
    {
      id: 4,
      image: Bill,
      image2: Bill2,
      header: 'Business Invoicing & AI-Powered Advisory',
      content:
        'Send simple yet powerful invoices with automated collections & reconciliations. Get AI-powered advisory.',
      animate: 'fade-down',
    },
  ]
  const breakpoints = {
    // when window width is >= 640px
    640: {
      slidesPerView: 2,
    },
    // when window width is >= 768px
    768: {
      slidesPerView: 3,
    },
  }
  const Testimonies = [
    {
      id: 1,
      image: David,
      name: 'Sullivan David',
      title: 'Chief Enabler - ShopUrban',
      desc: ' Making International transactions to our service providers has been a pain. We chose Spendwise virtual $card because convenience matters to us.',
    },
    {
      id: 2,
      image: Groove,
      name: 'Roger',
      title: 'CEO - Groove Finance',
      desc: `The technical support team at Spendwise is Top Notch, Fast
                    and Reliable, It’s a 1 call 7 answers approach`,
    },
    {
      id: 3,
      image: Mamaz,
      name: 'Nmachi',
      title: ' CEO - Mamahz Inc',
      desc: `  I signed up and got my virtual card in minutes and it worked
                    seamlessly unlike others I previously used that were a
                    hassle.`,
    },
    {
      id: 4,
      image: Juwon,
      name: 'Jesujuwon',
      title: 'Digital Nomad',
      desc: 'Yes! Spendwise is a digital customer-centric service platform... Reliable always.',
    },
    {
      id: 5,
      image: Ifi,
      name: 'Ifeanyi Muogbo',
      title: 'Software Engineer',
      desc: `Spendwise's ease of verification and use makes it a better choice than others in the market. Within 5 mins i was able to create an account and get my Card. The team is doing great `,
    },
  ]
  useEffect(() => {
    document.title = 'Spendwise Expense Management'
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % 4)
    }, 6000)

    return () => clearInterval(interval)
  }, [])
  useEffect(() => {
    AOS.init()
  }, [])

  return (
    <div className='Landing2'>
      <Navigation2 />
      <div className='landing_hero '>
        <div className='hero-wrapper'>
          <div
            className='hero-left'
            data-aos='fade-up'
            data-aos-duration='1500'
          >
            <h1>All-in-one Spend Management and payment solution.</h1>
            <p className='automate'>
              Manage your expense approvals, Get virtual USD card, and Make
              foreign payments from your naira wallet.
            </p>
            <div className='buttons'>
              <button onClick={() => navigate('/register')}>
                Get Started For Free <BsArrowRight />
              </button>
              <button onClick={() => navigate('/pricing')}>
                See Pricing
                <BsArrowRight />
              </button>
            </div>
          </div>
          <div
            className='hero-right'
            data-aos='fade-down'
            data-aos-duration='1500'
          >
            <img src={laptop} alt='' />
          </div>
        </div>
      </div>
      <div className='cards-cover'>
        <div
          className='landing_cards'
          data-aos='fade-up'
          data-aos-duration='1000'
        >
          <div className='left'>
            <img
              src={card1}
              alt=''
              data-aos='fade-left'
              data-aos-duration='1500'
            />
            <div className='right'>
              {/* <p>
                Pay globally with our virtual USD card accepted at all merchant
                sites
              </p> */}
              <div className='right_wrap'>
                {cards_feats.map((item) => (
                  <div className='card' key={item.id}>
                    <div className='image'>
                      <AiOutlineCheck />
                    </div>
                    <div>
                      <p className='cont'>{item.content}</p>
                    </div>
                  </div>
                ))}
              </div>
              <button onClick={() => navigate('/login')}>
                Get Virtual Card
              </button>
            </div>
          </div>
          <div className='right'>
            <div className='top'>
              <h1>Get 3D Secure Virtual USD Card</h1>
              <p>
                Pay globally with our virtual USD card accepted at all merchant
                sites
              </p>
            </div>
            <div className='right_wrap dit'>
              {cards_feats.map((item) => (
                <div className='card' key={item.id}>
                  <div className='image'>
                    <AiOutlineCheck />
                  </div>
                  <div>
                    <p className='cont'>{item.content}</p>
                  </div>
                </div>
              ))}
            </div>
            <button className='dy' onClick={() => navigate('/login')}>
              Get Virtual Card
            </button>
          </div>
        </div>
      </div>
      <div className='cards-cover'>
        <div
          className='landing_cards difc'
          data-aos='fade-up'
          data-aos-duration='1000'
        >
          <div className='right'>
            <div className='top'>
              <h1>Make Foreign Payments To Businesses Globally. </h1>
              <p>
                With Spendwise International business payments, you can pay any
                business invoice from your Spendwise naira wallet.
              </p>
            </div>
            <div className='right_wrap dit'>
              {foreign_feats.map((item) => (
                <div className='card' key={item.id}>
                  <div className='image'>
                    <AiOutlineCheck />
                  </div>
                  <div>
                    <p className='cont'>{item.content}</p>
                  </div>
                </div>
              ))}
            </div>
            <button className='dy' onClick={() => navigate('/login')}>
              Initiate Transfer
            </button>
          </div>
          <div className='left'>
            <img
              src={FX}
              alt=''
              data-aos='fade-right'
              data-aos-duration='1500'
            />
            <p className='image-info'>Sign in to check current rates.</p>
            <div className='right'>
              {/* <p>
                Pay globally with our virtual USD card accepted at all merchant
                sites
              </p> */}
              <div className='right_wrap'>
                {foreign_feats.map((item) => (
                  <div className='card' key={item.id}>
                    <div className='image'>
                      <AiOutlineCheck />
                    </div>
                    <div>
                      <p className='cont'>{item.content}</p>
                    </div>
                  </div>
                ))}
              </div>
              <button onClick={() => navigate('/login')}>
                Initiate Transfer
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='feats-cover'>
        <div
          className='landing_features'
          data-aos='fade-up'
          data-aos-duration='1000'
        >
          <div className='top'>
            <h1>Our Products</h1>
            <p>
              Here are some amazing features to help keep your business finance
              on track!
            </p>
          </div>
          <div className='feat_wrap'>
            {Products.map((item) => (
              <div
                key={item.id}
                className='card'
                data-aos={item.animate}
                data-aos-duration='1500'
              >
                <h2 className='change'>{item.header}</h2>
                <div className='image'>
                  <img
                    src={item.image}
                    alt=''
                    onClick={() => navigate('/product')}
                  />
                </div>
                <h2 className='initial'>{item.header}</h2>
                <p>{item.content}</p>
                <div className='button'>
                  <button onClick={() => navigate('/product')}>
                    Learn More
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className='coming-cover'>
        <div
          className='coming_soon'
          data-aos='fade-up'
          data-aos-duration='1500'
        >
          <div className='top'>
            <h1>Coming Soon</h1>
            <p>Expense management and payment solution for businesses.</p>
          </div>
          <div className='wrapper'>
            {coming_soon.map((item, i) => (
              <div
                className={`card ${activeIndex === i ? 'active' : ''}`}
                key={item.id}
              >
                {activeIndex === i ? (
                  <img src={item.image2} alt='' />
                ) : (
                  <img src={item.image} alt='' />
                )}
                <h2>{item.header}</h2>
                <div className='lines'>
                  <div className='line1'></div>
                  <div className='line2'></div>
                </div>
                <p>{item.content}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className='apicover' data-aos='fade-up' data-aos-duration='1000'>
        <div className='api-service'>
          <div className='top'>
            <h1> Our API services </h1>
            <p>
              Integrate our APIs to build your product with a few lines of code.
            </p>
          </div>
          <div className='wrapper'>
            <div className='main' data-aos='fade-up' data-aos-duration='1500'>
              <img src={Code} alt='' />
              <div>
                <button onClick={() => navigate('/register')}>
                  Signup to access API docs and sandbox
                </button>
              </div>
            </div>
            <div className='card' data-aos='fade-down' data-aos-duration='1500'>
              <img src={Save} alt='' />
              <h2>Wallet API</h2>
              <div className='lines'>
                <div className='line1'></div>
                <div className='line2'></div>
              </div>
              <p>
                Create wallets for users with our powerful yet easy
                -to-integrate Naira wallet APIs
              </p>
            </div>
            <div className='card' data-aos='fade-up' data-aos-duration='1500'>
              <img src={Cardt} alt='' />
              <h2>Virtual Card API</h2>
              <div className='lines'>
                <div className='line1'></div>
                <div className='line2'></div>
              </div>
              <p>
                Create 3D secured and globally accepted Naira and USD virtual
                cards for users with our powerful virtual card APIs. Start
                Issuing cards in minutes
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='testi-cover' data-aos='fade-up' data-aos-duration='1500'>
        <div className='testi'>
          <div className='top'>
            <h1> Testimonials </h1>
            {/* <p>Some </p> */}
          </div>
          <div className='wrapper'>
            <Swiper
              modules={[Pagination, Autoplay]}
              className='mySwiper'
              pagination={{ clickable: true }}
              slidesPerView={1}
              spaceBetween={17}
              breakpoints={breakpoints}
              autoplay={{ delay: 3000 }}
            >
              {Testimonies.map((item, i) => (
                <SwiperSlide key={i + 1}>
                  <div className='topd'>
                    <div className='left'>
                      <div className='image'>
                        <img src={item.image} alt='' />
                      </div>
                      <div className='text'>
                        <h2>{item.name}</h2> <p>{item.title}</p>
                      </div>
                    </div>
                    <div className='right'>
                      <h3>“</h3>
                    </div>
                  </div>
                  <div className='swipp-body'>
                    <p>{item.desc}</p>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
      <div className='coverxx'>
        <div className='whatsapp'>
          <a href='https://wa.me/2348138134873'>
            <BsWhatsapp />
          </a>
        </div>
        <p>Let's Chat</p>
      </div>
      <FrequentQuestions data-aos='fade-up' data-aos-duration='1500' />
      <Footer />
    </div>
  )
}

export default LandingPage
