import React, { useState, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { FaChevronLeft, FaRegEye, FaRegEyeSlash } from 'react-icons/fa'

function MagicPin({ navigate, notify }) {
  const authToken = sessionStorage.getItem('userId')

  const [showPassword, setShownPassword] = useState(false)
  const [showPassword2, setShownPassword2] = useState(false)
  const [showPassword3, setShownPassword3] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm()
  const password = useRef({})
  password.current = watch('new_pin', '')
  const onPasswordChange = (formData) => {
    fetch('https://www.spendwise.ng/api/purse/create_or_update_pin/', {
      method: 'post',
      body: JSON.stringify({ ...formData, action: 'update' }),
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            notify('success', data.message)
            navigate('/settings/security')
          })
        } else {
          res.json().then((data) => {
            notify('error', data.message)
          })
        }
      })
      .catch((err) => {
        notify('error', 'Something went wrong please try again')
      })
  }
  return (
    <div className='bvn'>
      <div className='bvn-top'>
        <h2 className='mai'> Security</h2>
        <h2
          onClick={() => {
            navigate('/settings/security')
          }}
          className='back'
        >
          <FaChevronLeft /> Back
        </h2>
      </div>
      <div className='not_available'>
        <h2>Change Magic Pin</h2>
        {/* <p>No quesations set by user.</p> */}
        <form onSubmit={handleSubmit(onPasswordChange)}>
          <div className='card'>
            <p>Current Pin</p>
            <div className='input'>
              <input
                type={showPassword ? 'number' : 'password'}
                name='old_pin'
                id=''
                {...register('old_pin', {
                  required: 'Provide a current pin',
                  minLength: {
                    value: 4,
                    message: 'Current pin must have at least 4 characters',
                  },
                  maxLength: {
                    value: 4,
                    message: 'Current pin must have at least 4 characters',
                  },
                  pattern: {
                    value: /^(?=.*?[0-9]).{4,}$/,
                    message: 'Pin must be numbers',
                  },
                })}
              />
              {showPassword ? (
                <FaRegEyeSlash
                  onClick={() => {
                    setShownPassword(!showPassword)
                  }}
                />
              ) : (
                <FaRegEye
                  onClick={() => {
                    setShownPassword(!showPassword)
                  }}
                />
              )}
            </div>
            {errors.old_pin && (
              <h6 className='vError' style={{ fontSize: '10px', color: 'red' }}>
                {errors.old_pin.message}
              </h6>
            )}
          </div>
          <div className='card'>
            <p>New Pin</p>
            <div className='input'>
              <input
                type={showPassword2 ? 'number' : 'password'}
                name='new_pin'
                {...register('new_pin', {
                  required: 'Provide a new pin',
                  minLength: {
                    value: 4,
                    message: 'Current pin must have at least 4 characters',
                  },
                  maxLength: {
                    value: 4,
                    message: 'Current pin must have at least 4 characters',
                  },
                  pattern: {
                    value: /^(?=.*?[0-9]).{4,}$/,
                    message: 'Pin must be numbers',
                  },
                })}
              />
              {showPassword2 ? (
                <FaRegEyeSlash
                  onClick={() => {
                    setShownPassword2(!showPassword2)
                  }}
                />
              ) : (
                <FaRegEye
                  onClick={() => {
                    setShownPassword2(!showPassword2)
                  }}
                />
              )}
            </div>
            {errors.new_pin && (
              <h6 className='vError' style={{ fontSize: '10px', color: 'red' }}>
                {errors.new_pin.message}
              </h6>
            )}
          </div>
          <div className='card'>
            <p>Confirm New Pin</p>
            <div className='input'>
              <input
                type={showPassword3 ? 'number' : 'password'}
                name='confirm_pin'
                {...register('confirm_pin', {
                  required: 'You must re-enter your pin',
                  validate: (value) =>
                    value === password.current || 'The pin do not match',
                })}
              />
              {showPassword3 ? (
                <FaRegEyeSlash
                  onClick={() => {
                    setShownPassword3(!showPassword3)
                  }}
                />
              ) : (
                <FaRegEye
                  onClick={() => {
                    setShownPassword3(!showPassword3)
                  }}
                />
              )}
            </div>
            {errors.confirm_pin && (
              <h6 className='vError' style={{ fontSize: '10px', color: 'red' }}>
                {errors.confirm_pin.message}
              </h6>
            )}
          </div>
          <div className='button'>
            <button>Change Pin</button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default MagicPin
