import React from 'react'
import { useNavigate, Link } from 'react-router-dom'

function TransactionSumarry({ walletTransactions, transactionFound }) {
  const navigate = useNavigate()
  return (
    <div className='transaction_summary'>
      <div className='wallet_top'>
        <h2 className='wallet-text'>E-Wallet</h2>
      </div>
      <div className='wallet_transaction-summary'>
        <div className='wallet_table'>
          <div className='wallet_table-top'>
            <h2>Wallet Transaction Summary</h2>
            <button onClick={() => navigate('/e-wallet/dashboard')}>
              Back
            </button>
          </div>
          <div className='wallet_table-wrap'>
            {transactionFound ? (
              <table>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Naration</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {walletTransactions
                    .sort((a, b) => b.tx_date.localeCompare(a.tx_date))
                    .map((item) => (
                      <tr
                        key={item.tx_id}
                        onClick={() =>
                          navigate(`/e-wallet/reciept/${item.tx_id}`)
                        }
                      >
                        <td className='date'>
                          {new Date(item.tx_date).toDateString()}
                        </td>
                        <td>
                          <Link to={`/e-wallet/reciept/${item.tx_id}`}>
                            {item.narration.length > 50
                              ? item.narration.slice(0, 50) + '...'
                              : item.narration}
                          </Link>
                        </td>
                        <td className={item.mode}>
                          ₦{item.amount.toLocaleString()}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            ) : (
              <h2
                style={{
                  color: '#c70000',
                  fontFamily: 'Open Sans',
                  fontSize: '2.2vw',
                }}
              >
                Nothing at the moment. Fund your wallet please and make a
                transaction
              </h2>
            )}
          </div>
        </div>
        <div className='mobile_table'>
          {walletTransactions
            .sort((a, b) => b.tx_date.localeCompare(a.tx_date))
            .map((item) => (
              <div
                className='rows'
                key={item.tx_id}
                onClick={() => navigate(`/e-wallet/reciept/${item.tx_id}`)}
              >
                <div className='cells roll'>
                  <h2>{item.provider}</h2>
                  <h2 className={item.mode}>₦{item.amount.toLocaleString()}</h2>
                </div>
                <div className='cells long'>
                  <p>{item.narration}</p>
                </div>
                <div className='cells long'>
                  <p className='time'>
                    {new Date(item.tx_date).toLocaleString()}
                  </p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}

export default TransactionSumarry
