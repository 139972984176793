import React, { useState, useRef } from 'react'
import './ExpenseDocuments.scss'
import { FaDownload, FaFilePdf } from 'react-icons/fa'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import { Navigation } from 'swiper'

function ExpenseDocuments() {
  const imageList = [
    {
      name: 'Pretty NAILS',
      url: 'https://images.unsplash.com/photo-1588359953494-0c215e3cedc6?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8bmFpbHxlbnwwfDF8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60',
    },
    {
      name: 'Paint set',
      url: 'https://images.unsplash.com/photo-1619607146034-5a05296c8f9a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8bmFpbHxlbnwwfDF8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60',
    },
    {
      name: 'Awesome Nails',
      url: 'https://images.unsplash.com/photo-1608278618512-c5968bfa0523?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=282&q=80',
    },
  ]

  const [activeThumbnailIndex, setActiveThumbnailIndex] = useState(0)
  const mainSwiperRef = useRef(null)
  const thumbnailSwiperRef = useRef(null)

  const handleThumbnailClick = (index) => {
    setActiveThumbnailIndex(index)
    if (mainSwiperRef.current && thumbnailSwiperRef.current) {
      mainSwiperRef.current.swiper.slideTo(index)
    }
  }
  return (
    <div className='expense-document'>
      <div className='top'>
        <h2>Expense Documents</h2>
      </div>
      <div className='document-rows'>
        <div className='document-left'>
          <div className='rest-body'>
            <Swiper
              navigation={true}
              modules={[Navigation]}
              className='mySwiper'
              ref={mainSwiperRef}
              onSwiper={(swiper) => (mainSwiperRef.current = swiper)}
            >
              {imageList.map((item) => (
                <SwiperSlide key={item.name}>
                  <div className='head'>
                    <p>
                      {item.name} <FaDownload />
                    </p>
                  </div>
                  <img src={item.url} alt='' />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
        <div className='document-right'>
          {imageList.map((item, i) => (
            <div
              className='card'
              key={item.name}
              // className={`thumbnail ${
              //   activeThumbnailIndex === 0 ? 'active' : ''
              // }`}
              onClick={() => handleThumbnailClick(i)}
            >
              <div className='svg'>
                <FaFilePdf />
              </div>
              <p>{item.name}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ExpenseDocuments
