import React, { useState } from 'react'
import { RiRadioButtonFill, RiCheckboxBlankCircleLine } from 'react-icons/ri'

function CardComponent({ item, age, setAge }) {
  return (
    <div className='cardd' onClick={() => setAge(item.id)}>
      <div className='imgf'>
        <img src={item.image} alt='' />
        <p>........... 546</p>
      </div>
      <div>
        {age === item.id && <RiRadioButtonFill className='full' />}
        {age !== item.id && <RiCheckboxBlankCircleLine className='empy' />}
      </div>
    </div>
  )
}

export default CardComponent
