import React, { useEffect } from 'react'
import { BsBank2, BsCircle, BsWalletFill } from 'react-icons/bs'
import { ReactComponent as BankT } from '../../../../../../Image/bank-transfer.svg'
import { ReactComponent as SpendT } from '../../../../../../Image/spendwise-wallet.svg'
import { ReactComponent as Foreign } from '../../../../../../Image/currency exchange.svg'
import { useNavigate } from 'react-router'
function SelectTransfer({ notify }) {
  const navigate = useNavigate()
  const user = JSON.parse(sessionStorage.getItem('user'))

  return (
    <div className='select-transfer'>
      <div className='label'>
        <p
          onClick={() => {
            navigate('/e-wallet/dashboard')
          }}
        >
          Wallet
        </p>
      </div>
      <div className='selectBody'>
        <div className='coverX'>
          <div className='top'>
            <h3>Choose a transfer type</h3>
          </div>
          <div className='rest'>
            <div className='mother'>
              <h2>Local Transfers</h2>
              <div
                className='card'
                onClick={() => {
                  navigate('/e-wallet/transfers/bank-transfer')
                }}
              >
                <div className='left'>
                  <div className='one'>
                    <SpendT />
                  </div>
                  <div className='two'>
                    <h3>Transfer To Bank Account</h3>
                    <p>Transfer to a Nigerian Local Bank</p>
                  </div>
                </div>
                <div className='right'>
                  <BsCircle />
                </div>
              </div>
              <div
                className='card'
                onClick={() => {
                  navigate('/e-wallet/transfers/wallet-transfer')
                }}
              >
                <div className='left'>
                  <div className='one'>
                    <BankT />
                  </div>
                  <div className='two'>
                    <h3>Transfer to a Spendwise Wallet</h3>
                    <p>Transfer to another Spendwise user’s account</p>
                  </div>
                </div>
                <div className='right'>
                  <BsCircle />
                </div>
              </div>
            </div>
            <div className='mother'>
              <h2>Foreign Transfers</h2>
              <div
                className='card'
                onClick={() => {
                  if (user.is_business) {
                    navigate('/e-wallet/transfers/foreign-transfer/select')
                  } else {
                    notify(
                      'info',
                      'This feature is only available to Business profiles'
                    )
                  }
                }}
              >
                <div className='left'>
                  <div className='one'>
                    <Foreign />
                  </div>
                  <div className='two'>
                    <h3>Foreign account</h3>
                    <p>
                      Transfer funds from your spenwise wallet to a foreign
                      account.
                    </p>
                  </div>
                </div>
                <div className='right'>
                  <BsCircle />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SelectTransfer
