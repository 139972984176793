import React, { useState, useEffect } from 'react'
import './ExpenseRequest.scss'
import { BsFillCloudArrowUpFill } from 'react-icons/bs'
import pdf from '../../../../../../Image/pdf.svg'
import jpg from '../../../../../../Image/jpg.svg'
import illustration from '../../../../../../Image/Illustration.png'
import { useNavigate, useParams } from 'react-router-dom'
import FormLoader from '../../../../../../Component/FormLoader/FormLoader'

function EditRequest({ notify }) {
  const { new_id } = useParams()
  const authToken = sessionStorage.getItem('userId')
  const [isApproved, setIsApproved] = useState(false)
  const [selectedFiles, setSelectedFiles] = useState([])
  const navigate = useNavigate()

  const [requestPage, setRequestPage] = useState('box-one')

  const handleFileChange = (e) => {
    const file = e.target.files[0]
    const reader = new FileReader()
    reader.readAsDataURL(file)
    if (file.size > 3000000) {
      notify('error', 'file must be below 3mb')
    } else {
      reader.onloadend = () => {
        setSelectedFiles(file)
      }
    }
    // setSelectedFiles((prevFiles) => [
    //   ...prevFiles,
    //   ...Object.values(e.target.files),
    // ])
  }

  // This is submit
  const [requestTitle, setRequestTitle] = useState('')
  const [amount, setAmount] = useState('')
  const [description, setDescription] = useState('')
  const [formLoading, setformLoading] = useState(false)
  const [loading, setLoading] = useState(true)

  const checkStepOne = () => {
    if (requestTitle.length < 1) {
      notify('warn', 'Request title is required')
    } else if (amount.length < 1) {
      notify('warn', 'Amount is required')
    } else if (description.length < 1) {
      notify('warn', 'Description title is required')
    } else {
      handleSubmit()
    }
  }
  const handleSubmit = () => {
    const data = {
      request_title: requestTitle,
      amount: amount,
      description: description,
      payout_required: isApproved,
      support_doc: selectedFiles,
      expense_id: new_id,
    }
    const form_data = new FormData()
    Object.keys(data).forEach((key) => {
      form_data.append(key, data[key])
    })
    const obj = {}
    form_data.forEach((value, key) => {
      obj[key] = value
    })
    setformLoading(true)
    fetch(`${process.env.REACT_APP_API_URL}/expense/update_expense/`, {
      method: 'post',
      headers: {
        // 'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
      body: form_data,
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setRequestPage('box-two')
            setformLoading(false)
          })
        } else {
          res.json().then((data) => {
            notify('error', data.message)
            setformLoading(false)
          })
        }
      })
      .catch(() => {
        notify(
          'error',
          'Internal server errorr. Check your internet Connection'
        )
        setformLoading(false)
      })
  }

  //////
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/expense/get_expense/`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
      body: JSON.stringify({
        expense_id: parseInt(new_id),
      }),
    }).then((res) => {
      if (res.status === 201) {
        res.json().then((data) => {
          setRequestTitle(data.data.title)
          setIsApproved(data.data.payout_required)
          setAmount(data.data.amount)
          setDescription(data.data.description)
          setLoading(false)
        })
      } else if (res.status === 204) {
        setLoading(false)
      }
    })
  }, [])
  return loading ? (
    <FormLoader />
  ) : (
    <div className='expenseRequest'>
      <div className='exp-top'>
        <div className='exp-cover'>
          <div className='top'>
            <h2>Edit Expense Request</h2>
          </div>
          <div className='exp-form'>
            {requestPage === 'box-one' ? (
              <div className='exp-one'>
                <div className='card'>
                  <p>Request Title</p>
                  <input
                    type='text'
                    placeholder='Enter title of request'
                    onChange={(e) => setRequestTitle(e.target.value)}
                    value={requestTitle}
                  />
                </div>
                <div className='row'>
                  <div className='cardx'>
                    <p>Authorization style</p>
                    <div className='cardx-exp'>
                      <div
                        className={!isApproved ? 'approved active' : 'approved'}
                        onClick={() => setIsApproved(false)}
                      >
                        <p>Approve only</p>
                      </div>
                      <div
                        className={isApproved ? 'approved active' : 'approved'}
                        onClick={() => setIsApproved(true)}
                      >
                        <p>Approve and Disburse</p>
                      </div>
                    </div>
                  </div>

                  <div className='cardx'>
                    <p>Expense Amount</p>
                    <input
                      className='expense-amount'
                      placeholder='#0.00'
                      type='number'
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                    />
                  </div>
                </div>
                <div className='card'>
                  <p>Description</p>
                  <textarea
                    name=''
                    id=''
                    cols='30'
                    rows='3'
                    onChange={(e) => setDescription(e.target.value)}
                    value={description}
                  ></textarea>
                </div>
                <div className='input0file'>
                  <input
                    type='file'
                    name=''
                    id='documents'
                    onChange={handleFileChange}
                    accept='image/*, .pdf'
                  />
                </div>
                <label htmlFor='documents'>
                  <div className='card'>
                    <div className='card-upload'>
                      <BsFillCloudArrowUpFill />
                      <p>
                        Drop your documents here or <span>click to browse</span>
                      </p>
                    </div>
                    <p>
                      Upload a file each with a size below 3 MB. Only the
                      following formats are allowed: pdf, jpg, png.
                    </p>
                  </div>
                </label>
                {selectedFiles.type && (
                  <div className='card'>
                    <div className='card-invoice'>
                      <div className='left'>
                        <div>
                          <img
                            alt=''
                            src={
                              selectedFiles.type.includes('image') ? jpg : pdf
                            }
                          />
                        </div>
                        <div>
                          <h6>{selectedFiles.name}</h6>
                          <p>{selectedFiles.size / 1024}kb </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {formLoading ? (
                  <FormLoader />
                ) : (
                  <div className='buttons'>
                    <button>Cancel</button>
                    <button onClick={() => checkStepOne()}>Edit</button>
                  </div>
                )}
              </div>
            ) : (
              <div className='exp-one'>
                <div className='success-card'>
                  <h3>Succesful</h3>
                  <img alt='' src={illustration} />
                  <p>Expense request updated.</p>
                </div>

                <div className='buttons'>
                  <button
                    onClick={() => navigate('/expense-approval/dashboard')}
                  >
                    Go To Request Dashboard
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div></div>
    </div>
  )
}

export default EditRequest
