const CoutriedData = [
  { name: 'Afghanistan', shortcode: 'AF' },
  { name: 'Albania', shortcode: 'AL' },
  { name: 'Algeria', shortcode: 'DZ' },
  { name: 'Andorra', shortcode: 'AD' },
  { name: 'Angola', shortcode: 'AO' },
  { name: 'Antigua and Barbuda', shortcode: 'AG' },
  { name: 'Argentina', shortcode: 'AR' },
  { name: 'Armenia', shortcode: 'AM' },
  { name: 'Australia', shortcode: 'AU' },
  { name: 'Austria', shortcode: 'AT' },
  { name: 'Azerbaijan', shortcode: 'AZ' },
  { name: 'Bahamas', shortcode: 'BS' },
  { name: 'Bahrain', shortcode: 'BH' },
  { name: 'Bangladesh', shortcode: 'BD' },
  { name: 'Barbados', shortcode: 'BB' },
  { name: 'Belarus', shortcode: 'BY' },
  { name: 'Belgium', shortcode: 'BE' },
  { name: 'Belize', shortcode: 'BZ' },
  { name: 'Benin', shortcode: 'BJ' },
  { name: 'Bhutan', shortcode: 'BT' },
  { name: 'Bolivia', shortcode: 'BO' },
  { name: 'Bosnia and Herzegovina', shortcode: 'BA' },
  { name: 'Botswana', shortcode: 'BW' },
  { name: 'Brazil', shortcode: 'BR' },
  { name: 'Brunei', shortcode: 'BN' },
  { name: 'Bulgaria', shortcode: 'BG' },
  { name: 'Burkina Faso', shortcode: 'BF' },
  { name: 'Burundi', shortcode: 'BI' },
  { name: 'Cabo Verde', shortcode: 'CV' },
  { name: 'Cambodia', shortcode: 'KH' },
  { name: 'Cameroon', shortcode: 'CM' },
  { name: 'Canada', shortcode: 'CA' },
  { name: 'Central African Republic', shortcode: 'CF' },
  { name: 'Chad', shortcode: 'TD' },
  { name: 'Chile', shortcode: 'CL' },
  { name: 'China', shortcode: 'CN' },
  { name: 'Colombia', shortcode: 'CO' },
  { name: 'Comoros', shortcode: 'KM' },
  { name: 'Congo, Democratic Republic of the', shortcode: 'CD' },
  { name: 'Congo, Republic of the', shortcode: 'CG' },
  { name: 'Costa Rica', shortcode: 'CR' },
  { name: "Cote d'Ivoire", shortcode: 'CI' },
  { name: 'Croatia', shortcode: 'HR' },
  { name: 'Cuba', shortcode: 'CU' },
  { name: 'Cyprus', shortcode: 'CY' },
  { name: 'Czech Republic', shortcode: 'CZ' },
  { name: 'Denmark', shortcode: 'DK' },
  { name: 'Djibouti', shortcode: 'DJ' },
  { name: 'Dominica', shortcode: 'DM' },
  { name: 'Dominican Republic', shortcode: 'DO' },
  { name: 'East Timor (Timor-Leste)', shortcode: 'TL' },
  { name: 'Ecuador', shortcode: 'EC' },
  { name: 'Egypt', shortcode: 'EG' },
  { name: 'El Salvador', shortcode: 'SV' },
  { name: 'Equatorial Guinea', shortcode: 'GQ' },
  { name: 'Eritrea', shortcode: 'ER' },
  { name: 'Estonia', shortcode: 'EE' },
  { name: 'Eswatini', shortcode: 'SZ' },
  { name: 'Ethiopia', shortcode: 'ET' },
  { name: 'Fiji', shortcode: 'FJ' },
  { name: 'Finland', shortcode: 'FI' },
  { name: 'France', shortcode: 'FR' },
  { name: 'Gabon', shortcode: 'GA' },
  { name: 'Gambia', shortcode: 'GM' },
  { name: 'Georgia', shortcode: 'GE' },
  { name: 'Germany', shortcode: 'DE' },
  { name: 'Ghana', shortcode: 'GH' },
  { name: 'Greece', shortcode: 'GR' },
  { name: 'Grenada', shortcode: 'GD' },
  { name: 'Guatemala', shortcode: 'GT' },
  { name: 'Guinea', shortcode: 'GN' },
  { name: 'Guinea-Bissau', shortcode: 'GW' },
  { name: 'Guyana', shortcode: 'GY' },
  { name: 'Haiti', shortcode: 'HT' },
  { name: 'Honduras', shortcode: 'HN' },
  { name: 'Hungary', shortcode: 'HU' },
  { name: 'Iceland', shortcode: 'IS' },
  { name: 'India', shortcode: 'IN' },
  { name: 'Indonesia', shortcode: 'ID' },
  { name: 'Iran', shortcode: 'IR' },
  { name: 'Iraq', shortcode: 'IQ' },
  { name: 'Ireland', shortcode: 'IE' },
  { name: 'Israel', shortcode: 'IL' },
  { name: 'Italy', shortcode: 'IT' },
  { name: 'Jamaica', shortcode: 'JM' },
  { name: 'Japan', shortcode: 'JP' },
  { name: 'Jordan', shortcode: 'JO' },
  { name: 'Kazakhstan', shortcode: 'KZ' },
  { name: 'Kenya', shortcode: 'KE' },
  { name: 'Kiribati', shortcode: 'KI' },
  { name: 'Korea, North', shortcode: 'KP' },
  { name: 'Korea, South', shortcode: 'KR' },
  { name: 'Kosovo', shortcode: 'XK' },
  { name: 'Kuwait', shortcode: 'KW' },
  { name: 'Kyrgyzstan', shortcode: 'KG' },
  { name: 'Laos', shortcode: 'LA' },
  { name: 'Latvia', shortcode: 'LV' },
  { name: 'Lebanon', shortcode: 'LB' },
  { name: 'Lesotho', shortcode: 'LS' },
  { name: 'Liberia', shortcode: 'LR' },
  { name: 'Libya', shortcode: 'LY' },
  { name: 'Liechtenstein', shortcode: 'LI' },
  { name: 'Lithuania', shortcode: 'LT' },
  { name: 'Luxembourg', shortcode: 'LU' },
  { name: 'Madagascar', shortcode: 'MG' },
  { name: 'Malawi', shortcode: 'MW' },
  { name: 'Malaysia', shortcode: 'MY' },
  { name: 'Maldives', shortcode: 'MV' },
  { name: 'Mali', shortcode: 'ML' },
  { name: 'Malta', shortcode: 'MT' },
  { name: 'Marshall Islands', shortcode: 'MH' },
  { name: 'Mauritania', shortcode: 'MR' },
  { name: 'Mauritius', shortcode: 'MU' },
  { name: 'Mexico', shortcode: 'MX' },
  { name: 'Micronesia', shortcode: 'FM' },
  { name: 'Moldova', shortcode: 'MD' },
  { name: 'Monaco', shortcode: 'MC' },
  { name: 'Mongolia', shortcode: 'MN' },
  { name: 'Montenegro', shortcode: 'ME' },
  { name: 'Morocco', shortcode: 'MA' },
  { name: 'Mozambique', shortcode: 'MZ' },
  { name: 'Myanmar (Burma)', shortcode: 'MM' },
  { name: 'Namibia', shortcode: 'NA' },
  { name: 'Nauru', shortcode: 'NR' },
  { name: 'Nepal', shortcode: 'NP' },
  { name: 'Netherlands', shortcode: 'NL' },
  { name: 'New Zealand', shortcode: 'NZ' },
  { name: 'Nicaragua', shortcode: 'NI' },
  { name: 'Niger', shortcode: 'NE' },
  { name: 'Nigeria', shortcode: 'NG' },
  { name: 'North Macedonia (formerly Macedonia)', shortcode: 'MK' },
  { name: 'Norway', shortcode: 'NO' },
  { name: 'Oman', shortcode: 'OM' },
  { name: 'Pakistan', shortcode: 'PK' },
  { name: 'Palau', shortcode: 'PW' },
  { name: 'Palestine', shortcode: 'PS' },
  { name: 'Panama', shortcode: 'PA' },
  { name: 'Papua New Guinea', shortcode: 'PG' },
  { name: 'Paraguay', shortcode: 'PY' },
  { name: 'Peru', shortcode: 'PE' },
  { name: 'Philippines', shortcode: 'PH' },
  { name: 'Poland', shortcode: 'PL' },
  { name: 'Portugal', shortcode: 'PT' },
  { name: 'Qatar', shortcode: 'QA' },
  { name: 'Romania', shortcode: 'RO' },
  { name: 'Russia', shortcode: 'RU' },
  { name: 'Rwanda', shortcode: 'RW' },
  { name: 'Saint Kitts and Nevis', shortcode: 'KN' },
  { name: 'Saint Lucia', shortcode: 'LC' },
  { name: 'Saint Vincent and the Grenadines', shortcode: 'VC' },
  { name: 'Samoa', shortcode: 'WS' },
  { name: 'San Marino', shortcode: 'SM' },
  { name: 'Sao Tome and Principe', shortcode: 'ST' },
  { name: 'Saudi Arabia', shortcode: 'SA' },
  { name: 'Senegal', shortcode: 'SN' },
  { name: 'Serbia', shortcode: 'RS' },
  { name: 'Seychelles', shortcode: 'SC' },
  { name: 'Sierra Leone', shortcode: 'SL' },
  { name: 'Singapore', shortcode: 'SG' },
  { name: 'Slovakia', shortcode: 'SK' },
  { name: 'Slovenia', shortcode: 'SI' },
  { name: 'Solomon Islands', shortcode: 'SB' },
  { name: 'Somalia', shortcode: 'SO' },
  { name: 'South Africa', shortcode: 'ZA' },
  { name: 'South Sudan', shortcode: 'SS' },
  { name: 'Spain', shortcode: 'ES' },
  { name: 'Sri Lanka', shortcode: 'LK' },
  { name: 'Sudan', shortcode: 'SD' },
  { name: 'Suriname', shortcode: 'SR' },
  { name: 'Sweden', shortcode: 'SE' },
  { name: 'Switzerland', shortcode: 'CH' },
  { name: 'Syria', shortcode: 'SY' },
  { name: 'Taiwan', shortcode: 'TW' },
  { name: 'Tajikistan', shortcode: 'TJ' },
  { name: 'Tanzania', shortcode: 'TZ' },
  { name: 'Thailand', shortcode: 'TH' },
  { name: 'Togo', shortcode: 'TG' },
  { name: 'Tonga', shortcode: 'TO' },
  { name: 'Trinidad and Tobago', shortcode: 'TT' },
  { name: 'Tunisia', shortcode: 'TN' },
  { name: 'Turkey', shortcode: 'TR' },
  { name: 'Turkmenistan', shortcode: 'TM' },
  { name: 'Tuvalu', shortcode: 'TV' },
  { name: 'Uganda', shortcode: 'UG' },
  { name: 'Ukraine', shortcode: 'UA' },
  { name: 'United Arab Emirates', shortcode: 'AE' },
  { name: 'United Kingdom', shortcode: 'GB' },
  { name: 'United States', shortcode: 'US' },
  { name: 'Uruguay', shortcode: 'UY' },
  { name: 'Uzbekistan', shortcode: 'UZ' },
  { name: 'Vanuatu', shortcode: 'VU' },
  { name: 'Vatican City', shortcode: 'VA' },
  { name: 'Venezuela', shortcode: 'VE' },
  { name: 'Vietnam', shortcode: 'VN' },
  { name: 'Yemen', shortcode: 'YE' },
  { name: 'Zambia', shortcode: 'ZM' },
  { name: 'Zimbabwe', shortcode: 'ZW' },
]

export default CoutriedData
