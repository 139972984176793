import React from 'react'
import './WhatsNew.scss'
import { FaTimes } from 'react-icons/fa'
import Logo from '../../../../Image/Logo3.png'
import DashImg from '../../../../Image/dashboard (1) 1.png'
import { useGlobalContext } from '../../../../Context/Context'

function Spendwise2() {
  const { toggleShowSpendwise20 } = useGlobalContext()
  return (
    <div className='whatsnew'>
      <div className='top'>
        <div className='left'>
          <img src={Logo} alt='' />
        </div>
        <div className='right'>
          <FaTimes onClick={toggleShowSpendwise20} />
        </div>
      </div>
      <div className='new-bodyt'>
        <div className='tag'>
          <h2>Spendwise 2.0! is Here</h2>
        </div>
        <p>
          Watch out for upcoming new page updates and product announcements
          starting with our redesigned dashboard.
        </p>
        <img src={DashImg} alt='' />
        <h3>Dashboard 2.0!</h3>
        <p className='pt'>
          You now have a more slicker, easier-to-navigate and more functional
          dashboard!
        </p>
      </div>
    </div>
  )
}

export default Spendwise2
