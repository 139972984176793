import React, { useState } from 'react'
import * as Sentry from '@sentry/react'
import './App.css'
import './Pages/Landinpage/Landingpage.scss'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Register from './Pages/Authentication/Register'
import Login from './Pages/Authentication/Login'
import Forgot from './Pages/Authentication/Forget'
import Dashboard from './Pages/Dashboard/Dashboard'
import Password from './Pages/Authentication/Password-reset'
import Username from './Pages/Authentication/Username'
import Verify from './Pages/Authentication/Verify-email'
import ProtectedRoute from './ProtectedRoute'
import { BankProvider } from './Pages/Dashboard/components/Finance/Finance'
import LandingPage from './Pages/Landinpage/LandingPage'
import LandingPage2 from './Pages/Landinpage/LandingPage2'
import Faq from './Pages/Faq/Faq'
import Scroll from './Scroll.js'
import Pricing from './Pages/Pricing/Pricing'
import Products from './Pages/Products/Products'
import Privacy from './Pages/Privacy/Privacy'
import Organizy from './Pages/Authentication/Organizy'
function App() {
  const [loggedIn, setLoggedIn] = useState(false)
  const [loading, setLoading] = useState(true)
  const [userObj, setUserObj] = useState({})
  const notify = (type, message) => {
    if (type === 'info') {
      toast.info(message)
    }
    if (type === 'success') {
      toast.success(message)
    }
    if (type === 'warn') {
      toast.warn(message)
    }
    if (type === 'error') {
      toast.error(message)
    }
  }
  const logUserIn = (user) => {
    sessionStorage.setItem('user', JSON.stringify(user))
    sessionStorage.setItem('userId', user.token)
    setUserObj(user)
    setLoggedIn(true)
    setLoading(false)
  }
  const logOut403 = () => {
    sessionStorage.clear()
    setUserObj('')
    notify(
      'warn',
      'Ooops! You have logged in on a different device. This session has expired.'
    )
    window.location.replace('/login')
  }
  const logOut = () => {
    sessionStorage.clear()
    setUserObj('')
    setLoggedIn(false)
  }
  return (
    <BrowserRouter>
      <ToastContainer
        position='top-center'
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Scroll />
      <Routes>
        <Route path='/*' element={<LandingPage2 />} />
        <Route path='/' element={<LandingPage2 />} />
        <Route path='/app' element={<LandingPage />} />
        <Route path='/pricing' element={<Pricing />} />
        <Route path='/product' element={<Products />} />
        <Route path='/faq' element={<Faq />} />
        <Route path='/privacy' element={<Privacy />} />
        <Route
          path='/login'
          element={<Login notify={notify} Login={logUserIn} />}
        />
        <Route path='/register' element={<Register notify={notify} />} />
        <Route path='/forget-password' element={<Forgot notify={notify} />} />
        <Route path='/forget-username' element={<Username notify={notify} />} />
        <Route
          path='/password-reset/:username'
          element={<Password notify={notify} />}
        />
        <Route
          path='/organization/:email/:token'
          element={<Organizy notify={notify} />}
        />
        <Route
          forceRefresh={true}
          path='/verify-email'
          element={<Verify notify={notify} user={userObj} loading={loading} />}
        />
        <Route
          exact
          element={<ProtectedRoute loggedIn={loggedIn} notify={notify} />}
        >
          <Route
            path='/:page'
            element={
              <BankProvider notify={notify}>
                <Dashboard
                  notify={notify}
                  logout={logOut}
                  logout2={logOut403}
                />
              </BankProvider>
            }
          />
          <Route
            path='/:page/:id'
            element={
              <BankProvider notify={notify}>
                <Dashboard
                  notify={notify}
                  logout={logOut}
                  logout2={logOut403}
                />
              </BankProvider>
            }
          />
          <Route
            path='/:page/:id/:new_id'
            element={
              <BankProvider notify={notify}>
                <Dashboard
                  notify={notify}
                  logout={logOut}
                  logout2={logOut403}
                />
              </BankProvider>
            }
          />
          <Route
            path='/:page/:id/:new_id/:fourth'
            element={
              <BankProvider notify={notify}>
                <Dashboard
                  notify={notify}
                  logout={logOut}
                  logout2={logOut403}
                />
              </BankProvider>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default Sentry.withProfiler(App)
