const CategoriesData = [
  {
    id: 2,
    Content: 'Airtime/Data',
  },
  {
    id: 22,
    Content: 'ATM Transaction',
  },
  {
    id: 14,
    Content: 'Banking Charges',
  },
  {
    id: 1,
    Content: 'Bills/Utilities',
  },
  {
    id: 15,
    Content: 'Business Expense',
  },
  {
    id: 26,
    Content: 'Consumables',
  },
  {
    id: 27,
    Content: 'Consultancy',
  },
  {
    id: 20,
    Content: 'Debt Payments',
  },
  {
    id: 3,
    Content: 'Education',
  },
  {
    id: 4,
    Content: 'Entertainment',
  },
  {
    id: 24,
    Content: 'Equipment',
  },
  {
    id: 13,
    Content: 'Family',
  },
  {
    id: 77,
    Content: 'Food/Drinks',
  },
  {
    id: 5,
    Content: 'Gifts/Donations',
  },
  {
    id: 12,
    Content: 'Household',
  },
  {
    id: 7,
    Content: 'Insurance',
  },
  {
    id: 19,
    Content: 'Interest',
  },
  {
    id: 8,
    Content: 'Investment/Savings',
  },
  {
    id: 6,
    Content: 'Medical/Healthcare',
  },
  {
    id: 23,
    Content: 'Payroll',
  },
  {
    id: 25,
    Content: 'Petty Cash',
  },
  {
    id: 18,
    Content: 'POS Payment',
  },
  {
    id: 9,
    Content: 'Shopping',
  },
  {
    id: 17,
    Content: 'Transfers',
  },
  {
    id: 11,
    Content: 'Transportation',
  },

  {
    id: 16,
    Content: 'Travel',
  },

  {
    id: 21,
    Content: 'Uncategorized',
  },
]
export default CategoriesData
