import React from 'react'
import { useNavigate } from 'react-router-dom'
import { FaSuitcase } from 'react-icons/fa'

function CBizHome() {
  const navigate = useNavigate()
  return (
    <div className='cbiz_home'>
      <div className='wrapper'>
        <div className='log-wra'>
          <div className='logo'>
            <FaSuitcase />
          </div>
        </div>
        <div className='text'>
          <h2>Activate Business Profile</h2>
          <p>
            This feature offers business owners with more advantages & tools to
            manage their businesses.
          </p>
          <h3>What type of business do you operate?</h3>
          <div className='buttons'>
            <button
              onClick={() => navigate('/activate-business/registered-business')}
            >
              Registered Business
            </button>
            <button
              onClick={() =>
                navigate('/activate-business/unregistered-business')
              }
            >
              Unregistered Business
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CBizHome
