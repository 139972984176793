import React, { useState, useRef, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import './Auth.scss'
import Logo from '../../Image/logo1.png'
import Man from '../../Image/man.png'
import Icon1 from '../../Image/Group1.png'
import Icon2 from '../../Image/Group2.png'
import Icon3 from '../../Image/Group3.png'
import Angle from '../../Image/angle.png'

function Password({ notify }) {
  const [shown, setShown] = useState(false)
  const [formLoading, setFormLoading] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm()
  const navigate = useNavigate()
  const password = useRef({})
  password.current = watch('password', '')
  const { username } = useParams()
  const onReset = (formData) => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    setFormLoading(true)

    fetch(`https://www.spendwise.ng/api/accounts/password_reset/`, {
      method: 'post',
      headers: { 'content-Type': 'application/json' },
      body: JSON.stringify({
        ...formData,
        username,
      }),
      redirect: 'follow',
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message === 'Password changed!.') {
          setFormLoading(false)
          setTimeout(() => {
            navigate(`/login`)
          }, 2000)
          notify('success', data.message)
        } else {
          // notify('error', data.message)
          notify('error', data.message)
          setFormLoading(false)
        }
      })
      .catch((err) => {
        notify('error', 'An error occured. Unable to reach server')
        setFormLoading(false)
      })
  }
  useEffect(() => {
    document.title = 'Spendwise | Password Reset'
  }, [])
  return (
    <>
      <section className='auth'>
        <div className='logo'>
          <img src={Logo} alt='' onClick={() => navigate('/')} />
        </div>
        <div className='wrap'>
          <div className='left'>
            <div className='icons'>
              <div className='can'>
                <img src={Icon1} alt='' />
              </div>
              <div className='can edit'>
                <img src={Icon2} alt='' />
              </div>
              <div className='can'>
                <img src={Icon3} alt='' />
              </div>
            </div>
            <div className='man'>
              <img src={Man} alt='' />
            </div>
          </div>
          <div className='right'>
            <div className='angle'>
              <div>
                <img src={Angle} alt='' />
              </div>
            </div>
            <div className='form'>
              <div className='head'>
                <h1>Password Reset</h1>
              </div>
              <form className='auth-form' onSubmit={handleSubmit(onReset)}>
                <div className='card'>
                  <p>OTP Code</p>
                  <input
                    type='number'
                    inputMode='numeric'
                    autocomplete='off'
                    name='otp'
                    {...register('otp', {
                      required: 'OTP number is required',
                    })}
                    id=''
                  />
                  {errors.otp && (
                    <h6 className='vError'>{errors.otp.message}</h6>
                  )}
                </div>
                <div className='card password'>
                  <p>New Password</p>
                  <div>
                    <input
                      type={shown ? 'text' : 'password'}
                      name='password'
                      autocomplete='off'
                      {...register('password', {
                        required: 'Provide a password',
                        minLength: {
                          value: 7,
                          message: 'Password must have at least 8 characters',
                        },
                        pattern: {
                          value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{7,}$/,
                          message:
                            'Passwords have to contain at least one uppercase and one lower case character and a number',
                        },
                      })}
                    />
                    <p onClick={() => setShown(!shown)}>
                      {shown ? <span>hide</span> : <span>show</span>}
                    </p>
                  </div>
                  {errors.password && (
                    <h6 className='vError'>{errors.password.message}</h6>
                  )}
                </div>
                <div className='card password'>
                  <p>Confirm New Password</p>
                  <div>
                    <input
                      type={shown ? 'text' : 'password'}
                      name='confirm_password'
                      autocomplete='off'
                      {...register('confirm_password', {
                        required: 'You must re-enter your password',
                        validate: (value) =>
                          value === password.current ||
                          'The passwords do not match',
                      })}
                    />
                    <p onClick={() => setShown(!shown)}>
                      {shown ? <span>hide</span> : <span>show</span>}
                    </p>
                  </div>
                  {errors.password2 && (
                    <h6 className='vError'>{errors.password2.message}</h6>
                  )}
                </div>
                <div className='card'>
                  <button
                    type='submit'
                    value={formLoading ? '.....' : 'SUBMIT'}
                  >
                    {formLoading ? '.....' : ' Reset password'}
                  </button>
                </div>
                <div className='card edit'>
                  <p>
                    Remember your password? <Link to='/login'>Login</Link>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Password
