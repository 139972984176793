import React, { useState } from 'react'
import VerveLogo from '../../../../../Image/vereve3.png'
import Logo from '../../../../../Image/cardlogo.png'
import Logo2 from '../../../../../Image/cardlogo2.png'

function CardBox({ item, navigate }) {
  return (
    <div
      className='card-box'
      key={item.card_id}
      onClick={() => navigate(`/sub-wallet/edit/${item.card_id}`)}
    >
      <div className='card-box_top'>
        <h2>{item.alias} </h2>
      </div>
      <div className='credit-card-body'>
        <div className='credit-card-body-top'>
          <img src={Logo} alt='' />
          {/* <p>
            {' '}
            ₦
            {item.balance
              .toLocaleString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          </p> */}
        </div>
        <div className='card-number'>
          <p>{item.masked_pan.slice(0, 4)}</p>
          <p>{item.masked_pan.slice(4, 8)}</p>
          <p>{item.masked_pan.slice(8, 12)}</p>
          <p>{item.masked_pan.slice(12, 16)}</p>
        </div>
        <div className='credit-card-body-bottom'>
          <div>
            <p>Card Holder</p>
            <h2>{item.alias}</h2>
          </div>
          <div>
            <p>Exp</p>
            <h2>
              {item.expiry_month}/{item.expiry_year}
            </h2>
          </div>
          <div className='vlogo'>
            <img src={VerveLogo} alt='' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CardBox
