import React, { useState } from 'react'
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai'

function QuestionTab({ item }) {
  const [click, setClick] = useState('false')

  return (
    <div className='faq-tabs'>
      <div
        className={!click ? 'tab-head active' : 'tab-head'}
        onClick={() => setClick(!click)}
      >
        <h1>{item.title}</h1>
        {!click ? <AiOutlineMinus /> : <AiOutlinePlus />}
      </div>
      {!click && <div className='tab-body'>{item.content}</div>}
    </div>
  )
}

export default QuestionTab
