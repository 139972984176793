import React, { useEffect, useState } from 'react'
import { useBankContext } from '../Finance/Finance'
import './FinanceModal.scss'
import { AiOutlineClose } from 'react-icons/ai'
import { FaLongArrowAltDown, FaLongArrowAltUp } from 'react-icons/fa'
import { BsThreeDotsVertical } from 'react-icons/bs'
const authToken = sessionStorage.getItem('userId')

function FinanceModal() {
  const {
    toggleFinance,
    foundFinance,
    financeFound,
    notify,
    bankTransactions,
    bankTransactionsSeen,
  } = useBankContext()

  return financeFound ? (
    <div className='financemodal'>
      <div className='close'>
        <AiOutlineClose onClick={toggleFinance} />
      </div>
      <div className='body'>
        <div className='fin-row'>
          <div className='fin-cards'>
            <div className='circle1'></div>
            <div className='circle2'></div>
            <div className='bank top'>
              {foundFinance.account_type !== 'SpendWise Wallet' ? (
                <div className='bank-name'>
                  <p>{foundFinance.bank_name}</p>

                  <span>{foundFinance.account_type}</span>
                </div>
              ) : (
                <div className='bank-name'>
                  <p>{foundFinance.account_type}</p>
                </div>
              )}
              <div className='month'>
                <p>{foundFinance.month}</p>
              </div>
            </div>
            <div className='bank'>
              <div className='balance'>
                <p>Balance</p>
                <span>&#8358;{foundFinance.balance.toLocaleString()} </span>
                <p className='asat'>as at: {foundFinance.as_at}</p>
              </div>
              <div className='dot'>
                <BsThreeDotsVertical />
              </div>
            </div>
            <div className='flow'>
              <div className='flow-sign'>
                <div className='sign'>
                  <span>
                    <FaLongArrowAltDown />{' '}
                  </span>
                  <p>Inflow</p>
                </div>

                <p className='mon'>
                  {' '}
                  &#8358;{foundFinance.inflows.toLocaleString()}
                </p>
              </div>
              <div className='flow-sign'>
                <div className='sign'>
                  <span>
                    <FaLongArrowAltUp />{' '}
                  </span>{' '}
                  <p>Outflow</p>
                </div>
                <p className='mon'>
                  {' '}
                  &#8358;{foundFinance.outflows.toLocaleString()}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='activty'>
        <h2>Your Activities</h2>
        <select name='' id=''>
          <option value=''>All</option>
        </select>
      </div>
      <div className='table-wrap'>
        {bankTransactionsSeen ? (
          <table>
            <thead>
              <tr>
                <th>Narration</th>
                <th>Date/Time</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {bankTransactions.map((item) => (
                <tr key={item.id}>
                  <td>{item.tx_description}</td>
                  <td>{new Date(item.tx_date).toLocaleString('en-US')}</td>
                  <td>
                    ₦
                    {item.tx_amt_original
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <h1>Transactions not found</h1>
        )}
      </div>
    </div>
  ) : (
    <h1>Loading</h1>
  )
}

export default FinanceModal
