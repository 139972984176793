import React, { useState } from 'react'
import { BsFileEarmarkPlus } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'
import Loader from '../../../../../Image/loder.gif.gif'

function CBizUnReg({ notify }) {
  const authToken = sessionStorage.getItem('userId')
  const navigate = useNavigate()
  const [Loading, setLoading] = useState(false)
  const [dIdImage, setDIdImage] = useState({
    string: 'Upload valid NIN, driver’s license or int’l passport',
    file: null,
  })
  const [utilityBillType, setUtilityBillType] = useState({
    string: 'Ultility bill image',
    file: null,
  })
  const [businessLogo, setBusinessLogo] = useState({
    string: 'Upload business logo',
    file: null,
  })
  const [businessName, setBusinessName] = useState('')
  const [iDType, setIDType] = useState('')
  const [iDNumber, setiDNumber] = useState('')
  const [directorName, setDirectorName] = useState('')
  const [utilityType, setUtilityType] = useState('')
  const onSelectDIdImage = (e) => {
    const file = e.target.files[0]
    const reader = new FileReader()
    reader.readAsDataURL(file)
    if (file.size > 1000000) {
      notify('error', 'ID image must be below 1mb')
    } else {
      reader.onloadend = () => {
        setDIdImage({
          string: file.name,
          file,
        })
      }
    }
  }
  const onSelectUtilityBillType = (e) => {
    const file = e.target.files[0]
    const reader = new FileReader()
    reader.readAsDataURL(file)
    if (file.size > 1000000) {
      notify('error', 'Utility Bill must be below 1mb')
    } else {
      reader.onloadend = () => {
        setUtilityBillType({
          string: file.name,
          file,
        })
      }
    }
  }
  const onSelectBusinessLogo = (e) => {
    const file = e.target.files[0]
    const reader = new FileReader()
    reader.readAsDataURL(file)
    if (file.size > 1000000) {
      notify('error', 'Utility Bill must be below 1mb')
    } else {
      reader.onloadend = () => {
        setBusinessLogo({
          string: file.name,
          file,
        })
      }
    }
  }
  const onSubmitRegistration = (e) => {
    e.preventDefault()
    setLoading(true)
    const form_data = new FormData()
    form_data.append('company_logo', businessLogo.file)
    form_data.append('director_id_image', dIdImage.file)
    form_data.append('utility_bill_image', utilityBillType.file)
    form_data.append('business_name', businessName)
    form_data.append('director_id_type', iDType)
    form_data.append('director_id_no', iDNumber)
    form_data.append('utility_bill_type', utilityType)
    form_data.append('director_name', directorName)
    form_data.append('registered', false)
    fetch(`${process.env.REACT_APP_API_URL}/accounts/register_business/`, {
      method: 'post',
      headers: {
        Authorization: `Token ${authToken}`,
      },
      body: form_data,
      redirect: 'follow',
    })
      .then((res) => {
        if (res.status === 200) {
          setLoading(false)
          res.json().then((data) => {
            notify('success', data.message)
            setTimeout(() => {
              window.location.replace('/home')
            }, 5000)
          })
        } else {
          res.json().then((data) => {
            notify('error', data.message)
            setLoading(false)
          })
        }
      })
      .catch((err) => {
        notify('error', err.message)
        setLoading(false)
      })
  }
  return (
    <div className='cbiz_form'>
      <div className='headf'>
        <h1>Business Profile</h1>
        <p>Create a business profile for additional benefits.</p>
      </div>
      <div className='form'>
        <div className='form_top'>
          <h1>Unregistered Business Documents</h1>
          <button
            onClick={() => navigate('/activate-business/registered-business')}
          >
            Registered Business
          </button>
        </div>
        <form className='form_body' onSubmit={onSubmitRegistration}>
          <div className='form_card'>
            <p className='label'>
              Business Logo
              <span style={{ color: '#d00000', fontSize: '20px' }}>*</span>
            </p>
            <input
              type='file'
              name=''
              id='business_logo'
              className='file'
              accept='image/*'
              onChange={onSelectBusinessLogo}
              required
            />
            <label htmlFor='business_logo'>
              <div className='input_act'>
                <p>{businessLogo.string}</p>
                <BsFileEarmarkPlus />
              </div>
            </label>
          </div>
          <div className='form_card'>
            <p className='label'>
              Business name{' '}
              <span style={{ color: '#d00000', fontSize: '20px' }}>*</span>
            </p>
            <input
              type='text'
              name=''
              id=''
              placeholder='Enter business name'
              onChange={(e) => setBusinessName(e.target.value)}
              required
            />
          </div>
          <div className='form_card'>
            <p className='label'>
              Full name of business owner{' '}
              <span style={{ color: '#d00000', fontSize: '20px' }}>*</span>
            </p>
            <input
              type='text'
              name=''
              id=''
              placeholder='Enter business owner full name'
              onChange={(e) => setDirectorName(e.target.value)}
              required
            />
          </div>
          <div className='form_card'>
            <p className='label'>
              Business owner ID type
              <span style={{ color: '#d00000', fontSize: '20px' }}>*</span>
            </p>
            <select
              name=''
              id=''
              onChange={(e) => setIDType(e.target.value)}
              required
            >
              <option value=''>select</option>
              <option value='Drivers license'>Driver's license</option>
              <option value='International Passport'>
                International Passport
              </option>
              <option value='NIN'>NIN</option>
            </select>
          </div>
          <div className='form_card'>
            <p className='label'>
              Business owner valid ID card{' '}
              <span style={{ color: '#d00000', fontSize: '20px' }}>*</span>
            </p>
            <input
              type='file'
              name=''
              id='dIdImage'
              className='file'
              accept='image/*'
              onChange={onSelectDIdImage}
              required
            />
            <label htmlFor='dIdImage'>
              <div className='input_act'>
                <p>{dIdImage.string}</p>
                <BsFileEarmarkPlus />
              </div>
            </label>
          </div>
          <div className='form_card'>
            <p className='label'>
              Business owner valid ID card number{' '}
              <span style={{ color: '#d00000', fontSize: '20px' }}>*</span>
            </p>
            <input
              type={iDType === 'NIN' ? 'number' : 'text'}
              name=''
              id=''
              placeholder='Enter ID card number'
              onChange={(e) => setiDNumber(e.target.value)}
              required
            />
          </div>
          <div className='form_card'>
            <p className='label'>
              Ultility bill type
              <span style={{ color: '#d00000', fontSize: '20px' }}>*</span>
            </p>
            <select
              name=''
              id=''
              required
              onChange={(e) => setUtilityType(e.target.value)}
            >
              <option value=''>select</option>
              <option value='Electricity Bill'>Electricity Bill</option>
              <option value='Waste Bill'>Waste Bill</option>
              <option value='Water Bill'>Water Bill</option>
            </select>
          </div>
          <div className='form_card'>
            <p className='label'>
              Ultility bill image
              <span style={{ color: '#d00000', fontSize: '20px' }}>*</span>
            </p>
            <input
              type='file'
              name=''
              id='utilityBillType'
              className='file'
              accept='image/*'
              onChange={onSelectUtilityBillType}
              required
            />
            <label htmlFor='utilityBillType'>
              <div className='input_act'>
                <p>{utilityBillType.string}</p>
                <BsFileEarmarkPlus />
              </div>
            </label>
          </div>
          {Loading ? (
            <div className='loader'>
              <img src={Loader} alt='' />
            </div>
          ) : (
            <div className='buttons'>
              <button
                className='delete'
                onClick={(e) => {
                  e.preventDefault()
                  navigate('/activate-business/home')
                }}
              >
                Back
              </button>
              <button className='fund'>Submit</button>
            </div>
          )}
        </form>
      </div>
    </div>
  )
}

export default CBizUnReg
