import React, { useState } from 'react'
import { useGlobalContext } from '../../../../Context/Context'
import { useForm, Controller } from 'react-hook-form'
import { AiOutlineClose } from 'react-icons/ai'
import './Createbudget.scss'
import CategoriesData from '../../../../Data/CategoriesData'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

const authToken = sessionStorage.getItem('userId')

function CreateBudget({ notify }) {
  const [formLoading, setFormLoading] = useState(false)
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm()

  const onCreate = (formData) => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    setFormLoading(true)

    function convert(str) {
      var date = new Date(str),
        mnth = ('0' + (date.getMonth() + 1)).slice(-2),
        day = ('0' + date.getDate()).slice(-2)
      return [date.getFullYear(), mnth, day].join('-')
    }
    const startDate = convert(formData.start_date)
    const endDate = convert(formData.end_date)

    fetch(`${process.env.REACT_APP_API_URL}/budget/create/`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
      body: JSON.stringify({
        ...formData,
        start_date: startDate,
        end_date: endDate,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message === 'Budget successfully created!') {
          notify('success', data.message)
          setFormLoading(false)
          setTimeout(() => {
            window.location.reload()
          }, 2000)
        } else {
          notify('error', data.message)
          setFormLoading(false)
        }
      })
      .catch((err) => {})
  }

  const { toggleBudget } = useGlobalContext()
  const my_date = new Date()
  const first_date = new Date(my_date.getFullYear(), my_date.getMonth(), 1)
  const last_date = new Date(my_date.getFullYear(), my_date.getMonth() + 1, 0)
  return (
    <div className='createbudget'>
      <div className='close'>
        <AiOutlineClose onClick={toggleBudget} />
      </div>
      <div className='text'>
        <h2>Create Budget</h2>
        <p>Create budget and set time frame</p>
      </div>
      <form onSubmit={handleSubmit(onCreate)}>
        <div className='card'>
          <p>Select a category</p>
          <select
            type='text'
            list='category'
            name='category'
            {...register('category', {
              required: 'Category is required',
            })}
          >
            {CategoriesData.map((item) => (
              <option value={item.Content} key={item.id}>
                {item.Content}
              </option>
            ))}
          </select>
          {errors.category && (
            <h6 className='vError'>{errors.category.message}</h6>
          )}
        </div>
        <div className='card'>
          <p>Budget amount</p>
          <input
            type='number'
            name='budget_amount'
            id=''
            {...register('budget_amount', {
              required: 'Amount is required',
            })}
          />
          {errors.budget_amount && (
            <h6 className='vError'>{errors.budget_amount.message}</h6>
          )}
        </div>
        <div className='card'>
          <p>Select start date</p>
          <Controller
            control={control}
            name='start_date'
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <DatePicker
                onChange={onChange}
                onBlur={onBlur}
                selected={value}
                minDate={first_date}
                maxDate={last_date}
                showMonthDropdown
                showYearDropdown
                dropdownMode='select'
              />
            )}
          />
        </div>
        <div className='card'>
          <p>Select end date</p>
          <Controller
            control={control}
            name='end_date'
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <DatePicker
                onChange={onChange}
                onBlur={onBlur}
                selected={value}
                minDate={first_date}
                maxDate={last_date}
                showMonthDropdown
                showYearDropdown
                dropdownMode='select'
              />
            )}
          />
        </div>
        <div className='button'>
          <button type='submit' className='create'>
            {formLoading ? '.....' : 'Create'}
          </button>
        </div>
      </form>
    </div>
  )
}

export default CreateBudget
