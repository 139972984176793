import React from 'react'
// import './select.scss'

function SelectComponent({ options, placeholder, setValue, value, extra }) {
  return (
    <div className='select-container'>
      <select
        value={value}
        onChange={(e) => {
          setValue(e.target.value)
          if (extra) {
            extra()
          }
        }}
      >
        {placeholder && <option value=''>{placeholder}</option>}
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  )
}

export default SelectComponent
