import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import Logo2 from '../../../../../Image/cardlogo2.png'
import VerveLogo3 from '../../../../../Image/masterlogo.png'
import Successful from '../../../../../Image/Successful.png'
import API from '../../../../../Context/API'
import { FaChevronLeft, FaLastfmSquare } from 'react-icons/fa'
import { PaystackButton } from 'react-paystack'
import Loader from '../../../../../Image/loder.gif.gif'

const cardsList = [
  {
    id: 1,
    name: 'Virtual usd card',
    type: 'USD',
    amount: 340,
    card_number: 'Request for virtual USD card',
    currency: 'usd',
    card_type: 'virtual',
    limit: 250,
  },
  {
    id: 2,
    name: 'Virtual naira card',
    type: 'NGN',
    amount: 11690,
    card_number: 'Request for virtual Naira card',
    currency: 'ngn',
    card_type: 'virtual',
  },
  // {
  //   id: 3,
  //   name: 'Physical naira card',
  //   type: 'physical',
  //   card_number: 'Request for physical Naira card',
  //   valid: '07/25',
  //   currency: 'Naira',
  //   card_type: 'physical',
  // },
]
function Request({ notify, user }) {
  const publicKey = process.env.REACT_APP_PAYSTACK_PUBLIC_KEY
  const authToken = sessionStorage.getItem('userId')
  const navigate = useNavigate()
  const [cardRequestStage, setCardRequestStage] = useState('sudo')
  const [SelectedCard, setSelectedCard] = useState({
    name: '',
    type: '',
    amount: 0,
    card_number: '',
    card_type: '',
    currency: '',
  })
  const [dollarRate, setdollarRate] = useState(0)
  const [creditAmmount, setCreditAmmount] = useState(0)
  const [cardName, setCardName] = useState('')
  const [confirmed, setConfirmed] = useState(false)
  const [confirmedDetails, setConfirmedDetails] = useState({
    message: '',
    narration: '',
    total_bill_naira: 0,
  })
  const [reqLoading, setReqLoading] = useState(false)
  const [sudoDetails, setSudoDetails] = useState({})
  useEffect(() => {
    document.title = 'Spendwise | Virtual card request'
  }, [])

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/cards/usd_exchange_rate/`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
      body: JSON.stringify({
        issuer: 'sudo',
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setdollarRate(data.rate.funding_rate)
          })
        } else {
          res.json().then((data) => {})
        }
      })
      .catch(() => {
        notify('error', 'Internal server errorr, Contact support.')
      })
  }, [])
  useEffect(() => {
    API.FetchSudoAccount()
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setSudoDetails(
              data.wallet_data.find(
                (item) => item.wallet_type === 'main-wallet'
              )
            )
          })
        } else {
          res.json().then((data) => {})
        }
      })
      .catch(() => {})
  }, [])
  const completePurchase = () => {
    setReqLoading(true)

    fetch(
      `https://y18x0oais3.execute-api.eu-west-1.amazonaws.com/prod/card/create_card`,
      {
        method: 'post',
        headers: {
          'content-Type': 'application/json',
          Authorization: `Token ${authToken}`,
        },
        body: JSON.stringify({
          alias: cardName,
          currency: 'USD',
          amount: parseInt(creditAmmount),
          wallet_id: sudoDetails.account_id,
          issuer: 'sudo',
        }),
      }
    )
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            notify('success', data.message)
            setCardRequestStage('four')
            setReqLoading(false)
          })
        } else {
          res.json().then((data) => {
            notify('error', data.message)
            setReqLoading(false)
          })
        }
      })
      .catch(() => {
        notify('error', 'Internal server errorr, Contact support.')
      })
  }
  const confirmTotalBillsSudo = () => {
    fetch(`${process.env.REACT_APP_API_URL}/cards/total_bill/`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
      body: JSON.stringify({
        alias: cardName,
        event: 'card creation',
        currency: 'USD',
        amount: parseInt(creditAmmount),
        issuer: 'sudo',
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setConfirmed(true)
            setConfirmedDetails(data)
          })
        } else {
          res.json().then((data) => {})
        }
      })
      .catch(() => {
        notify('error', 'Internal server errorr, Contact support.')
      })
  }
  return (
    <div className='virtual_cards-wrap'>
      <div className='cover-wrapper'>
        <div className='back-but'>
          <p onClick={() => navigate(-1)}>
            <FaChevronLeft /> Back
          </p>
        </div>

        {cardRequestStage === 'sudo' && (
          <div className='step-two'>
            {reqLoading && (
              <div className='loade'>
                <img src={Loader} alt='loading...' />
              </div>
            )}
            <div className='card-box'>
              <div className='credit-card-body' id='USD'>
                <div className='credit-card-body-top'>
                  <img src={Logo2} alt='' />
                </div>

                <div className='credit-card-body-bottom'>
                  <div>
                    <p>Card Holder</p>
                  </div>
                  <img src={VerveLogo3} alt='' />
                </div>
              </div>
              <h2>Virtual usd card</h2>
              <h3>Amount to Credit</h3>
              <p className='fee'>
                How much will you like to fund the card with in {''}
                <span>USD</span>.
              </p>
              <>
                <p className='dollar'>
                  Amount to pay in naira (₦{dollarRate} / $1){' '}
                  <span>₦{(creditAmmount * dollarRate).toLocaleString()}</span>
                </p>
              </>
              {confirmed && (
                // <p className='dollar'>
                //   Total amount plus charges:{' '}
                //   <span>
                //     ₦{confirmedDetails.total_bill_naira.toLocaleString()}
                //   </span>
                // </p>
                <p
                  className='dollar'
                  style={{
                    color: '#fe8b00',
                    wordSpacing: '3px',
                    lineHeight: '25px',
                    letterSpacing: '1px',
                  }}
                >
                  {confirmedDetails.narration}
                </p>
              )}
              <div className='form-group'>
                <input
                  type='tel'
                  name='number'
                  className='form-control'
                  placeholder={`Amount in USD`}
                  max={250}
                  onChange={(e) => {
                    setCreditAmmount(e.target.value)
                  }}
                  required
                  disabled={confirmed}
                />
              </div>
              <div className='form-group'>
                <input
                  type='text'
                  name='alias'
                  className='form-control'
                  placeholder='Card name'
                  maxLength={30}
                  onChange={(e) => setCardName(e.target.value)}
                  disabled={confirmed}
                  required
                />
              </div>

              <>
                <>
                  {creditAmmount > 10000 || creditAmmount < 5 ? (
                    <p>
                      Amount is not allowed (amount must be above $5 and below
                      $10,000)
                    </p>
                  ) : (
                    // <PaystackButton
                    //   className='paystack-button'
                    //   {...componentProps}
                    // />
                    <>
                      {confirmed ? (
                        <>
                          <button
                            onClick={() => {
                              setConfirmed(false)
                            }}
                          >
                            Edit
                          </button>
                          <button
                            onClick={() => {
                              completePurchase()
                            }}
                          >
                            Pay Now
                          </button>
                          <button
                            onClick={() => {
                              if (user.is_business) {
                                navigate('/e-wallet/secure')
                              } else {
                                navigate('/e-wallet/dashboard')
                              }
                            }}
                            style={{ opacity: '0.7' }}
                          >
                            Go to wallet
                          </button>
                        </>
                      ) : (
                        <button
                          onClick={() => {
                            confirmTotalBillsSudo()
                          }}
                        >
                          Confirm
                        </button>
                      )}
                    </>
                  )}
                </>
                {/* <PaystackButton
                  className='paystack-button'
                  {...componentProps}
                /> */}
              </>
            </div>
          </div>
        )}
        {cardRequestStage === 'four' && (
          <div className='step-two'>
            <div className='card-box'>
              <div className='credit-card-body' id='USD'>
                <div className='credit-card-body-top'>
                  <img src={Logo2} alt='' />
                </div>
                <div className='che'>
                  <img src={Successful} className='check' alt='' />
                </div>
                <div className='credit-card-body-bottom'>
                  <div>
                    <p>Card Holder</p>
                  </div>
                  <img src={VerveLogo3} alt='' />
                </div>
              </div>
              <h2>{cardName}</h2>
              <h3>Your request was successful!</h3>
              <p className='fee'>
                Your Virtual <span>USD</span> Card, will be available within 24
                Hours. You will be alerted when the card is created and
                credited.
              </p>
              <button
                onClick={() => window.location.replace('/debit-card/usd-card')}
              >
                Done
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Request
