import React, { useState } from 'react'
import './Occupation.scss'
import Loader from '../../../../Image/loder.gif.gif'
import PinInput from 'react-pin-input'

function MagicPin({ notify }) {
  const authToken = sessionStorage.getItem('userId')
  const [occupation, setOccupation] = useState('')
  const [purpose, setPurpose] = useState('')
  const [loading, setLoading] = useState(false)
  const [pin, setPin] = useState('')
  const [newPin, setNewPin] = useState('')
  const Submit = () => {
    setLoading(true)
    if (newPin.length !== 4) {
      notify('warn', 'Please fill in the Pins correctly.')
      setLoading(false)
    } else {
      fetch('https://www.spendwise.ng/api/purse/create_or_update_pin/', {
        method: 'post',
        headers: {
          'content-Type': 'application/json',
          Authorization: `Token ${authToken}`,
        },
        body: JSON.stringify({
          new_pin: pin,
          confirm_pin: newPin,
          action: 'create',
        }),
      }).then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            notify('success', data.message)
            setTimeout(() => {
              window.location.reload()
            }, 2000)
            setLoading(false)
          })
        } else {
          res.json().then((data) => {
            notify('error', data.message)
            setLoading(false)
          })
        }
      })
    }
  }
  return (
    <div className='occupation'>
      <div className='top'>
        <h2>Create a Transaction Pin</h2>
      </div>
      <div className='form'>
        <div className='form-wrapper'>
          <div className='card'>
            <p>New Pin:</p>
            <PinInput
              length={4}
              initialValue=''
              secret
              type='numeric'
              inputMode='numeric'
              style={{ padding: '10px' }}
              inputStyle={{
                borderColor: '#e6e6e6',
                borderWidth: '3px',
                borderStyle: 'solid',
                background: '#f1f1f1',
                fontSize: ' 3rem',
                borderRadius: '20px',
                height: '70px',
                width: '70px',
                margin: '0 5px',
              }}
              inputFocusStyle={{ borderColor: 'blue' }}
              onComplete={(value, index) => {
                setPin(value)
              }}
              autoSelect={true}
              regexCriteria={/^[0-9]*$/}
            />
          </div>
          <div className='card'>
            <p>Confirm Pin:</p>
            <PinInput
              length={4}
              initialValue=''
              secret
              type='numeric'
              inputMode='numeric'
              style={{ padding: '10px' }}
              inputStyle={{
                borderColor: '#e6e6e6',
                borderWidth: '3px',
                borderStyle: 'solid',
                background: '#f1f1f1',
                fontSize: ' 3rem',
                borderRadius: '20px',
                height: '70px',
                width: '70px',
                margin: '0 5px',
              }}
              inputFocusStyle={{ borderColor: 'blue' }}
              onComplete={(value, index) => {
                setNewPin(value)
              }}
              autoSelect={true}
              regexCriteria={/^[0-9]*$/}
            />
          </div>

          <div className='button'>
            {loading ? (
              <img src={Loader} />
            ) : (
              <button onClick={Submit}>Create Magic Pin</button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default MagicPin
