import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import SDashboard from './components/SDashboard'
import SubwalletBoard from './components/SubwalletBoard'
import API from '../../../../Context/API'
import Loader from '../../../../Image/loder.gif.gif'
import './SubWallet.scss'
import EditCard from './components/EditCard'

function SubWallet({ notify }) {
  const { id } = useParams()
  const navigate = useNavigate()
  const [sudoCardsList, setSudoCardsList] = useState([])
  const [loading, setLoading] = useState(true)
  const [walletList, setWalletList] = useState([])
  useEffect(() => {
    API.FetchSudoCards()
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setSudoCardsList(data.card_data)
          })
        } else {
          res.json().then((data) => {})
        }
      })
      .catch(() => {})
  }, [])
  useEffect(() => {
    document.title = 'Spendwise | Sub Wallet'
  }, [])
  useEffect(() => {
    API.FetchSudoAccount()
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setWalletList(data.wallet_data)
            setLoading(false)
          })
        } else {
          res.json().then((data) => {
            setLoading(false)
          })
        }
      })
      .catch(() => {})
  }, [])
  const SubWPage = () => {
    switch (id) {
      case 'dashboard':
        return <SDashboard walletList={walletList} notify={notify} />
      case 'wallet':
        return <SubwalletBoard sudoCardsList={sudoCardsList} notify={notify} />
      case 'edit':
        return <EditCard sudoCardsList={sudoCardsList} notify={notify} />

      default:
        break
    }
  }
  return loading ? (
    <div className='vloader'>
      <img src={Loader} />
    </div>
  ) : (
    <div className='subWallet'>{SubWPage()}</div>
  )
}

export default SubWallet
