import React, { useState } from 'react'
import './Occupation.scss'
import Loader from '../../../../Image/loder.gif.gif'

function Occupation({ notify }) {
  const authToken = sessionStorage.getItem('userId')
  const industry = [
    {
      name: 'Fashion',
    },
    {
      name: 'Banking',
    },
    {
      name: 'Media',
    },
    {
      name: 'Real Estate',
    },
    {
      name: 'Computer Software/Engineering',
    },
    {
      name: 'Civil Servant',
    },
    {
      name: 'Education Management',
    },
    {
      name: 'Financial Services',
    },
    {
      name: 'Hospitality',
    },
    {
      name: 'Human Resources/HR',
    },
    {
      name: 'Logistics/Transportation',
    },
    {
      name: 'Marketing/Advertising/Sales',
    },
    {
      name: 'Medical field',
    },
    {
      name: 'Music / Art',
    },
    {
      name: 'Student',
    },
  ]
  const [occupation, setOccupation] = useState('')
  const [purpose, setPurpose] = useState('')
  const [loading, setLoading] = useState(false)
  const Submit = () => {
    setLoading(true)
    if (occupation.length < 5) {
      notify('warn', 'Please confirm that you have selected all the fields.')
      setLoading(false)
    } else {
      fetch('https://www.spendwise.ng/api/accounts/profile_works/', {
        method: 'post',
        headers: {
          'content-Type': 'application/json',
          Authorization: `Token ${authToken}`,
        },
        body: JSON.stringify({
          occupation: occupation,
          purpose: purpose,
        }),
      }).then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            notify('success', data.message)
            window.location.reload()
            setLoading(false)
          })
        } else {
          res.json().then((data) => {
            notify('error', data.message)
            setLoading(false)
          })
        }
      })
    }
  }
  return (
    <div className='occupation'>
      <div className='top'>
        <h2>Let’s Know You Better!</h2>
      </div>
      <div className='form'>
        <div className='form-wrapper'>
          <div className='card'>
            <p>Occupation/Industry:</p>
            <select
              name=''
              id=''
              onChange={(e) => setOccupation(e.target.value)}
            >
              <option value=''>Select occupation type</option>
              <option value='Entrepreneur'>Entrepreneur</option>
              {industry
                .sort((a, b) => a.name - b.name)
                .map((item) => (
                  <option value={item.name} key={item.name}>
                    {item.name}
                  </option>
                ))}
              <option value='Others'>Others</option>
            </select>
          </div>
          <div className='card'>
            <p>Purpose of signing up:</p>
            <select name='' id='' onChange={(e) => setPurpose(e.target.value)}>
              <option value=''>Select your purpose of signing up</option>
              <option value='Virtual USD Card'>Virtual USD Card</option>
              {/* <option value='Bank accounts and finance management'>
                Bank accounts and finance management
              </option> */}
              <option value='Wallet for payments/collection'>
                Wallet for payments/collection
              </option>
              {/* <option value='Expense tracking and budgeting'>
                Expense tracking and budgeting
              </option> */}
            </select>
          </div>
          {purpose.includes('Virtual USD Card') && (
            <div className='card'>
              <p>Purpose of signing up:</p>
              <select
                name=''
                id=''
                onChange={(e) => setPurpose(`${purpose} - ` + e.target.value)}
              >
                <option value=''>Why do you need a virtual card:</option>
                <option value='I want to get a virtual USD card for personal shopping'>
                  I want to get a virtual USD card for personal shopping
                </option>
                <option value='I sell, and need virtual USD card to purchase products'>
                  I sell, and need virtual USD card to purchase products
                </option>
                <option value='I need a virtual USD card to pay for my travel/visa expenses'>
                  I need a virtual USD card to pay for my travel/visa expenses
                </option>
                <option value='I need a virtual USD card to pay for my school expenses'>
                  I need a virtual USD card to pay for my school expenses
                </option>
                <option value=' Other Personal Payments'>
                  Other Personal Payments
                </option>
                <option value=' API services'>API services</option>
              </select>
            </div>
          )}

          <div className='button'>
            {loading ? (
              <img src={Loader} />
            ) : (
              <button onClick={Submit}>Submit</button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Occupation
