import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { FaSuitcase } from 'react-icons/fa'
import { RiArrowRightUpLine } from 'react-icons/ri'
import { BsCreditCardFill } from 'react-icons/bs'
import { useGlobalContext } from '../../../../Context/Context'
import API from '../../../../Context/API'
import Banner from '../../../../Image/spendwise-dashboard.jpg'
import Celeb from '../../../../Image/celeb.png'
import File from '../../../../Image/file.png'
import dashboardImg from '../../../../Image/dashboard (1) 1.png'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import './Dashboard.scss'
import Loader from '../../../../Image/loder.gif.gif'

function Dashboard({ mono, user, notify, hasAccess }) {
  const navigate = useNavigate()
  const authToken = sessionStorage.getItem('userId')
  const { budgetCount, toggleCard, GetStartedG, toggleShowSpendwise20 } =
    useGlobalContext()
  useEffect(() => {
    document.title = 'Spendwise | Dashboard'
  }, [])
  const [walletTransactions, setWalletTransactions] = useState([])
  const [transactionFound, setTransactionFound] = useState('loading')
  useEffect(() => {
    API.FetchSudoAccount()
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            fetch(
              `https://p499kkwmp9.execute-api.eu-west-1.amazonaws.com/prod/wallet/transactions`,
              {
                method: 'post',
                headers: {
                  'content-Type': 'application/json',
                  Authorization: `Token ${authToken}`,
                },
                body: JSON.stringify({
                  id: data.wallet_data.find(
                    (item) => item.wallet_type === 'main-wallet'
                  ).account_id,
                }),
              }
            ).then((res) => {
              if (res.status === 200) {
                res.json().then((data) => {
                  setWalletTransactions(data.transactions)
                  setTransactionFound('true')
                })
              } else {
                setTransactionFound('false')
              }
            })
          })
        } else {
          setTransactionFound('false')
        }
      })
      .catch(() => {})
  }, [])
  return (
    <div className='board'>
      <div className='head'>
        <div>
          <h1>
            Hello, &nbsp;
            {user.first_name === null || user.surname === null ? (
              <>{user.user}</>
            ) : (
              <>
                <span>{user.first_name}</span> &nbsp;
                <span>{user.surname}</span>
              </>
            )}
          </h1>
        </div>
        <div className='buyt'>
          {/* {!user.is_business && !user.is_business_created && (
            <button
              onClick={() => {
                if (user.biz_reg_submitted) {
                  notify('info', 'Verification in progress, please wait.')
                } else {
                  navigate('/activate-business/home')
                }
              }}
            >
              <span>
                <FaSuitcase />
              </span>
              {user.biz_reg_submitted ? (
                <p>Processing...</p>
              ) : (
                <p>Activate Business Profile</p>
              )}
            </button>
          )} */}
          {hasAccess(
            user.roles.map((role) => role.trim()),
            'cards'
          ) && (
            <button
              onClick={() => {
                toggleCard()
              }}
              className='add-bc'
            >
              <span>
                <BsCreditCardFill />
              </span>
              <p>Request Card </p>
            </button>
          )}
        </div>
      </div>
      <div className='new_top'>
        <div className='left'>
          <div className='one'>
            <h2>Getting Started</h2>
            <p>
              Welcome to Spendwise! We're here to help you manage your business
              spending and payment with ease.
            </p>
            <button onClick={GetStartedG}>Get Started</button>
          </div>
          <div className='two'>
            <img src={Celeb} alt='' />
          </div>
        </div>

        <div className='right grey'>
          <div className='right-head'>
            <h2 className='gp-header'>Foreign Payments</h2>
            <button
            // onClick={() => navigate('/e-wallet/dashboard')}
            >
              Check current rate
            </button>
          </div>
          <div className='rights'>
            <div className='card'>
              <div className='topx'>
                <h2>{budgetCount.total}</h2>
              </div>
              <div className='bottom'>
                <p>Completed Transfers</p>
              </div>
            </div>
            <div className='card'>
              <div className='topx'>
                <h2>{budgetCount.exceeded}</h2>
              </div>
              <div className='bottom'>
                <p>Pending Transfers</p>
              </div>
            </div>
            <div className='card'>
              <div className='topx'>
                <h2>{budgetCount.on_track}</h2>
              </div>
              <div className='bottom'>
                <p>Approved Beneficiaries</p>
              </div>
            </div>
            <div className='card'>
              <div className='topx'>
                <h2>{budgetCount.total}</h2>
              </div>
              <div className='bottom'>
                <p>Pending Beneficiaries</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='whats-new'>
        <div className='left'>
          <div className='topx'>
            <h2>What's New?</h2>
          </div>
          <Swiper
            modules={[Pagination, Autoplay]}
            className='mySwiper'
            pagination={{ clickable: true }}
            spaceBetween={17}
            autoplay={{ delay: 5000 }}
          >
            <SwiperSlide>
              <div className='swipet' onClick={toggleShowSpendwise20}>
                <div className='one'>
                  <img className='img' src={dashboardImg} alt='' />
                </div>
                <div className='two'>
                  <h3>Spendwise 2.0! Is Here</h3>
                  <p>
                    Watch out for upcoming new page updates and product
                    announcements starting with our redesigned dashboard.
                  </p>
                  <button>
                    <p>Learn More</p>
                    <RiArrowRightUpLine />
                  </button>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='swipet' onClick={toggleShowSpendwise20}>
                <div className='one'>
                  <img className='img' src={Celeb} alt='' />
                </div>
                <div className='two'>
                  <h3>Spendwise 2.0! Is Here</h3>
                  <p>
                    You now have a more slicker, easier-to-navigate and more
                    functional dashboard!
                  </p>
                  <button>
                    <p>Learn More</p>
                    <RiArrowRightUpLine />
                  </button>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        <div className='right'>
          <div className='topx'>
            <h2>Recent Transactions</h2>
            {/* {hasAccess(
              user.roles.map((role) => role.trim()),
              'cards'
            ) && (
              <button
                onClick={() => {
                  navigate('/expense')
                }}
              >
                <p>View All</p> <RiArrowRightLine />
              </button>
            )} */}
          </div>
          {transactionFound === 'loading' ? (
            <div className='loadee'>
              <img src={Loader} alt='' />
            </div>
          ) : (
            <>
              {transactionFound === 'false' ||
              walletTransactions.length <= 0 ? (
                <div className='bodys'>
                  <img src={File} alt='' />
                  <p>No Transaction For You Yet</p>
                </div>
              ) : (
                <div className='mobile_table'>
                  {walletTransactions
                    .sort((a, b) => b.tx_date.localeCompare(a.tx_date))
                    .slice(0, 5)
                    .map((item) => (
                      <div
                        className='rows'
                        key={item.tx_id}
                        onClick={() =>
                          navigate(`/e-wallet/reciept/${item.tx_id}`)
                        }
                      >
                        <div className='cells roll'>
                          <h2>{item.provider}</h2>
                          <h2 className={item.mode}>
                            ₦{item.amount.toLocaleString()}
                          </h2>
                        </div>
                        <div className='cells long'>
                          <p>
                            {item.narration.length > 80
                              ? item.narration.slice(0, 80) + '...'
                              : item.narration}
                          </p>
                        </div>
                        <div className='cells long'>
                          <p className='time'>
                            {new Date(item.tx_date).toLocaleString()}
                          </p>
                        </div>
                      </div>
                    ))}
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <div className='flex-chart'>
        <img style={{ width: '100%' }} src={Banner} alt='' />
      </div>
    </div>
  )
}

export default Dashboard
