import React, { useState, useRef, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import './Auth.scss'
import Logo from '../../Image/logo1.png'
import Man from '../../Image/man.png'
import Icon1 from '../../Image/Group1.png'
import Icon2 from '../../Image/Group2.png'
import Icon3 from '../../Image/Group3.png'
import Angle from '../../Image/angle.png'
import Data from './Data'

function Register({ notify }) {
  const [shown, setShown] = useState(false)
  const [formLoading, setFormLoading] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm()
  const navigate = useNavigate()
  const password = useRef({})
  password.current = watch('password', '')

  const onRegister = (formData) => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    setFormLoading(true)
    fetch('https://www.spendwise.ng/api/accounts/register/', {
      method: 'post',
      body: JSON.stringify({
        username: formData.username,
        email: formData.email.toLowerCase(),
        phone: formData.phone,
        password: formData.password,
        password2: formData.password2,
      }),
      headers: { 'content-Type': 'application/json' },
    })
      .then((response) => response.json())
      .then((data) => {
        if (typeof data.username === 'object') {
          notify('error', 'Username already exists')
          setFormLoading(false)
        } else if (typeof data.email === 'object') {
          notify('error', data.email.Email)
          setFormLoading(false)
        } else if (typeof data.phone === 'object') {
          notify('error', data.phone.phone)
          setFormLoading(false)
        } else {
          notify('success', data.message.message)
          setTimeout(() => {
            navigate(`/login`)
          }, 2000)
          setFormLoading(false)
        }
      })
      .catch((err) => {
        notify('error', 'An error occured. Unable to reach server')
        setFormLoading(false)
      })
  }
   useEffect(() => {
     document.title = 'Spendwise | Register'
   }, [])
  return (
    <>
      <section className='auth'>
        <div className='logo'>
          <img src={Logo} alt='' onClick={() => navigate('/')} />
        </div>
        <div className='wrap'>
          <div className='left'>
            <div className='icons'>
              <div className='can'>
                <img src={Icon1} alt='' />
              </div>
              <div className='can edit'>
                <img src={Icon2} alt='' />
              </div>
              <div className='can'>
                <img src={Icon3} alt='' />
              </div>
            </div>
            <div className='man'>
              <img src={Man} alt='' />
            </div>
          </div>
          <div className='right'>
            <div className='angle'>
              <div>
                <img src={Angle} alt='' />
              </div>
            </div>
            <div className='form'>
              <div className='head'>
                <h1>Sign up</h1>
                <p>Welcome</p>
              </div>
              <form onSubmit={handleSubmit(onRegister)} className='auth-form'>
                <div className='card'>
                  <p>
                    Username <span style={{ color: 'red' }}>*</span>
                  </p>
                  <input
                    type='text'
                    name='username'
                    {...register('username', {
                      required: 'Username is required',
                    })}
                  />
                  {errors.username && (
                    <h6 className='vError'>{errors.username.message}</h6>
                  )}
                </div>
                <div className='card'>
                  <p>
                    Email <span style={{ color: 'red' }}>*</span>
                  </p>
                  <input
                    type='email'
                    name='email'
                    id=''
                    {...register('email', {
                      required: 'Email is required',
                    })}
                  />
                  {errors.email && (
                    <h6 className='vError'>{errors.email.message}</h6>
                  )}
                </div>
                <div className='card'>
                  <p>
                    Phone number <span style={{ color: 'red' }}>*</span>
                  </p>
                  <input
                    type='tel'
                    name='phone'
                    {...register('phone', {
                      required: 'Phone number is required',
                      minLength: {
                        value: 11,
                        message:
                          'Phone number must have at least 11 characters',
                      },
                      maxLength: {
                        value: 11,
                        message: 'Phone number must contain 11 characters',
                      },
                    })}
                    id=''
                  />
                  {errors.phone && (
                    <h6 className='vError'>{errors.phone.message}</h6>
                  )}
                </div>

                <div className='card password'>
                  <p>
                    Password <span style={{ color: 'red' }}>*</span>
                  </p>
                  <div>
                    <input
                      type={shown ? 'text' : 'password'}
                      name='password'
                      {...register('password', {
                        required: 'Provide a password',
                        minLength: {
                          value: 7,
                          message: 'Password must have at least 8 characters',
                        },
                        pattern: {
                          value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{7,}$/,
                          message:
                            'Passwords have to contain at least one uppercase and one lower case character and a number',
                        },
                      })}
                    />
                    <p onClick={() => setShown(!shown)}>
                      {shown ? <span>hide</span> : <span>show</span>}
                    </p>
                  </div>
                  {errors.password && (
                    <h6 className='vError'>{errors.password.message}</h6>
                  )}
                </div>
                <div className='card password'>
                  <p>
                    Confirm Password <span style={{ color: 'red' }}>*</span>
                  </p>
                  <div>
                    <input
                      type={shown ? 'text' : 'password'}
                      name='password2'
                      {...register('password2', {
                        required: 'You must re-enter your password',
                        validate: (value) =>
                          value === password.current ||
                          'The passwords do not match',
                      })}
                    />
                    <p onClick={() => setShown(!shown)}>
                      {shown ? <span>hide</span> : <span>show</span>}
                    </p>
                  </div>
                  {errors.password2 && (
                    <h6 className='vError'>{errors.password2.message}</h6>
                  )}
                </div>
                <div className='card'>
                  <p>Referal Code</p>
                  <input
                    type='text'
                    name='referral_code'
                    {...register('referral_code')}
                    id=''
                  />
                </div>

                <div className='card'>
                  <button type='submit'>
                    {formLoading ? '.....' : 'Sign Up'}
                  </button>
                </div>
                <div className='card edit'>
                  <p>
                    Already have an account? <Link to='/login'>Login</Link>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Register
