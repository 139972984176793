import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Time from '../../../../../Image/time.png'
import Video from '../../../../../Image/video.png'
import API from '../../../../../Context/API'
import { useGlobalContext } from '../../../../../Context/Context'
import CardBox from './CardBox'
import CardBoxSudo from './CardBox_Sudo'
import Loader from '../../../../../Image/loder.gif.gif'
import VerveLogo3 from '../../../../../Image/masterlogo.png'
import Logo2 from '../../../../../Image/cardlogo2.png'

function DollarCard({ cards, notify, sudoCardsList, loading }) {
  const authToken = sessionStorage.getItem('userId')
  const { toggleCardHolder, toggleInfo } = useGlobalContext()
  const navigate = useNavigate()
  const CallCardHolder = () => {
    API.getCardHolder()
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            if (data.data.length >= 1) {
              navigate('/debit-card/request-sudo')
            } else {
              toggleCardHolder()
            }
          })
        } else {
          res.json().then((data) => {})
        }
      })
      .catch(() => {})
  }

  useEffect(() => {
    document.title = 'Spendwise | Virtual Card'
  }, [])

  return (
    <div className='virtual_cards-wrap'>
      <div className='request-head'>
        <div className='request-wrap'>
          <div
            className='card-box'
            onClick={() => {
              CallCardHolder()
            }}
          >
            <div className='card-box_top'>
              <h2>request for card</h2>
            </div>
            <div
              className='credit-card-body'
              id='blank'
              onClick={() => {
                CallCardHolder()
              }}
            >
              {/* <Link to='/debit-card/request'>
            <div className='click'></div>
          </Link> */}
              <div className='click'></div>
            </div>
          </div>
          <div className='infos'>
            <h2>How to Request a card</h2>
            <div className='infox'>
              <div className='infor' onClick={toggleInfo}>
                <img src={Time} alt='' />
                <p>
                  View our infographic to learn how to request virtual cards
                </p>
              </div>
              {/* <div className='infor'>
                <img src={Video} alt='' />
                <p>Watch our video to learn how to request virtual cards</p>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {loading ? (
        <div>
          <img src={Loader} alt='' />
        </div>
      ) : (
        <div className='cover-wrapper'>
          {sudoCardsList.filter((item) => item.currency === 'USD').length >
          0 ? (
            <>
              {' '}
              {sudoCardsList
                .filter((item) => item.currency === 'USD')
                .map((item) => (
                  <CardBoxSudo
                    className='dit'
                    item={item}
                    key={item.id}
                    locat='usd-edits'
                    navigate={navigate}
                  />
                ))}
            </>
          ) : (
            <div className='card-box op'>
              <div className='credit-card-body' id='USD'>
                <div className='credit-card-body-top'>
                  <img src={Logo2} alt='' />
                </div>
                <div className='card-number'>
                  <p> Virtual Dollar card</p>
                </div>
                <div className='credit-card-body-bottom'>
                  <div>
                    <p>Card Holder</p>
                  </div>

                  <img src={VerveLogo3} alt='' />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default DollarCard
