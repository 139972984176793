import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import CBizHome from './components/CBizHome'
import CBizRegister from './components/CBizRegister'
import CBizUnReg from './components/CBizUnReg'
import './CreateBiz.scss'

function CreateBiz({ notify }) {
  const { id } = useParams()
  const navigate = useNavigate()
  const CBizPage = () => {
    switch (id) {
      case 'home':
        return <CBizHome />
      case 'registered-business':
        return <CBizRegister notify={notify} />
      case 'unregistered-business':
        return <CBizUnReg notify={notify} />
      default:
        break
    }
  }
  return <div className='create-biz'>{CBizPage()}</div>
}

export default CreateBiz
