import React, { useState, useRef, useEffect } from 'react'
import PinInput from 'react-pin-input'
import { useNavigate } from 'react-router-dom'
import { BiLockAlt } from 'react-icons/bi'
import Loader from '../../../../../Image/loder.gif.gif'
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa'
import { useForm } from 'react-hook-form'

function Pin({ notify, sudoDetails }) {
  const authToken = sessionStorage.getItem('userId')
  const [pin, setPin] = useState('')
  const [formLoading, setFormLoading] = useState(false)
  const [showPassword, setShownPassword] = useState(false)
  const [showPassword2, setShownPassword2] = useState(false)
  const [showPassword3, setShownPassword3] = useState(false)
  const [PinNot, setPinNot] = useState(false)
  const walletLogin = (value) => {
    setFormLoading(true)
    fetch(
      `https://p499kkwmp9.execute-api.eu-west-1.amazonaws.com/prod/wallet/main_wallet_pin`,
      {
        method: 'post',
        headers: {
          'content-Type': 'application/json',
          Authorization: `Token ${authToken}`,
        },
        body: JSON.stringify({
          pin: value,
        }),
      }
    )
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            notify('success', data.message)
            navigate(`/physical-card/dashboard`)
            sessionStorage.setItem(
              '7e6d4defcc85a76e2cd2c156ef64a128e5',
              btoa(pin)
            )
            sessionStorage.setItem('adminIn', true)
            setFormLoading(false)
          })
        } else {
          res.json().then((data) => {
            notify('error', data.message)
            if (data.message.includes('Please change your default pin.')) {
              setPinNot(true)
            }
            setFormLoading(false)
          })
        }
      })
      .catch(() => {
        setFormLoading(false)
        notify('error', 'internal server error')
      })
  }
  useEffect(() => {
    document.title = 'Spendwise | Physical Cards'
  }, [])
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm()
  const password = useRef({})
  password.current = watch('new_pin', '')
  const onPasswordChange = (formData) => {
    setFormLoading(true)
    fetch(
      'https://p499kkwmp9.execute-api.eu-west-1.amazonaws.com/prod/wallet/pin_change',
      {
        method: 'post',
        body: JSON.stringify({
          old_pin: formData.old_pin,
          new_pin: formData.new_pin,
          new_pin2: formData.new_pin2,
          wallet_id: sudoDetails.account_id,
        }),
        headers: {
          'content-Type': 'application/json',
          Authorization: `Token ${authToken}`,
        },
      }
    )
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            notify('success', data.message)
            setFormLoading(false)
            setTimeout(() => {
              window.location.reload()
            }, 4000)
          })
        } else {
          res.json().then((data) => {
            notify('error', data.message)
            setFormLoading(false)
          })
        }
      })
      .catch((err) => {
        notify('error', 'Something went wrong please try again')
        setFormLoading(false)
      })
  }
  const navigate = useNavigate()
  return (
    <div className='physical_cards-wrap'>
      {!PinNot && (
        <>
          <div className='pin-head'>
            <div className='ped'>
              <div className='loghd'>
                <BiLockAlt />
              </div>
            </div>
            <h2>MASTER PIN</h2>
            <p>Enter PIN to access Physical Cards (default pin is 1234)</p>
          </div>
          <div className='transfer_pin'>
            <div className='pin_wrapper'>
              <PinInput
                length={4}
                initialValue=''
                secret
                type='numeric'
                inputMode='numeric'
                style={{ padding: '10px' }}
                inputStyle={{
                  borderColor: '#e6e6e6',
                  borderWidth: '3px',
                  borderStyle: 'solid',
                  background: '#f1f1f1',
                  fontSize: ' 3rem',
                  borderRadius: '20px',
                  height: '70px',
                  width: '70px',
                  margin: '0 5px',
                }}
                inputFocusStyle={{ borderColor: 'blue' }}
                onComplete={(value, index) => {
                  setPin(value)
                  walletLogin(value)
                }}
                autoSelect={true}
                regexCriteria={/^[0-9]*$/}
              />
              {formLoading ? (
                <div className='loader'>
                  <img src={Loader} alt='' />
                </div>
              ) : (
                <div className='t_button'></div>
              )}
            </div>
          </div>
        </>
      )}
      {PinNot && (
        <div className='change-pin'>
          <div className='wall-pin'>
            <div className='toprr'>
              <h3>Change Wallet Pin</h3>
              <p>
                Current pin is <b>1234</b>
              </p>
            </div>
            <form onSubmit={handleSubmit(onPasswordChange)}>
              <div className='card'>
                <p>Current Pin</p>
                <div className='input'>
                  <input
                    type={showPassword ? 'number' : 'password'}
                    name='old_pin'
                    id=''
                    {...register('old_pin', {
                      required: 'Provide a current pin',
                      minLength: {
                        value: 4,
                        message: 'Current pin must have at least 4 characters',
                      },
                      maxLength: {
                        value: 4,
                        message: 'Current pin must have at least 4 characters',
                      },
                      pattern: {
                        value: /^(?=.*?[0-9]).{4,}$/,
                        message: 'Pin must be numbers',
                      },
                    })}
                  />
                  {showPassword ? (
                    <FaRegEyeSlash
                      onClick={() => {
                        setShownPassword(!showPassword)
                      }}
                    />
                  ) : (
                    <FaRegEye
                      onClick={() => {
                        setShownPassword(!showPassword)
                      }}
                    />
                  )}
                </div>
                {errors.old_pin && (
                  <h6
                    className='vError'
                    style={{ fontSize: '10px', color: 'red' }}
                  >
                    {errors.old_pin.message}
                  </h6>
                )}
              </div>
              <div className='card'>
                <p>New Pin</p>
                <div className='input'>
                  <input
                    type={showPassword2 ? 'number' : 'password'}
                    name='new_pin'
                    {...register('new_pin', {
                      required: 'Provide a new pin',
                      minLength: {
                        value: 4,
                        message: 'Current pin must have at least 4 characters',
                      },
                      maxLength: {
                        value: 4,
                        message: 'Current pin must have at least 4 characters',
                      },
                      pattern: {
                        value: /^(?=.*?[0-9]).{4,}$/,
                        message: 'Pin must be numbers',
                      },
                    })}
                  />
                  {showPassword2 ? (
                    <FaRegEyeSlash
                      onClick={() => {
                        setShownPassword2(!showPassword2)
                      }}
                    />
                  ) : (
                    <FaRegEye
                      onClick={() => {
                        setShownPassword2(!showPassword2)
                      }}
                    />
                  )}
                </div>
                {errors.new_pin && (
                  <h6
                    className='vError'
                    style={{ fontSize: '10px', color: 'red' }}
                  >
                    {errors.new_pin.message}
                  </h6>
                )}
              </div>
              <div className='card'>
                <p>Confirm New Pin</p>
                <div className='input'>
                  <input
                    type={showPassword3 ? 'number' : 'password'}
                    name='new_pin2'
                    {...register('new_pin2', {
                      required: 'You must re-enter your pin',
                      validate: (value) =>
                        value === password.current || 'The pin do not match',
                    })}
                  />
                  {showPassword3 ? (
                    <FaRegEyeSlash
                      onClick={() => {
                        setShownPassword3(!showPassword3)
                      }}
                    />
                  ) : (
                    <FaRegEye
                      onClick={() => {
                        setShownPassword3(!showPassword3)
                      }}
                    />
                  )}
                </div>
                {errors.new_pin2 && (
                  <h6
                    className='vError'
                    style={{ fontSize: '10px', color: 'red' }}
                  >
                    {errors.new_pin2.message}
                  </h6>
                )}
              </div>
              {formLoading ? (
                <img src={Loader} />
              ) : (
                <div className='button'>
                  <button>Change Pin</button>
                </div>
              )}
            </form>
          </div>
        </div>
      )}
    </div>
  )
}

export default Pin
