import React from 'react'
import { useNavigate, useParams } from 'react-router'

import Select from './ForeignComp/Select'
import InitiateTrans from './ForeignComp/InitiateTrans'
import AddBenefi from './ForeignComp/AddBenefi'
import TransferStatus from './ForeignComp/TransferStatus'

function ForeignTransfer({ notify }) {
  const { fourth } = useParams()
  const navigate = useNavigate()

  const pageToDisplay = () => {
    switch (fourth) {
      case 'select':
        return <Select />
      case 'add-beneficiary':
        return <AddBenefi notify={notify} />
      case 'initate-transfer':
        return <InitiateTrans notify={notify} />
      case 'transfer-status':
        return <TransferStatus notify={notify} />
      default:
    }
  }

  return (
    <div className='foreign-transfer'>
      <div className='showpage'>{pageToDisplay()}</div>
    </div>
  )
}

export default ForeignTransfer
