import React, { useState, useEffect } from 'react'
import './Budget.scss'
import { useForm, Controller } from 'react-hook-form'
import { useGlobalContext } from '../../../../Context/Context'
import Chart from 'react-apexcharts'
import API from '../../../../Context/API'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import { BsInfoCircleFill, BsFillCheckCircleFill } from 'react-icons/bs'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Month from '../../../../Data/Month'
import Year from '../../../../Data/Year'

const authToken = sessionStorage.getItem('userId')

function Budget({
  budget,
  noBudget,
  setNoBudget,
  foundbudget,
  setFoundBudget,
  notify,
  logout,
  setBudget,
  refreshBudget,
}) {
  const defaultMonth = Month[new Date().getMonth()]
  const { budgetCount, toggleBBudget } = useGlobalContext()
  const [fetched, setFetched] = useState(false)
  const [fetchedData, setFetchedData] = useState({})
  const [selected, setSelected] = useState('')
  const [archiveState, setArchiveState] = useState('budget')
  const [formLoading, setFormLoading] = useState(false)
  const [formLoading2, setFormLoading2] = useState(false)
  const [hideEdit, setHideEdit] = useState(false)
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm()
  const { register: register2, handleSubmit: handleSubmit2 } = useForm()
  const my_date = new Date()
  const first_date = new Date(my_date.getFullYear(), my_date.getMonth(), 1)
  const last_date = new Date(my_date.getFullYear(), my_date.getMonth() + 1, 0)
  useEffect(() => {
    document.title = 'Spendwise | Budget'
  }, [])
  const onFetch = (formData) => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    setFormLoading2(true)
    //  setFetchedBE(false)
    //  setFetched(false)

    const month = parseInt(formData.month)
    const year = parseInt(formData.year)
    //  const chosenMonth = Month[month - 1]
    //  const monthFull = chosenMonth.Full
    fetch(`${process.env.REACT_APP_API_URL}/budget/search_archive/`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
      body: JSON.stringify({
        month: month,
        year: year,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setBudget(data)
            setFoundBudget(true)
            setFetched(false)
            setHideEdit(true)
            setFormLoading2(false)
          })
        } else if (res.status === 400) {
          res.json().then((data) => {
            setFormLoading2(false)
            setFoundBudget(false)
            setNoBudget(data.message)
            setFetched(false)
            //  setFetchedBE(false)
          })
        } else {
          setFormLoading2(false)
        }
      })
      .catch((err) => {
        setFormLoading2(false)
      })
  }
  const onFind = (id) => {
    const foundItem = budget.results.find((item) => item.id === id)
    setFetchedData(foundItem)
    setFetched(true)
    setArchiveState('budget')
  }
  const archiveBudget = (data) => {
    fetch(`${process.env.REACT_APP_API_URL}/budget/archive_budget/`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
      body: JSON.stringify({
        budget_id: data,
      }),
    }).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          notify('success', data.message)
          setArchiveState('success')
          setTimeout(() => {
            window.location.reload()
          }, 2000)
        })
      } else if (res.status === 403) {
        setTimeout(() => {
          logout()
        }, 7000)
      } else {
        notify('error', 'Budget not archived successfully')
      }
    })
  }
  const clearBudget = () => {
    setArchiveState('budget')
  }
  const onEdit = (formData) => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    setFormLoading(true)
    function convert(str) {
      var date = new Date(str),
        mnth = ('0' + (date.getMonth() + 1)).slice(-2),
        day = ('0' + date.getDate()).slice(-2)
      return [date.getFullYear(), mnth, day].join('-')
    }
    const startDate = convert(formData.start_date)
    const endDate = convert(formData.end_date)

    fetch(`${process.env.REACT_APP_API_URL}/budget/update_budget/`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
      body: JSON.stringify({
        ...formData,
        id: fetchedData.id,
        start_date: startDate,
        end_date: endDate,
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            notify('success', data.message)
            setFormLoading(false)
            setTimeout(() => {
              window.location.reload()
            }, 2000)
          })
        } else if (response.status === 403) {
          setTimeout(() => {
            logout()
          }, 7000)
        } else {
          response.json().then((data) => {
            notify('error', data.message)
            setFormLoading(false)
          })
        }
      })
      .catch((err) => {})
  }

  const BudgetRow = ({ item }) => (
    <tr
      key={item.id}
      onClick={() => {
        onFind(item.id)
      }}
      className={fetchedData.id === item.id ? 'actives' : ''}
    >
      <td className='feed'>
        <span className='icon'>
          <img src={item.cat_icon} alt='' />
        </span>
        <span>{item.category}</span>
      </td>
      <td className='trans'>{item.status}</td>
      {/* <td className='spent'>₦{item.total.toLocaleString()}</td> */}
      <td className='budget'>₦{item.budget_amount.toLocaleString()}</td>
      <td>
        <p>{new Date(item.date_created).toDateString()}</p>
      </td>
    </tr>
  )
  return (
    <div className='budget'>
      <div className='head'>
        <div>
          <h1>Budgets</h1>
          <p>
            You currently have <span>{budgetCount.total}</span> set budgets,{' '}
            <span className='track'>{budgetCount.on_track}</span> on-track and{' '}
            <span className='exceed'>{budgetCount.exceeded}</span> exceeded
          </p>
        </div>
        <div>
          <button onClick={toggleBBudget}>Create Budget</button>
        </div>
      </div>
      <div className='expense-flow'>
        <form className='sort diff' onSubmit={handleSubmit2(onFetch)}>
          <div className='select-wrap'>
            <select name='month' id='' {...register2('month')}>
              <option value={defaultMonth.id}>{defaultMonth.Content}</option>
              {Month.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.Content}
                </option>
              ))}
            </select>
            <select name='year' id='' {...register2('year')}>
              <option value='2022'>2022</option>
              {Year.map((item) => (
                <option key={item.Content} value={item.Content}>
                  {item.Content}
                </option>
              ))}
            </select>
          </div>
          <button>View archive</button>
        </form>
        <div className='sort'>
          <button
            onClick={() => {
              refreshBudget()
            }}
          >
            Refresh
          </button>
        </div>
      </div>
      <div className='transaction-flex'>
        <div className='left'>
          <table className='left-table'>
            <thead className='left-tablehead'>
              <tr>
                <th>Category</th>
                <th>Status</th>
                {/* <th>Spent</th> */}
                <th>Budget</th>
                <th>Date Created</th>
              </tr>
            </thead>
            {foundbudget ? (
              <tbody className='left-tablebody'>
                {budget.results
                  .sort((a, b) => a.category.localeCompare(b.category))
                  .map((item, i) => (
                    <BudgetRow item={item} key={item.id} />
                  ))}
              </tbody>
            ) : (
              <tbody className='left-tablebody'>
                <tr>
                  <td className='no-budget'>
                    <h1>{noBudget}</h1>
                  </td>
                </tr>
              </tbody>
            )}
          </table>
        </div>
        <div className='right'>
          {fetched ? (
            <>
              {archiveState === 'budget' && (
                <div className='wrap-card'>
                  {fetchedData.balance > 0 ? (
                    <p className='right-head'>
                      You have{' '}
                      <span style={{ color: `#${fetchedData.color}` }}>
                        ₦{fetchedData.balance.toLocaleString()}
                      </span>{' '}
                      left on this budget
                    </p>
                  ) : (
                    <p className='right-head'>
                      You have exceeded your budget by{' '}
                      <span style={{ color: `#${fetchedData.color}` }}>
                        ₦{fetchedData.balance.toLocaleString()}
                      </span>
                    </p>
                  )}

                  <CircularProgressbar
                    value={(
                      (fetchedData.total / fetchedData.budget_amount) *
                      100
                    ).toFixed(0)}
                    text={`${(
                      (fetchedData.total / fetchedData.budget_amount) *
                      100
                    ).toFixed(0)}%`}
                    styles={buildStyles({
                      textColor: `#${fetchedData.color}`,
                      pathColor: `#${fetchedData.color}`,
                      trailColor: '#c3c3c3',
                    })}
                    strokeWidth={5}
                  />
                  <div className='money-flex'>
                    <div>
                      <p className='label'>Amount Spent</p>
                      <p className='spent'>
                        ₦{fetchedData.total.toLocaleString()}
                      </p>
                    </div>
                    <div>
                      <p className='label'>Budget</p>
                      <p className='mon'>
                        ₦{fetchedData.budget_amount.toLocaleString()}
                      </p>
                    </div>
                  </div>
                  {hideEdit ? (
                    <></>
                  ) : (
                    <div className='button'>
                      <button onClick={() => setArchiveState('edit')}>
                        Edit Budget
                      </button>
                      <button
                        className='archive'
                        onClick={() => setArchiveState('ask')}
                      >
                        Archive budget
                      </button>
                    </div>
                  )}
                </div>
              )}
              {archiveState === 'ask' && (
                <div className='ask-user'>
                  <div className='ask-wrapper'>
                    <h1>Are you sure you want to archive this budget</h1>
                    <div className='button'>
                      <button onClick={clearBudget}>No</button>
                      <button onClick={() => archiveBudget(fetchedData.id)}>
                        Yes
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {archiveState === 'success' && (
                <div className='archive-success'>
                  <div className='success-wrap'>
                    <h2>Budget archived successfully</h2>
                    <BsFillCheckCircleFill />
                  </div>
                  <button>Continue</button>
                </div>
              )}
              {archiveState === 'edit' && (
                <div className='archive-edit'>
                  <div className='top'>
                    <h2>Edit Budget</h2>
                    <p>
                      Note that every field is required <span>*</span>
                    </p>
                  </div>
                  <form onSubmit={handleSubmit(onEdit)}>
                    <div className='card'>
                      <p>Budget Amount</p>
                      <input
                        type='number'
                        name='budget_amount'
                        id=''
                        {...register('budget_amount', {
                          required: 'Amount is required',
                        })}
                      />
                      {errors.budget_amount && (
                        <h6 className='vError'>
                          {errors.budget_amount.message}
                        </h6>
                      )}
                    </div>
                    <div className='card'>
                      <p>Select start date</p>
                      <Controller
                        control={control}
                        name='start_date'
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <DatePicker
                            onChange={onChange}
                            onBlur={onBlur}
                            selected={value}
                            minDate={first_date}
                            maxDate={last_date}
                          />
                        )}
                      />
                    </div>
                    <div className='card'>
                      <p>Select end date</p>
                      <Controller
                        control={control}
                        name='end_date'
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <DatePicker
                            onChange={onChange}
                            onBlur={onBlur}
                            selected={value}
                            minDate={first_date}
                            maxDate={last_date}
                          />
                        )}
                      />
                    </div>
                    <div className='card'>
                      <button type='submit' className='create'>
                        {formLoading ? '.....' : 'Edit'}
                      </button>
                    </div>
                  </form>
                  <div className='cancel'>
                    <button onClick={clearBudget}>Cancel</button>
                  </div>
                </div>
              )}
            </>
          ) : (
            <div className='wrap'>
              <BsInfoCircleFill />
              <p>Select budget to display information</p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Budget
