import React, { useState, useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router'
import Logo from '../../../../../Image/cardlogo.png'
import MasterLogo from '../../../../../Image/masterlogo.png'
import Successful from '../../../../../Image/Successful.png'
import Logo2 from '../../../../../Image/cardlogo2.png'
import VerveLogo from '../../../../../Image/vereve.png'
import VerveLogo3 from '../../../../../Image/masterlogo.png'
import API from '../../../../../Context/API'
import { BiCopy } from 'react-icons/bi'
import Loader from '../../../../../Image/loder.gif.gif'
import { FaChevronLeft } from 'react-icons/fa'
import { FcCancel } from 'react-icons/fc'
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa'
import { PaystackButton } from 'react-paystack'
import PinInput from 'react-pin-input'

function CardEdit({ notify }) {
  const publicKey = process.env.REACT_APP_PAYSTACK_PUBLIC_KEY
  const authToken = sessionStorage.getItem('userId')
  const navigate = useNavigate()
  const [cloading, setcLoading] = useState(true)
  const { new_id, id } = useParams()
  const [debitCard, setDebitCard] = useState({})
  const [cardTransactions, setCardTransactions] = useState([])
  const [numberCopied, setNumberCopied] = useState(false)
  const [cardStage, setCardStage] = useState('default')
  const [cardPage, setCardPage] = useState('main')
  const [dollarRate, setdollarRate] = useState(0)
  const [creditAmmount, setCreditAmmount] = useState(0)
  const [showAmount, setShowAmount] = useState(0)
  const [amount, setAmount] = useState(0)
  const [email, setEmail] = useState('')
  const [userName, setUserName] = useState('')
  const [liquidation, setLiquidation] = useState('')
  const [confirmed, setConfirmed] = useState(false)
  const [confirmedDetails, setConfirmedDetails] = useState({
    message: '',
    narration: '',
    total_bill_naira: 0,
  })
  const [magicPin, setmagicPin] = useState(0)
  const [showCvv, setShowCvv] = useState(false)
  const [freezeLoading, setfreezeLoading] = useState(false)
  const [cvv, setCvv] = useState('')
  const [cardNumber, setCardNumber] = useState('0000000000000000')
  const [sudoDetails, setSudoDetails] = useState({})
  const [formLoading, setFormLoading] = useState(false)
  const [checkBalance, setCheckBalance] = useState(false)
  useEffect(() => {
    fetch(
      `https://y18x0oais3.execute-api.eu-west-1.amazonaws.com/prod/card/card_details`,
      {
        method: 'post',
        headers: {
          'content-Type': 'application/json',
          Authorization: `Token ${authToken}`,
        },
        body: JSON.stringify({
          card_id: new_id,
        }),
      }
    )
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setDebitCard(data.card_data)
            setcLoading(false)
            fetch(
              `https://p499kkwmp9.execute-api.eu-west-1.amazonaws.com/prod/wallet/transactions`,
              {
                method: 'post',
                headers: {
                  'content-Type': 'application/json',
                  Authorization: `Token ${authToken}`,
                },
                body: JSON.stringify({
                  id: data.card_data.account,
                }),
              }
            ).then((res) => {
              if (res.status === 200) {
                res.json().then((data) => {
                  setCardTransactions(data.transactions)
                })
              } else {
                res.json().then((data) => {})
              }
            })
            fetch(
              `https://y18x0oais3.execute-api.eu-west-1.amazonaws.com/prod/card/tokenise`,
              {
                method: 'post',
                headers: {
                  'content-Type': 'application/json',
                  Authorization: `Token ${authToken}`,
                },
                body: JSON.stringify({
                  card_id: new_id,
                }),
              }
            ).then((res) => {
              if (res.status === 200) {
                res.json().then((datas) => {
                  fetch(
                    `https://vault.sudo.africa/cards/${new_id}/secure-data/cvv2`,
                    {
                      headers: {
                        Authorization: `Bearer ${datas.token}`,
                      },
                    }
                  ).then((res) => {
                    if (res.status === 200) {
                      res.json().then((data) => {
                        setCvv(data.data.cvv2)
                      })
                    } else {
                      res.json().then((data) => {})
                    }
                  })
                  fetch(
                    `https://vault.sudo.africa/cards/${new_id}/secure-data/number`,
                    {
                      headers: {
                        Authorization: `Bearer ${datas.token}`,
                      },
                    }
                  ).then((res) => {
                    if (res.status === 200) {
                      res.json().then((data) => {
                        setCardNumber(data.data.number)
                      })
                    } else {
                      res.json().then((data) => {})
                    }
                  })
                })
              } else {
                res.json().then((data) => {})
              }
            })
          })
        } else {
          res.json().then((data) => {})
        }
      })
      .catch(() => {})
  }, [])
  useEffect(() => {
    API.fetchUser()
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setEmail(data.email)
            setUserName(data.username)
          })
        } else if (res.status === 403) {
        }
      })
      .catch(() => {})
  }, [])
  useEffect(() => {
    document.title = 'Spendwise | Virtual Card'
  }, [])
  const RefreshCard = () => {
    fetch(
      `https://y18x0oais3.execute-api.eu-west-1.amazonaws.com/prod/card/card_details`,
      {
        method: 'post',
        headers: {
          'content-Type': 'application/json',
          Authorization: `Token ${authToken}`,
        },
        body: JSON.stringify({
          card_id: new_id,
        }),
      }
    )
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setDebitCard(data.card_data)
            setcLoading(false)
            fetch(
              `https://p499kkwmp9.execute-api.eu-west-1.amazonaws.com/prod/wallet/transactions`,
              {
                method: 'post',
                headers: {
                  'content-Type': 'application/json',
                  Authorization: `Token ${authToken}`,
                },
                body: JSON.stringify({
                  id: data.card_data.account,
                }),
              }
            ).then((res) => {
              if (res.status === 200) {
                res.json().then((data) => {
                  setCardTransactions(data.transactions)
                })
              } else {
                res.json().then((data) => {})
              }
            })
            fetch(
              `https://y18x0oais3.execute-api.eu-west-1.amazonaws.com/prod/card/tokenise`,
              {
                method: 'post',
                headers: {
                  'content-Type': 'application/json',
                  Authorization: `Token ${authToken}`,
                },
                body: JSON.stringify({
                  card_id: new_id,
                }),
              }
            ).then((res) => {
              if (res.status === 200) {
                res.json().then((datas) => {
                  fetch(
                    `https://vault.sudo.africa/cards/${new_id}/secure-data/cvv2`,
                    {
                      headers: {
                        Authorization: `Bearer ${datas.token}`,
                      },
                    }
                  ).then((res) => {
                    if (res.status === 200) {
                      res.json().then((data) => {
                        setCvv(data.data.cvv2)
                      })
                    } else {
                      res.json().then((data) => {})
                    }
                  })
                  fetch(
                    `https://vault.sudo.africa/cards/${new_id}/secure-data/number`,
                    {
                      headers: {
                        Authorization: `Bearer ${datas.token}`,
                      },
                    }
                  ).then((res) => {
                    if (res.status === 200) {
                      res.json().then((data) => {
                        setCardNumber(data.data.number)
                      })
                    } else {
                      res.json().then((data) => {})
                    }
                  })
                })
              } else {
                res.json().then((data) => {})
              }
            })
          })
        } else {
          res.json().then((data) => {})
        }
      })
      .catch(() => {})
    setCreditAmmount(0)
    setTimeout(() => {
      setCardStage('default')
    }, 3000)
  }
  const copyCardNumber = (e) => {
    navigator.clipboard.writeText(e)
    setNumberCopied(true)
  }
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/cards/usd_exchange_rate/`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
      body: JSON.stringify({
        issuer: 'sudo',
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setdollarRate(data.rate.funding_rate)
            setLiquidation(data.rate.liquidation_rate)
          })
        } else {
          res.json().then((data) => {})
        }
      })
      .catch(() => {})
  }, [])
  useEffect(() => {
    API.FetchSudoAccount()
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setSudoDetails(
              data.wallet_data.find(
                (item) => item.wallet_type === 'main-wallet'
              )
            )
          })
        } else {
          res.json().then((data) => {})
        }
      })
      .catch(() => {})
  }, [])
  const completePurchase = () => {
    setFormLoading(true)
    fetch(
      `https://y18x0oais3.execute-api.eu-west-1.amazonaws.com/prod/card/topup`,
      {
        method: 'post',
        headers: {
          'content-Type': 'application/json',
          Authorization: `Token ${authToken}`,
        },
        body: JSON.stringify({
          alias: debitCard.alias,
          currency: 'USD',
          amount: parseInt(creditAmmount),
          wallet_id: sudoDetails.account_id,
          issuer: 'sudo',
          card_id: debitCard.card_id,
        }),
      }
    )
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            notify('success', data.message)
            setFormLoading(false)
            setTimeout(() => {
              RefreshCard()
            }, 4000)
          })
        } else {
          res.json().then((data) => {
            notify('error', data.message)
            setFormLoading(false)
          })
        }
      })
      .catch(() => {
        notify('error', 'An error occured try againg later.')
        setFormLoading(false)
      })
  }
  const DeleteCard = () => {
    if (debitCard.balance > 0 && debitCard.currency === 'USD') {
      fetch(`${process.env.REACT_APP_API_URL}/cards/liquidate_card/`, {
        method: 'post',
        headers: {
          'content-Type': 'application/json',
          Authorization: `Token ${authToken}`,
        },
        body: JSON.stringify({
          id: debitCard.id,
        }),
      }).then((res) => {
        if (res.status === 200) {
          fetch(`${process.env.REACT_APP_API_URL}/cards/delete_card/`, {
            method: 'delete',
            headers: {
              'content-Type': 'application/json',
              Authorization: `Token ${authToken}`,
            },
            body: JSON.stringify({
              card_id: debitCard.id,
            }),
          }).then((res) => {
            if (res.status === 200) {
              res.json().then((data) => {
                notify('success', data.message)
                setTimeout(() => {
                  window.location.replace('/debit-card/dashboard')
                }, 4000)
              })
            } else {
              res.json().then((data) => {
                notify('error', data.message)
              })
            }
          })
        } else {
          res.json().then((data) => {
            notify('error', data.message)
          })
        }
      })
    } else {
      fetch(`${process.env.REACT_APP_API_URL}/cards/delete_card/`, {
        method: 'delete',
        headers: {
          'content-Type': 'application/json',
          Authorization: `Token ${authToken}`,
        },
        body: JSON.stringify({
          card_id: debitCard.id,
        }),
      }).then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setTimeout(() => {
              window.location.replace('/debit-card/dashboard')
            }, 4000)
          })
        } else {
          res.json().then((data) => {
            notify('error', data.message)
          })
        }
      })
    }
  }
  const LiquidateCard = () => {
    setFormLoading(true)
    fetch(
      `https://y18x0oais3.execute-api.eu-west-1.amazonaws.com/prod/card/liquidate`,
      {
        method: 'post',
        headers: {
          'content-Type': 'application/json',
          Authorization: `Token ${authToken}`,
        },
        body: JSON.stringify({
          alias: debitCard.alias,
          amount: parseInt(creditAmmount),
          loader_wallet_id: sudoDetails.account_id,
          issuer: 'sudo',
          card_id: debitCard.card_id,
        }),
      }
    ).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          notify('success', data.message)
          RefreshCard()
          setFormLoading(false)
        })
      } else {
        res.json().then((data) => {
          notify('error', data.message)
          setFormLoading(false)
        })
      }
    })
  }
  const FreezeCard = () => {
    setfreezeLoading(true)
    fetch(`${process.env.REACT_APP_API_URL}/cards/freeze_card/`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
      body: JSON.stringify({
        card_id: debitCard.id,
        action: 'freeze',
        pin: magicPin,
      }),
    }).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          notify('success', data.message)
          RefreshCard()
          setfreezeLoading(false)
        })
      } else {
        res.json().then((data) => {
          notify('error', data.message)
          setfreezeLoading(false)
        })
      }
    })
  }
  const UnfreezeCard = () => {
    setfreezeLoading(true)
    fetch(`${process.env.REACT_APP_API_URL}/cards/freeze_card/`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
      body: JSON.stringify({
        card_id: debitCard.id,
        action: 'unfreeze',
        pin: magicPin,
      }),
    }).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          notify('success', data.message)
          RefreshCard()
          setfreezeLoading(false)
        })
      } else {
        res.json().then((data) => {
          notify('error', data.message)
          setfreezeLoading(false)
        })
      }
    })
  }
  const confirmTotalBills = () => {
    setFormLoading(true)
    fetch(`${process.env.REACT_APP_API_URL}/cards/total_bill/`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
      body: JSON.stringify({
        alias: debitCard.alias,
        event: 'card funding',
        currency: debitCard.currency,
        amount: parseInt(creditAmmount),
        issuer: 'sudo',
      }),
    }).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setConfirmed(true)
          setConfirmedDetails(data)
          setFormLoading(false)
        })
      } else {
        res.json().then((data) => {
          setFormLoading(false)
        })
      }
    })
  }
  return cloading ? (
    <div className='vloader'>
      <img src={Loader} />
    </div>
  ) : (
    <div className='virtual_cards-edit'>
      {cardPage === 'main' && (
        <div className='main-card'>
          <div className='back-but'>
            <p
              onClick={() => {
                if (id === 'usd-edits') {
                  navigate('/debit-card/usd-card')
                } else {
                  navigate('/debit-card/dashboard')
                }
              }}
            >
              <FaChevronLeft /> Back
            </p>
          </div>
          <div className='card-box'>
            <div className='card_wrap'>
              <div className='card-box_top'>
                <h2>{debitCard.alias}</h2>
              </div>
              <div className='credit-card-body' id={debitCard.currency}>
                {debitCard.re_issue_status === 'failed' && (
                  <div className='cancel'>
                    <FcCancel />
                  </div>
                )}
                <div className='credit-card-body-top'>
                  {debitCard.currency === 'USD' ? (
                    <img src={Logo2} alt='' />
                  ) : (
                    <img src={Logo} alt='' />
                  )}
                  <p>
                    {debitCard.re_issue_status === 'failed' ? (
                      '-'
                    ) : (
                      <>
                        {debitCard.currency === 'USD' ? '$' : '₦'}
                        {debitCard.balance.toLocaleString()}
                      </>
                    )}
                  </p>
                </div>

                <div className='card-number'>
                  <p>{cardNumber.slice(0, 4)}</p>
                  <p>{cardNumber.slice(4, 8)}</p>
                  <p>{cardNumber.slice(8, 12)}</p>
                  <p>{cardNumber.slice(12, 16)}</p>
                  <p>{cardNumber.slice(16, 20)}</p>
                </div>
                <div className='credit-card-body-bottom'>
                  <div>
                    <p>Card Holder</p>
                    <h2>{debitCard.name}</h2>
                  </div>
                  <div>
                    <p>Exp</p>
                    <h2>
                      {debitCard.expiryMonth}/{debitCard.expiryYear}
                    </h2>
                  </div>
                  <div>
                    <p style={{ display: 'flex', alignItems: 'center' }}>
                      cvv{' '}
                      {showCvv ? (
                        <FaRegEyeSlash
                          style={{ marginLeft: '5px', cursor: 'pointer' }}
                          onClick={() => {
                            setShowCvv(!showCvv)
                          }}
                        />
                      ) : (
                        <FaRegEye
                          style={{ marginLeft: '5px', cursor: 'pointer' }}
                          onClick={() => {
                            setShowCvv(!showCvv)
                          }}
                        />
                      )}
                    </p>
                    <h2
                      onClick={() => {
                        setShowCvv(!showCvv)
                      }}
                    >
                      {showCvv ? <>{cvv}</> : <>***</>}
                    </h2>
                  </div>
                </div>
                <div className='vlogo'>
                  <img src={VerveLogo3} alt='' />
                </div>
              </div>
            </div>
          </div>
          {debitCard.re_issue_status === 'failed' && (
            <p className='dets'>
              Following our earlier communication on the termination of all
              cards as a result of global AML efforts, your card was canceled.
              However, our attempt to re-issue it failed as it held less than
              USD5 mandated by our issuer. You can now fund your wallet and
              request a new card. We would provide update on transfer of your
              previous card balance to your new card within 72hrs.
            </p>
          )}

          <div className='wrapper'>
            {cardStage === 'default' && (
              <div>
                <div className='card_details'>
                  <div className='long'>
                    <p
                      onClick={() => {
                        copyCardNumber(cardNumber)
                      }}
                    >
                      {cardNumber}
                    </p>
                    {numberCopied ? (
                      <p style={{ fontSize: '12px', color: 'green' }}>Copied</p>
                    ) : (
                      <BiCopy
                        onClick={() => {
                          copyCardNumber(cardNumber)
                        }}
                      />
                    )}
                  </div>
                  <div className='det_wrap'>
                    <div className='short'>
                      <p>
                        {debitCard.expiryMonth}/{debitCard.expiryYear}
                      </p>
                    </div>
                    <div className='short'>
                      <p>{showCvv ? <>{cvv}</> : <>***</>}</p>
                    </div>
                  </div>
                </div>
                <h4 className='bills'>Billing Address</h4>
                <div className='card_details'>
                  <div className='long'>
                    <p>
                      {debitCard.billingAddress.line1},
                      {debitCard.billingAddress.city}
                    </p>
                  </div>
                  <div className='det_wrap'>
                    <div className='short'>
                      <p>
                        {debitCard.billingAddress.state},
                        {debitCard.billingAddress.country}
                      </p>
                    </div>
                    <div className='short'>
                      <p>{debitCard.billingAddress.postalCode}</p>
                    </div>
                  </div>
                </div>
                <div className='buttons'>
                  <button
                    className='fund'
                    onClick={() => {
                      setCardStage('fund')
                      setConfirmed(false)
                      setCreditAmmount(0)
                    }}
                  >
                    Fund Card
                  </button>
                  <button
                    className='transct'
                    onClick={() => setCardPage('transactions')}
                  >
                    Transactions
                  </button>
                  <button
                    className='delete'
                    onClick={() => setCardStage('liquidate')}
                  >
                    Withdrawal
                  </button>
                  {/* <button
                    className='fund'
                    onClick={() => setCardStage('freeze')}
                  >
                    {debitCard.status === 'active' ? 'Freeze' : 'Unfreeze'} card
                  </button>
                  <button
                    className='delete'
                    onClick={() => setCardStage('delete')}
                  >
                    Delete Card
                  </button> */}
                </div>
              </div>
            )}
            {cardStage === 'fund' && (
              <div className='funding'>
                <p className='fee'>
                  How much will you like to fund the card with in
                  <span> {debitCard.currency}</span>.{' '}
                  {debitCard.currency === 'USD' &&
                    'Funding fee of $1.6 applies (card holding limit : $100,000)'}
                  {debitCard.currency !== 'USD' &&
                    '(card holding limit : ₦1,000,000)'}
                </p>
                {debitCard.currency === 'USD' && (
                  <>
                    <p className='dollar'>
                      Amount to pay in naira (₦{dollarRate} / $1){' '}
                      <span>
                        ₦{(creditAmmount * dollarRate).toLocaleString()}
                      </span>
                    </p>
                  </>
                )}
                {confirmed && (
                  <p
                    className='dollar'
                    style={{
                      color: '#fe8b00',
                      wordSpacing: '3px',
                      lineHeight: '25px',
                      letterSpacing: '1px',
                    }}
                  >
                    {confirmedDetails.narration}
                  </p>
                )}
                <div className='form-group'>
                  <input
                    type='number'
                    name='number'
                    className='form-control'
                    placeholder='Amount'
                    max={debitCard.currency === 'USD' && 250}
                    onChange={(e) => {
                      setCreditAmmount(e.target.value)
                      if (debitCard.currency === 'USD') {
                        setShowAmount(e.target.value)
                        // setAmount(e.target.value * dollarRate * 100)
                      } else {
                        setShowAmount(e.target.value)
                        // setAmount(e.target.value * 100)
                      }
                    }}
                    disabled={confirmed}
                    required
                  />
                </div>

                {formLoading ? (
                  <div>
                    <img src={Loader} alt='' />
                  </div>
                ) : (
                  <div className='fund-buttons'>
                    <button
                      className='delete'
                      onClick={() => {
                        setCardStage('default')
                        setConfirmed(false)
                        setCreditAmmount(0)
                      }}
                    >
                      Back
                    </button>
                    {debitCard.currency === 'USD' && (
                      <>
                        {(debitCard.currency === 'USD' &&
                          creditAmmount > 10000) ||
                        creditAmmount < 5 ||
                        creditAmmount * 1 + parseInt(debitCard.balance) >
                          100000 ? (
                          <p>
                            Amount is not allowed (amount must be above $5 and
                            below $10000)
                          </p>
                        ) : (
                          // <PaystackButton
                          //   className='paystack-button'
                          //   {...componentProps}
                          // />
                          <>
                            {confirmed ? (
                              <>
                                <button
                                  onClick={() => {
                                    setConfirmed(false)
                                  }}
                                  className='paystack-button'
                                >
                                  Edit
                                </button>
                                <button
                                  onClick={() => {
                                    completePurchase()
                                  }}
                                  className='paystack-button'
                                >
                                  Pay Now
                                </button>
                              </>
                            ) : (
                              <button
                                onClick={() => {
                                  confirmTotalBills()
                                }}
                                className='paystack-button'
                              >
                                Confirm
                              </button>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                )}
              </div>
            )}
            {cardStage === 'delete' && (
              <div className='deleting'>
                <p className='fee'>
                  Are you sure you want to liquidate this card?
                </p>
                {debitCard.currency === 'USD' && (
                  <p className='fee' style={{ color: '#0125bf' }}>
                    Liquidation Amount:- ₦
                    {(debitCard.balance * liquidation).toLocaleString()}
                  </p>
                )}
                <div className='delete-buttons'>
                  <button
                    className='fund'
                    onClick={() => setCardStage('default')}
                  >
                    Back
                  </button>
                  <button className='delete' onClick={() => DeleteCard()}>
                    Delete Card
                  </button>
                </div>
              </div>
            )}
            {cardStage === 'liquidate' && (
              <div className='deleting'>
                <p className='fee'>
                  Are you sure you want to liquidate a part of this card? Note
                  that you can't have less than $1 in your card after
                  liquidating.
                </p>
                {debitCard.currency === 'USD' && (
                  <p className='fee' style={{ color: '#0125bf' }}>
                    Liquidation rate:- ₦{liquidation.toLocaleString()}/$
                  </p>
                )}
                <div className='form-group'>
                  <input
                    type='number'
                    name='number'
                    className='form-control'
                    placeholder='Amount to withdraw in USD'
                    onChange={(e) => {
                      setCreditAmmount(e.target.value)
                      if (e.target.value > debitCard.balance - 1) {
                        setCheckBalance(true)
                      } else {
                        setCheckBalance(false)
                      }
                    }}
                    required
                  />
                  {checkBalance && (
                    <p style={{ color: 'red', fontSize: '14px' }}>
                      {' '}
                      Withdrawal amount not allowed
                    </p>
                  )}
                </div>
                {formLoading ? (
                  <div>
                    <img src={Loader} alt='' />
                  </div>
                ) : (
                  <div className='delete-buttons'>
                    <button
                      className='fund'
                      onClick={() => setCardStage('default')}
                    >
                      Back
                    </button>
                    {!checkBalance && (
                      <button
                        className='fund'
                        onClick={() => LiquidateCard()}
                        style={{ background: '#009a5b' }}
                      >
                        Withdraw
                      </button>
                    )}
                  </div>
                )}
              </div>
            )}
            {cardStage === 'freeze' && (
              <>
                {debitCard.status === 'active' && (
                  <div className='deleting'>
                    <p className='fee'>
                      Are you sure you want to Freeze this card? Enter magic pin
                    </p>
                    <div className='enter_pin'>
                      <PinInput
                        length={4}
                        initialValue=''
                        secret
                        onChange={(value, index) => {
                          setmagicPin(value)
                        }}
                        type='numeric'
                        inputMode='numeric'
                        style={{ padding: '10px' }}
                        inputStyle={{
                          borderColor: '#e6e6e6',
                          borderWidth: '3px',
                          borderStyle: 'solid',
                          background: '#f1f1f1',
                          fontSize: ' 3rem',
                          borderRadius: '20px',
                          height: '70px',
                          width: '70px',
                          margin: '0 5px',
                        }}
                        inputFocusStyle={{ borderColor: 'blue' }}
                        onComplete={(value, index) => {}}
                        autoSelect={true}
                        regexCriteria={/^[0-9]*$/}
                      />
                    </div>
                    {freezeLoading ? (
                      <img src={Loader} alt='' />
                    ) : (
                      <div className='delete-buttons'>
                        <button
                          className='fund'
                          onClick={() => setCardStage('default')}
                        >
                          Back
                        </button>
                        <button
                          className='fund'
                          onClick={() => FreezeCard()}
                          style={{ background: '#009a5b' }}
                        >
                          Freeze card
                        </button>
                      </div>
                    )}
                  </div>
                )}
                {debitCard.status !== 'active' && (
                  <div className='deleting'>
                    <p className='fee'>
                      Are you sure you want to unFreeze this card? Enter magic
                      pin
                    </p>
                    <div className='enter_pin'>
                      <PinInput
                        length={4}
                        initialValue=''
                        secret
                        onChange={(value, index) => {
                          setmagicPin(value)
                        }}
                        type='numeric'
                        inputMode='numeric'
                        style={{ padding: '10px' }}
                        inputStyle={{
                          borderColor: '#e6e6e6',
                          borderWidth: '3px',
                          borderStyle: 'solid',
                          background: '#f1f1f1',
                          fontSize: ' 3rem',
                          borderRadius: '20px',
                          height: '70px',
                          width: '70px',
                          margin: '0 5px',
                        }}
                        inputFocusStyle={{ borderColor: 'blue' }}
                        onComplete={(value, index) => {}}
                        autoSelect={true}
                        regexCriteria={/^[0-9]*$/}
                      />
                    </div>
                    {freezeLoading ? (
                      <img src={Loader} alt='' />
                    ) : (
                      <div className='delete-buttons'>
                        <button
                          className='fund'
                          onClick={() => setCardStage('default')}
                        >
                          Back
                        </button>
                        <button
                          className='fund'
                          onClick={() => UnfreezeCard()}
                          style={{ background: '#009a5b' }}
                        >
                          Unfreeze card
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      )}
      {cardPage === 'transactions' && (
        <div className='card_transactions' style={{ paddingTop: '0' }}>
          <div className='back-but'>
            <p onClick={() => setCardPage('main')}>
              <FaChevronLeft /> Back
            </p>
          </div>
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Type</th>
                <th>Amount</th>
                <th>Naration</th>
                <th>Balance</th>
              </tr>
            </thead>
            <tbody>
              {cardTransactions
                .sort((a, b) => b.tx_date.localeCompare(a.tx_date))
                .map((item) => (
                  <tr key={item.tx_id}>
                    <td className='date'>
                      {new Date(item.tx_date).toLocaleString()}
                    </td>
                    <td className={item.mode}>{item.mode}</td>
                    <td>${item.amount}</td>
                    <td>{item.narration}</td>
                    <td>${item.running_balance}</td>
                  </tr>
                ))}
            </tbody>
          </table>
          <div className='mobile_table'>
            {cardTransactions.map((item) => (
              <div className='rows' key={item.tx_id}>
                <div className='cells roll'>
                  <h2>{item.provider}</h2>
                  <h2 className={item.mode}>${item.amount.toLocaleString()}</h2>
                </div>
                <div className='cells long'>
                  <p>{item.narration}</p>
                </div>
                <div className='cells long'>
                  <p className='time'>
                    {new Date(item.tx_date).toLocaleString()}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default CardEdit
