import React from 'react'
import './GetStarted.scss'
import { FaTimes } from 'react-icons/fa'
import Card from '../../../../Image/credit-card (3) 1.png'
import Business from '../../../../Image/business-bag 1.png'
import Wallet from '../../../../Image/wallet (1) 1.png'
import Api from '../../../../Image/computer-setting 1.png'
import { useGlobalContext } from '../../../../Context/Context'
import { useNavigate } from 'react-router'

function GetStarted({ user, hasAccess }) {
  const { GetStartedG } = useGlobalContext()
  const navigate = useNavigate()
  return (
    <div className='get-started'>
      <div className='infor-top'>
        <h2>Getting Started </h2>
        <FaTimes onClick={GetStartedG} />
      </div>
      <div className='cards'>
        <div
          className='card'
          onClick={() => {
            navigate('/debit-card/usd-card')
            GetStartedG()
          }}
        >
          <div className='left'>
            <img src={Card} alt='' />
          </div>
          <div className='right'>
            <h2>Get Virtual USD Card</h2>
            <p>
              (Tip: You would be prompted to create wallet if you don’t have
              one)
            </p>
          </div>
        </div>
        <div
          className='card'
          onClick={() => {
            navigate('/e-wallet/dashboard')
            GetStartedG()
          }}
        >
          <div className='left'>
            <img src={Wallet} alt='' />
          </div>
          <div className='right'>
            <h2>Create Your Wallet</h2>
          </div>
        </div>
        {!user.is_business && !user.is_business_created && (
          <div
            className='card'
            onClick={() => {
              if (!user.biz_reg_submitted) {
                navigate('/activate-business/home')
                GetStartedG()
              }
            }}
          >
            <div className='left'>
              <img src={Business} alt='' />
            </div>
            <div className='right'>
              <h2>
                {user.biz_reg_submitted
                  ? 'Business Verification in progress'
                  : 'Activate Your Business Profile'}
              </h2>
              <p>
                (Tip: Both registered businesses and Unregistered businesses can
                activate a business profile.)
              </p>
            </div>
          </div>
        )}

        <div
          className='card'
          onClick={() => {
            if (
              user.is_business ||
              (user.is_business_created &&
                hasAccess(
                  user.roles.map((role) => role.trim()),
                  'developer'
                ))
            ) {
              navigate('/developers')
              GetStartedG()
            }
          }}
        >
          <div className='left'>
            <img src={Api} alt='' />
          </div>
          <div className='right'>
            <h2>Access Our APIs</h2>
            <p>
              (Tip: You would need to activate your Business profile if you
              haven’t done so.)
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GetStarted
