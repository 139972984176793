import React, { useState, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { BiTransfer, BiLockAlt } from 'react-icons/bi'
import { GiReceiveMoney } from 'react-icons/gi'
import { BsStack, BsListTask, BsGridFill } from 'react-icons/bs'
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa'
import Loader from '../../../../../Image/loder.gif.gif'
import ListBox from './ListBox'
import CardBox from './CardBox'
import { useNavigate, useParams } from 'react-router-dom'
import API from '../../../../../Context/API'
import RequestCard from './RequestCard'
import { useGlobalContext } from '../../../../../Context/Context'

function SubwalletBoard({ notify }) {
  const authToken = sessionStorage.getItem('userId')
  const navigate = useNavigate()
  const { new_id } = useParams()
  const [walletInfo, setWalletInfo] = useState([])
  const [selectedWallet, setSelectedWallet] = useState({ available_balance: 0 })
  const [majorWalletPage, setMajorWalletPage] = useState('home')
  const [walletPage, setWalletPage] = useState('dashboard')
  const [ultimateCardList, setUltimateCardList] = useState([])
  const [ListCard, setListCard] = useState([])
  const [findCard, setFindCard] = useState('')
  const [BulkAdding, setBulkAdding] = useState([])
  const [bulkAmount, setBulkAmount] = useState(0)
  const [displayType, setDisplayType] = useState('grid')
  const [cardList, setCardList] = useState('')
  const [bulkLoading, setBulkLoading] = useState(false)
  const [cardTransactions, setCardTransactions] = useState([])
  const [showPassword, setShownPassword] = useState(false)
  const [showPassword2, setShownPassword2] = useState(false)
  const [showPassword3, setShownPassword3] = useState(false)
  const [walletLoading, setWalletLoading] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm()
  const password = useRef({})
  password.current = watch('new_pin', '')
  const { user } = useGlobalContext()

  useEffect(() => {
    const bucketOfTomatoes = sessionStorage.getItem(
      'ddf8837e6d4defcc85a76e2cd2c156ef64a128e5'
    )
    API.FetchSudoAccount()
      //Add pagination for the list of transactions ad they grow, look at the total pages to find the next transaction
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setSelectedWallet(
              data.wallet_data.find((item) => item.account_id === new_id)
            )
            fetch(
              `https://p499kkwmp9.execute-api.eu-west-1.amazonaws.com/prod/wallet/wallet`,
              {
                method: 'post',
                headers: {
                  'content-Type': 'application/json',
                  Authorization: `Token ${authToken}`,
                },
                body: JSON.stringify({
                  wallet_id: data.wallet_data.find(
                    (item) => item.account_id === new_id
                  ).account_id,
                  pin: atob(bucketOfTomatoes),
                }),
              }
            ).then((res) => {
              if (res.status === 200) {
                res.json().then((data) => {
                  setWalletLoading(false)
                  setWalletInfo(data.wallet_data)
                  setListCard(data.wallet_data.cards)
                  setUltimateCardList(data.wallet_data.cards)
                  fetch(
                    `https://p499kkwmp9.execute-api.eu-west-1.amazonaws.com/prod/wallet/transactions`,
                    {
                      method: 'post',
                      headers: {
                        'content-Type': 'application/json',
                        Authorization: `Token ${authToken}`,
                      },
                      body: JSON.stringify({
                        id: data.wallet_data._id,
                      }),
                    }
                  ).then((res) => {
                    if (res.status === 200) {
                      res.json().then((data) => {
                        setWalletLoading(false)
                        setCardTransactions(data.transactions)
                      })
                    } else {
                      res.json().then((data) => {})
                    }
                  })
                })
              } else {
                res.json().then((data) => {})
              }
            })
          })
        } else {
          res.json().then((data) => {})
        }
      })
      .catch(() => {})
  }, [])
  const finddCard = (found) => {
    setFindCard(found)
    if (findCard !== '') {
      const filteredCard = ultimateCardList.filter((item) => {
        return Object.values(item)
          .join('')
          .toLowerCase()
          .includes(findCard.toLowerCase())
      })
      setListCard(filteredCard)
    } else {
      setListCard(ultimateCardList)
    }
  }
  const bulkFund = () => {
    setBulkLoading(true)
    fetch(
      `https://p499kkwmp9.execute-api.eu-west-1.amazonaws.com/prod/wallet/fund_card_account`,
      {
        method: 'post',
        headers: {
          'content-Type': 'application/json',
          Authorization: `Token ${authToken}`,
        },
        body: JSON.stringify({
          data: BulkAdding,
          loader_wallet_id: sessionStorage.getItem('wallet-account'),
          pin: atob(
            sessionStorage.getItem('ddf8837e6d4defcc85a76e2cd2c156ef64a128e5')
          ),
        }),
      }
    )
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setBulkLoading(false)
            notify('success', data.message)
          })
        } else {
          res.json().then((data) => {
            notify('error', data.message)
            setBulkLoading(false)
          })
        }
      })
      .catch(() => {
        notify('error', 'Unable to connect to server')
        setBulkLoading(false)
      })
  }
  function deleteBulk(id) {
    setBulkAdding((bulk) => {
      return bulk.filter((item) => item.card_id !== id)
    })
  }
  const onPasswordChange = (formData) => {
    setBulkLoading(true)
    fetch(
      'https://y18x0oais3.execute-api.eu-west-1.amazonaws.com/prod/wallet/pin_change',
      {
        method: 'post',
        body: JSON.stringify({
          old_pin: formData.old_pin,
          new_pin: formData.new_pin,
          new_pin2: formData.new_pin2,
          wallet_id: selectedWallet.account_id,
        }),
        headers: {
          'content-Type': 'application/json',
          Authorization: `Token ${authToken}`,
        },
      }
    )
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            notify('success', data.message)
            setBulkLoading(false)
            setTimeout(() => {
              window.location.reload()
            }, 4000)
          })
        } else {
          res.json().then((data) => {
            notify('error', data.message)
            setBulkLoading(false)
          })
        }
      })
      .catch((err) => {
        notify('error', 'Something went wrong please try again')
        setBulkLoading(false)
      })
  }
  return walletLoading ? (
    <div className='vloader'>
      <img src={Loader} />
    </div>
  ) : (
    <div className='subwallet_wallet'>
      {majorWalletPage === 'home' && (
        <>
          <div className='sub-wall-head'>
            <div className='headf'>
              <h1>Wallet - {walletInfo.accountNumber}</h1>
              <p>Manage different business e-wallets with ease.</p>
            </div>
            <div className='ballance'>
              <p>Balance:</p>{' '}
              <h2>
                NGN 
                {selectedWallet.available_balance
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </h2>
            </div>
          </div>
          <div className='sub-wall-navigate'>
            <div
              className={walletPage === 'dashboard' ? 'active' : ''}
              onClick={() => {
                setWalletPage('dashboard')
              }}
            >
              <BsStack />
              <p>Dashboard</p>
            </div>
            <div
              className={walletPage === 'transactions' ? 'active' : ''}
              onClick={() => {
                setWalletPage('transactions')
              }}
            >
              <BiTransfer />
              <p>Transactions</p>
            </div>
            <div
              className={walletPage === 'liquidate' ? 'active' : ''}
              // onClick={() => {
              //   setWalletPage('liquidate')
              // }}
              style={{ opacity: '0.1', cursor: 'not-allowed' }}
            >
              <GiReceiveMoney /> <p>Liquidate wallet</p>
            </div>
            <div
              className={walletPage === 'pin' ? 'active' : ''}
              onClick={() => {
                setWalletPage('pin')
              }}
            >
              <BiLockAlt /> <p>Wallet pin</p>
            </div>
          </div>
          <div className='sub-wall-main'>
            {walletPage === 'dashboard' && (
              <div className='wall-dash'>
                <div className='phy-card_top'>
                  <input
                    type='search'
                    name=''
                    id=''
                    placeholder='Search for card by Name or number'
                    onChange={(e) => finddCard(e.target.value)}
                  />

                  <select
                    name=''
                    id=''
                    onChange={(e) => setCardList(e.target.value)}
                  >
                    <option value=''>Sort By</option>
                    <option value='highest'>Highest balance</option>
                    <option value='lowest'>Lowest balance</option>
                  </select>
                  <div>
                    {displayType === 'grid' ? (
                      <BsListTask onClick={() => setDisplayType('list')} />
                    ) : (
                      <BsGridFill onClick={() => setDisplayType('grid')} />
                    )}
                  </div>
                </div>
                {displayType === 'grid' && (
                  <>
                    {cardList === 'highest' ? (
                      <div className='card-cover'>
                        <div className='card-box'>
                          <div className='card-box_top'>
                            <h2>request for card</h2>
                          </div>
                          <div
                            className='credit-card-body'
                            id='blank'
                            onClick={() => {
                              setMajorWalletPage('cardAsk')
                            }}
                          >
                            <div className='click'></div>
                            <div className='click'></div>
                          </div>
                        </div>
                        {findCard.length > 1 ? (
                          <>
                            {ListCard.sort((a, b) => b.balance - a.balance).map(
                              (item) => (
                                <CardBox
                                  item={item}
                                  key={item.id}
                                  navigate={navigate}
                                />
                              )
                            )}
                          </>
                        ) : (
                          <>
                            {ultimateCardList
                              .sort((a, b) => b.balance - a.balance)
                              .map((item) => (
                                <CardBox
                                  item={item}
                                  key={item.id}
                                  navigate={navigate}
                                />
                              ))}
                          </>
                        )}
                      </div>
                    ) : (
                      <div className='card-cover'>
                        <div className='card-box'>
                          <div className='card-box_top'>
                            <h2>request for card</h2>
                          </div>
                          <div
                            className='credit-card-body'
                            id='blank'
                            onClick={() => {
                              setMajorWalletPage('cardAsk')
                            }}
                          >
                            <div className='click'></div>
                            <div className='click'></div>
                          </div>
                        </div>
                        {findCard.length > 1 ? (
                          <>
                            {ListCard.sort((a, b) => a.balance - b.balance).map(
                              (item) => (
                                <CardBox
                                  item={item}
                                  key={item.id}
                                  navigate={navigate}
                                />
                              )
                            )}
                          </>
                        ) : (
                          <>
                            {ultimateCardList
                              .sort((a, b) => a.balance - b.balance)
                              .map((item) => (
                                <CardBox
                                  item={item}
                                  key={item.id}
                                  navigate={navigate}
                                />
                              ))}
                          </>
                        )}
                      </div>
                    )}
                  </>
                )}
                {displayType === 'list' && (
                  <div className='table-boss'>
                    <div className='fund-top'>
                      <input
                        type='number'
                        name=''
                        min={1}
                        id=''
                        onChange={(e) => setBulkAmount(e.target.value)}
                        disabled={BulkAdding.length > 0 ? true : false}
                        placeholder='Amount to fund cards'
                      />
                      <button
                        onClick={() => {
                          if (BulkAdding.length === 0) {
                            notify('error', 'Please select cards')
                          } else {
                            bulkFund()
                          }
                        }}
                      >
                        {bulkLoading ? (
                          <img
                            style={{
                              width: '50px',
                              height: '10px',
                              objectFit: 'cover',
                            }}
                            src={Loader}
                          />
                        ) : (
                          'Bulk Fund Card'
                        )}
                      </button>
                    </div>
                    {cardList === 'highest' ? (
                      <div className='table-cover'>
                        <table>
                          <thead>
                            <tr>
                              <th>Name on card</th>
                              <th>Card number</th>
                              <th>Expiry date</th>
                              <th>Card balance</th>
                              <th>Bulk Funding</th>
                            </tr>
                          </thead>
                          <tbody>
                            {findCard.length > 1 ? (
                              <>
                                {ListCard.sort(
                                  (a, b) => b.balance - a.balance
                                ).map((item) => (
                                  <ListBox
                                    item={item}
                                    key={item.id}
                                    setBulkAdding={setBulkAdding}
                                    BulkAdding={BulkAdding}
                                    deleteBulk={deleteBulk}
                                    bulkAmount={bulkAmount}
                                    notify={notify}
                                  />
                                ))}
                              </>
                            ) : (
                              <>
                                {ultimateCardList
                                  .sort((a, b) => b.balance - a.balance)
                                  .map((item) => (
                                    <ListBox
                                      item={item}
                                      key={item.id}
                                      setBulkAdding={setBulkAdding}
                                      BulkAdding={BulkAdding}
                                      deleteBulk={deleteBulk}
                                      bulkAmount={bulkAmount}
                                      notify={notify}
                                    />
                                  ))}
                              </>
                            )}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div className='table-cover'>
                        <table>
                          <thead>
                            <tr>
                              <th>Name on card</th>
                              <th>Card number</th>
                              <th>Expiry date</th>
                              <th>Card balance</th>
                              <th>Bulk Funding</th>
                            </tr>
                          </thead>
                          <tbody>
                            {findCard.length > 1 ? (
                              <>
                                {ListCard.sort(
                                  (a, b) => a.balance - b.balance
                                ).map((item) => (
                                  <ListBox
                                    item={item}
                                    key={item.id}
                                    setBulkAdding={setBulkAdding}
                                    BulkAdding={BulkAdding}
                                    deleteBulk={deleteBulk}
                                    bulkAmount={bulkAmount}
                                    notify={notify}
                                  />
                                ))}
                              </>
                            ) : (
                              <>
                                {ultimateCardList
                                  .sort((a, b) => a.balance - b.balance)
                                  .map((item) => (
                                    <ListBox
                                      item={item}
                                      key={item.id}
                                      setBulkAdding={setBulkAdding}
                                      BulkAdding={BulkAdding}
                                      deleteBulk={deleteBulk}
                                      bulkAmount={bulkAmount}
                                      notify={notify}
                                    />
                                  ))}
                              </>
                            )}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
            {walletPage === 'transactions' && (
              <div className='wall-trans'>
                <div className='card_transactions'>
                  <table>
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Type</th>
                        <th>Amount</th>
                        <th>Naration</th>
                        <th>Provider</th>
                        <th>Balance</th>
                      </tr>
                    </thead>
                    <tbody>
                      {cardTransactions
                        .sort((a, b) => b.tx_date.localeCompare(a.tx_date))
                        .map((item) => (
                          <tr key={item.tx_id}>
                            <td className='date'>
                              {new Date(item.tx_date).toDateString()}
                            </td>
                            <td className={item.mode}>{item.mode}</td>
                            <td>₦{item.amount.toLocaleString()}</td>
                            <td>{item.narration}</td>
                            <td>{item.provider}</td>
                            <td>₦{item.running_balance.toLocaleString()}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
            {walletPage === 'liquidate' && <div>liquidate</div>}
            {walletPage === 'pin' && (
              <div className='wall-pin'>
                <div className='toprr'>
                  <h3>Change Wallet Pin</h3>
                  <p>
                    Default pin is <b>1234</b>
                  </p>
                </div>
                <form onSubmit={handleSubmit(onPasswordChange)}>
                  <div className='card'>
                    <p>Current Pin</p>
                    <div className='input'>
                      <input
                        type={showPassword ? 'number' : 'password'}
                        name='old_pin'
                        id=''
                        {...register('old_pin', {
                          required: 'Provide a current pin',
                          minLength: {
                            value: 4,
                            message:
                              'Current pin must have at least 4 characters',
                          },
                          maxLength: {
                            value: 4,
                            message:
                              'Current pin must have at least 4 characters',
                          },
                          pattern: {
                            value: /^(?=.*?[0-9]).{4,}$/,
                            message: 'Pin must be numbers',
                          },
                        })}
                      />
                      {showPassword ? (
                        <FaRegEyeSlash
                          onClick={() => {
                            setShownPassword(!showPassword)
                          }}
                        />
                      ) : (
                        <FaRegEye
                          onClick={() => {
                            setShownPassword(!showPassword)
                          }}
                        />
                      )}
                    </div>
                    {errors.old_pin && (
                      <h6
                        className='vError'
                        style={{ fontSize: '10px', color: 'red' }}
                      >
                        {errors.old_pin.message}
                      </h6>
                    )}
                  </div>
                  <div className='card'>
                    <p>New Pin</p>
                    <div className='input'>
                      <input
                        type={showPassword2 ? 'number' : 'password'}
                        name='new_pin'
                        {...register('new_pin', {
                          required: 'Provide a new pin',
                          minLength: {
                            value: 4,
                            message:
                              'Current pin must have at least 4 characters',
                          },
                          maxLength: {
                            value: 4,
                            message:
                              'Current pin must have at least 4 characters',
                          },
                          pattern: {
                            value: /^(?=.*?[0-9]).{4,}$/,
                            message: 'Pin must be numbers',
                          },
                        })}
                      />
                      {showPassword2 ? (
                        <FaRegEyeSlash
                          onClick={() => {
                            setShownPassword2(!showPassword2)
                          }}
                        />
                      ) : (
                        <FaRegEye
                          onClick={() => {
                            setShownPassword2(!showPassword2)
                          }}
                        />
                      )}
                    </div>
                    {errors.new_pin && (
                      <h6
                        className='vError'
                        style={{ fontSize: '10px', color: 'red' }}
                      >
                        {errors.new_pin.message}
                      </h6>
                    )}
                  </div>
                  <div className='card'>
                    <p>Confirm New Pin</p>
                    <div className='input'>
                      <input
                        type={showPassword3 ? 'number' : 'password'}
                        name='new_pin2'
                        {...register('new_pin2', {
                          required: 'You must re-enter your pin',
                          validate: (value) =>
                            value === password.current ||
                            'The pin do not match',
                        })}
                      />
                      {showPassword3 ? (
                        <FaRegEyeSlash
                          onClick={() => {
                            setShownPassword3(!showPassword3)
                          }}
                        />
                      ) : (
                        <FaRegEye
                          onClick={() => {
                            setShownPassword3(!showPassword3)
                          }}
                        />
                      )}
                    </div>
                    {errors.new_pin2 && (
                      <h6
                        className='vError'
                        style={{ fontSize: '10px', color: 'red' }}
                      >
                        {errors.new_pin2.message}
                      </h6>
                    )}
                  </div>
                  {bulkLoading ? (
                    <img src={Loader} />
                  ) : (
                    <div className='button'>
                      <button>Change Pin</button>
                    </div>
                  )}
                </form>
              </div>
            )}
          </div>
        </>
      )}
      {majorWalletPage === 'cardAsk' && (
        <>
          <div className=''>
            <RequestCard
              notify={notify}
              setMajorWalletPage={setMajorWalletPage}
              selectedWallet={selectedWallet}
            />
          </div>
        </>
      )}
    </div>
  )
}

export default SubwalletBoard
