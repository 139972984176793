import React, { useState, useEffect } from 'react'
import { BsFillCheckSquareFill, BsSquare } from 'react-icons/bs'
import { useNavigate } from 'react-router'
import { FcOk, FcCancel } from 'react-icons/fc'
import { useForm, Controller } from 'react-hook-form'
import Exclamation from '../../../../../Image/Status Report.png'
import PinInput from 'react-pin-input'
import Loader from '../../../../../Image/loder.gif.gif'
import API from '../../../../../Context/API'
import { FaChevronLeft } from 'react-icons/fa'
import sudo_bank_codes from '../../../../../Data/BankCodes'

const navigatorButton = [
  {
    id: 6,
    name: 'Main wallet',
    text: `Local bank transfers`,
    available: 'false',
    link: 'bank-account-naira',
  },
  {
    id: 5,
    name: 'Main wallet',
    text: `Transfer to another Spendwise user's account`,
    available: 'true',
    link: 'spendwise-wallet-naira',
  },
  {
    id: 2,
    name: 'Foreign Account',
    // text: `Transfer to a foreign account of your choice`,
    text: `comming soon...`,
    available: 'false',
    link: 'foreign-account',
  },
  {
    id: 3,
    name: 'Self Account',
    // text: `Transfer to your connected banks`,
    text: `comming soon...`,
    available: 'false',
    link: 'self-account',
  },
]
const bank_codes = [
  {
    BankCode: '044',
    BankName: 'Access Bank Nigeria',
    BankSortCode: '000014',
  },
  {
    BankCode: '035A',
    BankName: 'Alat By Wema',
    BankSortCode: '000017',
  },
  {
    BankCode: '044',
    BankName: 'Diamond (Access Bank Plc)',
    BankSortCode: '000014',
  },
  {
    BankCode: '050',
    BankName: 'Ecobank Nigeria Plc',
    BankSortCode: '000010',
  },
  {
    BankCode: '070',
    BankName: 'Fidelity Bank Plc',
    BankSortCode: '000007',
  },
  {
    BankCode: '011',
    BankName: 'First Bank Plc',
    BankSortCode: '000016',
  },
  {
    BankCode: '214',
    BankName: 'First City Monument Bank',
    BankSortCode: '000003',
  },
  {
    BankCode: '058',
    BankName: 'Gtbank Plc',
    BankSortCode: '000013',
  },
  {
    BankCode: '030',
    BankName: 'Heritage Bank',
    BankSortCode: '000020',
  },
  {
    BankCode: '090175',
    BankName: 'Highstreet Microfinance Bank',
    BankSortCode: '090175',
  },
  {
    BankCode: '301',
    BankName: 'Jaiz Bank',
    BankSortCode: '000006',
  },
  {
    BankCode: '082',
    BankName: 'Keystone Bank Plc',
    BankSortCode: '000002',
  },
  {
    BankCode: '100011',
    BankName: 'Kuda',
    BankSortCode: '090267',
  },
  {
    BankCode: '100026',
    BankName: 'One Finance',
    BankSortCode: '100026',
  },
  {
    BankCode: '076',
    BankName: 'Polaris Bank',
    BankSortCode: '000008',
  },
  {
    BankCode: '101',
    BankName: 'Providus Bank',
    BankSortCode: '000023',
  },
  {
    BankCode: '039',
    BankName: 'Stanbic Ibtc Bank Plc',
    BankSortCode: '000012',
  },
  {
    BankCode: '068',
    BankName: 'Standard Chartered Bank Nigeria Limited',
    BankSortCode: '000021',
  },
  {
    BankCode: '232',
    BankName: 'Sterling Bank Plc',
    BankSortCode: '000001',
  },
  {
    BankCode: '100',
    BankName: 'Suntrust Bank',
    BankSortCode: '000022',
  },
  {
    BankCode: '032',
    BankName: 'Union Bank Of Nigeria Plc',
    BankSortCode: '000018',
  },
  {
    BankCode: '033',
    BankName: 'United Bank For Africa Plc',
    BankSortCode: '000004',
  },
  {
    BankCode: '215',
    BankName: 'Unity Bank Plc',
    BankSortCode: '000011',
  },
  {
    BankCode: '035',
    BankName: 'Wema Bank Plc',
    BankSortCode: '000017',
  },
  {
    BankCode: '057',
    BankName: 'Zenith Bank Plc',
    BankSortCode: '000015',
  },
]

function TransferWallet({ notify, bank }) {
  const navigate = useNavigate()
  const authToken = sessionStorage.getItem('userId')
  const [userBankDet, setUserBankDet] = useState({
    BankCode: '',
    BankName: '',
  })

  const [sudoUserBankDet, setSudoUserBankDet] = useState({
    bankCode: '',
    name: '',
  })
  const [transferType, setTransferType] = useState('bank-account-naira')
  const [formLoading, setFormLoading] = useState(false)
  const [transferStage, setTransferStage] = useState('one')
  const [AmountGreater, setAmountGreater] = useState(false)
  const [userWalletDetails, setUserWalletDetails] = useState({})
  const [accountNumberFecthing, setAccountNumberFecthing] = useState()
  const [accountNumberCorrect, setAccountNumberCorrect] = useState(false)
  const [AccountDetails, setAccountDetails] = useState({
    account_name: '',
  })
  const [sudoAccountDetails, setSudoAccountDetails] = useState({
    ac_name: '',
  })
  const [walletDetails, setWalletDetails] = useState({
    account_name: '',
  })
  const [saveBeneficiary, setSaveBeneficiary] = useState(false)
  const [saveBeneficiaryTrue, setSaveBeneficiaryTrue] = useState('no')
  const [transferAmmount, setTransferAmmount] = useState(0)
  const [transferNarration, setTransferNarration] = useState('')
  const [transferPin, setTransferPin] = useState('')
  const [spendwiseUsername, setSpendwiseUsername] = useState('')
  const [walletError, setWalletError] = useState('')
  const [transferResponse, setTransferResponse] = useState({})
  useEffect(() => {
    // redundant code
    API.checkWallet()
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setUserWalletDetails(data[0])
          })
        } else {
          res.json().then((data) => {})
        }
      })
      .catch(() => {
        notify('error', 'Internal server errorr, Contact support.')
      })
  }, [])
  const fetchUseBankDet = (e) => {
    const findUserBank = bank_codes.find((item) => item.BankCode === e)
    setUserBankDet(findUserBank)
  }
  const fetchSudoUseBankDet = (e) => {
    const findUserBank = sudo_bank_codes.find((item) => item.bankCode === e)
    setSudoUserBankDet(findUserBank)
  }
  // fetchUseBankDete()
  const fetchUserName = (account_no) => {
    setAccountNumberFecthing(true)
    fetch(`${process.env.REACT_APP_API_URL}/purse/verify_account/`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
      body: JSON.stringify({
        account_no: account_no,
        bank_code: userBankDet.BankCode,
        issuer: 'mono',
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          setAccountNumberCorrect(true)
          res.json().then((data) => {
            setAccountDetails(data)
          })
        } else {
          setAccountNumberFecthing(false)
          res.json().then((data) => {
            setAccountNumberCorrect(false)
          })
        }
      })
      .catch(() => {
        notify('error', 'Internal server errorr, Contact support.')
      })
  }
  const fetchSudoUserName = (account_no) => {
    setAccountNumberFecthing(true)
    fetch(
      `https://p499kkwmp9.execute-api.eu-west-1.amazonaws.com/prod/wallet/verify`,
      {
        method: 'post',
        headers: {
          'content-Type': 'application/json',
          Authorization: `Token ${authToken}`,
        },
        body: JSON.stringify({
          ac_no: account_no,
          bank_code: sudoUserBankDet.bankCode,
        }),
      }
    )
      .then((res) => {
        if (res.status === 200) {
          setAccountNumberCorrect(true)
          res.json().then((data) => {
            setSudoAccountDetails(data.message)
          })
        } else {
          setAccountNumberFecthing(false)
          res.json().then((data) => {
            setAccountNumberCorrect(false)
          })
        }
      })
      .catch(() => {
        notify('error', 'Internal server errorr, Contact support.')
      })
  }
  const fetchWalletUsername = () => {
    setAccountNumberFecthing(true)
    fetch(`${process.env.REACT_APP_API_URL}/purse/verify_wallet_account/`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
      body: JSON.stringify({
        account: spendwiseUsername,
        issuer: 'mono',
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          setAccountNumberCorrect(true)
          res.json().then((data) => {
            setWalletDetails(data)
          })
        } else {
          setAccountNumberFecthing(false)
          res.json().then((data) => {
            setWalletError(data.message)
            setAccountNumberCorrect(false)
          })
        }
      })
      .catch(() => {
        notify('error', 'Internal server errorr, Contact support.')
      })
  }
  const walletVerifyTransfer = () => {
    setFormLoading(true)
    fetch(`${process.env.REACT_APP_API_URL}/purse/transfer_in/`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
      // body: JSON.stringify(walletTransfer),
      body: JSON.stringify({
        receiver_id: walletDetails.user,
        amount: transferAmmount,
        save_action: saveBeneficiaryTrue,
        pin: transferPin,
        username: spendwiseUsername,
        narration: transferNarration,
        issuer: 'mono',
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          setFormLoading(false)
          res.json().then((data) => {
            setTransferResponse(data)
            notify('success', data.message)
            setTransferStage('success')
          })
        } else {
          setFormLoading(false)
          res.json().then((data) => {
            notify('error', data.message.message)
            notify('error', data.message)
            if (data.message.includes('Magic PIN not set')) {
              setTimeout(() => {
                navigate('/settings/security')
              }, 4000)
            }
          })
        }
      })
      .catch(() => {
        notify('error', 'Internal server errorr, Contact support.')
      })
  }
  const nairaWalletVerifyTransfer = (value) => {
    setFormLoading(true)
    fetch(
      `https://p499kkwmp9.execute-api.eu-west-1.amazonaws.com/prod/wallet/transfer`,
      {
        method: 'post',
        headers: {
          'content-Type': 'application/json',
          Authorization: `Token ${authToken}`,
        },
        body: JSON.stringify({
          receiver_id: walletDetails.user,
          amount: transferAmmount,
          pin: value,
          direction: 'in',
          narration: transferNarration,
          issuer: 'sudo',
        }),
      }
    )
      .then((res) => {
        if (res.status === 200) {
          setFormLoading(false)
          res.json().then((data) => {
            setTransferResponse(data)
            notify('success', data.message)
            setTransferStage('success')
          })
        } else {
          setFormLoading(false)
          res.json().then((data) => {
            notify('error', data.message.message)
            notify('error', data.message)
            if (data.message.includes('Magic PIN not set')) {
              setTimeout(() => {
                navigate('/settings/security')
              }, 4000)
            }
          })
        }
      })
      .catch(() => {
        notify('error', 'Internal server errorr, Contact support.')
        setFormLoading(false)
      })
  }
  const localVerifyTransfer = () => {
    setFormLoading(true)
    fetch(`${process.env.REACT_APP_API_URL}/purse/transfer_out/`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
      body: JSON.stringify({
        account_no: AccountDetails.account_no,
        bank_code: userBankDet.BankCode,
        ac_name: AccountDetails.account_name,
        amount: transferAmmount,
        narration: transferNarration,
        save_action: saveBeneficiaryTrue,
        bank_name: userBankDet.BankName,
        pin: transferPin,
        issuer: 'mono',
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setTransferStage('success')
            setFormLoading(false)
            setTransferResponse(data)
          })
        } else {
          setFormLoading(false)
          res.json().then((data) => {
            notify('error', data.message)
            //  setAccountNumberCorrect(false)
            if (data.message.includes('Magic PIN not set')) {
              setTimeout(() => {
                navigate('/settings/security')
              }, 4000)
            } else if (data.message.includes('Transfer is processing')) {
              setTransferStage('success')
            }
          })
        }
      })
      .catch(() => {
        notify('error', 'Internal server errorr, Contact support.')
      })
  }
  const nairaLocalVerifyTransfer = (value) => {
    setFormLoading(true)
    fetch(
      `https://p499kkwmp9.execute-api.eu-west-1.amazonaws.com/prod/wallet/transfer`,
      {
        method: 'post',
        headers: {
          'content-Type': 'application/json',
          Authorization: `Token ${authToken}`,
        },
        body: JSON.stringify({
          ac_no: sudoAccountDetails.ac_no,
          bank_code: sudoUserBankDet.bankCode,
          amount: transferAmmount,
          narration: transferNarration,
          pin: value,
          issuer: 'sudo',
          direction: 'out',
        }),
      }
    )
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setTransferStage('success')
            setFormLoading(false)
            setTransferResponse(data)
          })
        } else {
          setFormLoading(false)
          res.json().then((data) => {
            notify('error', data.message)
            //  setAccountNumberCorrect(false)
            if (data.message.includes('Magic PIN not set')) {
              setTimeout(() => {
                navigate('/settings/security')
              }, 4000)
            }
          })
        }
      })
      .catch((err) => {
        notify('info', 'Transfer processing.')
        setFormLoading(false)
        setTimeout(() => {
          window.location.replace('/e-wallet/dashboard')
        }, 4000)
      })
  }
  const VerifyBalance = () => {
    // if (transferAmmount >= parseInt(userWalletDetails.available_balance)) {
    //   setTransferStage('two')
    //   setAmountGreater(true)
    // } else {
    //   setTransferStage('two')
    //   setAmountGreater(false)
    // }
    setTransferStage('two')
  }
  const VerifySudoBalance = () => {
    // if (transferAmmount >= parseInt(userWalletDetails.available_balance)) {
    //   setTransferStage('two')
    //   setAmountGreater(true)
    // } else {
    //   setTransferStage('two')
    //   setAmountGreater(false)
    // }
    setTransferStage('two')
  }
  const checkForEmptyWallet = () => {
    if (
      transferAmmount === 0 ||
      spendwiseUsername === '' ||
      walletDetails.account_name === '' ||
      transferNarration === ''
    ) {
      notify('error', 'Fill up empty fields')
    } else {
      VerifyBalance()
    }
  }
  const checkForEmptyLocal = () => {
    if (transferAmmount === 0 || transferNarration === '') {
      notify('error', 'Fill up empty fields')
    } else {
      VerifyBalance()
    }
  }
  const refreshAll = () => {
    setTransferAmmount(0)
    setTransferStage('one')
    setUserWalletDetails({})
    setAccountNumberFecthing()
    setAmountGreater(false)
    setAccountNumberCorrect(false)
    setAccountDetails({ account_name: '' })
    setWalletDetails({ account_name: '' })
    setSaveBeneficiary(false)
    setSaveBeneficiaryTrue('no')
    setTransferNarration('')
    setTransferPin('')
    setSpendwiseUsername('')
    setWalletError('')
    setTransferResponse({})
  }
  return (
    <div className='wallet_transfer'>
      {transferType === 'spendwise-wallet-naira' && (
        <>
          {transferStage === 'one' && (
            <div className='transfer_stage1'>
              <div className='wallet_top'>
                <h2 className='wallet-text'>E-Wallet</h2>
              </div>
              <div className='wallet_transfer-wrap'>
                <div className='wallet_transfer-navigatior'>
                  {navigatorButton.map((item) => (
                    <button
                      key={item.id}
                      className={item.link === transferType ? 'true' : 'false'}
                      onClick={() => {
                        if (
                          item.link === 'foreign-account' ||
                          item.link === 'self-account'
                        ) {
                        } else {
                          setTransferType(item.link)
                          refreshAll()
                        }
                      }}
                    >
                      <h2>{item.name}</h2>
                      <p>{item.text}</p>
                    </button>
                  ))}
                </div>
                <div className='wallet_transfer-body'>
                  <div className='back-but'>
                    <p onClick={() => navigate('/e-wallet/dashboard')}>
                      <FaChevronLeft /> Back
                    </p>
                  </div>
                  <div className='wallet_transfer-body_top'>
                    <h2>Spendwise Wallet</h2>
                    <p>How much would you like to transfer?</p>
                    <div className='amount-input'>
                      <div>
                        <h3> ₦</h3>
                        <input
                          type='number'
                          onChange={(e) => setTransferAmmount(e.target.value)}
                          min='1'
                          defaultValue={transferAmmount}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='wrapper'>
                    <div className='card diff'>
                      <p>
                        Spendwise Username{' '}
                        {accountNumberCorrect ? <FcOk /> : <FcCancel />}
                      </p>
                      <div>
                        <input
                          type='text'
                          onChange={(e) => {
                            setSpendwiseUsername(e.target.value)
                          }}
                          defaultValue={spendwiseUsername}
                        />
                      </div>
                      {accountNumberFecthing === false && (
                        <p
                          style={{
                            color: 'red',
                            fontSize: '10px',
                            paddingTop: '5px',
                          }}
                        >
                          {walletError}
                        </p>
                      )}
                      <button onClick={() => fetchWalletUsername()}>
                        Find User
                      </button>
                    </div>
                  </div>
                  <div className='card diff'>
                    <p>Account Name</p>
                    <input
                      type='text'
                      value={walletDetails.account_name}
                      disabled
                      readOnly
                    />
                  </div>
                  <div className='card diff'>
                    <p>Naration</p>
                    <textarea
                      name=''
                      id=''
                      cols='30'
                      rows='5'
                      onChange={(e) => setTransferNarration(e.target.value)}
                      defaultValue={transferNarration}
                    ></textarea>
                  </div>
                  <div className='transfer_save'>
                    {saveBeneficiary ? (
                      <BsFillCheckSquareFill className='ticked' />
                    ) : (
                      <BsSquare
                        className='not-tick'
                        onClick={() => {
                          setSaveBeneficiary(true)
                          setSaveBeneficiaryTrue('save')
                        }}
                      />
                    )}
                    <p className='gen'>Generate & Save Receipt</p>
                  </div>
                  <div className='transfer_buttons'>
                    <button onClick={() => navigate('/e-wallet/dashboard')}>
                      Cancel
                    </button>
                    <button
                      onClick={() => {
                        checkForEmptyWallet()
                      }}
                    >
                      Send
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {transferStage === 'two' && (
            <div className='transfer_stage2'>
              <div className='wallet_transfer-wrap'>
                {AmountGreater && (
                  <div className='transfer_Modal'>
                    <div className='info_card'>
                      <img src={Exclamation} alt='' />
                      <p>
                        You can not transfer an amount greater than your current
                        balance.
                      </p>
                      <button
                        onClick={() => {
                          setTransferStage('one')
                          setAmountGreater(false)
                        }}
                      >
                        Back
                      </button>
                    </div>
                  </div>
                )}
                <div className='wallet_transfer-body'>
                  <div
                    className='wallet_transfer-body_top'
                    style={{ paddingBottom: '30px' }}
                  >
                    <h2 style={{ fontSize: '30px' }}>Transfer Details</h2>
                    <p style={{ fontSize: '20px' }}>
                      Please verify the information below.
                    </p>
                  </div>
                  <div className='wallet_transfer-body_top'>
                    <div className='amount-input'>
                      <div>
                        <h3> ₦</h3>
                        <input type='text' disabled value={transferAmmount} />
                      </div>
                    </div>
                  </div>
                  <div className='wrapper'>
                    <div className='card diff'>
                      <p>Spendwise Username</p>
                      <input
                        type='text'
                        name=''
                        id=''
                        value={spendwiseUsername}
                        disabled
                      />
                    </div>
                  </div>
                  <div className='card diff'>
                    <p>Naration</p>
                    <textarea
                      name=''
                      id=''
                      cols='30'
                      rows='5'
                      value={transferNarration}
                      disabled
                    ></textarea>
                  </div>
                  <div
                    className='transfer_save'
                    style={{
                      paddingBottom: '20px',
                      color: '#000',
                      justifyContent: 'right',
                      width: '100%',
                    }}
                  >
                    <p
                      className='gen'
                      style={{ color: '#000', fontSize: '20px' }}
                    >
                      Transfer Fee is ₦50<span style={{ color: 'red' }}>*</span>
                    </p>
                  </div>
                  <div className='transfer_save'>
                    {saveBeneficiary ? (
                      <BsFillCheckSquareFill className='ticked' />
                    ) : (
                      <BsSquare className='not-tick' />
                    )}
                    <p className='gen'>Generate & Save Receipt</p>
                  </div>
                  <div className='transfer_buttons'>
                    <button onClick={() => setTransferStage('one')}>
                      Edit
                    </button>
                    <button onClick={() => navigate('/e-wallet/dashboard')}>
                      Cancel
                    </button>
                    <button
                      onClick={() => {
                        setTransferStage('three')
                      }}
                    >
                      Send
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {transferStage === 'three' && (
            <div className='transfer_pin'>
              <div className='wallet_transfer-wrap'>
                <div className='wallet_transfer-body'>
                  <div
                    className='wallet_transfer-body_top'
                    style={{ paddingBottom: '30px' }}
                  >
                    <h2 style={{ fontSize: '30px' }}>Transfer Verification</h2>
                    <p style={{ fontSize: '20px' }}>
                      Please input your Magic PIN to initiate this transaction.
                    </p>
                  </div>
                  <div className='transfer_pin'>
                    <div className='pin_wrapper'>
                      <PinInput
                        length={4}
                        initialValue=''
                        secret
                        type='numeric'
                        inputMode='numeric'
                        style={{ padding: '10px' }}
                        inputStyle={{
                          borderColor: '#e6e6e6',
                          borderWidth: '3px',
                          borderStyle: 'solid',
                          background: '#f1f1f1',
                          fontSize: ' 3rem',
                          borderRadius: '20px',
                          height: '70px',
                          width: '70px',
                          margin: '0 5px',
                        }}
                        inputFocusStyle={{ borderColor: 'blue' }}
                        onComplete={(value, index) => {
                          setTransferPin(value)
                          nairaWalletVerifyTransfer(value)
                        }}
                        autoSelect={true}
                        regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                      />
                      {formLoading ? (
                        <div className='loader'>
                          <img src={Loader} alt='' />
                        </div>
                      ) : (
                        <div className='t_button'>
                          <button
                            onClick={() => {
                              nairaWalletVerifyTransfer()
                            }}
                          >
                            Confirm & Transfer
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {transferStage === 'success' && (
            <div className='success'>
              <div>
                {' '}
                <div className='req-top'>
                  <h2>Transfer Status</h2>
                  <p>Your Transfer was successfully proccessed!</p>
                </div>
                <div className='req-center'>
                  <FcOk />
                  <h3>Successful</h3>
                </div>
                <div className='req-botom'>
                  <button
                    onClick={() => {
                      window.location.replace('/e-wallet/dashboard')
                    }}
                  >
                    Done
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {transferType === 'self-account' && (
        <>
          {transferStage === 'one' && (
            <div className='transfer_stage1'>
              <div className='wallet_top'>
                <h2 className='wallet-text'>E-Wallet</h2>
              </div>
              <div className='wallet_transfer-wrap'>
                <div className='wallet_transfer-navigatior'>
                  {navigatorButton.map((item) => (
                    <button
                      key={item.id}
                      className={item.link === transferType ? 'true' : 'false'}
                      onClick={() => setTransferType(item.link)}
                    >
                      <h2>{item.name}</h2>
                      <p>{item.text}</p>
                    </button>
                  ))}
                </div>
                <div className='wallet_transfer-body'>
                  <div className='back-but'>
                    <p onClick={() => navigate('/e-wallet/dashboard')}>
                      <FaChevronLeft /> Back
                    </p>
                  </div>
                  <div className='wallet_transfer-body_top'>
                    <h2>Conncted Banks</h2>
                    <p>How much would you like to transfer?</p>
                    <div className='amount-input'>
                      <div>
                        <h3> ₦</h3>
                        <input type='number' />
                      </div>
                    </div>
                  </div>
                  <div className='wallet_benefeiaries'>
                    <p>Select Account</p>
                    <select name='' id=''>
                      <option value=''>Select</option>
                    </select>
                  </div>
                  <div className='wrapper'>
                    <div className='card'>
                      <p>Bank Name</p>
                      <input type='text' />
                    </div>
                    <div className='card'>
                      <p>Account Number</p>
                      <div>
                        <input
                          type='tel'
                          onChange={(e) => {
                            if (e.target.value.length === 10) {
                              fetchUserName(e.target.value)
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='card diff'>
                    <p>Account Name</p>
                    <input type='text' />
                  </div>
                  <div className='card diff'>
                    <p>Naration</p>
                    <textarea name='' id='' cols='30' rows='5'></textarea>
                  </div>
                  <div className='transfer_save'>
                    <BsFillCheckSquareFill className='ticked' />
                    <p className='gen'>Generate & Save Receipt</p>
                  </div>
                  <div className='transfer_buttons'>
                    <button onClick={() => navigate('/e-wallet/dashboard')}>
                      Cancel
                    </button>
                    <button onClick={() => setTransferStage('two')}>
                      Send
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {transferStage === 'two' && (
            <div className='transfer_stage2'>
              <div className='wallet_transfer-wrap'>
                {AmountGreater && (
                  <div className='transfer_Modal'>
                    <div className='info_card'>
                      <img src={Exclamation} alt='' />
                      <p>
                        You can not transfer an amount greater than your current
                        balance.
                      </p>
                      <button
                        onClick={() => {
                          setTransferStage('one')
                        }}
                      >
                        Back
                      </button>
                    </div>
                  </div>
                )}
                <div className='wallet_transfer-body'>
                  <div
                    className='wallet_transfer-body_top'
                    style={{ paddingBottom: '30px' }}
                  >
                    <h2 style={{ fontSize: '30px' }}>Transfer Details</h2>
                    <p style={{ fontSize: '20px' }}>
                      Please verify the information below.
                    </p>
                  </div>
                  <div className='wallet_transfer-body_top'>
                    <div className='amount-input'>
                      <div>
                        <h3> ₦</h3>
                        <input
                          type='text'
                          readOnly
                          disabled
                          value='10,000,000'
                        />
                      </div>
                    </div>
                  </div>
                  <div className='wrapper'>
                    <div className='card'>
                      <p>Bank Name</p>
                      <input type='text' name='' id='' value='Gt Bank' />
                    </div>
                    <div className='card'>
                      <p>Account Number</p>
                      <div>
                        <input type='tel' value='0458473697' />
                      </div>
                    </div>
                  </div>
                  <div className='card diff'>
                    <p>Account Name</p>
                    <input type='text' value='Igbinosa Iwinosa Godsfavour' />
                  </div>
                  <div className='card diff'>
                    <p>Naration</p>
                    <textarea
                      name=''
                      id=''
                      cols='30'
                      rows='5'
                      value='For the Babes Them'
                    ></textarea>
                  </div>
                  <div className='transfer_save'>
                    <label className='container'>
                      Generate & Save Receipt
                      <input type='checkbox' disabled checked='checked' />
                      <span className='checkmark'></span>
                    </label>
                  </div>
                  <div className='transfer_buttons'>
                    <button onClick={() => setTransferStage('one')}>
                      Edit
                    </button>
                    <button onClick={() => navigate('/e-wallet/dashboard')}>
                      Cancel
                    </button>
                    <button onClick={() => setTransferStage('three')}>
                      Send
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {transferStage === 'three' && (
            <div className='transfer_pin'>
              <div className='wallet_transfer-wrap'>
                <div className='wallet_transfer-body'>
                  <div
                    className='wallet_transfer-body_top'
                    style={{ paddingBottom: '30px' }}
                  >
                    <h2 style={{ fontSize: '30px' }}>Transfer Verification</h2>
                    <p style={{ fontSize: '20px' }}>
                      Please input your Magic PIN to initiate this transaction.
                    </p>
                  </div>
                  <div className='transfer_pin'>
                    <div className='pin_wrapper'>
                      <PinInput
                        length={4}
                        initialValue=''
                        secret
                        onChange={(value, index) => {}}
                        type='numeric'
                        inputMode='numeric'
                        style={{ padding: '10px' }}
                        inputStyle={{
                          borderColor: '#e6e6e6',
                          borderWidth: '3px',
                          borderStyle: 'solid',
                          background: '#f1f1f1',
                          fontSize: ' 3rem',
                          borderRadius: '20px',
                          height: '70px',
                          width: '70px',
                          margin: '0 5px',
                        }}
                        inputFocusStyle={{ borderColor: 'blue' }}
                        onComplete={(value, index) => {}}
                        autoSelect={true}
                        regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                      />
                      <div className='t_button'>
                        <button>Confirm & Transfer</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {transferStage === 'success' && (
            <div className='success'>
              <div>
                {' '}
                <div className='req-top'>
                  <h2>Transfer Status</h2>
                  <p>Your Transfer was successfully proccessed!</p>
                </div>
                <div className='req-center'>
                  <FcOk />
                  <h3>Successful</h3>
                </div>
                <div className='req-botom'>
                  <button
                    onClick={() => {
                      window.location.replace('/e-wallet/dashboard')
                    }}
                  >
                    Done
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {transferType === 'bank-account-naira' && (
        <>
          {transferStage === 'one' && (
            <div className='transfer_stage1'>
              <div className='wallet_top'>
                <h2 className='wallet-text'>E-Wallet</h2>
              </div>
              <div className='wallet_transfer-wrap'>
                <div className='wallet_transfer-navigatior'>
                  {navigatorButton.map((item) => (
                    <button
                      key={item.id}
                      className={item.link === transferType ? 'true' : 'false'}
                      onClick={() => {
                        if (
                          item.link === 'foreign-account' ||
                          item.link === 'self-account'
                        ) {
                        } else {
                          setTransferType(item.link)
                          refreshAll()
                        }
                      }}
                    >
                      <h2>{item.name}</h2>
                      <p>{item.text}</p>
                    </button>
                  ))}
                </div>
                <div className='wallet_transfer-body'>
                  <div className='back-but'>
                    <p onClick={() => navigate('/e-wallet/dashboard')}>
                      <FaChevronLeft /> Back
                    </p>
                  </div>
                  <div className='wallet_transfer-body_top'>
                    <h2>Local Bank Account</h2>
                    <p>How much would you like to transfer?</p>
                    <div className='amount-input'>
                      <div>
                        <h3> ₦</h3>
                        <input
                          type='number'
                          onChange={(e) => setTransferAmmount(e.target.value)}
                          min='1'
                          defaultValue={transferAmmount}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='wrapper'>
                    <div className='card'>
                      <p>Recipient Bank</p>
                      <select
                        name=''
                        id=''
                        onChange={(e) => {
                          fetchSudoUseBankDet(e.target.value)
                          setAccountNumberFecthing()
                          setAccountDetails({
                            account_no: '',
                            account_name: '',
                          })
                          setAccountNumberCorrect(false)
                        }}
                      >
                        <option value=''>{sudoUserBankDet.name}</option>
                        <option value=''>Select</option>
                        {sudo_bank_codes
                          .sort((a, b) => a.name.localeCompare(b.name))
                          .map((item) => (
                            <option value={item.bankCode} key={item.bankCode}>
                              {item.name}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className='card'>
                      <p>
                        Account Number
                        {accountNumberCorrect ? <FcOk /> : <FcCancel />}
                      </p>
                      <div>
                        <input
                          type='tel'
                          onChange={(e) => {
                            if (e.target.value.length === 10) {
                              fetchSudoUserName(e.target.value)
                            }
                          }}
                          disabled={accountNumberFecthing ? true : false}
                          defaultValue={sudoAccountDetails.ac_no}
                        />
                      </div>
                      {accountNumberFecthing === false && (
                        <p
                          style={{
                            color: 'red',
                            fontSize: '10px',
                            paddingTop: '5px',
                          }}
                        >
                          Account number incorrect, please confirm and try
                          again.
                        </p>
                      )}
                    </div>
                  </div>
                  <div className='card diff'>
                    <p>Account Name</p>
                    <input
                      type='text'
                      value={sudoAccountDetails.ac_name}
                      disabled
                      readOnly
                    />
                  </div>
                  <div className='card diff'>
                    <p>Naration</p>
                    <textarea
                      name=''
                      id=''
                      cols='30'
                      rows='5'
                      onChange={(e) => setTransferNarration(e.target.value)}
                      defaultValue={transferNarration}
                    ></textarea>
                  </div>
                  <div className='transfer_save'>
                    {saveBeneficiary ? (
                      <BsFillCheckSquareFill
                        className='ticked'
                        onClick={() => {
                          setSaveBeneficiary(false)
                          setSaveBeneficiaryTrue('')
                        }}
                      />
                    ) : (
                      <BsSquare
                        className='not-tick'
                        onClick={() => {
                          setSaveBeneficiary(true)
                          setSaveBeneficiaryTrue('save')
                        }}
                      />
                    )}
                    <p className='gen'>Generate & Save Receipt</p>
                  </div>
                  <div className='transfer_buttons'>
                    <button onClick={() => navigate('/e-wallet/dashboard')}>
                      Cancel
                    </button>
                    <button
                      onClick={() => {
                        checkForEmptyLocal()
                      }}
                    >
                      Send
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {transferStage === 'two' && (
            <div className='transfer_stage2'>
              <div className='wallet_transfer-wrap'>
                {AmountGreater && (
                  <div className='transfer_Modal'>
                    <div className='info_card'>
                      <img src={Exclamation} alt='' />
                      <p>
                        You can not transfer an amount greater than your current
                        balance.
                      </p>
                      <button
                        onClick={() => {
                          setTransferStage('one')
                          setAmountGreater(false)
                        }}
                      >
                        Back
                      </button>
                    </div>
                  </div>
                )}
                <div className='wallet_transfer-body'>
                  <div
                    className='wallet_transfer-body_top'
                    style={{ paddingBottom: '30px' }}
                  >
                    <h2 style={{ fontSize: '30px' }}>Transfer Details</h2>
                    <p style={{ fontSize: '20px' }}>
                      Please verify the information below.
                    </p>
                  </div>
                  <div className='wallet_transfer-body_top'>
                    <div className='amount-input'>
                      <div>
                        <h3> ₦</h3>
                        <input
                          type='text'
                          readOnly
                          disabled
                          value={transferAmmount}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='wrapper'>
                    <div className='card'>
                      <p>Recipient Bank</p>
                      <input
                        type='text'
                        name=''
                        id=''
                        value={sudoUserBankDet.name}
                      />
                    </div>
                    <div className='card'>
                      <p>Account Number</p>
                      <div>
                        <input type='tel' value={sudoAccountDetails.ac_no} />
                      </div>
                    </div>
                  </div>
                  <div className='card diff'>
                    <p>Account Name</p>
                    <input type='text' value={sudoAccountDetails.ac_name} />
                  </div>
                  <div className='card diff'>
                    <p>Naration</p>
                    <textarea
                      name=''
                      id=''
                      cols='30'
                      rows='5'
                      value={transferNarration}
                    ></textarea>
                  </div>
                  <div className='transfer_save'>
                    {saveBeneficiary ? (
                      <BsFillCheckSquareFill className='ticked' />
                    ) : (
                      <BsSquare className='not-tick' />
                    )}
                    <p className='gen'>Generate & Save Receipt</p>
                  </div>
                  <div className='transfer_buttons'>
                    <button onClick={() => setTransferStage('one')}>
                      Edit
                    </button>
                    <button onClick={() => navigate('/e-wallet/dashboard')}>
                      Cancel
                    </button>
                    <button
                      onClick={() => {
                        setTransferStage('three')
                      }}
                    >
                      Send
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {transferStage === 'three' && (
            <div className='transfer_pin'>
              <div className='wallet_transfer-wrap'>
                <div className='wallet_transfer-body'>
                  <div
                    className='wallet_transfer-body_top'
                    style={{ paddingBottom: '30px' }}
                  >
                    <h2 style={{ fontSize: '30px' }}>Transfer Verification</h2>
                    <p style={{ fontSize: '20px' }}>
                      Please input your Magic PIN to initiate this transaction.
                    </p>
                  </div>
                  <div className='transfer_pin'>
                    <div className='pin_wrapper'>
                      <PinInput
                        length={4}
                        initialValue=''
                        secret
                        type='numeric'
                        inputMode='numeric'
                        style={{ padding: '10px' }}
                        inputStyle={{
                          borderColor: '#e6e6e6',
                          borderWidth: '3px',
                          borderStyle: 'solid',
                          background: '#f1f1f1',
                          fontSize: ' 3rem',
                          borderRadius: '20px',
                          height: '70px',
                          width: '70px',
                          margin: '0 5px',
                        }}
                        inputFocusStyle={{ borderColor: 'blue' }}
                        onComplete={(value, index) => {
                          setTransferPin(value)
                          nairaLocalVerifyTransfer(value)
                        }}
                        autoSelect={true}
                        regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                      />
                      {formLoading ? (
                        <div className='loader'>
                          <img src={Loader} alt='' />
                        </div>
                      ) : (
                        <div className='t_button'>
                          <button
                            onClick={() => {
                              nairaLocalVerifyTransfer()
                            }}
                          >
                            Confirm & Transfer
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {transferStage === 'success' && (
            <div className='success'>
              <div>
                <div className='req-top'>
                  <h2>Transfer Status</h2>
                  <p>Your Transfer was successfully proccessed!</p>
                </div>
                <div className='req-center'>
                  <FcOk />
                  <h3>Successful</h3>
                </div>
                <div className='req-botom'>
                  <button
                    onClick={() => {
                      window.location.replace('/e-wallet/dashboard')
                    }}
                  >
                    Done
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {transferType === 'foreign-account' && (
        <>
          {transferStage === 'one' && (
            <div className='transfer_stage1'>
              <div className='wallet_top'>
                <h2 className='wallet-text'>E-Wallet</h2>
              </div>
              <div className='wallet_transfer-wrap'>
                <div className='wallet_transfer-navigatior'>
                  {navigatorButton.map((item) => (
                    <button
                      key={item.id}
                      className={item.link === transferType ? 'true' : 'false'}
                      onClick={() => setTransferType(item.link)}
                    >
                      <h2>{item.name}</h2>
                      <p>{item.text}</p>
                    </button>
                  ))}
                </div>
                <div className='wallet_transfer-body'>
                  <div className='back-but'>
                    <p onClick={() => navigate('/e-wallet/dashboard')}>
                      <FaChevronLeft /> Back
                    </p>
                  </div>
                  <div className='wallet_transfer-body_top'>
                    <h2>Foreign Bank Account</h2>
                    <p>How much would you like to transfer?</p>
                    <div className='amount-input'>
                      <div>
                        <h3> ₦</h3>
                        <input type='number' />
                      </div>
                    </div>
                  </div>
                  <div className='wallet_benefeiaries'>
                    <p>Select from Beneficiaries</p>
                    <select name='' id=''>
                      <option value=''>Select</option>
                    </select>
                  </div>
                  <div className='wrapper'>
                    <div className='card'>
                      <p>Recipient Bank</p>
                      <select
                        name=''
                        id=''
                        onChange={(e) => fetchUseBankDet(e.target.value)}
                      >
                        <option value=''>Select</option>
                        {bank_codes.map((item) => (
                          <option value={item.BankCode} key={item.BankName}>
                            {item.BankName}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className='card'>
                      <p>Account Number</p>
                      <div>
                        <input
                          type='tel'
                          onChange={(e) => {
                            if (e.target.value.length === 10) {
                              fetchUserName(e.target.value)
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='card diff'>
                    <p>Account Name</p>
                    <input type='text' />
                  </div>
                  <div className='card diff'>
                    <p>Naration</p>
                    <textarea name='' id='' cols='30' rows='5'></textarea>
                  </div>
                  <div className='transfer_save'>
                    <BsFillCheckSquareFill className='ticked' />
                    <p className='gen'>Generate & Save Receipt</p>
                  </div>
                  <div className='transfer_buttons'>
                    <button onClick={() => navigate('/e-wallet/dashboard')}>
                      Cancel
                    </button>
                    <button onClick={() => setTransferStage('two')}>
                      Send
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {transferStage === 'two' && (
            <div className='transfer_stage2'>
              <div className='wallet_transfer-wrap'>
                {AmountGreater && (
                  <div className='transfer_Modal'>
                    <div className='info_card'>
                      <img src={Exclamation} alt='' />
                      <p>
                        You can not transfer an amount greater than your current
                        balance.
                      </p>
                      <button
                        onClick={() => {
                          setTransferStage('one')
                        }}
                      >
                        Back
                      </button>
                    </div>
                  </div>
                )}
                <div className='wallet_transfer-body'>
                  <div
                    className='wallet_transfer-body_top'
                    style={{ paddingBottom: '30px' }}
                  >
                    <h2 style={{ fontSize: '30px' }}>Transfer Details</h2>
                    <p style={{ fontSize: '20px' }}>
                      Please verify the information below.
                    </p>
                  </div>
                  <div className='wallet_transfer-body_top'>
                    <div className='amount-input'>
                      <div>
                        <h3> ₦</h3>
                        <input
                          type='text'
                          disabled
                          readOnly
                          value='10,000,000'
                        />
                      </div>
                    </div>
                  </div>
                  <div className='wrapper'>
                    <div className='card'>
                      <p>Recipient Bank</p>
                      <input type='text' name='' id='' value='Gt Bank' />
                    </div>
                    <div className='card'>
                      <p>Account Number</p>
                      <div>
                        <input type='tel' value='0458473697' />
                      </div>
                    </div>
                  </div>
                  <div className='card diff'>
                    <p>Account Name</p>
                    <input type='text' value='Igbinosa Iwinosa Godsfavour' />
                  </div>
                  <div className='card diff'>
                    <p>Naration</p>
                    <textarea
                      name=''
                      id=''
                      cols='30'
                      rows='5'
                      value='For the Babes Them'
                    ></textarea>
                  </div>
                  <div className='transfer_save'>
                    <label className='container'>
                      Generate & Save Receipt
                      <input type='checkbox' disabled checked='checked' />
                      <span className='checkmark'></span>
                    </label>
                  </div>
                  <div className='transfer_buttons'>
                    <button onClick={() => setTransferStage('one')}>
                      Edit
                    </button>
                    <button onClick={() => navigate('/e-wallet/dashboard')}>
                      Cancel
                    </button>
                    <button onClick={() => setTransferStage('three')}>
                      Send
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {transferStage === 'three' && (
            <div className='transfer_pin'>
              <div className='wallet_transfer-wrap'>
                <div className='wallet_transfer-body'>
                  <div
                    className='wallet_transfer-body_top'
                    style={{ paddingBottom: '30px' }}
                  >
                    <h2 style={{ fontSize: '30px' }}>Transfer Verification</h2>
                    <p style={{ fontSize: '20px' }}>
                      Please input your Magic PIN to initiate this transaction.
                    </p>
                  </div>
                  <div className='transfer_pin'>
                    <div className='pin_wrapper'>
                      <PinInput
                        length={4}
                        initialValue=''
                        secret
                        onChange={(value, index) => {}}
                        type='numeric'
                        inputMode='numeric'
                        style={{ padding: '10px' }}
                        inputStyle={{
                          borderColor: '#e6e6e6',
                          borderWidth: '3px',
                          borderStyle: 'solid',
                          background: '#f1f1f1',
                          fontSize: ' 3rem',
                          borderRadius: '20px',
                          height: '70px',
                          width: '70px',
                          margin: '0 5px',
                        }}
                        inputFocusStyle={{ borderColor: 'blue' }}
                        onComplete={(value, index) => {}}
                        autoSelect={true}
                        regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                      />
                      <div className='t_button'>
                        <button>Confirm & Transfer</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default TransferWallet
