import React, { useState, useEffect } from 'react'
import { FaChevronLeft } from 'react-icons/fa'
import { BsFillDashCircleFill, BsCheckSquare } from 'react-icons/bs'
import { GoInfo } from 'react-icons/go'
import { ImCheckboxUnchecked } from 'react-icons/im'
import { useNavigate } from 'react-router'
import Atm from '../../../../../Image/ATM Icon.png'
import Pos from '../../../../../Image/POS.png'
import Web from '../../../../../Image/Web Icon.png'
import Mobile from '../../../../../Image/Mobile Icon.png'
import ChannelCard from './ChannelCard'
import Loader from '../../../../../Image/loder.gif.gif'
import { FcOk } from 'react-icons/fc'
import API from '../../../../../Context/API'
import { useGlobalContext } from '../../../../../Context/Context'
import Select from 'react-select'
import restrictionData from '../../../../../Data/restrictionData'
import { AiOutlineCloseSquare } from 'react-icons/ai'

function RequestCard({ notify, sudoDetails }) {
  const authToken = sessionStorage.getItem('userId')
  const { user } = useGlobalContext()
  const ChannelList = [
    {
      id: 1,
      name: 'ATM',
      content: 'Automated teller machine (ATM) withdrawals and transactions',
      image: Atm,
      sortName: 'Atm',
    },
    {
      id: 2,
      name: 'POS',
      content: 'Point of sales (POS) purchases',
      image: Pos,
      sortName: 'Pos',
    },
    {
      id: 3,
      name: 'WEB',
      content: 'Online purchases',
      image: Web,
      sortName: 'Web',
    },
    {
      id: 4,
      name: 'MOBILE',
      content: 'Mobile purchases',
      image: Mobile,
      sortName: 'mobile',
    },
  ]
  useEffect(() => {
    document.title = 'Spendwise | Physical Cards Request'
  }, [])
  const navigate = useNavigate()
  const [referLoading, setReferLoading] = useState(false)
  const [formFields, setFormFields] = useState([{ interval: '', amount: '' }])
  const [afterSubmit, setAfterSubmit] = useState(false)
  const [atmField, setAtmField] = useState()
  const [posField, setPosField] = useState()
  const [webField, setWebField] = useState()
  const [mobileField, setMobileField] = useState()
  const [addAChanel, setAddAChanel] = useState('ask')
  const [addPChanel, setAddPChanel] = useState('ask')
  const [addWChanel, setAddWChanel] = useState('ask')
  const [addMChanel, setAddMChanel] = useState('ask')
  const [chanTouch, setChanTouch] = useState('none')
  const [cardName, setcardName] = useState('')
  const [batchAsk, setBatchAsk] = useState(false)
  const [saveAsk, setSaveAsk] = useState(false)
  const [batch, setBatch] = useState(1)
  const [settingsName, setSettingsName] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [creationLevel, setCreationLevel] = useState(1)
  const [savedSettings, setSavedSettings] = useState([])
  const [defaultAddress, setDefaultAddress] = useState(false)
  const [line1, setLine1] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [postalCode, setPostalCode] = useState('')
  const [newAddress, setNewAddress] = useState(false)
  const [expedite, setExpedite] = useState(false)
  const handleFormChange = (event, index) => {
    let data = [...formFields]
    data[index][event.target.name] = event.target.value
    setFormFields(data)
  }
  const addFields = () => {
    let object = {
      interval: '',
      amount: '',
    }
    setFormFields([...formFields, object])
  }
  const removeFields = (index) => {
    let data = [...formFields]
    data.splice(index, 1)
    setFormFields(data)
  }
  const submit = (e) => {
    e.preventDefault()
    setReferLoading(true)
    const totalsubmission = {
      controls: {
        channels: {
          atm: atmField,
          pos: posField,
          web: webField,
          mobile: mobileField,
        },
        spendingLimits: formFields,
      },
      order_details: {
        nameOnCards: [cardName],
        expedite: expedite,
        shippingAddress: {
          line1: line1,
          city: city,
          state: state,
          postalCode: postalCode,
          country: 'Nigeria',
        },
      },
      currency: 'NGN',
      alias: cardName,
      issuer: 'sudo',
      batch: batchAsk,
      batch_count: parseInt(batch),
      save_settings: saveAsk,
      save_name: settingsName,
      wallet_id: sudoDetails.account_id,
    }
    fetch(
      `https://y18x0oais3.execute-api.eu-west-1.amazonaws.com/prod/card/create_card`,
      {
        method: 'post',
        // mode: 'no-cors',
        headers: {
          'content-Type': 'application/json',
          Authorization: `Token ${authToken}`,
        },
        body: JSON.stringify(totalsubmission),
      }
    )
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            notify('success', data.message)
            setSuccessMessage(data.message)
            setReferLoading(false)
            setCreationLevel(2)
          })
        } else {
          res.json().then((data) => {
            setReferLoading(false)
            notify('error', data.message)
          })
        }
      })
      .catch(() => {
        notify('error', 'An error occured please try again.')
        setReferLoading(false)
      })
  }
  useEffect(() => {
    API.FetchSavedSettings()
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setSavedSettings(data.settings_data)
          })
        } else {
          res.json().then((data) => {})
        }
      })
      .catch(() => {})
  }, [])
  const selectedSettings = (e) => {
    if (e.length > 1) {
      const selectEd = savedSettings.find((item) => item.settings_id === e)
      setChanTouch('nil')
      setFormFields(selectEd.controls.spendingLimits)
      setAtmField(selectEd.controls.channels.atm)
      setPosField(selectEd.controls.channels.pos)
      setWebField(selectEd.controls.channels.web)
      setMobileField(selectEd.controls.channels.mobile)
      setAddAChanel('nil')
      setAddPChanel('nil')
      setAddWChanel('nil')
      setAddMChanel('nil')
    }
  }

  return (
    <div className='physical_cards-wrap'>
      {creationLevel === 1 && (
        <div className='cover-wrap'>
          <div className='back-but'>
            <p onClick={() => navigate('/physical-card/dashboard')}>
              <FaChevronLeft /> Back
            </p>
          </div>
          <div className='headd'>
            <div className='left'>
              <h2>Channels</h2>
              <p>Select channels where your card can be used.</p>
            </div>
            <div className='right'>
              <select
                name=''
                id=''
                onChange={(e) => selectedSettings(e.target.value)}
              >
                <option value=''>Saved settings</option>
                {savedSettings.map((item) => (
                  <option value={item.settings_id} key={item.settings_id}>
                    {item.settings_id}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className='chan-chan'>
            <div className='edit_channel'>
              <div className='card'>
                <div className='card-wra'>
                  <div className='left'>
                    <img src={Atm} alt='' />
                    <h2>ATM</h2>
                  </div>
                  <div className='right'>
                    {atmField === true && (
                      <BsCheckSquare
                        onClick={() => setAddAChanel('ask')}
                        style={{ color: '#1eb80f' }}
                      />
                    )}
                    {atmField === false && (
                      <AiOutlineCloseSquare
                        onClick={() => setAddAChanel('ask')}
                        style={{ color: 'red' }}
                      />
                    )}
                  </div>
                </div>
                {addAChanel === 'ask' && (
                  <div className='left_other'>
                    <p>Do you want to:</p>
                    <button
                      onClick={() => {
                        setAddAChanel('nil')
                        setAtmField(true)
                      }}
                    >
                      Allow
                    </button>
                    <button
                      onClick={() => {
                        setAddAChanel('nil')
                        setAtmField(false)
                      }}
                    >
                      Block
                    </button>
                  </div>
                )}
              </div>
              <div className='card'>
                <div className='card-wra'>
                  <div className='left'>
                    <img src={Pos} alt='' />
                    <h2>POS</h2>
                  </div>
                  <div className='right'>
                    {posField === true && (
                      <BsCheckSquare
                        onClick={() => setAddPChanel('ask')}
                        style={{ color: '#1eb80f' }}
                      />
                    )}
                    {posField === false && (
                      <AiOutlineCloseSquare
                        onClick={() => setAddPChanel('ask')}
                        style={{ color: 'red' }}
                      />
                    )}
                  </div>
                </div>
                {addPChanel === 'ask' && (
                  <div className='left_other'>
                    <p>Do you want to:</p>
                    <button
                      onClick={() => {
                        setAddPChanel('nil')
                        setPosField(true)
                      }}
                    >
                      Allow
                    </button>
                    <button
                      onClick={() => {
                        setAddPChanel('nil')
                        setPosField(false)
                      }}
                    >
                      Block
                    </button>
                  </div>
                )}
              </div>
              <div className='card'>
                <div className='card-wra'>
                  <div className='left'>
                    <img src={Web} alt='' />
                    <h2>WEB</h2>
                  </div>
                  <div className='right'>
                    {webField === true && (
                      <BsCheckSquare
                        onClick={() => setAddWChanel('ask')}
                        style={{ color: '#1eb80f' }}
                      />
                    )}
                    {webField === false && (
                      <AiOutlineCloseSquare
                        onClick={() => setAddWChanel('ask')}
                        style={{ color: 'red' }}
                      />
                    )}
                  </div>
                </div>
                {addWChanel === 'ask' && (
                  <div className='left_other'>
                    <p>Do you want to:</p>
                    <button
                      onClick={() => {
                        setAddWChanel('nil')
                        setWebField(true)
                      }}
                    >
                      Allow
                    </button>
                    <button
                      onClick={() => {
                        setAddWChanel('nil')
                        setWebField(false)
                      }}
                    >
                      Block
                    </button>
                  </div>
                )}
              </div>
              <div className='card'>
                <div className='card-wra'>
                  <div className='left'>
                    <img src={Mobile} alt='' />
                    <h2>MOBILE</h2>
                  </div>
                  <div className='right'>
                    {mobileField === true && (
                      <BsCheckSquare
                        onClick={() => setAddMChanel('ask')}
                        style={{ color: '#1eb80f' }}
                      />
                    )}
                    {mobileField === false && (
                      <AiOutlineCloseSquare
                        onClick={() => setAddMChanel('ask')}
                        style={{ color: 'red' }}
                      />
                    )}
                  </div>
                </div>
                {addMChanel === 'ask' && (
                  <div className='left_other'>
                    <p>Do you want to:</p>
                    <button
                      onClick={() => {
                        setAddMChanel('nil')
                        setMobileField(true)
                      }}
                    >
                      Allow
                    </button>
                    <button
                      onClick={() => {
                        setAddMChanel('nil')
                        setMobileField(false)
                      }}
                    >
                      Block
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='limit-form'>
            <p className='info'>
              Set your card <b>spending limit</b>.
            </p>
            <form onSubmit={submit}>
              {formFields.map((form, index) => {
                return (
                  <div className='wrapper' key={index + 1}>
                    <div className='card'>
                      <p>Interval </p>
                      <select
                        name='interval'
                        id=''
                        onChange={(event) => handleFormChange(event, index)}
                        defaultValue={form.interval}
                      >
                        <option value={form.interval}>{form.interval}</option>
                        <option value='daily'>Daily</option>
                        <option value='weekly'>Weekly</option>
                        <option value='monthly'>Monthly</option>
                      </select>
                    </div>
                    <div className='card'>
                      <p>Amount </p>
                      <input
                        type='number'
                        name='amount'
                        min={1}
                        id=''
                        onChange={(event) => handleFormChange(event, index)}
                        defaultValue={form.amount}
                      />
                    </div>
                    <div className='icon'>
                      <BsFillDashCircleFill
                        onClick={() => removeFields(index)}
                      />
                    </div>
                  </div>
                )
              })}
            </form>

            <div className='new-wrapper'>
              <div className='adder'>
                <button onClick={addFields}>Add More</button>
              </div>
              {/* <div className='restrictions'>
                <p className='info'>
                  Allowed <b>Categories</b>.
                </p>
                <Select
                  options={restrictionData}
                  isMulti
                  onChange={(opt) => console.log(opt)}
                />
              </div>
              <div className='restrictions'>
                <p className='info'>
                  Blocked <b>Categories</b>.
                </p>
                <Select
                  options={restrictionData}
                  isMulti
                  onChange={(opt) => console.log(opt)}
                />
              </div> */}
              <div className='alias'>
                <div className='card'>
                  <p>
                    Card Name
                    <span style={{ color: '#d00000', fontSize: '20px' }}>
                      *
                    </span>
                  </p>
                  <input
                    type='text'
                    onChange={(e) => setcardName(e.target.value)}
                  />
                </div>
                <div className='cards'>
                  <div className='click'>
                    <p
                      title='Select batch to request multiple cards with this setting'
                      style={{ cursor: 'pointer' }}
                    >
                      Batch?
                      <span>
                        <GoInfo />
                      </span>
                    </p>
                    {batchAsk ? (
                      <BsCheckSquare
                        onClick={() => setBatchAsk(false)}
                        style={{ color: '#1eb80f' }}
                      />
                    ) : (
                      <ImCheckboxUnchecked onClick={() => setBatchAsk(true)} />
                    )}
                  </div>
                  {batchAsk && (
                    <div className='fom'>
                      <input
                        type='number'
                        name=''
                        min={1}
                        id=''
                        placeholder='Number of Card'
                        onChange={(e) => setBatch(e.target.value)}
                      />
                    </div>
                  )}
                </div>
                <div className='cards'>
                  <div className='click'>
                    <p
                      title='Save settings to use this setting to create other cards'
                      style={{ cursor: 'pointer' }}
                    >
                      Save card setings?
                      <span>
                        <GoInfo />
                      </span>
                    </p>
                    {saveAsk ? (
                      <BsCheckSquare
                        onClick={() => setSaveAsk(false)}
                        style={{ color: '#1eb80f' }}
                      />
                    ) : (
                      <ImCheckboxUnchecked onClick={() => setSaveAsk(true)} />
                    )}
                  </div>
                  {saveAsk && (
                    <div className='fom'>
                      <input
                        type='text'
                        name=''
                        min={1}
                        id=''
                        placeholder='Settings name'
                        onChange={(e) => setSettingsName(e.target.value)}
                      />
                    </div>
                  )}
                </div>
                <div className='cards'>
                  <div className='click'>
                    <p
                      title='For speedy delivery'
                      style={{ cursor: 'pointer' }}
                    >
                      Express delivery
                      <span>
                        <GoInfo />
                      </span>
                    </p>
                    {expedite ? (
                      <BsCheckSquare
                        onClick={() => setExpedite(false)}
                        style={{ color: '#1eb80f' }}
                      />
                    ) : (
                      <ImCheckboxUnchecked onClick={() => setExpedite(true)} />
                    )}
                  </div>
                </div>
              </div>
              <div className='delivery'>
                <p className='info'>
                  Delivery <b>Address</b>.
                </p>
                <div className='contentfd'>
                  <div className='left'>
                    <p>Use Default</p>
                    <div className='div'>
                      <h2>
                        {user.address}, {user.city}, {user.state}.
                        {user.postal_code}
                      </h2>
                      <div className='cgs'>
                        {defaultAddress ? (
                          <BsCheckSquare
                            style={{ color: '#1eb80f' }}
                            onClick={() => {
                              setDefaultAddress(false)
                              setLine1('')
                              setCity('')
                              setState('')
                              setPostalCode('')
                            }}
                          />
                        ) : (
                          <ImCheckboxUnchecked
                            onClick={() => {
                              setDefaultAddress(true)
                              setLine1(user.address)
                              setCity(user.city)
                              setState(user.state)
                              setPostalCode(user.postal_code)
                              setNewAddress(false)
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  {!defaultAddress && (
                    <div className='right'>
                      <p>OR</p>
                      <button
                        onClick={() => {
                          setNewAddress(true)
                        }}
                      >
                        Enter New Address
                      </button>
                    </div>
                  )}
                </div>
                {newAddress && (
                  <div className='deliver-form'>
                    <div className='form_card'>
                      <input
                        type='text'
                        placeholder='Line 1'
                        onChange={(e) => setLine1(e.target.value)}
                      />
                    </div>
                    <div className='form_card'>
                      <input
                        type='text'
                        placeholder='city'
                        onChange={(e) => setCity(e.target.value)}
                      />
                    </div>
                    <div className='form_card'>
                      <input
                        type='text'
                        placeholder='state'
                        onChange={(e) => setState(e.target.value)}
                      />
                    </div>
                    <div className='form_card'>
                      <input
                        type='text'
                        placeholder='postal_code'
                        onChange={(e) => setPostalCode(e.target.value)}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className='payment'>
                <span style={{ color: 'red' }}>*</span>Please note that a fee of
                NGN 2,000 will be charged from your wallet for each card.
              </div>
              {afterSubmit && (
                <>
                  {referLoading ? (
                    <div className='button'>
                      <img src={Loader} alt='' />
                    </div>
                  ) : (
                    <div className='button'>
                      <button
                        style={{ background: 'red' }}
                        onClick={() => setAfterSubmit(false)}
                      >
                        Cancel
                      </button>
                      <button onClick={submit}>Proceed</button>
                    </div>
                  )}
                </>
              )}
              {!afterSubmit && (
                <div className='button'>
                  <button onClick={() => setAfterSubmit(true)}>Submit</button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {creationLevel === 2 && (
        <div className='success'>
          <div>
            {' '}
            <div className='req-top'>
              <h2>Card Creation Status</h2>
              <p>{successMessage}</p>
            </div>
            <div className='req-center'>
              <FcOk />
              <h3>Successful</h3>
            </div>
            <div className='req-botom'>
              <button
                onClick={() => {
                  window.location.replace('/physical-card/dashboard')
                }}
              >
                Done
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default RequestCard
