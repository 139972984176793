import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import FundFriend from './WalletPages/FundFriend'
import FundWallet from './WalletPages/FundWallet'
import TransactionSumarry from './WalletPages/TransactionSumarry'
import TransferWallet from './WalletPages/TransferWallet'
import WalletDashboard from './WalletPages/WalletDashboard'
import { useGlobalContext } from '../../../../Context/Context'
import Loader from '../../../../Image/loder.gif.gif'
import './EWallet.scss'
import API from '../../../../Context/API'
import Pin from './WalletPages/Pin'
import Reciept from './WalletPages/Reciept'
import NewTransfer from './WalletPages/New-Transfer'

function EWallet({ notify, bank, user }) {
  const navigate = useNavigate()
  const { id } = useParams()
  const authToken = sessionStorage.getItem('userId')
  const [walletTransactions, setWalletTransactions] = useState([])
  const [transactionFound, setTransactionFound] = useState(false)
  const [checkWallet, setcheckWallet] = useState(true)
  // const [checkWalletNotAvailable, setcheckWalletNotAvailable] = useState(false)
  const { toggleCardHolder } = useGlobalContext()
  const [sudoDetails, setSudoDetails] = useState({
    available_balance: 0,
  })
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    document.title = 'Spendwise | EWallet'
  }, [])
  useEffect(() => {
    API.FetchSudoAccount()
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setcheckWallet(false)
            setLoading(false)
            setSudoDetails(
              data.wallet_data.find(
                (item) => item.wallet_type === 'main-wallet'
              )
            )
            fetch(
              `https://p499kkwmp9.execute-api.eu-west-1.amazonaws.com/prod/wallet/transactions`,
              {
                method: 'post',
                headers: {
                  'content-Type': 'application/json',
                  Authorization: `Token ${authToken}`,
                },
                body: JSON.stringify({
                  id: data.wallet_data.find(
                    (item) => item.wallet_type === 'main-wallet'
                  ).account_id,
                }),
              }
            ).then((res) => {
              if (res.status === 200) {
                res.json().then((data) => {
                  setWalletTransactions(data.transactions)
                  setTransactionFound(true)
                })
              } else {
                res.json().then((data) => {})
              }
            })
          })
        } else {
          res.json().then((data) => {
            setSudoDetails({
              account_name: 'unavailable',
              bank1: 'unavailable',
              account_no1: 'unavailable',
              available_balance: 0,
            })
            if (
              data.message ===
              'No user found or user has no sudo holder account.'
            ) {
              setcheckWallet(true)
            }
            setLoading(false)
          })
        }
      })
      .catch(() => {})
  }, [])

  const walletPage = () => {
    switch (id) {
      case 'dashboard':
        return (
          <WalletDashboard
            walletTransactions={walletTransactions}
            transactionFound={transactionFound}
            sudoDetails={sudoDetails}
            notify={notify}
          />
        )
      case 'transfer':
        return <TransferWallet notify={notify} bank={bank} />
      case 'transfers':
        return (
          <NewTransfer notify={notify} bank={bank} sudoDetails={sudoDetails} />
        )
      case 'reciept':
        return (
          <Reciept
            walletTransactions={walletTransactions}
            transactionFound={transactionFound}
          />
        )
      case 'transaction-summary':
        return (
          <TransactionSumarry
            walletTransactions={walletTransactions}
            transactionFound={transactionFound}
          />
        )
      case 'fund-wallet':
        return (
          <FundWallet user={user} notify={notify} sudoDetails={sudoDetails} />
        )
      case 'fund-friend':
        return <FundFriend notify={notify} />
      case 'secure':
        return <Pin notify={notify} sudoDetails={sudoDetails} />

      default:
        return <div>No page to display</div>
    }
  }
  // useEffect(() => {
  //   API.getCardHolder()
  //     .then((res) => {
  //       if (res.status === 200) {
  //         res.json().then((data) => {
  //           if (data.data.length >= 1) {
  //             setcheckWallet(false)
  //           } else {
  //             setcheckWallet(true)
  //           }
  //         })
  //       } else {
  //         res.json().then((data) => {})
  //       }
  //     })
  //     .catch(() => {})
  // }, [])
  const createWalletAccount = () => {
    toggleCardHolder()
  }
  return loading ? (
    <div className='vloader'>
      <img src={Loader} />
    </div>
  ) : (
    <div className='e-wallet'>
      {checkWallet && (
        <div className='overlay'>
          <button onClick={() => createWalletAccount()}>Create Wallet</button>
        </div>
      )}
      {/* {checkWalletNotAvailable && (
        <div className='overlay diff'>
          <div>
            <h2>
              Wallet creation in process.. You will be notified when it is
              created
            </h2>
            <img src={Loader} alt='' />
          </div>
        </div>
      )} */}

      <div> {walletPage()}</div>
    </div>
  )
}

export default EWallet
