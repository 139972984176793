import React, { useEffect, useState } from 'react'
import '../ExpenseReceipt/ExpenseReceipt.scss'
import approvedDisbursestamp from '../ExpenseReceipt/images/approvedstamp.png'
import approvedstamp from '../ExpenseReceipt/images/Stamps.png'
import declinedstamp from '../ExpenseReceipt/images/Stamps (1).png'
import clientLogo from '../ExpenseReceipt/images/clients-logo.svg'
import { useParams } from 'react-router-dom'
import FormLoader from '../../../../../../Component/FormLoader/FormLoader'
import { useGlobalContext } from '../../../../../../Context/Context'

export default function ExpenseReceipt() {
  const authToken = sessionStorage.getItem('userId')
  const { new_id } = useParams()
  const [requestData, setRequestData] = useState({})
  const [loading, setLoading] = useState(true)
  const [approver, setApprover] = useState({})
  const [signature, setSignature] = useState([])
  const [sigLoading, setSigLoading] = useState(true)
  const { businessInfo } = useGlobalContext()

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/expense/get_expense/`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
      body: JSON.stringify({
        expense_id: parseInt(new_id),
      }),
    }).then((res) => {
      if (res.status === 201) {
        res.json().then((data) => {
          setRequestData(data.data)
          setLoading(false)
          const approve = data.data.approvers.find(
            (item) => item.user === data.data.next_approver_id
          )
          setApprover(approve)
          fetch('https://www.spendwise.ng/api/expense/get_signature/', {
            method: 'post',
            body: JSON.stringify({
              user_ids: data.data.approvers.map((item) => item.bu_id),
            }),
            headers: {
              'content-Type': 'application/json',
              Authorization: `Token ${authToken}`,
            },
          })
            .then((res) => {
              if (res.status === 200) {
                res.json().then((signature) => {
                  setSignature(signature.data)
                  setSigLoading(false)
                })
              } else {
                res.json().then((signature) => {
                  setSigLoading(false)
                })
              }
            })
            .catch((err) => {})
        })
      } else if (res.status === 204) {
        setLoading(false)
      }
    })
  }, [])

  const findApproverStatus = (item) => {
    if (item.approved) {
      return 'Approved'
    } else if (item.action_required) {
      return 'Declined'
    } else if (!item.approved && approver.user === item.user) {
      return 'Approving'
    } else {
      return 'Pending'
    }
  }
  const formatDate = (item) => {
    const date = new Date(item).toDateString()
    return date
  }
  const showStamp = (item) => {
    console.log(item)
    if (item.approved) {
      return 'approved'
    } else if (item.approved && item.payout_required && item.paid) {
      return 'approved_disbursed'
    } else if (item.action_required) {
      return 'declined'
    }
  }
  return loading ? (
    <FormLoader />
  ) : (
    <div className='expenseReceipt'>
      <div className='expense-receipt-cover'>
        <div className='expense-receipt-form'>
          <div className='stamp'>
            {showStamp(requestData) === 'approved' ? (
              <img className='floated-stamp' src={approvedstamp} width='200' />
            ) : showStamp(requestData) === 'approved_disbursed' ? (
              <img
                className='floated-stamp'
                src={approvedDisbursestamp}
                width='200'
              />
            ) : showStamp(requestData) === 'declined' ? (
              <img className='floated-stamp' src={declinedstamp} width='200' />
            ) : (
              ''
            )}
          </div>
          <div className='expense-receipt-header'>
            <div></div>
            <div className='expense-receipt-logo'>
              <img src={businessInfo.company_logo} width={34} />
              <h4>{businessInfo.business_name}</h4>
              <p>Doc No: {`${businessInfo.business_name}-${requestData.id}`}</p>
            </div>
          </div>
          <div className='main-form'>
            <h2>1.Request Details</h2>
            <div className='expense-form-details'>
              <h4>Request Title</h4>
              <p>{requestData.title}</p>
            </div>

            <div className='expense-row'>
              <div className='expense-form-details border-bottom'>
                <h4>Request Type</h4>
                <p>{requestData.type_of.replace(/_/g, ' ')}</p>
              </div>

              <div className='expense-form-details border-bottom'>
                <h4>Request Date</h4>
                <p>{new Date(requestData.create_date).toDateString()}</p>
              </div>
            </div>
            <div className='expense-row'>
              <div className='expense-form-details border-bottom'>
                <h4>Authorization style</h4>
                <p>
                  {requestData.payout_required
                    ? 'Approve & Disburse'
                    : 'Approve'}
                </p>
              </div>

              <div className='expense-form-details border-bottom'>
                <h4>Expense Amount</h4>
                <p>{parseFloat(requestData.amount).toLocaleString()}</p>
              </div>
            </div>

            <div className='expense-form-details'>
              <h4>Description</h4>
              <p>{requestData.description}</p>
            </div>
          </div>
          <div className='main-form'>
            <h2>2. Approvers Details</h2>
            {requestData.approvers.map((item) => (
              <div className='expense-form-details dif' key={item.id}>
                <div className='one'>
                  <h4>{item.approver}</h4>
                  <p>{item.approver_email}</p>
                </div>
                <div className='two'>
                  <div className='sig'>
                    {item.approved ? (
                      <div>
                        {sigLoading ? (
                          ''
                        ) : (
                          <>
                            {' '}
                            {signature.length > 0 ? (
                              <div>
                                {signature.find(
                                  (sigs) => sigs.bu_id === item.bu_id
                                ) ? (
                                  <img
                                    src={
                                      signature.find(
                                        (sigs) => sigs.bu_id === item.bu_id
                                      ).image
                                    }
                                    alt=''
                                  />
                                ) : (
                                  <p className='signature'>{item.approver}</p>
                                )}
                              </div>
                            ) : (
                              <p className='signature'>{item.approver}</p>
                            )}
                          </>
                        )}
                      </div>
                    ) : (
                      <p>{findApproverStatus(item)}</p>
                    )}
                  </div>
                  <h6>
                    {findApproverStatus(item) === 'Approved'
                      ? formatDate(item.approved_date)
                      : findApproverStatus(item) === 'Declined' &&
                        formatDate(item.action_required_date)}
                  </h6>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
