import React from 'react'
import FaqQuestions from '../../../../Data/FaqQuestions'
import QuestionTab from '../../../../Component/FrequentQuestions/QuestionTab'

function Faq() {
  return (
    <div className='faq' style={{ paddingTop: '0px' }}>
      <div className='faq-main'>
        <div className='faq-top'>
          <h1>Frequently Asked Questions</h1>
        </div>
        <div className='faq-wrap'>
          <div className='cover'>
            {FaqQuestions.map((item) => (
              <QuestionTab item={item} key={item.id} />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Faq
