import React from 'react'
import { useParams } from 'react-router'
import SelectTransfer from './TransferComp/SelectTransfer'
import BankTransfer from './TransferComp/BankTransfer'
import WalletTransfer from './TransferComp/WalletTransfer'
import ForeignTransfer from './TransferComp/ForeignTransfer'

function NewTransfer({ sudoDetails, notify }) {
  const { new_id } = useParams()
  const user = JSON.parse(sessionStorage.getItem('user'))

  const transferPage = () => {
    switch (new_id) {
      case 'select':
        return <SelectTransfer notify={notify} />
      case 'bank-transfer':
        return <BankTransfer sudoDetails={sudoDetails} notify={notify} />
      case 'wallet-transfer':
        return <WalletTransfer sudoDetails={sudoDetails} notify={notify} />
      case 'foreign-transfer':
        if (user.is_business) {
          return <ForeignTransfer notify={notify} />
        } else {
          return <SelectTransfer />
        }
      default:
        return <SelectTransfer />
        break
    }
  }
  return <div className='new_transfer'>{transferPage()}</div>
}

export default NewTransfer
