import React, { useEffect, useState } from 'react'
import './Sidebar.scss'
import Logo from '../../../../Image/logo1.png'
import Angle from '../../../../Image/angle2.png'
import { useParams } from 'react-router'
import {
  RiDashboardFill,
  RiSettings5Fill,
  RiCustomerService2Line,
} from 'react-icons/ri'
import {
  FaWallet,
  FaSuitcase,
  FaSignOutAlt,
  FaTimes,
  FaQuestion,
  FaCreditCard,
} from 'react-icons/fa'
import { GiTakeMyMoney, GiSpanner, GiWallet } from 'react-icons/gi'
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai'
import { IoMdWallet } from 'react-icons/io'
import { GoGraph } from 'react-icons/go'
import { TfiWrite } from 'react-icons/tfi'
import { MdGroups } from 'react-icons/md'
import { BsBank2, BsBack, BsFillCreditCardFill } from 'react-icons/bs'
import API from '../../../../Context/API'

function Sidebar({
  navigate,
  page,
  handleToggle,
  handleLogout,
  user,
  hasAccess,
  notify,
}) {
  const [expenseToggle, setExpenseToggle] = useState('false')
  const [walletToggle, setWalletToggle] = useState('false')
  const [financeToggle, setFinanceToggle] = useState('false')
  const [isSmallScreen, setIsSmallScreen] = useState(false)
  const { id } = useParams()
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 1024)
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  const handleDropClick = (tag) => {
    if (tag === 'expense') {
      if (expenseToggle) {
        setExpenseToggle(!expenseToggle)
        setWalletToggle('false')
        setFinanceToggle('false')
      } else {
        setExpenseToggle('false')
      }
    } else if (tag === 'wallet') {
      if (walletToggle) {
        setWalletToggle(!walletToggle)
        setExpenseToggle('false')
        setFinanceToggle('false')
      } else {
        setWalletToggle('false')
      }
    } else if (tag === 'finance') {
      if (financeToggle) {
        setFinanceToggle(!financeToggle)
        setExpenseToggle('false')
        setWalletToggle('false')
      } else {
        setFinanceToggle('false')
      }
    }
  }
  useEffect(() => {
    if (page.includes('debit-card')) {
      setExpenseToggle(!expenseToggle)
    } else if (page.includes('wallet')) {
      setWalletToggle(!walletToggle)
    } else if (
      page.includes('expense') ||
      page.includes('finance') ||
      page.includes('budget') ||
      page.includes('all-expense')
    ) {
      setFinanceToggle(!financeToggle)
    }
  }, [page])
  const removeStoredLocal = () => {
    sessionStorage.removeItem('ddf8837e6d4defcc85a76e2cd2c156ef64a128e5')
    sessionStorage.removeItem('7e6d4defcc85a76e2cd2c156ef64a128e5')
    sessionStorage.removeItem('adminIn')
    sessionStorage.removeItem('wallet-account')
  }
  const returnedUser = JSON.parse(sessionStorage.getItem('user'))
  return (
    <div className='sidebar'>
      <div className='side-comp'>
        <div className='close'>
          <FaTimes onClick={handleToggle} />
        </div>
        <div className='logo'>
          <img
            src={Logo}
            alt=''
            style={{ cursor: 'pointer' }}
            onClick={() => {
              navigate('/')
            }}
          />
        </div>
        <div className='top'>
          <div
            className={`${page === 'home' ? 'card active' : 'card'}`}
            onClick={() => {
              navigate('/home')
              removeStoredLocal()
              if (isSmallScreen) {
                handleToggle()
              }
              setWalletToggle('false')
              setFinanceToggle('false')
              setExpenseToggle('false')
            }}
          >
            <RiDashboardFill />
            <p>Dashboard</p>
          </div>
          <div className={`${!expenseToggle ? 'cards active' : 'cards'}`}>
            <div
              className='card-top'
              onClick={() => {
                handleDropClick('expense')
              }}
            >
              <div className='left'>
                <GiWallet />
                <p>Cards</p>
              </div>
              <div className='right'>
                {!expenseToggle ? <AiOutlineUp /> : <AiOutlineDown />}
              </div>
            </div>
            {!expenseToggle && (
              <div className='card-body'>
                <ul>
                  <li
                    onClick={() => {
                      navigate('/debit-card/usd-card')
                      if (isSmallScreen) {
                        handleToggle()
                      }
                      removeStoredLocal()
                    }}
                    className={
                      id === 'usd-card' ||
                      id === 'usd-edits' ||
                      id === 'request-sudo'
                        ? 'it'
                        : ''
                    }
                  >
                    <FaCreditCard /> <p>Virtual cards (USD)</p>
                  </li>
                  <li
                    onClick={() => {
                      navigate('/debit-card/ngn-card')
                      if (isSmallScreen) {
                        handleToggle()
                      }
                      removeStoredLocal()
                    }}
                    className={id === 'ngn-card' && 'it'}
                  >
                    <FaCreditCard /> <p>Virtual cards (Naira)</p>
                  </li>
                  {returnedUser.is_business && (
                    <li
                      onClick={() => {
                        navigate('/physical-card/secure')
                        if (isSmallScreen) {
                          handleToggle()
                        }
                        removeStoredLocal()
                      }}
                      className={page.includes('physical-card') && 'it'}
                    >
                      <FaCreditCard /> <p>Physical cards (Naira)</p>
                    </li>
                  )}
                </ul>
              </div>
            )}
          </div>
          <div className={`${!walletToggle ? 'cards active' : 'cards'}`}>
            <div
              className='card-top'
              onClick={() => {
                handleDropClick('wallet')
              }}
            >
              <div className='left'>
                <IoMdWallet />
                <p>Wallet</p>
              </div>
              <div className='right'>
                {!walletToggle ? <AiOutlineUp /> : <AiOutlineDown />}
              </div>
            </div>
            {!walletToggle && (
              <div className='card-body'>
                <ul>
                  <li
                    onClick={() => {
                      navigate('/e-wallet/dashboard')
                      if (isSmallScreen) {
                        handleToggle()
                      }
                      removeStoredLocal()
                    }}
                    className={page.includes('e-wallet') && 'it'}
                  >
                    <FaWallet /> <p>Main wallet</p>
                  </li>
                  {returnedUser.is_business && (
                    <li
                      onClick={() => {
                        navigate('/sub-wallet/dashboard')
                        if (isSmallScreen) {
                          handleToggle()
                        }
                        removeStoredLocal()
                      }}
                      className={page.includes('sub-wallet') && 'it'}
                    >
                      <FaWallet /> <p>Sub wallet</p>
                    </li>
                  )}
                </ul>
              </div>
            )}
          </div>
          {/* <div className={`${!financeToggle ? 'cards active' : 'cards'}`}>
            <div
              className='card-top'
              onClick={() => {
                handleDropClick('finance')
              }}
            >
              <div className='left'>
                <FaCoins />
                <p>Finance</p>
              </div>
              <div className='right'>
                {!financeToggle ? <AiOutlineUp /> : <AiOutlineDown />}
              </div>
            </div>
            {!financeToggle && (
              <div className='card-body'>
                <ul>
                  <li
                    onClick={() => {
                      navigate('/finance')
                      if (isSmallScreen) {
                        handleToggle()
                      }
                      removeStoredLocal()
                    }}
                    className={page.includes('finance') && 'it'}
                  >
                    <BsBank2 />
                    <p>Finance Dashboard</p>
                  </li>
                  <li
                    onClick={() => {
                      navigate('/expense')
                      if (isSmallScreen) {
                        handleToggle()
                      }
                      removeStoredLocal()
                    }}
                    className={page.includes('expense') && 'it'}
                  >
                    <GoGraph />
                    <p>Expense Tracking</p>
                  </li>
                  <li
                    onClick={() => {
                      navigate('/budget')
                      if (isSmallScreen) {
                        handleToggle()
                      }
                      removeStoredLocal()
                    }}
                    className={page.includes('budget') && 'it'}
                  >
                    <TfiWrite />
                    <p>Budgeting</p>
                  </li>
                </ul>
              </div>
            )}
          </div> */}
          {/* {returnedUser.is_business && (
            <div
              className={`${
                page.includes('developers') ? 'card active' : 'card'
              }`}
              onClick={() => {
                if (isSmallScreen) {
                  handleToggle()
                }
                navigate('/developers')
                removeStoredLocal()
              }}npm runjfnjnfjnfjnjnjnjnjf
            >
              <GiSpanner />
              <p>Developers</p>
            </div>
          )} */}
          {(returnedUser.is_business || returnedUser.is_business_created) &&
            hasAccess(
              user.roles.map((role) => role.trim()),
              'userMgt'
            ) && (
              <div
                className={`${
                  page.includes('user-management') ? 'card active' : 'card'
                }`}
                onClick={() => {
                  if (isSmallScreen) {
                    handleToggle()
                  }
                  navigate('/user-management/dashboard')
                  removeStoredLocal()
                }}
              >
                <MdGroups />
                <p>User Management</p>
              </div>
            )}
          {/* <div
            className={`${
              page.includes('expense-approval') ? 'card active' : 'card'
            }`}
            onClick={() => {
              if (isSmallScreen) {
                handleToggle()
              }
              navigate('/expense-approval/dashboard')
              removeStoredLocal()
            }}
          >
            <GiTakeMyMoney />
            <p>Expense Approval</p>
          </div> */}
        </div>

        <div className='bottom'>
          {!returnedUser.is_business && !returnedUser.is_business_created && (
            <div
              className={`${
                page === 'activate-business' ? 'card active' : 'card'
              }`}
              onClick={() => {
                if (isSmallScreen) {
                  handleToggle()
                }
                removeStoredLocal()
                if (user.biz_reg_submitted) {
                  notify('info', 'Verification in progress, please wait.')
                } else {
                  if (user.bvn === null) {
                    notify('info', 'Complete profile creation.')
                    navigate('/settings/profile')
                  } else {
                    navigate('/activate-business/home')
                  }
                }
              }}
            >
              <i></i>
              <FaSuitcase />
              {user.biz_reg_submitted ? (
                <p>Processing...</p>
              ) : (
                <p>Activate Business Profile</p>
              )}
            </div>
          )}
          <div
            className={`${page === 'faqs' ? 'card active' : 'card'}`}
            onClick={() => {
              if (isSmallScreen) {
                handleToggle()
              }
              navigate('/faqs')
              removeStoredLocal()
            }}
          >
            <i></i>
            <FaQuestion />
            <p>FAQs</p>
          </div>
          <div
            className={`${page === 'contact' ? 'card active' : 'card'}`}
            onClick={() => {
              if (isSmallScreen) {
                handleToggle()
              }
              navigate('/contact')
              removeStoredLocal()
            }}
          >
            <i></i>
            <RiCustomerService2Line />
            <p>Contact Us</p>
          </div>
          <div
            className={`${page.includes('settings') ? 'card active' : 'card'}`}
            onClick={() => {
              if (isSmallScreen) {
                handleToggle()
              }
              navigate('/settings/profile')
              removeStoredLocal()
            }}
          >
            <i></i>
            <RiSettings5Fill />
            <p>Settings</p>
          </div>
          <div className='card log' onClick={handleLogout}>
            <FaSignOutAlt />
            <p>Logout</p>
          </div>
        </div>
      </div>
      <div className='angle'>
        <img src={Angle} alt='' />
      </div>
    </div>
  )
}

export default Sidebar
