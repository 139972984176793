import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import CardWall from '../../../../../Image/CardWallet.png'
import CardCredit from '../../../../../Image/credit-card.png'
import Dollar from '../../../../../Image/dollar2.png'
import { ReactComponent as Foreign } from '../../../../../Image/currency-exchange-1.svg'
import { ReactComponent as Send } from '../../../../../Image/plus 1.svg'
import { ReactComponent as Card } from '../../../../../Image/plus 1-1.svg'
import {
  FaCaretLeft,
  FaCaretRight,
  FaMoneyBill,
  FaWallet,
  FaCreditCard,
  FaPlus,
  FaInfoCircle,
  FaTimes,
} from 'react-icons/fa'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/bundle'
import SwiperCore, { Navigation, Pagination } from 'swiper'
import API from '../../../../../Context/API'
import { useGlobalContext } from '../../../../../Context/Context'
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa'
import Ngn from '../../../../../Image/ngn.svg'
import Usd from '../../../../../Image/united-states-flag-icon.svg'
import Gbp from '../../../../../Image/united-kingdom-flag-icon.svg'
import Eur from '../../../../../Image/europe-flag-icon.svg'
import Cny from '../../../../../Image/china-flag-icon.svg'
import Cad from '../../../../../Image/canada-flag-icon.svg'
import ForeignData from '../../../../../Data/ForeignDatas'

const dashCardList = [
  {
    name: 'FUNDS',
    icon: Dollar,
    button: 'Fund wallet',
    navigate: 'e-wallet/fund-wallet',
    class: 'orange',
    logo: FaWallet,
  },
  {
    name: 'TRANSFER',
    icon: CardWall,
    button: 'Send money',
    class: 'blue',
    navigate: 'e-wallet/transfers/select',
    logo: FaMoneyBill,
  },
  {
    name: 'CARDS',
    icon: CardCredit,
    button: 'Request a card',
    class: 'black',
    navigate: 'debit-card/dashboard',
    logo: FaCreditCard,
  },
]

SwiperCore.use([Pagination, Navigation])
function WalletDashboard({
  walletTransactions,
  transactionFound,
  sudoDetails,
  notify,
}) {
  const authToken = sessionStorage.getItem('userId')
  const navigate = useNavigate()
  const { user, toggleInfo1 } = useGlobalContext()
  const [rate, setRate] = useState({})
  const [selectedCurrency, setSelectedCurrency] = useState({})
  const [nairaChange, setNairaChange] = useState('')
  const [otherChange, setOtherChange] = useState('')
  const [showCharges, setshowCharges] = useState('false')

  const fetchRate = () => {
    fetch(`${process.env.REACT_APP_API_URL}/cards/usd_exchange_rate/`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
      body: JSON.stringify({
        issuer: 'verto',
      }),
    }).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setRate(data.rate)
        })
      } else {
        res.json().then((data) => {})
      }
    })
  }
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/cards/usd_exchange_rate/`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
      body: JSON.stringify({
        issuer: 'verto',
      }),
    }).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setRate(data.rate)
          setSelectedCurrency({
            sign: '$',
            name: 'USD',
            flag: Usd,
            rate: data.rate.USD,
          })
        })
      } else {
        res.json().then((data) => {})
      }
    })
  }, [])
  const openModalNow = (e) => {
    fetchRate()
    setNairaChange('')
    setOtherChange('')
    const currencyMapping = {
      EUR: {
        sign: '€',
        name: 'EUR',
        flag: Eur,
        rate: rate.EUR,
      },
      GBP: {
        sign: '£',
        name: 'GBP',
        flag: Gbp,
        rate: rate.GBP,
      },
      USD: {
        sign: '$',
        name: 'USD',
        flag: Usd,
        rate: rate.USD,
      },
      CAD: {
        sign: '$',
        name: 'CAD',
        flag: Cad,
        rate: rate.CAD,
      },
      CNY: {
        sign: '¥',
        name: 'CNY',
        flag: Cny,
        rate: rate.CNY,
      },
    }
    const selectedCurrencyData = currencyMapping[e]
    if (selectedCurrencyData) {
      setSelectedCurrency(selectedCurrencyData)
    }
  }
  const handleNairaChange = (e) => {
    setNairaChange(e.target.value)
    const value = parseInt(e.target.value) / selectedCurrency.rate
    setOtherChange(value.toFixed(2))
  }
  const handleOtherChange = (e) => {
    setOtherChange(e.target.value)
    const value = parseInt(e.target.value) * selectedCurrency.rate
    setNairaChange(value.toFixed(2))
  }
  const [showAmount, setShowAmount] = useState(false)

  return (
    <div className='wallet_dashboard'>
      <div className='label'>
        <p>E-Wallet</p>
      </div>
      <div className='rowlex'>
        <div className='left'>
          <div className='left-top'>
            <div className='one'>
              <p>Main Wallet Balance</p>
              {showAmount ? (
                <FaRegEyeSlash onClick={() => setShowAmount(false)} />
              ) : (
                <FaRegEye onClick={() => setShowAmount(true)} />
              )}
            </div>
            <div
              className='two'
              onClick={() => navigate(`/e-wallet/fund-wallet`)}
            >
              <FaPlus />
              <p>Fund Wallet</p>
            </div>
          </div>
          <div className='left-rest'>
            <div className='one'>
              {showAmount ? (
                <h2>
                  ₦
                  {sudoDetails.available_balance
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </h2>
              ) : (
                <h2>₦****</h2>
              )}
            </div>
            <div className='two'>
              <h3 onClick={toggleInfo1}>How To Fund Wallet</h3>
            </div>
          </div>
        </div>
        <div className='right'>
          <div
            className='card'
            onClick={() => navigate(`/e-wallet/transfers/select`)}
          >
            <Send />
            <button>Send Money</button>
          </div>
          <div
            className='card grey'
            // onClick={() => {
            //   if (user.is_business) {
            //     navigate('/e-wallet/transfers/foreign-transfer/select')
            //   } else {
            //     notify(
            //       'info',
            //       'This feature is only available to Business profiles'
            //     )
            //   }
            // }}
          >
            <Foreign />
            <button>Foreign Transfer</button>
          </div>
          <div
            className='card'
            onClick={() => navigate(`/debit-card/usd-card`)}
          >
            <Card />
            <button>Request Card</button>
          </div>
        </div>
      </div>
      <div className='rowx'>
        <div className='left grey'>
          <div className='left-top'>
            <h2>Foreign Transfer Rate Check</h2>
          </div>
          <div className='selects'>
            <p>
              Currency <span>*</span>
            </p>
            <select
              name=''
              id=''
              value={selectedCurrency.name}
              disabled
              onChange={(e) => openModalNow(e.target.value)}
            >
              <option value=''>Select Currency</option>
              {ForeignData.map((item) => (
                <option value={item.currency}>{item.currency}</option>
              ))}
            </select>
          </div>
          <div className='info'>
            <div className='one'>
              {/* <FaInfoCircle onClick={() => setshowCharges(!showCharges)} /> */}
            </div>
            <div className='two'>
              <p className='exchange-rate'>
                1 {selectedCurrency.name} = NGN {selectedCurrency.rate}
              </p>
            </div>
          </div>
          {!showCharges && (
            <div className='charges'>
              <div className='close'>
                <FaTimes onClick={() => setshowCharges(!showCharges)} />
              </div>
              <p>
                Swift transactions have a fixed transfer fee of $30, while none
                swift transfers are $15
              </p>
            </div>
          )}
          <div className='transaction-box'>
            <div className='card'>
              <p>Amount</p>
              <div className='transaction-box-input'>
                <h2>N</h2>
                <input
                  type='number'
                  onChange={(e) => handleNairaChange(e)}
                  value={nairaChange}
                  disabled
                />
                <img src={Ngn} />
                <p>NGN</p>
              </div>
            </div>
            <div className='card'>
              <p>They Receive</p>
              <div className='transaction-box-input'>
                <h2>{selectedCurrency.sign}</h2>
                <input
                  type='number'
                  onChange={(e) => handleOtherChange(e)}
                  value={otherChange}
                  disabled
                />
                <div className='curr'>
                  <div className='dets'>
                    <img src={selectedCurrency.flag} />
                    <p>{selectedCurrency.name}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='right grey'>
          <div>
            <h2 className='gp-header'>Foreign Payments</h2>
          </div>
          <div className='rights'>
            <div className='card'>
              <div className='topx'>
                <h2>0</h2>
              </div>
              <div className='bottom'>
                <p>Completed Transfers</p>
              </div>
            </div>
            <div className='card'>
              <div className='topx'>
                <h2>0</h2>
              </div>
              <div className='bottom'>
                <p>Pending Transfers</p>
              </div>
            </div>
            <div className='card'>
              <div className='topx'>
                <h2>0</h2>
              </div>
              <div className='bottom'>
                <p>Approved Beneficiaries</p>
              </div>
            </div>
            <div className='card'>
              <div className='topx'>
                <h2>0</h2>
              </div>
              <div className='bottom'>
                <p>Pending Beneficiaries</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='mobile_transactions'>
        <div className='wallet_table-top'>
          <h2>Wallet Transaction Summary</h2>
          <button onClick={() => navigate('/e-wallet/transaction-summary')}>
            View All
          </button>
        </div>
        <div className='mobile_table'>
          {walletTransactions
            .slice(0, 5)
            .sort((a, b) => b.tx_date.localeCompare(a.tx_date))
            .map((item) => (
              <div
                className='rows'
                key={item.tx_id}
                onClick={() => navigate(`/e-wallet/reciept/${item.tx_id}`)}
              >
                <div className='cells roll'>
                  <h2>{item.provider}</h2>
                  <h2 className={item.mode}>₦{item.amount.toLocaleString()}</h2>
                </div>
                <div className='cells long'>
                  <p>
                    {item.narration.length > 80
                      ? item.narration.slice(0, 80) + '...'
                      : item.narration}
                  </p>
                </div>
                <div className='cells long'>
                  <p className='time'>
                    {new Date(item.tx_date).toLocaleString()}
                  </p>
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className='wallet_table'>
        <div className='wallet_table-top'>
          <h2>Wallet Transaction Summary</h2>
          <button onClick={() => navigate('/e-wallet/transaction-summary')}>
            View All
          </button>
        </div>
        <div className='wallet_table-wrap'>
          {transactionFound ? (
            <table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Naration</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {walletTransactions
                  .slice(0, 10)
                  .sort((a, b) => b.tx_date.localeCompare(a.tx_date))
                  .map((item) => (
                    <tr
                      key={item.tx_id}
                      onClick={() =>
                        navigate(`/e-wallet/reciept/${item.tx_id}`)
                      }
                    >
                      <td className='date'>
                        {new Date(item.tx_date).toDateString()}
                      </td>
                      <td>
                        {item.narration.length > 50
                          ? item.narration.slice(0, 50) + '...'
                          : item.narration}
                      </td>
                      <td className={item.mode}>
                        ₦{item.amount.toLocaleString()}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          ) : (
            <h2
              style={{
                color: '#c70000',
                fontFamily: 'Open Sans',
                fontSize: '2.2vw',
              }}
            >
              Nothing at the moment. Fund your wallet please and make a
              transaction
            </h2>
          )}
        </div>
      </div>
    </div>
  )
}

export default WalletDashboard
