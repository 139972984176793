import React from 'react'
import Footer from '../../Component/Footer/Footer'
import QuestionTab from '../../Component/FrequentQuestions/QuestionTab'
import Navigation from '../../Component/Navigation/Navigation2'
import FaqQuestions from '../../Data/FaqQuestions'
import { BsWhatsapp } from 'react-icons/bs'

function Faq() {
  return (
    <div>
      <Navigation />
      <div className='coverxx'>
        <div className='whatsapp'>
          <a href='https://wa.me/2348138134873'>
            <BsWhatsapp />
          </a>
        </div>
        <p>Let's Chat</p>
      </div>
      <div className='faq diff'>
        <div className='faq-main'>
          <div className='faq-top'>
            <h1>Frequently Asked Questions</h1>
          </div>
          <div className='faq-wrap'>
            <div className='cover'>
              {FaqQuestions.map((item) => (
                <QuestionTab item={item} key={item.id} />
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Faq
