import React from 'react'
import './InforGraphics.scss'
import { FaTimes } from 'react-icons/fa'
import ClickImg from '../../../../Image/click (3) 1.png'
import Card1 from '../../../../Image/currency-transfer 1.png'
import Card2 from '../../../../Image/currency-transfer 1-1.png'
import { useGlobalContext } from '../../../../Context/Context'

const InfoList = [
  {
    id: 1,
    image: ClickImg,
    details: 'Click on ‘Fund Wallet’ .',
    extra: '(Details of the account tied to your wallet is displayed)',
  },
  {
    id: 2,
    image: Card1,
    details:
      'Make transfer from your regular bank app to your wallet account details displayed.',
  },
  {
    id: 3,
    image: Card2,
    details:
      'Your wallet balance would be updated with the amount transferred within a few minutes.',
  },
]
function InfoFund() {
  const { toggleInfo1 } = useGlobalContext()
  return (
    <div className='info-main'>
      <div className='infor-top'>
        <div className='close'>
          <FaTimes onClick={toggleInfo1} />
        </div>
        <h2>Steps To Fund Your Wallet </h2>
      </div>
      <div className='cards'>
        {InfoList.map((item) => (
          <div className='card x' key={item.id}>
            <div className='num'>
              <p>{item.id}</p>
            </div>
            <div className='img'>
              <img src={item.image} alt='' />
            </div>
            <div className='text'>
              <p>{item.details}</p>
            </div>
            <div className='extra'>
              <p>{item.extra}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default InfoFund
