import React, { useState, useEffect } from 'react'
import './ExpenseRequest.scss'
import { FaCheck } from 'react-icons/fa'
import { BsFillCloudArrowUpFill } from 'react-icons/bs'
import { AiOutlineClose } from 'react-icons/ai'
import { FcOk, FcCancel } from 'react-icons/fc'
import { CiSearch } from 'react-icons/ci'
import { GrAdd } from 'react-icons/gr'
import SelectComponent from '../../../Select/select'
import pdf from '../../../../../../Image/pdf.svg'
import jpg from '../../../../../../Image/jpg.svg'
import illustration from '../../../../../../Image/Illustration.png'
import sudo_bank_codes from '../../../../../../Data/BankCodes'
import API from '../../../../../../Context/API'
import { useNavigate } from 'react-router-dom'
import FormLoader from '../../../../../../Component/FormLoader/FormLoader'
import { useGlobalContext } from '../../../../../../Context/Context'

function ExpenseRequest({ notify }) {
  const authToken = sessionStorage.getItem('userId')
  const [page, setPage] = useState('one')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isApproved, setIsApproved] = useState(false)
  const [selectedFiles, setSelectedFiles] = useState([])
  const [employees, setEmployees] = useState([])
  const navigate = useNavigate()
  const { departmentList, FetchDepartments } = useGlobalContext()

  const [requestPage, setRequestPage] = useState('box-one')

  const handleInputClick = () => {
    setIsModalOpen(true)
  }
  const handleAdd = (data) => {
    // const newEmployees = employees.push((item, i) => i === index)
    const Find = employees.find((item) => item.id === data.id)
    if (Find) {
      const newEmployee = employees.filter((item) => item.id !== data.id)
      setEmployees(newEmployee)
    } else {
      setEmployees((prev) => [...prev, data])
    }
  }
  const handleFileChange = (e) => {
    const file = e.target.files[0]
    const reader = new FileReader()
    reader.readAsDataURL(file)
    if (file.size > 3000000) {
      notify('error', 'file must be below 3mb')
    } else {
      reader.onloadend = () => {
        setSelectedFiles(file)
      }
    }
    // setSelectedFiles((prevFiles) => [
    //   ...prevFiles,
    //   ...Object.values(e.target.files),
    // ])
  }

  // This is submit
  const [requestTitle, setRequestTitle] = useState('')
  const [amount, setAmount] = useState('')
  const [description, setDescription] = useState('')
  const [requestType, setRequestType] = useState('')
  const [showForm, setShowForm] = useState('')
  const [sudoUserBankDet, setSudoUserBankDet] = useState({
    bankCode: '',
    name: '',
  })
  const [accountNumberCorrect, setAccountNumberCorrect] = useState(false)
  const [sudoAccountDetails, setSudoAccountDetails] = useState({
    ac_name: '',
  })
  const [accountNumberFecthing, setAccountNumberFecthing] = useState()
  const [recipientPhone, setRecipientPhone] = useState('')
  const [recipientEmail, setRecipientEmail] = useState('')
  const [recipientAddress, setRecipientAddress] = useState('')
  const [recipientDepartment, setRecipientDepartment] = useState('')
  const [formLoading, setformLoading] = useState(false)

  const checkStepOne = () => {
    if (requestTitle.length < 1) {
      notify('warn', 'Request title is required')
    } else if (amount.length < 1) {
      notify('warn', 'Amount is required')
    } else if (description.length < 1) {
      notify('warn', 'Description title is required')
    } else if (employees.length < 1) {
      notify('warn', 'Select at least one approver')
    } else if (requestType.length < 1) {
      notify('warn', 'Request type is required')
    } else {
      setPage('two')
    }
  }
  //////////////////////////
  const fetchSudoUseBankDet = (e) => {
    const findUserBank = sudo_bank_codes.find((item) => item.bankCode === e)
    setSudoUserBankDet(findUserBank)
  }
  const fetchSudoUserName = (account_no) => {
    setAccountNumberFecthing(true)
    fetch(
      `https://p499kkwmp9.execute-api.eu-west-1.amazonaws.com/prod/wallet/verify`,
      {
        method: 'post',
        headers: {
          'content-Type': 'application/json',
          Authorization: `Token ${authToken}`,
        },
        body: JSON.stringify({
          ac_no: account_no,
          bank_code: sudoUserBankDet.bankCode,
        }),
      }
    )
      .then((res) => {
        if (res.status === 200) {
          setAccountNumberCorrect(true)
          res.json().then((data) => {
            setSudoAccountDetails(data.message)
          })
        } else {
          setAccountNumberFecthing(false)
          res.json().then((data) => {
            setAccountNumberCorrect(false)
          })
        }
      })
      .catch(() => {
        notify('error', 'Internal server errorr, Contact support.')
      })
  }
  const Clear = () => {
    setAccountNumberFecthing()
    setAccountNumberCorrect(false)
    setSudoAccountDetails({
      ac_name: '',
    })
  }

  const handleSubmit = () => {
    const newIDs = employees.map((item) => item.id)
    const data = {
      request_title: requestTitle,
      amount: amount,
      description: description,
      type_of: requestType,
      approver_ids: JSON.stringify(newIDs),
      payout_required: isApproved,
      beneficiary_type: showForm,
      support_doc: selectedFiles,
    }
    if (showForm === 'personal') {
      data.recipient_fname = sudoAccountDetails.ac_name
      data.recipient_phone = recipientPhone
      data.recipient_email = recipientEmail
      data.recipient_address = recipientAddress
      data.recipient_bank_code = sudoUserBankDet.bankCode
      data.recipient_ac_number = sudoAccountDetails.ac_no
    } else if (showForm === 'team') {
      data.department_id = recipientDepartment
    } else if (showForm === 'vendor') {
      data.vendor_name = sudoAccountDetails.ac_name
      data.vendor_email = recipientEmail
      data.vendor_phone = recipientPhone
      data.vendor_address = recipientAddress
      data.vendor_bank_code = sudoUserBankDet.bankCode
      data.vendor_ac_number = sudoAccountDetails.ac_no
    }
    const form_data = new FormData()
    Object.keys(data).forEach((key) => {
      form_data.append(key, data[key])
    })
    // for (let i = 0; i < selectedFiles.length; i++) {
    //   form_data.append('support_doc', selectedFiles[i]) // 'files[]' is the key used for each file
    // }
    const obj = {}
    form_data.forEach((value, key) => {
      obj[key] = value
    })
    setformLoading(true)
    fetch(`${process.env.REACT_APP_API_URL}/expense/expense/`, {
      method: 'post',
      headers: {
        // 'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
      body: form_data,
    })
      .then((res) => {
        if (res.status === 201) {
          res.json().then((data) => {
            setRequestPage('box-two')
            setformLoading(false)
          })
        } else {
          res.json().then((data) => {
            notify('error', data.message)
            setformLoading(false)
          })
        }
      })
      .catch(() => {
        notify(
          'error',
          'Internal server errorr. Check your internet Connection'
        )
        setformLoading(false)
      })
  }

  //////
  const loggedInUserDetails = JSON.parse(sessionStorage.getItem('user'))
  const [UserList, setUserList] = useState({})
  const [searchName, setSearchName] = useState('')
  useEffect(() => {
    API.fetchUserMana().then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setUserList(data)
        })
      } else if (res.status === 204) {
        setUserList({ count: 0, results: [] })
      }
    })
    FetchDepartments()
  }, [])
  return (
    <div className='expenseRequest'>
      {isModalOpen && (
        <div className='open-modal'>
          <div className='modal-content'>
            <div className='modal-header'>
              <div>
                <h3>Select Request Approver</h3>
                <p>Invite or add Minimum of one approver</p>
              </div>

              <div>
                <AiOutlineClose onClick={() => setIsModalOpen(false)} />
              </div>
            </div>

            <div className='filter-tab'>
              {/* <div className='filter'>
                <CiFilter />
                <input placeholder='Filter' />
              </div> */}

              <div className='filter'>
                <CiSearch />
                <input
                  placeholder='Search username, Role, Title, Department'
                  id='searchInput'
                  onChange={(e) => setSearchName(e.target.value)}
                />
              </div>
            </div>

            <div>
              {UserList.results
                .filter(
                  (item) =>
                    item.user !== loggedInUserDetails.user_id &&
                    item.username
                      .toLocaleLowerCase()
                      .includes(searchName.toLocaleLowerCase())
                )
                .map((item, i) => (
                  <div className='approver-names' key={item.user}>
                    <div className='app-name'>
                      <div>
                        <h4>{item.username}</h4>
                        <p>{item.roles}</p>
                      </div>
                      {employees.find((data) => data.user === item.user) && (
                        <span>ADDED</span>
                      )}
                    </div>
                    <GrAdd size={24} onClick={() => handleAdd(item)} />
                  </div>
                ))}
            </div>
            <div className='buttons'>
              <button onClick={() => setIsModalOpen(false)}>Cancel</button>
              <button onClick={() => setIsModalOpen(false)}>
                Add Approvers
              </button>
            </div>
          </div>
        </div>
      )}
      <div className='exp-top'>
        <div className='exp-cover'>
          <div className='top'>
            <h2>Expense Request</h2>
          </div>
          <div className='exp-progress'>
            <div
              className={page === 'one' ? 'one round active' : 'one round done'}
            >
              <div className='num'>
                {page === 'one' ? <p>1</p> : <FaCheck />}
              </div>
              <h4>Request Details</h4>
            </div>

            <div className={page === 'two' ? 'seperator k' : 'seperator'}></div>

            <div
              className={
                page === 'two'
                  ? 'two round active'
                  : page === 'three'
                  ? 'two round done'
                  : 'two round'
              }
            >
              <div className='num'>
                {page === 'two' ? (
                  <p>2</p>
                ) : page === 'three' ? (
                  <FaCheck />
                ) : (
                  <p>2</p>
                )}
              </div>
              <h4>Beneficiary Details</h4>
            </div>
            {/* {isApproved && (
              <>
                <div
                  className={page === 'three' ? 'seperator k' : 'seperator'}
                ></div>

                <div
                  className={
                    page === 'three' ? 'three round done' : 'three round'
                  }
                >
                  <div className='num'>
                    {page === 'three' ? <FaCheck /> : <p>3</p>}
                  </div>
                  <h4>Payout Details</h4>
                </div>
              </>
            )} */}
          </div>

          <div className='exp-form'>
            {requestPage === 'box-one' ? (
              <>
                {page === 'one' ? (
                  <div className='exp-one'>
                    <div className='card'>
                      <p>Request Title</p>
                      <input
                        type='text'
                        placeholder='Enter title of request'
                        onChange={(e) => setRequestTitle(e.target.value)}
                        value={requestTitle}
                      />
                    </div>

                    <div className='row'>
                      <div className='cardx'>
                        <p>Request Type</p>
                        <SelectComponent
                          setValue={setRequestType}
                          value={requestType}
                          options={[
                            {
                              value: 'expense_request',
                              label: 'Expense request',
                            },
                            {
                              value: 'invoice_payment',
                              label: 'Invoice Payment',
                            },
                            {
                              value: 'expense_reimbursement',
                              label: 'Reimbursement',
                            },
                          ]}
                          placeholder='Select Request Type'
                        />
                      </div>

                      <div className='cardx'>
                        <p>Request Approver</p>
                        <input
                          onClick={handleInputClick}
                          value={employees
                            .map((item) => item.username)
                            .join(', ')
                            .toUpperCase()}
                        />
                      </div>
                    </div>

                    <div className='row'>
                      <div className='cardx'>
                        <p>Authorization style</p>
                        <div className='cardx-exp'>
                          <div
                            className={
                              !isApproved ? 'approved active' : 'approved'
                            }
                            onClick={() => setIsApproved(false)}
                          >
                            <p>Approve only</p>
                          </div>
                          <div
                            className={
                              isApproved ? 'approved active' : 'approved'
                            }
                            onClick={() => setIsApproved(true)}
                          >
                            <p>Approve and Disburse</p>
                          </div>
                        </div>
                      </div>

                      <div className='cardx'>
                        <p>Expense Amount</p>
                        <input
                          className='expense-amount'
                          placeholder='#0.00'
                          type='number'
                          value={amount}
                          onChange={(e) => setAmount(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className='card'>
                      <p>Description</p>
                      <textarea
                        name=''
                        id=''
                        cols='30'
                        rows='3'
                        onChange={(e) => setDescription(e.target.value)}
                        value={description}
                      ></textarea>
                    </div>
                    <div className='input0file'>
                      <input
                        type='file'
                        name=''
                        id='documents'
                        onChange={handleFileChange}
                        accept='image/*, .pdf'
                      />
                    </div>
                    <label htmlFor='documents'>
                      <div className='card'>
                        <div className='card-upload'>
                          <BsFillCloudArrowUpFill />
                          <p>
                            Drop your documents here or{' '}
                            <span>click to browse</span>
                          </p>
                        </div>
                        <p>
                          Upload a file each with a size below 3 MB. Only the
                          following formats are allowed: pdf, jpg, png.
                        </p>
                      </div>
                    </label>
                    {selectedFiles.type && (
                      <div className='card'>
                        <div className='card-invoice'>
                          <div className='left'>
                            <div>
                              <img
                                alt=''
                                src={
                                  selectedFiles.type.includes('image')
                                    ? jpg
                                    : pdf
                                }
                              />
                            </div>
                            <div>
                              <h6>{selectedFiles.name}</h6>
                              <p>{selectedFiles.size / 1024}kb </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {/* <div className='card'>
                      <div className='card-invoice'>
                        <div className='left'>
                          <div>
                            <img alt='' src={jpg} />
                          </div>
                          <div>
                            <h6>Invoice.pdf</h6>
                            <p>313 KB . 10 Dec, 2022 </p>
                          </div>
                        </div>
                        <div className='right'>
                          <BsDownload />
                          <RiDeleteBin6Line />
                        </div>
                      </div>
                    </div> */}

                    <div className='buttons'>
                      <button>Cancel</button>
                      <button onClick={() => checkStepOne()}>Next</button>
                    </div>
                  </div>
                ) : (
                  <div className='exp-one'>
                    <div className='card'>
                      <p>Beneficiary</p>
                      <SelectComponent
                        extra={Clear}
                        options={[
                          { value: 'personal', label: 'Personal' },
                          { value: 'team', label: 'Department' },
                          { value: 'vendor', label: 'Vendor' },
                        ]}
                        value={showForm}
                        setValue={setShowForm}
                        placeholder='Select Beneficiary Type'
                      />
                    </div>

                    {showForm === 'personal' && (
                      <div>
                        <div className='row'>
                          <div className='cardx'>
                            <p>Recipient email</p>
                            <input
                              type='email'
                              value={recipientEmail}
                              onChange={(event) => {
                                setRecipientEmail(event.target.value)
                              }}
                            />
                          </div>

                          <div className='cardx'>
                            <p>Recipient phone number</p>
                            <input
                              type='tel'
                              value={recipientPhone}
                              onChange={(event) => {
                                setRecipientPhone(event.target.value)
                              }}
                            />
                          </div>
                        </div>

                        <div className='row'>
                          <div className='cardx'>
                            <p>Select Bank Institution</p>
                            <SelectComponent
                              setValue={fetchSudoUseBankDet}
                              options={sudo_bank_codes
                                .map((item) => ({
                                  value: item.bankCode,
                                  label: item.name,
                                }))
                                .sort((a, b) => a.label.localeCompare(b.label))}
                              extra={Clear}
                            />
                          </div>
                          <div className='cardx'>
                            <p>
                              Bank Account Number{' '}
                              {accountNumberCorrect ? <FcOk /> : <FcCancel />}
                            </p>
                            <input
                              type='number'
                              onChange={(e) => {
                                if (e.target.value.length === 10) {
                                  fetchSudoUserName(e.target.value)
                                }
                              }}
                              disabled={accountNumberFecthing ? true : false}
                              defaultValue={sudoAccountDetails.ac_no}
                            />
                            {accountNumberFecthing === false && (
                              <p
                                style={{
                                  color: 'red',
                                  fontSize: '10px',
                                  paddingTop: '5px',
                                }}
                              >
                                Account number incorrect, please confirm and try
                                again.
                              </p>
                            )}
                          </div>
                        </div>

                        <div className='row'>
                          <div className='cardx'>
                            <p>Bank Account Name</p>
                            <input
                              type='text'
                              value={sudoAccountDetails.ac_name}
                              disabled
                              readOnly
                            />
                          </div>
                          <div className='cardx'>
                            <p>Recipient Address</p>
                            <input
                              type='text'
                              value={recipientAddress}
                              onChange={(event) => {
                                setRecipientAddress(event.target.value)
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {showForm === 'team' && (
                      <div>
                        <div className='row'>
                          <div className='cardx long'>
                            <p>Company's Team</p>
                            <SelectComponent
                              extra={Clear}
                              options={departmentList.map((item) => ({
                                value: item.id,
                                label: item.name,
                              }))}
                              value={recipientDepartment}
                              setValue={setRecipientDepartment}
                              placeholder='Select Beneficiary Type'
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    {showForm === 'vendor' && (
                      <div>
                        <div className='row'>
                          <div className='cardx'>
                            <p>Vendor email</p>
                            <input
                              type='email'
                              value={recipientEmail}
                              onChange={(event) => {
                                setRecipientEmail(event.target.value)
                              }}
                            />
                          </div>

                          <div className='cardx'>
                            <p>Vendor phone number</p>
                            <input
                              type='tel'
                              value={recipientPhone}
                              onChange={(event) => {
                                setRecipientPhone(event.target.value)
                              }}
                            />
                          </div>
                        </div>

                        <div className='row'>
                          <div className='cardx'>
                            <p>Vendor Bank Institution</p>
                            <SelectComponent
                              setValue={fetchSudoUseBankDet}
                              options={sudo_bank_codes
                                .map((item) => ({
                                  value: item.bankCode,
                                  label: item.name,
                                }))
                                .sort((a, b) => a.label.localeCompare(b.label))}
                              extra={Clear}
                            />
                          </div>
                          <div className='cardx'>
                            <p>
                              Bank Account Number{' '}
                              {accountNumberCorrect ? <FcOk /> : <FcCancel />}
                            </p>
                            <input
                              type='number'
                              onChange={(e) => {
                                if (e.target.value.length === 10) {
                                  fetchSudoUserName(e.target.value)
                                }
                              }}
                              disabled={accountNumberFecthing ? true : false}
                              defaultValue={sudoAccountDetails.ac_no}
                            />
                            {accountNumberFecthing === false && (
                              <p
                                style={{
                                  color: 'red',
                                  fontSize: '10px',
                                  paddingTop: '5px',
                                }}
                              >
                                Account number incorrect, please confirm and try
                                again.
                              </p>
                            )}
                          </div>
                        </div>

                        <div className='row'>
                          <div className='cardx'>
                            <p>Bank Account Name</p>
                            <input
                              type='text'
                              value={sudoAccountDetails.ac_name}
                              disabled
                              readOnly
                            />
                          </div>
                          <div className='cardx'>
                            <p>Vendor Address</p>
                            <input
                              type='text'
                              value={recipientAddress}
                              onChange={(event) => {
                                setRecipientAddress(event.target.value)
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {formLoading ? (
                      <FormLoader />
                    ) : (
                      <div className='buttons'>
                        <button onClick={() => setPage('one')}>Cancel</button>
                        <button
                          // onClick={() => checkStepTwo()}
                          onClick={handleSubmit}
                        >
                          Generate Approval Request
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </>
            ) : (
              <div className='exp-one'>
                <div className='success-card'>
                  <h3>Succesful</h3>
                  <img alt='' src={illustration} />
                  <p>Expense Request was Successfully Sent</p>
                </div>

                <div className='buttons'>
                  <button onClick={() => window.location.reload()}>
                    Initiate another Request
                  </button>
                  <button
                    onClick={() => navigate('/expense-approval/dashboard')}
                  >
                    Go To Request Dashboard
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div></div>
    </div>
  )
}

export default ExpenseRequest
