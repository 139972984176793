import React, { useState, useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router'
import Logo from '../../../../../Image/cardlogo.png'
import MasterLogo from '../../../../../Image/masterlogo.png'
import Successful from '../../../../../Image/Successful.png'
import Logo2 from '../../../../../Image/cardlogo2.png'
import VerveLogo from '../../../../../Image/vereve.png'
import VerveLogo3 from '../../../../../Image/masterlogo.png'
import API from '../../../../../Context/API'
import { BiCopy } from 'react-icons/bi'
import Loader from '../../../../../Image/loder.gif.gif'
import { FaChevronLeft } from 'react-icons/fa'
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa'
import { PaystackButton } from 'react-paystack'
import PinInput from 'react-pin-input'

function CardEdit({ notify }) {
  const publicKey = process.env.REACT_APP_PAYSTACK_PUBLIC_KEY
  const authToken = sessionStorage.getItem('userId')
  const navigate = useNavigate()
  const [cloading, setcLoading] = useState(true)
  const { new_id } = useParams()
  const [debitCard, setDebitCard] = useState({})
  const [cardTransactions, setCardTransactions] = useState([])
  const [numberCopied, setNumberCopied] = useState(false)
  const [cardStage, setCardStage] = useState('default')
  const [cardPage, setCardPage] = useState('main')
  const [dollarRate, setdollarRate] = useState(0)
  const [creditAmmount, setCreditAmmount] = useState(0)
  const [showAmount, setShowAmount] = useState(0)
  const [amount, setAmount] = useState(0)
  const [email, setEmail] = useState('')
  const [id, setId] = useState('')
  const [userName, setUserName] = useState('')
  const [liquidation, setLiquidation] = useState('')
  const [confirmed, setConfirmed] = useState(false)
  const [confirmedDetails, setConfirmedDetails] = useState({
    message: '',
    narration: '',
    total_bill_naira: 0,
  })
  const [magicPin, setmagicPin] = useState(0)
  const [showCvv, setShowCvv] = useState(false)
  const [freezeLoading, setfreezeLoading] = useState(false)
  useEffect(() => {
    API.getvirtualCard()
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setDebitCard(data.cards.find((item) => item.id === new_id))
            setcLoading(false)
            fetch(`${process.env.REACT_APP_API_URL}/cards/card_txns/`, {
              method: 'post',
              headers: {
                'content-Type': 'application/json',
                Authorization: `Token ${authToken}`,
              },
              body: JSON.stringify({
                card_id: data.cards.find((item) => item.id === new_id).id,
              }),
            }).then((res) => {
              if (res.status === 200) {
                res.json().then((data) => {
                  setCardTransactions(data.message)
                })
              } else {
                res.json().then((data) => {})
              }
            })
          })
        } else {
          res.json().then((data) => {})
        }
      })
      .catch(() => {})
  }, [])
  useEffect(() => {
    API.fetchUser()
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setEmail(data.email)
            setId(data.id)
            setUserName(data.username)
          })
        } else if (res.status === 403) {
        }
      })
      .catch(() => {})
  }, [])
  useEffect(() => {
    document.title = 'Spendwise | Virtual Card'
  }, [])
  const RefreshCard = () => {
    API.getvirtualCard()
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setDebitCard(data.cards.find((item) => item.id === new_id))
            setcLoading(false)
          })
        } else {
          res.json().then((data) => {})
        }
      })
      .catch(() => {})
    setCreditAmmount(0)
    setTimeout(() => {
      setCardStage('default')
    }, 3000)
  }
  const copyCardNumber = (e) => {
    navigator.clipboard.writeText(e)
    setNumberCopied(true)
  }
  useEffect(() => {
    API.getDollarRate()
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setdollarRate(data.rate.funding_rate)
            setLiquidation(data.rate.liquidation_rate)
          })
        } else {
          res.json().then((data) => {})
        }
      })
      .catch(() => {})
  }, [])
  const completePurchase = () => {
    fetch(`${process.env.REACT_APP_API_URL}/cards/fund_virtual_card/`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
      body: JSON.stringify({
        card_id: debitCard.id,
        amount: parseInt(creditAmmount),
      }),
    }).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          notify('success', data.message)
          setTimeout(() => {
            RefreshCard()
          }, 4000)
        })
      } else {
        res.json().then((data) => {
          notify('error', data.message)
        })
      }
    })
  }
  const DeleteCard = () => {
    if (debitCard.balance > 0 && debitCard.currency === 'USD') {
      fetch(`${process.env.REACT_APP_API_URL}/cards/liquidate_card/`, {
        method: 'post',
        headers: {
          'content-Type': 'application/json',
          Authorization: `Token ${authToken}`,
        },
        body: JSON.stringify({
          id: debitCard.id,
        }),
      }).then((res) => {
        if (res.status === 200) {
          fetch(`${process.env.REACT_APP_API_URL}/cards/delete_card/`, {
            method: 'delete',
            headers: {
              'content-Type': 'application/json',
              Authorization: `Token ${authToken}`,
            },
            body: JSON.stringify({
              card_id: debitCard.id,
            }),
          }).then((res) => {
            if (res.status === 200) {
              res.json().then((data) => {
                notify('success', data.message)
                setTimeout(() => {
                  window.location.replace('/debit-card/dashboard')
                }, 4000)
              })
            } else {
              res.json().then((data) => {
                notify('error', data.message)
              })
            }
          })
        } else {
          res.json().then((data) => {
            notify('error', data.message)
          })
        }
      })
    } else {
      fetch(`${process.env.REACT_APP_API_URL}/cards/delete_card/`, {
        method: 'delete',
        headers: {
          'content-Type': 'application/json',
          Authorization: `Token ${authToken}`,
        },
        body: JSON.stringify({
          card_id: debitCard.id,
        }),
      }).then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setTimeout(() => {
              window.location.replace('/debit-card/dashboard')
            }, 4000)
          })
        } else {
          res.json().then((data) => {
            notify('error', data.message)
          })
        }
      })
    }
  }
  const LiquidateCard = () => {
    fetch(`${process.env.REACT_APP_API_URL}/cards/liquidate_card/`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
      body: JSON.stringify({
        id: debitCard.id,
      }),
    }).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          notify('success', data.message)
          RefreshCard()
        })
      } else {
        res.json().then((data) => {
          notify('error', data.message)
        })
      }
    })
  }
  const FreezeCard = () => {
    setfreezeLoading(true)
    fetch(`${process.env.REACT_APP_API_URL}/cards/freeze_card/`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
      body: JSON.stringify({
        card_id: debitCard.id,
        action: 'freeze',
        pin: magicPin,
      }),
    }).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          notify('success', data.message)
          RefreshCard()
          setfreezeLoading(false)
        })
      } else {
        res.json().then((data) => {
          notify('error', data.message)
          setfreezeLoading(false)
        })
      }
    })
  }
  const UnfreezeCard = () => {
    setfreezeLoading(true)
    fetch(`${process.env.REACT_APP_API_URL}/cards/freeze_card/`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
      body: JSON.stringify({
        card_id: debitCard.id,
        action: 'unfreeze',
        pin: magicPin,
      }),
    }).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          notify('success', data.message)
          RefreshCard()
          setfreezeLoading(false)
        })
      } else {
        res.json().then((data) => {
          notify('error', data.message)
          setfreezeLoading(false)
        })
      }
    })
  }
  const confirmTotalBills = () => {
    fetch(`${process.env.REACT_APP_API_URL}/cards/total_bill/`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
      body: JSON.stringify({
        alias: debitCard.alias,
        event: 'card funding',
        currency: debitCard.currency,
        amount: parseInt(creditAmmount),
      }),
    }).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setConfirmed(true)
          setConfirmedDetails(data)
        })
      } else {
        res.json().then((data) => {})
      }
    })
  }
  const componentProps = {
    email,
    amount,
    metadata: {
      custom_fields: [
        {
          name: userName,
          pk: id,
          card_action: 'save',
        },
      ],
    },
    publicKey,
    text: `Fund ${showAmount.toLocaleString()}`,
    onSuccess: () => {
      completePurchase()
    },
    onClose: () => notify('warn', ' Transaction closed'),
  }
  return cloading ? (
    <div className='vloader'>
      <img src={Loader} />
    </div>
  ) : (
    <div className='virtual_cards-edit'>
      {cardPage === 'main' && (
        <div className='main-card'>
          <div className='back-but'>
            <p onClick={() => navigate('/debit-card/dashboard')}>
              <FaChevronLeft /> Back
            </p>
          </div>
          <div className='card-box'>
            <div className='card_wrap'>
              <div className='card-box_top'>
                <h2>{debitCard.alias}</h2>
              </div>
              <div className='credit-card-body' id={debitCard.currency}>
                <div className='credit-card-body-top'>
                  {debitCard.currency === 'USD' ? (
                    <img src={Logo2} alt='' />
                  ) : (
                    <img src={Logo} alt='' />
                  )}
                  <p>
                    {debitCard.currency === 'USD' ? '$' : '₦'}
                    {debitCard.balance.toLocaleString()}
                  </p>
                </div>

                <div className='card-number'>
                  <p>{debitCard.pin.slice(0, 4)}</p>
                  <p>{debitCard.pin.slice(4, 8)}</p>
                  <p>{debitCard.pin.slice(8, 12)}</p>
                  <p>{debitCard.pin.slice(12, 16)}</p>
                  <p>{debitCard.pin.slice(16, 20)}</p>
                </div>
                <div className='credit-card-body-bottom'>
                  <div>
                    <p>Card Holder</p>
                    <h2>{debitCard.name}</h2>
                  </div>
                  <div>
                    <p>Exp</p>
                    <h2>
                      {debitCard.exp_month}/{debitCard.exp_year}
                    </h2>
                  </div>
                  <div>
                    <p style={{ display: 'flex', alignItems: 'center' }}>
                      cvv{' '}
                      {showCvv ? (
                        <FaRegEyeSlash
                          style={{ marginLeft: '5px', cursor: 'pointer' }}
                          onClick={() => {
                            setShowCvv(!showCvv)
                          }}
                        />
                      ) : (
                        <FaRegEye
                          style={{ marginLeft: '5px', cursor: 'pointer' }}
                          onClick={() => {
                            setShowCvv(!showCvv)
                          }}
                        />
                      )}
                    </p>
                    <h2
                      onClick={() => {
                        setShowCvv(!showCvv)
                      }}
                    >
                      {showCvv ? <>{debitCard.cvv}</> : <>***</>}
                    </h2>
                  </div>
                </div>
                <div className='vlogo'>
                  {debitCard.currency === 'USD' ? (
                    <img src={VerveLogo} alt='' />
                  ) : (
                    <img src={VerveLogo3} alt='' />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='wrapper'>
            {cardStage === 'default' && (
              <div>
                <div className='card_details'>
                  <div className='long'>
                    <p
                      onClick={() => {
                        copyCardNumber('1234567891234567')
                      }}
                    >
                      {debitCard.pin}
                    </p>
                    {numberCopied ? (
                      <p style={{ fontSize: '12px', color: 'green' }}>Copied</p>
                    ) : (
                      <BiCopy
                        onClick={() => {
                          copyCardNumber(debitCard.pin)
                        }}
                      />
                    )}
                  </div>
                  <div className='det_wrap'>
                    <div className='short'>
                      <p>
                        {' '}
                        {debitCard.exp_month}/{debitCard.exp_year}
                      </p>
                    </div>
                    <div className='short'>
                      <p>{debitCard.cvv}</p>
                    </div>
                  </div>
                </div>
                <h4 className='bills'>Billing Address</h4>
                <div className='card_details'>
                  <div className='long'>
                    <p>{debitCard.billing_address_street}</p>
                  </div>
                  <div className='det_wrap'>
                    <div className='short'>
                      <p>
                        {debitCard.billing_address_city},{' '}
                        {debitCard.billing_address_country}
                      </p>
                    </div>
                    <div className='short'>
                      <p>{debitCard.billing_address_postal_code}</p>
                    </div>
                  </div>
                </div>
                <div className='buttons'>
                  <button className='fund' onClick={() => setCardStage('fund')}>
                    Fund Card
                  </button>
                  <button
                    className='transct'
                    onClick={() => setCardPage('transactions')}
                  >
                    Transactions
                  </button>
                  <button
                    className='delete'
                    onClick={() => setCardStage('liquidate')}
                  >
                    Liquidate Card
                  </button>
                  <button
                    className='fund'
                    onClick={() => setCardStage('freeze')}
                  >
                    {debitCard.status === 'active' ? 'Freeze' : 'Unfreeze'} card
                  </button>
                  <button
                    className='delete'
                    onClick={() => setCardStage('delete')}
                  >
                    Delete Card
                  </button>
                </div>
              </div>
            )}
            {cardStage === 'fund' && (
              <div className='funding'>
                <p className='fee'>
                  How much will you like to fund the card with in
                  <span> {debitCard.currency}</span>.{' '}
                  {debitCard.currency === 'USD' &&
                    'Funding fee of $1.6 applies (card holding limit : $100,000)'}
                  {debitCard.currency !== 'USD' &&
                    '(card holding limit : ₦1,000,000)'}
                </p>
                {debitCard.currency === 'USD' && (
                  <>
                    <p className='dollar'>
                      Amount to pay in naira (₦{dollarRate} / $1){' '}
                      <span>
                        ₦{(creditAmmount * dollarRate).toLocaleString()}
                      </span>
                    </p>
                  </>
                )}
                {confirmed && (
                  <p
                    className='dollar'
                    style={{
                      color: '#fe8b00',
                      wordSpacing: '3px',
                      lineHeight: '25px',
                      letterSpacing: '1px',
                    }}
                  >
                    {confirmedDetails.narration}
                  </p>
                )}
                <div className='form-group'>
                  <input
                    type='number'
                    name='number'
                    className='form-control'
                    placeholder='Amount'
                    max={debitCard.currency === 'USD' && 250}
                    onChange={(e) => {
                      setCreditAmmount(e.target.value)
                      if (debitCard.currency === 'USD') {
                        setShowAmount(e.target.value)
                        // setAmount(e.target.value * dollarRate * 100)
                      } else {
                        setShowAmount(e.target.value)
                        // setAmount(e.target.value * 100)
                      }
                    }}
                    disabled={confirmed}
                    required
                  />
                </div>

                <div className='fund-buttons'>
                  <button
                    className='delete'
                    onClick={() => setCardStage('default')}
                  >
                    Back
                  </button>
                  {debitCard.currency === 'USD' && (
                    <>
                      {(debitCard.currency === 'USD' &&
                        creditAmmount > 10000) ||
                      creditAmmount < 10 ||
                      creditAmmount * 1 + parseInt(debitCard.balance) >
                        100000 ? (
                        <p>
                          Amount is not allowed (amount must be above $10 and
                          below $10000)
                        </p>
                      ) : (
                        // <PaystackButton
                        //   className='paystack-button'
                        //   {...componentProps}
                        // />
                        <>
                          {confirmed ? (
                            <>
                              <button
                                onClick={() => {
                                  setConfirmed(false)
                                }}
                                className='paystack-button'
                              >
                                Edit
                              </button>
                              <button
                                onClick={() => {
                                  completePurchase()
                                }}
                                className='paystack-button'
                              >
                                Pay Now
                              </button>
                            </>
                          ) : (
                            <button
                              onClick={() => {
                                confirmTotalBills()
                              }}
                              className='paystack-button'
                            >
                              Confirm
                            </button>
                          )}
                        </>
                      )}
                    </>
                  )}
                  {debitCard.currency !== 'USD' && (
                    <>
                      {(debitCard.currency !== 'USD' &&
                        creditAmmount > 1000000) ||
                      creditAmmount * 1 + parseInt(debitCard.balance) >
                        1000000 ? (
                        <p>
                          Amount is not allowed (amount must be above ₦100 and
                          below ₦1,000,000)
                        </p>
                      ) : (
                        // <PaystackButton
                        //   className='paystack-button'
                        //   {...componentProps}
                        // />
                        <>
                          {confirmed ? (
                            <>
                              <button
                                onClick={() => {
                                  setConfirmed(false)
                                }}
                                className='paystack-button'
                              >
                                Edit
                              </button>
                              <button
                                onClick={() => {
                                  completePurchase()
                                }}
                                className='paystack-button'
                              >
                                Pay Now
                              </button>
                            </>
                          ) : (
                            <button
                              onClick={() => {
                                confirmTotalBills()
                              }}
                              className='paystack-button'
                            >
                              Confirm
                            </button>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            )}
            {cardStage === 'delete' && (
              <div className='deleting'>
                <p className='fee'>
                  Are you sure you want to liquidate this card?
                </p>
                {debitCard.currency === 'USD' && (
                  <p className='fee' style={{ color: '#0125bf' }}>
                    Liquidation Amount:- ₦
                    {(debitCard.balance * liquidation).toLocaleString()}
                  </p>
                )}
                <div className='delete-buttons'>
                  <button
                    className='fund'
                    onClick={() => setCardStage('default')}
                  >
                    Back
                  </button>
                  <button className='delete' onClick={() => DeleteCard()}>
                    Delete Card
                  </button>
                </div>
              </div>
            )}
            {cardStage === 'liquidate' && (
              <div className='deleting'>
                <p className='fee'>
                  Are you sure you want to liquidate this card?
                </p>
                {debitCard.currency === 'USD' && (
                  <p className='fee' style={{ color: '#0125bf' }}>
                    Liquidation Amount:- ₦
                    {(debitCard.balance * liquidation).toLocaleString()}
                  </p>
                )}
                <div className='delete-buttons'>
                  <button
                    className='fund'
                    onClick={() => setCardStage('default')}
                  >
                    Back
                  </button>
                  <button
                    className='fund'
                    onClick={() => LiquidateCard()}
                    style={{ background: '#009a5b' }}
                  >
                    Liquidate
                  </button>
                </div>
              </div>
            )}
            {cardStage === 'freeze' && (
              <>
                {debitCard.status === 'active' && (
                  <div className='deleting'>
                    <p className='fee'>
                      Are you sure you want to Freeze this card? Enter magic pin
                    </p>
                    <div className='enter_pin'>
                      <PinInput
                        length={4}
                        initialValue=''
                        secret
                        onChange={(value, index) => {
                          setmagicPin(value)
                        }}
                        type='numeric'
                        inputMode='numeric'
                        style={{ padding: '10px' }}
                        inputStyle={{
                          borderColor: '#e6e6e6',
                          borderWidth: '3px',
                          borderStyle: 'solid',
                          background: '#f1f1f1',
                          fontSize: ' 3rem',
                          borderRadius: '20px',
                          height: '70px',
                          width: '70px',
                          margin: '0 5px',
                        }}
                        inputFocusStyle={{ borderColor: 'blue' }}
                        onComplete={(value, index) => {}}
                        autoSelect={true}
                        regexCriteria={/^[0-9]*$/}
                      />
                    </div>
                    {freezeLoading ? (
                      <img src={Loader} alt='' />
                    ) : (
                      <div className='delete-buttons'>
                        <button
                          className='fund'
                          onClick={() => setCardStage('default')}
                        >
                          Back
                        </button>
                        <button
                          className='fund'
                          onClick={() => FreezeCard()}
                          style={{ background: '#009a5b' }}
                        >
                          Freeze card
                        </button>
                      </div>
                    )}
                  </div>
                )}
                {debitCard.status !== 'active' && (
                  <div className='deleting'>
                    <p className='fee'>
                      Are you sure you want to unFreeze this card? Enter magic
                      pin
                    </p>
                    <div className='enter_pin'>
                      <PinInput
                        length={4}
                        initialValue=''
                        secret
                        onChange={(value, index) => {
                          setmagicPin(value)
                        }}
                        type='numeric'
                        inputMode='numeric'
                        style={{ padding: '10px' }}
                        inputStyle={{
                          borderColor: '#e6e6e6',
                          borderWidth: '3px',
                          borderStyle: 'solid',
                          background: '#f1f1f1',
                          fontSize: ' 3rem',
                          borderRadius: '20px',
                          height: '70px',
                          width: '70px',
                          margin: '0 5px',
                        }}
                        inputFocusStyle={{ borderColor: 'blue' }}
                        onComplete={(value, index) => {}}
                        autoSelect={true}
                        regexCriteria={/^[0-9]*$/}
                      />
                    </div>
                    {freezeLoading ? (
                      <img src={Loader} alt='' />
                    ) : (
                      <div className='delete-buttons'>
                        <button
                          className='fund'
                          onClick={() => setCardStage('default')}
                        >
                          Back
                        </button>
                        <button
                          className='fund'
                          onClick={() => UnfreezeCard()}
                          style={{ background: '#009a5b' }}
                        >
                          Unfreeze card
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      )}
      {cardPage === 'transactions' && (
        <div className='card_transactions'>
          <div className='back-but'>
            <p onClick={() => setCardPage('main')}>
              <FaChevronLeft /> Back
            </p>
          </div>
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Type</th>
                <th>Naration</th>
                <th>Transaction Fee</th>
                <th>Amount</th>
                <th>Balance</th>
              </tr>
            </thead>
            <tbody>
              {cardTransactions
                .sort((a, b) => b.create_date.localeCompare(a.create_date))
                .map((item) => (
                  <tr key={item.tx_id}>
                    <td className='date'>
                      {new Date(item.create_date).toDateString()}
                    </td>
                    <td className={item.tx_type}>{item.tx_type}</td>
                    <td>{item.narration}</td>
                    <td>
                      {debitCard.currency === 'USD' ? '$' : '₦'}
                      {item.fee === null ? '0' : `${item.fee}`}
                    </td>
                    <td>
                      {debitCard.currency === 'USD' ? '$' : '₦'}
                      {item.amount}
                    </td>
                    <td>
                      {debitCard.currency === 'USD' ? '$' : '₦'}
                      {item.balance}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  )
}

export default CardEdit
