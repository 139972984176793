import React, { useEffect } from 'react'
import { useNavigate } from 'react-router'
import Navigation from '../../Component/Navigation/Navigation2'
import './Products.scss'
import { BsArrowRight, BsWhatsapp } from 'react-icons/bs'
import Image1 from '../Landinpage/images/product (1).png'
import Image2 from '../Landinpage/images/corporate.png'
import Image3 from '../Landinpage/images/product (3).png'
import Footer from '../../Component/Footer/Footer'
import { AiOutlineCheck } from 'react-icons/ai'
import AOS from 'aos'
import 'aos/dist/aos.css'

function Products() {
  const navigate = useNavigate()
  useEffect(() => {
    AOS.init()
  }, [])
  return (
    <div className='products'>
      <Navigation />
      <div className='hero'>
        <div
          className='hero-cover '
          data-aos='fade-up'
          data-aos-duration='1500'
        >
          <h1>Our Products</h1>
          <p>
            See amazing expense management and payment solutions to help keep
            your business spending on track!
          </p>
          <div className='buttons'>
            <button onClick={() => navigate('/register')}>
              Get Started For Free <BsArrowRight />
            </button>
            <button onClick={() => navigate('/pricing')}>
              See Pricing
              <BsArrowRight />
            </button>
          </div>
        </div>
      </div>
      <div className='coverxx'>
        <div className='whatsapp'>
          <a href='https://wa.me/2348138134873'>
            <BsWhatsapp />
          </a>
        </div>
        <p>Let's Chat</p>
      </div>
      <div className='flex-wrap'>
        <div className='flex-card'>
          <div className='left' data-aos='fade-right' data-aos-duration='1500'>
            <h1>Corporate Expense Cards</h1>
            <img src={Image1} alt='' />
          </div>
          <div className='right'>
            <div className='top' data-aos='fade-top' data-aos-duration='1500'>
              <h1>Corporate Expense Cards</h1>
              <p>
                Issue physical debit cards to your staff/teams to manage and
                control operational spend seamlessly.
              </p>
            </div>
            <div className='right_wrap'>
              <div
                className='card'
                data-aos='fade-down'
                data-aos-duration='1500'
              >
                <div className='image'>
                  <AiOutlineCheck />
                </div>
                <div>
                  <p className='cont'>
                    Get co-branded cards with your company brand.
                  </p>
                </div>
              </div>
              <div
                className='card'
                data-aos='fade-down'
                data-aos-duration='1500'
              >
                <div className='image'>
                  <AiOutlineCheck />
                </div>
                <div>
                  <p className='cont'>
                    Easily manage spending limits, preferred channels, and where
                    your cards can be used from your dashboard.
                  </p>
                </div>
              </div>
              <div
                className='card '
                data-aos='fade-down'
                data-aos-duration='1500'
              >
                <div className='image'>
                  <AiOutlineCheck />
                </div>
                <div>
                  <p className='cont'>
                    Separate your operational expenses and other project based
                    expenses from your main business accounts.
                  </p>
                </div>
              </div>
              <div
                className='card'
                data-aos='fade-down'
                data-aos-duration='1500'
              >
                <div className='image'>
                  <AiOutlineCheck />
                </div>
                <div>
                  <p className='cont'>
                    Get your cards delivered to you within 5 working days.
                  </p>
                </div>
              </div>
              <div
                className='card'
                data-aos='fade-down'
                data-aos-duration='1500'
              >
                <div className='image'>
                  <AiOutlineCheck />
                </div>
                <div>
                  <p className='cont'>
                    Its free to access corporate cards for your business
                  </p>
                </div>
              </div>
            </div>
            <button
              onClick={() => navigate('/register')}
              data-aos='fade-down'
              data-aos-duration='1500'
            >
              Get Now
            </button>
          </div>
        </div>
      </div>
      <div className='flex-wrap'>
        <div className='flex-card diff'>
          <div className='right'>
            <div className='top' data-aos='fade-top' data-aos-duration='1500'>
              <h1>Digital Wallet</h1>
              <p>
                Create wallets in seconds! If you are a registered business you
                can have your wallet in your business name, and as many wallets
                as required for your various teams.
              </p>
            </div>
            <div className='right_wrap'>
              <div
                className='card'
                data-aos='fade-down'
                data-aos-duration='1500'
              >
                <div className='image'>
                  <AiOutlineCheck />
                </div>
                <div>
                  <p className='cont'>Its free to create your wallet.</p>
                </div>
              </div>
              <div
                className='card'
                data-aos='fade-down'
                data-aos-duration='1500'
              >
                <div className='image'>
                  <AiOutlineCheck />
                </div>
                <div>
                  <p className='cont'>
                    Businesses can create wallet in their business name.
                  </p>
                </div>
              </div>
              <div
                className='card'
                data-aos='fade-down'
                data-aos-duration='1500'
              >
                <div className='image'>
                  <AiOutlineCheck />
                </div>
                <div>
                  <p className='cont'>
                    Manage multiple team spending needs via multiple wallets.
                  </p>
                </div>
              </div>
              <div
                className='card'
                data-aos='fade-down'
                data-aos-duration='1500'
              >
                <div className='image'>
                  <AiOutlineCheck />
                </div>
                <div>
                  <p className='cont'>
                    Create your wallets in less than one minute.
                  </p>
                </div>
              </div>
            </div>
            <button
              onClick={() => navigate('/register')}
              data-aos='fade-down'
              data-aos-duration='1500'
            >
              Get Wallet
            </button>
          </div>
          <div className='left' data-aos='fade-left' data-aos-duration='1500'>
            <h1>Digital Wallet</h1>
            <img src={Image3} alt='' />
          </div>
        </div>
      </div>
      <div className='flex-wrap'>
        <div className='flex-card'>
          <div className='left' data-aos='fade-right' data-aos-duration='1500'>
            <h1>International Business Payment</h1>
            <img src={Image2} alt='' />
          </div>
          <div className='right'>
            <div className='top' data-aos='fade-top' data-aos-duration='1500'>
              <h1>International Business Payment</h1>
              <p>
                With Spendwise International business payments you can pay any
                business invoice from our Spendwise naira wallet.
              </p>
            </div>
            <div className='right_wrap'>
              <div
                className='card'
                data-aos='fade-down'
                data-aos-duration='1500'
              >
                <div className='image'>
                  <AiOutlineCheck />
                </div>
                <div>
                  <p className='cont'>
                    Payout in USD, GBP, CAD, EURO, Chinese Yuan.
                  </p>
                </div>
              </div>
              <div
                className='card'
                data-aos='fade-down'
                data-aos-duration='1500'
              >
                <div className='image'>
                  <AiOutlineCheck />
                </div>
                <div>
                  <p className='cont'>Most Competitive FX rates.</p>
                </div>
              </div>
              <div
                className='card'
                data-aos='fade-down'
                data-aos-duration='1500'
              >
                <div className='image'>
                  <AiOutlineCheck />
                </div>
                <div>
                  <p className='cont'>
                    Money received in 24 -48 hrs anywhere in the world.
                  </p>
                </div>
              </div>
            </div>
            <button
              onClick={() => navigate('/login')}
              data-aos='fade-down'
              data-aos-duration='1500'
            >
              Initiate Transfer
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Products
