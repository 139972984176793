import React, { useState, useEffect } from 'react'
import API from '../../../../../../../Context/API'
import { FiSearch } from 'react-icons/fi'
import {
  FaChevronDown,
  FaChevronRight,
  FaFile,
  FaPaperPlane,
  FaTimes,
} from 'react-icons/fa'
import { AiOutlinePlus, AiFillCheckCircle } from 'react-icons/ai'
import { BiRefresh } from 'react-icons/bi'
import { AiOutlineFile } from 'react-icons/ai'
import { IoIosArrowDown } from 'react-icons/io'
import { useNavigate } from 'react-router'
import { IoMdArrowDropdown } from 'react-icons/io'
import Ngn from '../../../../../../../Image/ngn.svg'
import Usd from '../../../../../../../Image/united-states-flag-icon.svg'
import Gbp from '../../../../../../../Image/united-kingdom-flag-icon.svg'
import Eur from '../../../../../../../Image/europe-flag-icon.svg'
import Cny from '../../../../../../../Image/china-flag-icon.svg'
import Cad from '../../../../../../../Image/canada-flag-icon.svg'
import PinInput from 'react-pin-input'
import Loader from '../../../../../../../Image/loder.gif.gif'

function InitiateTrans({ notify }) {
  const navigate = useNavigate()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [sendRequest, setSendRequest] = useState(false)
  const [transferSuccessful, setTransferSuccessful] = useState(false)
  const [showButtons, setshowButtons] = useState('')
  const [loading, setLoading] = useState(true)
  const [beneData, setBeneData] = useState([])
  const [selectedUser, setSelectedUser] = useState({})
  const [showMore, setShowMore] = useState(false)
  const authToken = sessionStorage.getItem('userId')
  const [rate, setRate] = useState({})
  const [nairaChange, setNairaChange] = useState('')
  const [otherChange, setOtherChange] = useState('')
  const [complainPicture, setComplainPicture] = useState({
    string: 'Select an Invoice File',
    file: null,
  })
  const onSelectComplainImage = (e) => {
    const file = e.target.files[0]
    setComplainPicture({
      string: file.name,
      file,
    })
  }

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/cards/usd_exchange_rate/`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
      body: JSON.stringify({
        issuer: 'verto',
      }),
    }).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setRate(data.rate)
        })
      } else {
        res.json().then((data) => {})
      }
    })
  }, [])

  const [selectedCurrency, setSelectedCurrency] = useState({})
  useEffect(() => {
    API.fetchFXBeneficiaries()
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setLoading(false)
            setBeneData(data.data)
          })
        } else {
          setLoading(false)
          res.json().then((data) => {})
        }
      })
      .catch(() => {})
  }, [])
  const showMoreNow = (e) => {
    setSelectedUser(e)
    setShowMore(true)
  }
  const openModalNow = (e) => {
    setIsModalOpen(true)
    setSelectedUser(e)
    const currencyMapping = {
      EUR: {
        sign: '€',
        name: 'EUR',
        flag: Eur,
        rate: rate.EUR,
      },
      GBP: {
        sign: '£',
        name: 'GBP',
        flag: Gbp,
        rate: rate.GBP,
      },
      USD: {
        sign: '$',
        name: 'USD',
        flag: Usd,
        rate: rate.USD,
      },
      CAD: {
        sign: '$',
        name: 'CAD',
        flag: Cad,
        rate: rate.CAD,
      },
      CNY: {
        sign: '¥',
        name: 'CNY',
        flag: Cny,
        rate: rate.CNY,
      },
    }
    const selectedCurrencyData = currencyMapping[e.currency]
    if (selectedCurrencyData) {
      setSelectedCurrency(selectedCurrencyData)
    }
  }
  const handleNairaChange = (e) => {
    setNairaChange(e.target.value)
    const value = parseInt(e.target.value) / selectedCurrency.rate
    setOtherChange(value.toFixed(2))
  }
  const handleOtherChange = (e) => {
    setOtherChange(e.target.value)
    const value = parseInt(e.target.value) * selectedCurrency.rate
    setNairaChange(value.toFixed(2))
  }

  ////
  const [showTotal, setShowTotal] = useState(false)
  const [totalBill, setTotalBill] = useState('')
  const [pin, setPin] = useState('')
  const [requestLoading, setRequestLoading] = useState(false)

  const showTotalAmount = () => {
    setRequestLoading(true)
    fetch(`${process.env.REACT_APP_API_URL}/cards/total_bill/`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
      body: JSON.stringify({
        amount: otherChange,
        event: selectedUser.tariff_group,
        currency: selectedUser.currency,
        issuer: 'vertofx',
      }),
    }).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setTotalBill(data)
          setShowTotal(true)
          setRequestLoading(false)
        })
      } else {
        res.json().then((data) => {})
      }
    })
  }
  const handleTransfer = () => {
    setRequestLoading(true)

    const form_data = new FormData()
    form_data.append('image', complainPicture.file)
    form_data.append('tag', 'fx_transfer')
    if (complainPicture.string === 'Select an Invoice File') {
      fetch(
        `https://kxu81bfj78.execute-api.eu-west-1.amazonaws.com/prod/fx/fx_transfer`,
        {
          method: 'post',
          headers: {
            'content-Type': 'application/json',
            Authorization: `Token ${authToken}`,
          },
          body: JSON.stringify({
            beneficiary_id: selectedUser.beneficiary_id,
            amount: parseInt(otherChange),
            pin: pin,
          }),
        }
      ).then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setTransferSuccessful(true)
            setSendRequest(false)
            setRequestLoading(false)
          })
        } else {
          res.json().then((data) => {
            notify('error', data.message)
            setRequestLoading(false)
            if (data.message == 'Wrong PIN!') {
            } else {
              setSendRequest(false)
              setIsModalOpen(true)
              setShowTotal(false)
            }
          })
        }
      })
    } else {
      fetch(`${process.env.REACT_APP_API_URL}/accounts/upload_image/`, {
        method: 'post',
        headers: {
          Authorization: `Token ${authToken}`,
        },
        body: form_data,
      }).then((res) => {
        if (res.status === 200) {
          res.json().then((file) => {
            fetch(
              `https://kxu81bfj78.execute-api.eu-west-1.amazonaws.com/prod/fx/fx_transfer`,
              {
                method: 'post',
                headers: {
                  'content-Type': 'application/json',
                  Authorization: `Token ${authToken}`,
                },
                body: JSON.stringify({
                  beneficiary_id: selectedUser.beneficiary_id,
                  amount: parseInt(otherChange),
                  pin: pin,
                  image: file.data.image_ref,
                }),
              }
            ).then((res) => {
              if (res.status === 200) {
                res.json().then((data) => {
                  setTransferSuccessful(true)
                  setSendRequest(false)
                  setRequestLoading(false)
                })
              } else {
                res.json().then((data) => {
                  notify('error', data.message)
                  setRequestLoading(false)
                  if (data.message == 'Wrong PIN!') {
                  } else {
                    setSendRequest(false)
                    setIsModalOpen(true)
                    setShowTotal(false)
                  }
                })
              }
            })
          })
        } else {
          res.json().then((data) => {
            notify('error', data.message)
            setRequestLoading(false)
          })
        }
      })
    }
  }

  return (
    <div className='initiate-transfer'>
      {isModalOpen && (
        <div className='open-modal'>
          <div className='modal-content'>
            <div className='close'>
              <FaTimes
                onClick={() => {
                  setIsModalOpen(false)
                  setShowTotal(false)
                  setNairaChange('')
                  setOtherChange('')
                  setComplainPicture({
                    string: 'Select an Invoice File',
                    file: null,
                  })
                }}
              />
            </div>
            <div className='modal-header'>
              <h3>Initiate Transfer Request</h3>
              <p>
                Exchange currency at competitive rates and send money across
                borders with ease.
              </p>
              <div className='rate'>
                <p className='exchange-rate'>
                  1 {selectedCurrency.name} = NGN {selectedCurrency.rate}
                </p>
              </div>
            </div>
            {showTotal ? (
              <>
                <div className='total'>
                  <div className='flex'>
                    <p>
                      Total Bill:{' '}
                      <b>NGN {totalBill.total_bill_naira.toLocaleString()}</b>
                    </p>
                    <p>Bill Breakdown :</p>
                    <p>{totalBill.narration}</p>
                  </div>
                </div>

                <button
                  className='modal-btn'
                  onClick={() => {
                    setShowTotal(false)
                  }}
                >
                  Edit
                </button>
                <button
                  className='modal-btn'
                  onClick={() => {
                    setSendRequest(true)
                    setIsModalOpen(false)
                  }}
                >
                  Send Request
                </button>
              </>
            ) : (
              <>
                <div className='transaction-box'>
                  <div className='card'>
                    <p>Amount in Naira</p>
                    <div className='transaction-box-input'>
                      <h2>N</h2>
                      <input
                        type='number'
                        onChange={(e) => handleNairaChange(e)}
                        value={nairaChange}
                      />
                      <img src={Ngn} />
                      <p>NGN</p>
                    </div>
                  </div>
                  <div className='card'>
                    <p>They Receive</p>
                    <div className='transaction-box-input'>
                      <h2>{selectedCurrency.sign}</h2>
                      <input
                        type='number'
                        onChange={(e) => handleOtherChange(e)}
                        value={otherChange}
                      />
                      <div className='curr'>
                        <div className='dets'>
                          <img src={selectedCurrency.flag} />
                          <p>{selectedCurrency.name}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='inovice'>
                  <p>Attach Invoice</p>
                  <div className='inputd'>
                    <input
                      type='file'
                      name=''
                      id='invoice'
                      onChange={onSelectComplainImage}
                    />
                    <label htmlFor='invoice'>
                      <div className='fow'>
                        <h2>{complainPicture.string}</h2>
                        <FaFile />
                      </div>
                    </label>
                  </div>
                </div>
                {requestLoading ? (
                  <div className='loaderx'>
                    <img src={Loader} alt='' />
                  </div>
                ) : (
                  <>
                    <button
                      className='modal-btn'
                      onClick={() => {
                        setIsModalOpen(false)
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className='modal-btn'
                      onClick={() => {
                        showTotalAmount()
                      }}
                    >
                      Proceed
                    </button>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      )}
      {sendRequest && (
        <div className='open-modal'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h3>Transfer Verification</h3>
              <p>Please input your Magic PIN to initiate this transaction</p>
            </div>
            <div className='transfer-verification-input'>
              <PinInput
                length={4}
                initialValue=''
                secret
                type='numeric'
                inputMode='numeric'
                style={{ padding: '10px' }}
                inputStyle={{
                  borderColor: '#e6e6e6',
                  borderWidth: '3px',
                  borderStyle: 'solid',
                  background: '#f1f1f1',
                  fontSize: ' 3rem',
                  borderRadius: '20px',
                  height: '70px',
                  width: '70px',
                  margin: '0 5px',
                }}
                inputFocusStyle={{ borderColor: 'blue' }}
                onComplete={(value, index) => {
                  setPin(value)
                }}
                autoSelect={true}
                regexCriteria={/^[0-9]*$/}
              />
            </div>
            {requestLoading ? (
              <div className='loaderx'>
                <img src={Loader} alt='' />
              </div>
            ) : (
              <>
                <button
                  className='modal-btn'
                  onClick={() => {
                    setIsModalOpen(true)
                    setSendRequest(false)
                  }}
                >
                  Back
                </button>
                <button
                  className='modal-btn'
                  onClick={() => {
                    handleTransfer()
                  }}
                >
                  Submit
                </button>
              </>
            )}
          </div>
        </div>
      )}
      {transferSuccessful && (
        <div className='open-modal'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h3>Transfer Request Successful</h3>
              <AiFillCheckCircle />
            </div>
            <div className='transfer-succesful-btns'>
              <button
                className='view-receipt'
                onClick={() => window.location.reload()}
              >
                Done
              </button>
              {/* <button className='transfer-status'>View Transfer Status</button> */}
            </div>
          </div>
        </div>
      )}
      {showMore && (
        <div className='open-modal'>
          <div className='modal-content'>
            <div className='headee'>
              <h1>Beneficiary Details</h1>
              <FaTimes onClick={() => setShowMore(false)} />
            </div>
            <div className='rows'>
              <div className='cells'>
                <h2>verification status</h2>
              </div>
              <div className='cellx'>
                <p>{selectedUser.verification_status}</p>
              </div>
            </div>
            <div className='rows'>
              <div className='cells'>
                <h2>Company name</h2>
              </div>
              <div className='cellx'>
                <p>{selectedUser.company_name}</p>
              </div>
            </div>
            <div className='rows'>
              <div className='cells'>
                <h2>Currency</h2>
              </div>
              <div className='cellx'>
                <p>{selectedUser.currency}</p>
              </div>
            </div>
            <div className='rows'>
              <div className='cells'>
                <h2>Bank name</h2>
              </div>
              <div className='cellx'>
                <p>{selectedUser.bank_name}</p>
              </div>
            </div>
            <div className='rows'>
              <div className='cells'>
                <h2>{selectedUser.ac_no ? 'Account Number' : 'IBAN'} </h2>
              </div>
              <div className='cellx'>
                <p>
                  {selectedUser.ac_no ? selectedUser.ac_no : selectedUser.iban}
                </p>
              </div>
            </div>
            <div className='rows'>
              <div className='cells'>
                <h2>
                  {selectedUser.sort_code !== null
                    ? 'Sort Code'
                    : selectedUser.routing_code !== null
                    ? 'Routing Code'
                    : selectedUser.cnaps !== null
                    ? 'CNAPS'
                    : selectedUser.routing_no_aba !== null
                    ? 'Routing no / Aba code'
                    : selectedUser.swift !== null
                    ? 'Swift'
                    : ''}
                </h2>
              </div>
              <div className='cellx'>
                <p>
                  {selectedUser.sort_code !== null
                    ? selectedUser.sort_code
                    : selectedUser.routing_code !== null
                    ? selectedUser.routing_code
                    : selectedUser.cnaps !== null
                    ? selectedUser.cnaps
                    : selectedUser.routing_no_aba !== null
                    ? selectedUser.routing_no_aba
                    : selectedUser.swift !== null
                    ? selectedUser.swift
                    : ''}
                </p>
              </div>
            </div>
            <div className='rows'>
              <div className='cells'>
                <h2>Email</h2>
              </div>
              <div className='cellx'>
                <p>{selectedUser.recipient_email}</p>
              </div>
            </div>
            <div className='rows'>
              <div className='cells'>
                <h2>Address</h2>
              </div>
              <div className='cellx'>
                <p>{selectedUser.beneficiary_address}</p>
              </div>
            </div>
            <div className='rows'>
              <div className='cells'>
                <h2>City</h2>
              </div>
              <div className='cellx'>
                <p>{selectedUser.beneficiary_city}</p>
              </div>
            </div>
            <div className='rows'>
              <div className='cells'>
                <h2>Country</h2>
              </div>
              <div className='cellx'>
                <p>{selectedUser.country}</p>
              </div>
            </div>
            {selectedUser.intermediary && (
              <>
                <div className='rows'>
                  <div className='cells'>
                    <h2>Intermediary bank name</h2>
                  </div>
                  <div className='cellx'>
                    <p>{selectedUser.intermediary_bank_name}</p>
                  </div>
                </div>
                <div className='rows'>
                  <div className='cells'>
                    <h2>Intermediary bank address</h2>
                  </div>
                  <div className='cellx'>
                    <p>{selectedUser.intermediary_bank_address}</p>
                  </div>
                </div>
                <div className='rows'>
                  <div className='cells'>
                    <h2>Intermediary ac country</h2>
                  </div>
                  <div className='cellx'>
                    <p>{selectedUser.intermediary_ac_country}</p>
                  </div>
                </div>
                <div className='rows'>
                  <div className='cells'>
                    <h2>Intermediary label tag</h2>
                  </div>
                  <div className='cellx'>
                    <p>{selectedUser.intermediary_label_tag}</p>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}
      <div className='label'>
        <p onClick={() => navigate('/e-wallet/transfers/select')}>Wallet</p>
        <FaChevronRight />
        <p
          onClick={() =>
            navigate('/e-wallet/transfers/foreign-transfer/select')
          }
        >
          Foreign Account
        </p>
        <FaChevronRight />
        <h3>Initiate Transfer</h3>
      </div>
      <div className='select-beneficiary-header'>
        <h2>Select Beneficiary</h2>
        <div className='sbh-buttons'>
          {/* <div className='sbh-field'>
            <FiSearch />
            <input placeholder='Select beneficiary...' />
          </div> */}
          <button
            onClick={() =>
              navigate('/e-wallet/transfers/foreign-transfer/add-beneficiary')
            }
          >
            <AiOutlinePlus />
            <p> Add Beneficiary</p>
          </button>
          <button
            onClick={() =>
              navigate('/e-wallet/transfers/foreign-transfer/transfer-status')
            }
          >
            <FaPaperPlane />
            <p>Transfer Status</p>
          </button>
          {/* <button>
            <AiOutlineFile />
            <p>Export</p>
          </button> */}
        </div>
      </div>
      {loading ? (
        <div className='loader'>
          <img src={Loader} alt='' />
        </div>
      ) : (
        <>
          {beneData.length > 0 ? (
            <div className='select-beneficiary-body'>
              <div className='table'>
                <div className='thead'>
                  <div className='row'>
                    <div className='th'>
                      <p>Company name </p>
                    </div>
                    <div className='th'>
                      <p>Bank name</p>
                    </div>
                    <div className='th'>
                      <p>Currency</p>
                    </div>
                    <div className='th'>
                      <p>Acc / IBAN</p>
                    </div>

                    <div className='th'>
                      <p>Country</p>
                    </div>
                    <div className='th'>
                      <p>Verification status</p>
                    </div>
                    <div className='th'>
                      <p>Actions</p>
                    </div>
                  </div>
                </div>
                <div className='tbody'>
                  {beneData.map((item) => (
                    <div className='card' key={item.beneficiary_id}>
                      <div className='row'>
                        <div className='td'>
                          <p>{item.company_name}</p>
                        </div>
                        <div className='td'>
                          <p>{item.bank_name}</p>
                        </div>
                        <div className='td'>
                          <p>{item.currency}</p>
                        </div>
                        <div className='td'>
                          <p>{item.ac_no ? item.ac_no : item.iban}</p>
                        </div>
                        <div className='td'>
                          <p>{item.country}</p>
                        </div>
                        <div className='td'>
                          <button className={item.verification_status}>
                            {item.verification_status}
                          </button>
                        </div>
                        <div className='td'>
                          <p>
                            <IoIosArrowDown
                              onClick={() => {
                                if (showButtons === item.beneficiary_id) {
                                  setshowButtons('')
                                } else {
                                  setshowButtons(item.beneficiary_id)
                                }
                              }}
                            />
                          </p>
                        </div>
                      </div>
                      {showButtons === item.beneficiary_id && (
                        <div className='buttons'>
                          <button onClick={() => showMoreNow(item)}>
                            Show More
                          </button>
                          {/* <button>Edit Beneficiary</button> */}
                          {(item.verification_status === 'verified' ||
                            item.verification_status === 'approved') && (
                            <button onClick={() => openModalNow(item)}>
                              Initiate Transfer
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <div className='mobile_transactions'>
                <div className='mobile_table'>
                  {beneData.map((item) => (
                    <div className='cardv' key={item.beneficiary_id}>
                      <div className='rows'>
                        <div className='cells'>
                          <h2>Company name</h2>
                        </div>
                        <div className='cellx'>
                          <p>{item.company_name}</p>
                        </div>
                      </div>
                      <div className='rows'>
                        <div className='cells'>
                          <h2>Currency</h2>
                        </div>
                        <div className='cellx'>
                          <p>{item.currency}</p>
                        </div>
                      </div>
                      <div className='rows'>
                        <div className='cells'>
                          <h2>Bank name</h2>
                        </div>
                        <div className='cellx'>
                          <p>{item.bank_name}</p>
                        </div>
                      </div>
                      <div className='rows'>
                        <div className='cells'>
                          <h2>{item.ac_no ? 'Account Number' : 'IBAN'} </h2>
                        </div>
                        <div className='cellx'>
                          <p>{item.ac_no ? item.ac_no : item.iban}</p>
                        </div>
                      </div>
                      <div className='rows'>
                        <div className='cells'>
                          <h2>
                            {item.sort_code !== null
                              ? 'Sort Code'
                              : item.routing_code !== null
                              ? 'Routing Code'
                              : item.cnaps !== null
                              ? 'CNAPS'
                              : item.routing_no_aba !== null
                              ? 'Routing no / Aba code'
                              : item.swift !== null
                              ? 'Swift'
                              : ''}
                          </h2>
                        </div>
                        <div className='cellx'>
                          <p>
                            {item.sort_code !== null
                              ? item.sort_code
                              : item.routing_code !== null
                              ? item.routing_code
                              : item.cnaps !== null
                              ? item.cnaps
                              : item.routing_no_aba !== null
                              ? item.routing_no_aba
                              : item.swift !== null
                              ? item.swift
                              : ''}
                          </p>
                        </div>
                      </div>
                      <div className='rows'>
                        <div className='cells'>
                          <h2>Country</h2>
                        </div>
                        <div className='cellx'>
                          <p>{item.country}</p>
                        </div>
                      </div>
                      <div className='rows'>
                        <div className='cells'>
                          <h2>verification status</h2>
                        </div>
                        <div className='cellx'>
                          <p>{item.verification_status}</p>
                        </div>
                      </div>
                      <div className='buttons'>
                        <button onClick={() => showMoreNow(item)}>
                          Show More
                        </button>
                        {/* <button>Edit Beneficiary</button> */}
                        {(item.verification_status === 'verified' ||
                          item.verification_status === 'approved') && (
                          <button onClick={() => openModalNow(item)}>
                            Initiate Transfer
                          </button>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            <div>No Beneficiary added</div>
          )}
        </>
      )}
    </div>
  )
}

export default InitiateTrans
