import React, { useState, useRef, useEffect } from 'react'
import AddCard from '../../../../../Image/E-wallet.png'
import walletCard from '../../../../../Image/cardG.png'
import PinInput from 'react-pin-input'
import Loader from '../../../../../Image/loder.gif.gif'
import { BiLockAlt } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa'
import { useForm } from 'react-hook-form'
import API from '../../../../../Context/API'

function SDashboard({ walletList, notify }) {
  const authToken = sessionStorage.getItem('userId')
  const navigate = useNavigate()
  const [formLoading, setFormLoading] = useState(false)
  const [bringPin, setBringPin] = useState(false)
  const [selectedWallet, setSelectedWallet] = useState([])
  const [pin, setPin] = useState('')
  const [dashboardLevel, setDashboardLevel] = useState('dashboard')
  const [showPassword, setShownPassword] = useState(false)
  const [showPassword2, setShownPassword2] = useState(false)
  const [showPassword3, setShownPassword3] = useState(false)
  const [bulkLoading, setBulkLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm()
  const password = useRef({})
  password.current = watch('new_pin', '')
  const findWallet = (id) => {
    const foundWallet = walletList.find((item) => item.account_id === id)
    setSelectedWallet(foundWallet)
  }
  const walletLogin = () => {
    fetch(
      `https://p499kkwmp9.execute-api.eu-west-1.amazonaws.com/prod/wallet/wallet`,
      {
        method: 'post',
        headers: {
          'content-Type': 'application/json',
          Authorization: `Token ${authToken}`,
        },
        body: JSON.stringify({
          wallet_id: selectedWallet.account_id,
          pin: pin,
        }),
      }
    ).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          notify('success', data.message)
          navigate(`/sub-wallet/wallet/${selectedWallet.account_id}`)
          sessionStorage.setItem(
            'ddf8837e6d4defcc85a76e2cd2c156ef64a128e5',
            btoa(pin)
          )
          sessionStorage.setItem('wallet-account', selectedWallet.account_id)
        })
      } else {
        res.json().then((data) => {
          notify('error', data.message)
          if (data.message.includes('Please change your default pin.')) {
            setDashboardLevel('change-pin')
          }
        })
      }
    })
  }
  const onPasswordChange = (formData) => {
    setBulkLoading(true)
    fetch(
      'https://p499kkwmp9.execute-api.eu-west-1.amazonaws.com/prod/wallet/pin_change',
      {
        method: 'post',
        body: JSON.stringify({
          old_pin: formData.old_pin,
          new_pin: formData.new_pin,
          new_pin2: formData.new_pin2,
          wallet_id: selectedWallet.account_id,
        }),
        headers: {
          'content-Type': 'application/json',
          Authorization: `Token ${authToken}`,
        },
      }
    )
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            notify('success', data.message)
            setBulkLoading(false)
            setTimeout(() => {
              window.location.reload()
            }, 4000)
          })
        } else {
          res.json().then((data) => {
            notify('error', data.message)
            setBulkLoading(false)
          })
        }
      })
      .catch((err) => {
        notify('error', 'Something went wrong please try again')
        setBulkLoading(false)
      })
  }
  const createWallet = () => {
    setFormLoading(true)
    API.CreateSudoAccount().then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setFormLoading(false)
          notify(
            'success',
            'wallet creation in progress, await confirmation email. Do not retry!!!'
          )
          setTimeout(() => {
            window.location.reload()
          }, 3000)
        })
      } else {
        res.json().then((data) => {
          notify('warn', data.message)
        })
      }
    })
  }

  return (
    <div className='sub-dashboard'>
      {dashboardLevel === 'dashboard' && (
        <div className='main-dash'>
          {showModal && (
            <div className='create-over'>
              <div className='wrappo'>
                <h1>Are you sure you want to create a new wallet</h1>
                <div className='buttons'>
                  <button onClick={() => setShowModal(false)}>NO</button>
                  <button onClick={() => createWallet()}>YES</button>
                </div>
              </div>
            </div>
          )}
          <div className='headf'>
            <h1>Sub-wallet</h1>
            <p>Manage different business e-wallets with ease.</p>
          </div>
          <div className='swarapper'>
            <div className='card' onClick={() => setShowModal(true)}>
              <img src={AddCard} alt='' />
              <p>Create sub-wallet</p>
            </div>
            {walletList
              .sort((a, b) => a.wallet_type.localeCompare(b.wallet_type))
              .map((data) => (
                <div
                  key={data.account_no1}
                  className='card'
                  onClick={() => {
                    setBringPin(true)
                    setDashboardLevel('enter-pin')
                    findWallet(data.account_id)
                  }}
                >
                  <img src={walletCard} alt='' />
                  <p>{data.wallet_type}</p>
                  <p>{data.account_no1}</p>
                </div>
              ))}
          </div>
        </div>
      )}
      {dashboardLevel === 'enter-pin' && (
        <div className='enter-pin'>
          <div className=''>
            <div className='pin-head'>
              <div className='ped'>
                <div className='loghd'>
                  <BiLockAlt />
                </div>
              </div>
              <h2>Transaction PIN</h2>
              <p>Enter PIN to access Sub-wallet</p>
            </div>
            <div className='transfer_pin'>
              <div className='pin_wrapper'>
                <PinInput
                  length={4}
                  initialValue=''
                  secret
                  onChange={(value, index) => {
                    setPin(value)
                  }}
                  type='numeric'
                  inputMode='numeric'
                  style={{ padding: '10px' }}
                  inputStyle={{
                    borderColor: '#e6e6e6',
                    borderWidth: '3px',
                    borderStyle: 'solid',
                    background: '#f1f1f1',
                    fontSize: ' 3rem',
                    borderRadius: '20px',
                    height: '70px',
                    width: '70px',
                    margin: '0 5px',
                  }}
                  inputFocusStyle={{ borderColor: 'blue' }}
                  onComplete={(value, index) => {}}
                  autoSelect={true}
                  regexCriteria={/^[0-9]*$/}
                />
                {formLoading ? (
                  <div className='loader'>
                    <img src={Loader} alt='' />
                  </div>
                ) : (
                  <div className='t_button'>
                    <button
                      onClick={() => {
                        setDashboardLevel('dashboard')
                      }}
                    >
                      Back
                    </button>
                    <button
                      onClick={() => {
                        walletLogin()
                      }}
                    >
                      Enter
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {dashboardLevel === 'change-pin' && (
        <div className='change-pin'>
          <div className='wall-pin'>
            <div className='toprr'>
              <h3>Change Wallet Pin</h3>
              <p>
                Current pin is <b>1234</b>
              </p>
            </div>
            <form onSubmit={handleSubmit(onPasswordChange)}>
              <div className='card'>
                <p>Current Pin</p>
                <div className='input'>
                  <input
                    type={showPassword ? 'number' : 'password'}
                    name='old_pin'
                    id=''
                    {...register('old_pin', {
                      required: 'Provide a current pin',
                      minLength: {
                        value: 4,
                        message: 'Current pin must have at least 4 characters',
                      },
                      maxLength: {
                        value: 4,
                        message: 'Current pin must have at least 4 characters',
                      },
                      pattern: {
                        value: /^(?=.*?[0-9]).{4,}$/,
                        message: 'Pin must be numbers',
                      },
                    })}
                  />
                  {showPassword ? (
                    <FaRegEyeSlash
                      onClick={() => {
                        setShownPassword(!showPassword)
                      }}
                    />
                  ) : (
                    <FaRegEye
                      onClick={() => {
                        setShownPassword(!showPassword)
                      }}
                    />
                  )}
                </div>
                {errors.old_pin && (
                  <h6
                    className='vError'
                    style={{ fontSize: '10px', color: 'red' }}
                  >
                    {errors.old_pin.message}
                  </h6>
                )}
              </div>
              <div className='card'>
                <p>New Pin</p>
                <div className='input'>
                  <input
                    type={showPassword2 ? 'number' : 'password'}
                    name='new_pin'
                    {...register('new_pin', {
                      required: 'Provide a new pin',
                      minLength: {
                        value: 4,
                        message: 'Current pin must have at least 4 characters',
                      },
                      maxLength: {
                        value: 4,
                        message: 'Current pin must have at least 4 characters',
                      },
                      pattern: {
                        value: /^(?=.*?[0-9]).{4,}$/,
                        message: 'Pin must be numbers',
                      },
                    })}
                  />
                  {showPassword2 ? (
                    <FaRegEyeSlash
                      onClick={() => {
                        setShownPassword2(!showPassword2)
                      }}
                    />
                  ) : (
                    <FaRegEye
                      onClick={() => {
                        setShownPassword2(!showPassword2)
                      }}
                    />
                  )}
                </div>
                {errors.new_pin && (
                  <h6
                    className='vError'
                    style={{ fontSize: '10px', color: 'red' }}
                  >
                    {errors.new_pin.message}
                  </h6>
                )}
              </div>
              <div className='card'>
                <p>Confirm New Pin</p>
                <div className='input'>
                  <input
                    type={showPassword3 ? 'number' : 'password'}
                    name='new_pin2'
                    {...register('new_pin2', {
                      required: 'You must re-enter your pin',
                      validate: (value) =>
                        value === password.current || 'The pin do not match',
                    })}
                  />
                  {showPassword3 ? (
                    <FaRegEyeSlash
                      onClick={() => {
                        setShownPassword3(!showPassword3)
                      }}
                    />
                  ) : (
                    <FaRegEye
                      onClick={() => {
                        setShownPassword3(!showPassword3)
                      }}
                    />
                  )}
                </div>
                {errors.new_pin2 && (
                  <h6
                    className='vError'
                    style={{ fontSize: '10px', color: 'red' }}
                  >
                    {errors.new_pin2.message}
                  </h6>
                )}
              </div>
              {bulkLoading ? (
                <img src={Loader} />
              ) : (
                <div className='button'>
                  <button>Change Pin</button>
                </div>
              )}
            </form>
          </div>
        </div>
      )}
    </div>
  )
}

export default SDashboard
