import React, { useState } from 'react'
import VerveLogo from '../../../../../Image/vereve.png'
import VerveLogo3 from '../../../../../Image/masterlogo.png'
import Logo from '../../../../../Image/cardlogo.png'
import Logo2 from '../../../../../Image/cardlogo2.png'
import { FcCancel } from 'react-icons/fc'

function CardBox({ item, navigate, locat }) {
  return (
    <div
      className='card-box dit'
      key={item.id}
      onClick={() => navigate(`/debit-card/${locat}/${item._id}`)}
    >
      <div className='card-box_top'>
        <h2>
          {item.alias}{' '}
          <span>
            ({item.type_of} {item.currency} card)
          </span>
        </h2>
      </div>
      <div className='credit-card-body' id={item.currency}>
        {item.re_issue_status === 'failed' && (
          <div className='cancel'>
            <FcCancel />
          </div>
        )}
        <div className='credit-card-body-top'>
          {item.currency === 'USD' ? (
            <img src={Logo2} alt='' />
          ) : (
            <img src={Logo} alt='' />
          )}
          <p>
            {item.re_issue_status === 'failed' ? (
              <p>-</p>
            ) : (
              <>
                {item.currency === 'USD' ? (
                  <p></p>
                ) : (
                  <p> ₦{item.balance.toLocaleString()}</p>
                )}
              </>
            )}
          </p>
        </div>
        <div className='card-number'>
          <p>{item.maskedPan.slice(0, 4)}</p>
          <p>{item.maskedPan.slice(4, 8)}</p>
          <p>{item.maskedPan.slice(8, 12)}</p>
          <p>{item.maskedPan.slice(12, 16)}</p>
          <p>{item.maskedPan.slice(16, 20)}</p>
        </div>
        <div className='credit-card-body-bottom'>
          <div>
            <p>Card Holder</p>
            <h2>{item.name}</h2>
          </div>
          <div>
            <p>Exp</p>
            <h2>
              {item.expiryMonth}/{item.expiryYear}
            </h2>
          </div>
          <div className='vlogo'>
            <img src={VerveLogo3} alt='' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CardBox
