import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { BsFillImageFill, BsWhatsapp } from 'react-icons/bs'
import Loader from '../../../../Image/loder.gif.gif'
import './Contact.scss'

function Contact({ notify }) {
  const authToken = sessionStorage.getItem('userId')
  const [Loading, setLoading] = useState(false)
  const { handleSubmit: handleSubmit5 } = useForm()
  const [complainPicture, setComplainPicture] = useState({
    string: 'Upload a Screenshot',
    file: null,
  })
  useEffect(() => {
    document.title = 'Spendwise | Contact Us'
  }, [])
  const [complainSubject, setComplainSubject] = useState('')
  const [complainMessage, setComplainMessage] = useState('')
  const onSelectComplainImage = (e) => {
    const file = e.target.files[0]
    setComplainPicture({
      string: file.name,
      file,
    })
  }
  const onSubmitContact = () => {
    setLoading(true)
    const form_data = new FormData()
    if (complainPicture.string === '') {
      form_data.append('image', complainPicture.file)
    }
    form_data.append('subject', complainSubject)
    form_data.append('message', complainMessage)
    fetch(`${process.env.REACT_APP_API_URL}/accounts/contact_us/`, {
      method: 'post',
      headers: {
        Authorization: `Token ${authToken}`,
      },
      body: form_data,
      redirect: 'follow',
    })
      .then((res) => {
        if (res.status === 200) {
          setLoading(false)
          res.json().then((data) => {
            notify('success', data.message)
            setTimeout(() => {
              window.location.reload()
            }, 5000)
          })
        } else {
          res.json().then((data) => {
            notify('error', 'check that every field was filled')
            setLoading(false)
          })
        }
      })
      .catch((err) => {})
  }
  return (
    <div className='contact_body'>
      <main>
        <div className='contact'>
          <div className='contact_top'>
            <h2>Contact Us</h2>
            <div className='coverxx dif'>
              <div className='whatsapp'>
                <a href='https://wa.me/2348138134873'>
                  <BsWhatsapp />
                </a>
              </div>
              <p>Let's Chat</p>
            </div>
          </div>
          <form className='form' onSubmit={handleSubmit5(onSubmitContact)}>
            <div className='card'>
              <p>Subject</p>
              <input
                type='text'
                name=''
                id=''
                onChange={(e) => setComplainSubject(e.target.value)}
              />
            </div>
            <div className='card'>
              <p>Message</p>
              <textarea
                name=''
                id=''
                cols='30'
                rows='10'
                onChange={(e) => setComplainMessage(e.target.value)}
              ></textarea>
            </div>
            <div className='imagee'>
              <input
                type='file'
                name=''
                id='image_complain'
                accept='image/*'
                onChange={onSelectComplainImage}
              />
              <div className='imagee_label'>
                <label htmlFor='image_complain'>
                  <p>
                    {complainPicture.string}
                    <BsFillImageFill />
                  </p>
                </label>
              </div>
            </div>
            <div className='button'>
              {Loading ? (
                <img src={Loader} />
              ) : (
                <button
                // onClick={() => onSubmitContact()}
                >
                  Submit
                </button>
              )}
            </div>
          </form>
        </div>
      </main>
    </div>
  )
}

export default Contact
