import React, { useState } from 'react'
import Card from 'react-credit-cards'
import 'react-credit-cards/lib/styles.scss'
import './Card.scss'

function NewCardComponent() {
  const [number, setNumber] = useState('')
  const [focus, setFocus] = useState('')
  const [cvc, setCvc] = useState('')
  const [name, setName] = useState('')
  const [expiry, setExpiry] = useState('')

  return (
    <div className='new_card-component'>
      <Card
        cvc={cvc}
        expiry={expiry}
        name={name}
        number={number}
        focused={focus}
      />
      <form>
        <div className='form-group'>
          <input
            type='tel'
            name='number'
            className='form-control'
            placeholder='Card Number'
            onChange={(e) => setNumber(e.target.value)}
            onFocus={() => setFocus('number')}
            maxLength={16}
            required
          />
        </div>
        <div className='form-group'>
          <input
            type='text'
            name='name'
            className='form-control'
            placeholder='Name'
            required
            onChange={(e) => setName(e.target.value)}
            onFocus={() => setFocus('name')}
          />
        </div>
        <div className='row'>
          <div className='col-6'>
            <input
              type='tel'
              name='expiry'
              className='form-control'
              placeholder='Valid Thru'
              pattern='\d\d/\d\d'
              required
              onChange={(e) => setExpiry(e.target.value)}
              onFocus={() => setFocus('expiry')}
            />
          </div>
          <div className='col-6'>
            <input
              type='tel'
              name='cvc'
              className='form-control'
              placeholder='CVC'
              pattern='\d{3,4}'
              required
              onChange={(e) => setCvc(e.target.value)}
              onFocus={() => setFocus('cvc')}
            />
          </div>
        </div>
      </form>
    </div>
  )
}

export default NewCardComponent
