import React, { useState, useRef, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import './Auth.scss'
import Logo from '../../Image/logo1.png'
import Man from '../../Image/man.png'
import Icon1 from '../../Image/Group1.png'
import Icon2 from '../../Image/Group2.png'
import Icon3 from '../../Image/Group3.png'
import Angle from '../../Image/angle.png'
import Loader from '../../Image/loder.gif.gif'

function Login({ notify, Login }) {
  const [shown, setShown] = useState(false)
  const [formLoading, setFormLoading] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm()
  const navigate = useNavigate()
  const password = useRef({})
  password.current = watch('password', '')
  const onRegister = (formData) => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    setFormLoading(true)
    fetch(`${process.env.REACT_APP_API_URL}/accounts/login/`, {
      method: 'post',
      headers: { 'content-Type': 'application/json' },
      body: JSON.stringify({
        ...formData,
      }),
      redirect: 'follow',
    })
      .then((res) => {
        if (res.status === 200) {
          return res.json()
        } else if (res.status === 400) {
          res.json().then((data) => {
            notify('error', data.message)
            setFormLoading(false)
          })
        }
      })
      .catch((err) => {
        notify(
          'error',
          'An error occured. Unable to reach server. Please check your internet connection.'
        )
        setFormLoading(false)
      })
      .then((data) => {
        // setFormLoading(false)
        Login({ ...data })
        sessionStorage.setItem('user', JSON.stringify(data))
        if (!data.email_verified) {
          fetch('https://www.spendwise.ng/api/accounts/verify_email/', {
            method: 'get',
            headers: {
              'content-Type': 'application/json',
              Authorization: `Token ${data.token}`,
            },
          }).then((response) => response.json())
          setTimeout(() => {
            window.location.replace(`/verify-email/`)
          }, 2000)
          notify('warn', `Please verify your email address`)
        } else {
          setTimeout(() => {
            window.location.replace(`/home`)
          }, 2000)
          notify('success', `welcome ${data.user}`)
        }
      })
  }
  useEffect(() => {
    document.title = 'Spendwise | Login'
  }, [])
  return (
    <>
      <section className='auth'>
        <div className='logo'>
          <img src={Logo} alt='' onClick={() => navigate('/')} />
        </div>
        <div className='wrap'>
          <div className='left'>
            <div className='icons'>
              <div className='can'>
                <img src={Icon1} alt='' />
              </div>
              <div className='can edit'>
                <img src={Icon2} alt='' />
              </div>
              <div className='can'>
                <img src={Icon3} alt='' />
              </div>
            </div>
            <div className='man'>
              <img src={Man} alt='' />
            </div>
          </div>
          <div className='right'>
            <div className='angle'>
              <div>
                <img src={Angle} alt='' />
              </div>
            </div>
            <div className='form'>
              <div className='head'>
                <h1>Sign In</h1>
                <p>Welcome Back</p>
              </div>
              <form className='auth-form' onSubmit={handleSubmit(onRegister)}>
                <div className='card'>
                  <p>Username</p>
                  <input
                    type='text'
                    name='username'
                    {...register('username', {
                      required: 'Username is required',
                    })}
                  />
                  {errors.username && (
                    <h6 className='vError'>{errors.username.message}</h6>
                  )}
                </div>
                <div className='card password'>
                  <p>Password</p>
                  <div>
                    <input
                      type={shown ? 'text' : 'password'}
                      name='password'
                      {...register('password', {
                        required: 'Provide a password',
                        minLength: {
                          value: 7,
                          message: 'Password must have at least 8 characters',
                        },
                        pattern: {
                          value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{7,}$/,
                          message:
                            'Passwords have to contain at least one uppercase and one lower case character and a number',
                        },
                      })}
                    />
                    <p onClick={() => setShown(!shown)}>
                      {shown ? <span>hide</span> : <span>show</span>}
                    </p>
                  </div>
                  {errors.password && (
                    <h6 className='vError'>{errors.password.message}</h6>
                  )}
                </div>
                <div className='card check'>
                  <div>
                    <Link to='/forget-username'>Retrive Username</Link>
                  </div>
                  <div>
                    <Link to='/forget-password'>Forgot Password?</Link>
                  </div>
                </div>
                <div className='card'>
                  {formLoading ? (
                    <div className='loaders'>
                      <img src={Loader} alt='' />
                    </div>
                  ) : (
                    <button
                      type='submit'
                      value={formLoading ? '.....' : 'SUBMIT'}
                    >
                      Sign In
                    </button>
                  )}
                </div>
                <div className='card edit'>
                  <p>
                    Don't have an account?{' '}
                    <Link to='/register'>Click here</Link>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Login
